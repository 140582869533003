import {
  Text,
  HStack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import type { OutletProps } from 'react-router-dom'
import { Link, Outlet } from 'react-router-dom'

import type { TabType } from '@app/types'

type Props = {
  outletContext?: OutletProps['context'] | null
  tab?: string
  tabs: Record<string, TabType>
}

const PageTabs: FC<Props> = ({ outletContext = null, tab = '', tabs }) => {
  // split tabs based on whether it has a `collapsed` property set to true

  const uncollapsedTabs = Object.entries(tabs).reduce(
    (acc, [key, tabData]) => {
      if (!tabData.collapsed) {
        acc[key] = tabData
      }
      return acc
    },
    {} as Record<string, TabType>
  )

  const collapsedTabs = Object.entries(tabs).reduce(
    (acc, [key, tabData]) => {
      if (tabData.collapsed) {
        acc[key] = tabData
      }
      return acc
    },
    {} as Record<string, TabType>
  )

  const tabKeys = Object.keys(uncollapsedTabs)
  const tabKeysCollapsed = Object.keys(collapsedTabs)

  let tabIndex = tabKeys.findIndex((e) => tab.match(e))

  tabIndex = tabIndex === -1 ? 0 : tabIndex

  return (
    <Box>
      <Tabs index={tabIndex} isFitted isLazy>
        <TabList>
          {tabKeys.map((tabName) => {
            const tabData = tabs[tabName]
            const { label } = tabData

            return (
              <Tab key={`tabs_${tabName}`} as={Link} preventScrollReset to={tabData.to}>
                <HStack>
                  <Text fontSize="sm">{label || capitalize(tabName)}</Text>
                </HStack>
              </Tab>
            )
          })}
          {tabKeysCollapsed.length > 0 && (
            <Menu>
              <MenuButton key="tabs_more" as={Tab}>
                <Text fontSize="xs">More</Text>
              </MenuButton>
              <MenuList>
                {tabKeysCollapsed.map((tabName) => {
                  const tabData = tabs[tabName]
                  const { label } = tabData

                  return (
                    <MenuItem key={`tabs_${tabName}`} as={Link} to={tabData.to}>
                      {label || capitalize(tabName)}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Menu>
          )}
        </TabList>
        <TabPanels>
          <Outlet context={outletContext} />
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default PageTabs
