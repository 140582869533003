import {
  Avatar,
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Wrap
} from '@chakra-ui/react'
import { url as gravatarUrl } from 'gravatar/lib/gravatar'
import type { FC } from 'react'

import ContributorInviteButton from './contributorInviteButton'

import type { EntityContribution } from '@graphql/queries'

interface Props {
  contributions?: EntityContribution[]
}

const avatarSrc = (contributor, opts = {}) => {
  const gravatarOptions = { d: 404, protocol: 'https', ...opts }

  if (contributor?.email) {
    return gravatarUrl(contributor.email, gravatarOptions)
  }

  return null
}

const contributorSource = (contributor) => {
  if (contributor.sourceName === 'doubleloop') {
    return 'DoubleLoop Member'
  }

  return `via ${contributor.sourceName}`
}

const AvatarList: FC<Props> = ({ contributions = [] }) => (
  <Wrap shouldWrapChildren>
    {contributions.map((contribution) => {
      const { contributor } = contribution

      return (
        <Popover key={contributor.name} isLazy>
          <PopoverTrigger>
            <HStack cursor="pointer">
              <Avatar name={contributor.name} size="xs" src={avatarSrc(contributor, { s: 16 })} />
              <Text mr={2} color="muted" fontSize="sm">
                {contributor.name}
              </Text>
            </HStack>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody p={0}>
              <Center>
                <Box w="full">
                  <Box w="full" h={100} bg="gray.200" />
                  <Flex justify="center" mt={-12}>
                    <Avatar
                      css={{ border: '2px solid white' }}
                      name={contributor.name}
                      size="xl"
                      src={avatarSrc(contributor, { s: 92 })}
                    />
                  </Flex>
                  <Box p={6}>
                    <Stack align="center" mb={5} spacing={0}>
                      <Heading fontFamily="body" fontSize="2xl" fontWeight={500}>
                        {contributor.name}
                      </Heading>
                      <Text color="muted">{contributorSource(contributor)}</Text>
                    </Stack>

                    <ContributorInviteButton contributor={contributor} />
                  </Box>
                </Box>
              </Center>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )
    })}
  </Wrap>
)

export default AvatarList
