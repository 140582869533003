import { Collapse, HStack, Icon, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import MarkdownDisplay from '@app/shared/markdownDisplay'

interface Props {
  sourceSummary?: string
}

const SourceSummary: FC<Props> = ({ sourceSummary }: { sourceSummary: string }) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  if (!sourceSummary) {
    return null
  }

  return (
    <Stack m={2} p={2} borderRadius="sm" bgColor="bg.subtle">
      <Collapse in={show}>
        <MarkdownDisplay text={sourceSummary} />
      </Collapse>
      <HStack cursor="pointer" onClick={handleToggle}>
        <Text fontSize="sm" fontWeight="semibold">
          Show source summary
        </Text>
        <Spacer />
        <Icon as={show ? FiChevronUp : FiChevronDown} w={6} h={6} />
      </HStack>
    </Stack>
  )
}

export default SourceSummary
