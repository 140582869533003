import type { StackProps } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import type { ReactNode } from 'react'

interface PageStackProps extends StackProps {
  children: ReactNode
}

const PageStack = ({ children, ...rest }: PageStackProps) => (
  <Stack py={4} spacing={{ base: '6', md: '8' }} {...rest}>
    {children}
  </Stack>
)

export default PageStack
