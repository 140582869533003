import { Center, Text, VStack } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import type { FC } from 'react'

import Description from './description'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

interface Props extends BoxProps {
  title?: string
  description?: string
  children?: React.ReactNode
}

// This probably should be themed with variants
const EmptyState: FC<Props> = ({ title = 'No results', description = null, children = null, ...rest }) => {
  const { user } = useStoreCurrentUser()

  return (
    <Center
      w="100%"
      minH="36"
      m={4}
      px={6}
      py={8}
      border="2px"
      borderStyle="dashed"
      borderColor="gray.200"
      borderRadius="md"
      {...rest}
    >
      <VStack spacing={4}>
        <Text>{title}</Text>
        <Description text={description} />
        {user && children}
      </VStack>
    </Center>
  )
}

export default EmptyState
