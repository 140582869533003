import { Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { BsFiletypeCsv } from 'react-icons/bs'
import { HiOutlineLockClosed } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import GoogleSheetsMenuGroup from '@app/pages/metrics/components/googleSheetsMenuGroup'
import MetricCSVModal from '@app/pages/metrics/components/metricCSVModal'

const ListActionsMenu = () => {
  const googleSheet = useGetObjectsByProperties('googleSheet', { organizationLevel: true })?.[0]

  return (
    <Menu>
      <MenuButton as={Button}>...</MenuButton>
      <MenuList>
        <MetricCSVModal OpenerComponent={MenuItem} icon={<BsFiletypeCsv />} variant="ghost" />
        <MenuDivider />
        <MenuItem as={Link} icon={<HiOutlineLockClosed />} to="credentials">
          Credentials
        </MenuItem>
        <MenuDivider />
        <GoogleSheetsMenuGroup googleSheet={googleSheet} />
      </MenuList>
    </Menu>
  )
}

export default ListActionsMenu
