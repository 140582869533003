import type { StackDirection } from '@chakra-ui/react'
import { FormControl, FormLabel, Link, Spacer, Stack, Switch, useBoolean } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  name: string
  labelSize?: string
  stackDirection?: StackDirection
}

const TermsAndConditionsToggle: FC<Props> = ({ name, labelSize = 'md', stackDirection = 'row' as const, ...rest }) => {
  const [isChecked, setIsChecked] = useBoolean(false)

  return (
    <FormControl isRequired>
      <Stack direction={stackDirection}>
        <FormLabel mb="0" fontSize={labelSize} htmlFor={name}>
          I agree to DoubleLoop&apos;s{' '}
          <Link color="link" href="https://doubleloop.app/utility-pages/terms-of-service" isExternal>
            terms and conditions
          </Link>
        </FormLabel>
        <Spacer />
        <Switch
          id={name}
          isChecked={isChecked}
          name={name}
          onChange={setIsChecked.toggle}
          value={isChecked.toString()}
          {...rest}
        />
      </Stack>
    </FormControl>
  )
}

export default TermsAndConditionsToggle
