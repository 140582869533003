import { Box, Divider, HStack, Spacer, Stack, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'

import SourceSummary from './sourceSummary'

import type useGetObject from '@app/hooks/useGetObject'
import RichTextInput from '@app/next/forms/inline/richTextInput'
import RichTextInputWithLabel from '@app/next/forms/inline/richTextInputWithLabel'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import DrawerHeaderActions from '@app/pages/maps/components/drawer/drawerHeaderActions'
import CardTitle from '@app/shared/cards/components/cardTitle'
import { DomainObjectType } from '@app/shared/cards/components/cardTypeSection'
import Source from '@app/shared/entity/source'
import PageHeaderSection from '@app/shared/layout/pageHeaderSection'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'

interface Props {
  entity: ReturnType<typeof useGetObject<'entity'>>
}

const Header: FC<Props> = ({ entity }) => {
  const { sourceSummary } = entity

  return (
    <PageHeaderSection>
      <Stack>
        <HStack pl={4}>
          <DomainObjectType title="Work" icon={ICON_MAP.Work} color={COLOR_MAP.Entity} />
          <Spacer />
          <DrawerHeaderActions objId={entity.id} objType={entity.classType} />
        </HStack>
        <Box pl={4}>
          <Source entity={entity} asButton={false} />
        </Box>
        <Divider />
      </Stack>
      <Stack px={4} spacing={4}>
        <CardTitle domainObject={entity} name="name" fontSize={useBreakpointValue({ base: 'lg', md: 'xl' })} />
        <ViewerFieldWrapper value={entity.summary}>
          <RichTextInput domainObject={entity} name="summary" placeholder="Description..." />
        </ViewerFieldWrapper>
        {sourceSummary && <SourceSummary sourceSummary={sourceSummary} />}
        <ViewerFieldWrapper value={entity.goalHypothesis}>
          <RichTextInputWithLabel
            domainObject={entity}
            name="goalHypothesis"
            placeholder="Goal / Hypothesis..."
            label="Goal / Hypothesis"
          />
        </ViewerFieldWrapper>
        <ViewerFieldWrapper value={entity.results}>
          <RichTextInputWithLabel domainObject={entity} name="results" placeholder="Results..." label="Results" />
        </ViewerFieldWrapper>
      </Stack>
    </PageHeaderSection>
  )
}

export default Header
