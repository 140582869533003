import { Box, Button, HStack, Spacer, Text, useClipboard } from '@chakra-ui/react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

const APIToken = () => {
  const { user } = useStoreCurrentUser()
  const { organization } = user
  const { apiToken } = organization

  const { hasCopied, onCopy } = useClipboard(apiToken)

  return (
    <Box p={4} border="1px" borderColor="bg.muted" wordBreak="break-all">
      <HStack>
        <Text>{apiToken}</Text>
        <Spacer />
        <Box>
          <Button onClick={onCopy}>{hasCopied ? 'Copied!' : 'Copy to Clipboard'}</Button>
        </Box>
      </HStack>
    </Box>
  )
}

export default APIToken
