import {
  useColorModeValue,
  Table as ChakraTable,
  Tbody as ChakraTbody,
  Thead as ChakraThead,
  Tr as ChakraTr,
  Td as ChakraTd,
  Th as ChakraTh,
  ListItem as ChakraLi,
  OrderedList as ChakraOl,
  UnorderedList as ChakraUl,
  Link,
  Icon,
  Text
} from '@chakra-ui/react'
import type { FC, LegacyRef, ReactElement } from 'react'
import { memo } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import type { Options } from 'react-markdown'
import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { gruvboxLight as light, gruvboxDark as dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import remarkImages from 'remark-images'
import remarkMath from 'remark-math'

import CopyButton from '@app/pages/maps/components/aiChat/components/copyButton'
import type { MapDomainMessage } from '@app/types'

import 'katex/dist/katex.css'

const Code: Options['components']['code'] = ({ children, className, node: _, ref, ...rest }) => {
  const style = useColorModeValue(light, dark)
  const match = /language-(\w+)/.exec(className || '')

  return match ? (
    <SyntaxHighlighter
      {...rest}
      PreTag="div"
      language={match[1]}
      style={style}
      wrapLongLines
      ref={ref as LegacyRef<SyntaxHighlighter>}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code {...rest} className={className}>
      {children}
    </code>
  )
}

const Pre: Options['components']['pre'] = ({ children, ...rest }) => {
  const content = (children as ReactElement)?.props?.children

  return (
    <pre style={{ position: 'relative', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} {...rest}>
      {content && <CopyButton position="absolute" right="0" content={content} />}
      {children}
    </pre>
  )
}

const ExternalLink: Options['components']['a'] = ({ children, ...rest }) => (
  <Link fontWeight="bolder" isExternal {...rest}>
    {children} <Icon as={FiExternalLink} mx="2px" />
  </Link>
)

const Paragraph: Options['components']['p'] = ({ children, ...rest }) => (
  <Text as="p" whiteSpace="pre-wrap" wordBreak="break-word" {...rest} my={1}>
    {children}
  </Text>
)

const Table: Options['components']['table'] = ({ children, ...rest }) => (
  <ChakraTable whiteSpace="pre-wrap" wordBreak="break-word" {...rest} my={4}>
    {children}
  </ChakraTable>
)

const Td: Options['components']['td'] = ({ children, ...rest }) => (
  <ChakraTd whiteSpace="pre-wrap" wordBreak="normal" {...rest}>
    {children}
  </ChakraTd>
)

const COMPONENTS = {
  a: ExternalLink,
  code: Code,
  p: Paragraph,
  pre: Pre,

  table: Table,
  thead: ChakraThead,
  tbody: ChakraTbody,
  th: ChakraTh,
  tr: ChakraTr,
  td: Td,

  ol: ChakraOl,
  ul: ChakraUl,
  li: ChakraLi
}

type Props = {
  message: Pick<MapDomainMessage, 'id' | 'content'>
}

const MessageContent: FC<Props> = ({ message }) => {
  // Replace \[ with $$ and \] with $$ to ensure compatibility
  const processedText = message.content
    .replace(/\\\[/g, '$$$') // Replace all occurrences of \[ with $$
    .replace(/\\\]/g, '$$$') // Replace all occurrences of \] with $$
    .replace(/\\\(/g, '$$$') // Replace all occurrences of \( with $$
    .replace(/\\\)/g, '$$$') // Replace all occurrences of \) with $$

  return (
    <Markdown
      remarkPlugins={[remarkGfm, remarkImages, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={COMPONENTS}
    >
      {processedText}
    </Markdown>
  )
}

export default memo(MessageContent)
