import type { TagProps } from '@chakra-ui/react'
import { HStack, Icon, Tag, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiCaretDown } from 'react-icons/pi'

interface Props extends TagProps {
  rating: string
  color?: string
  tagSize?: string
  showChevron?: boolean
  tooltip?: string
}

const ConfidenceRatingTag: FC<Props> = ({
  rating,
  fontSize = 'sm',
  tagSize = 'sm',
  color = 'gray',
  showChevron = false,
  tooltip = '',
  ...rest
}) => (
  <Tooltip label={tooltip}>
    <Tag
      fontSize={fontSize}
      whiteSpace="nowrap"
      colorScheme={color}
      size={tagSize}
      variant="solid"
      {...rest}
      _hover={{ cursor: 'pointer' }}
    >
      <HStack>
        <span>{rating}</span>
        {showChevron && <Icon as={PiCaretDown} />}
      </HStack>
    </Tag>
  </Tooltip>
)

export default ConfidenceRatingTag
