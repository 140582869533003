import { FormControl, Select } from '@chakra-ui/react'
import type { SelectProps } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useFetcher, useSearchParams } from 'react-router-dom'

import type { StrategyFilterSelectOptionsQuery } from '@graphql/queries'

interface Props extends Omit<SelectProps, 'children'> {
  field: string
}

const MapFilterSelectInput: FC<Props> = ({ field, ...selectProps }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fetcher = useFetcher()

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/strategy/strategyFilterOptions')
    }
  }, [fetcher])

  const strategies: StrategyFilterSelectOptionsQuery['strategies']['collection'] = fetcher.data?.strategies

  if (!strategies || strategies.length === 0) {
    return null
  }

  const updateSearchParams = debounce((value) => {
    if (value) {
      searchParams.set(field, value)
    } else {
      searchParams.delete(field)
    }

    if (searchParams.get('page')) {
      searchParams.delete('page')
    }

    setSearchParams(searchParams)
  }, 100)

  const onChange = ({ target: { value } }) => {
    updateSearchParams(value)
  }

  return (
    <FormControl>
      <Select maxW="md" data-cy="map-filter-select" onChange={onChange} size="sm" {...selectProps}>
        <option value="">Select map</option>
        <option value="none">Not on any maps</option>
        {strategies.map((strategy) => (
          <option key={strategy.id} value={strategy.id}>
            {strategy.name}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default MapFilterSelectInput
