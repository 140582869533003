import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import EmailForm from './components/emailForm'

import SessionHeader from '@app/pages/sessions/components/sessionHeader'

const Email: FC = () => (
  <Stack w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} spacing="8">
    <SessionHeader title="Verify your email" />
    <EmailForm />
  </Stack>
)

export default Email
