import type { InputProps } from '@chakra-ui/react'
import type { FC } from 'react'

import useInlineEditorProps from '@app/hooks/useInlineEditorProps'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import RawTextInput from '@app/next/forms/textInput'
import type { MapDomainObject } from '@app/types'

const URI_SCHEMES = ['http', 'https', 'ftp', 'mailto', 'file', 'data', 'irc']

interface Props extends InputProps {
  domainObject: MapDomainObject
  name: string
  domainObjectUpdateId?: string
}

const TextInput: FC<Props> = ({ domainObject, name, defaultValue, domainObjectUpdateId = 'id', ...rest }) => {
  const { onChange, onBlur } = useInlineEditorProps(domainObject, domainObjectUpdateId)
  const { user } = useStoreCurrentUser()
  const editor = ['admin', 'editor'].includes(user?.role)

  const inlineStyles = {
    width: '100%',
    _disabled: {
      cursor: 'default'
    }
  }

  const valueIncludesScheme = (value: string) => URI_SCHEMES.some((scheme) => value?.includes(scheme))

  // In case of URL, add https:// if not present right before sending the onBlur event
  const handleBlur = (e) => {
    if (rest?.type === 'url' && e.target.value && !valueIncludesScheme(e.target.value)) {
      e.target.value = `https://${e.target.value}`
    }

    onBlur(e)
  }

  return (
    <RawTextInput
      name={name}
      value={defaultValue}
      variant="unstyled"
      onChange={onChange}
      onBlur={handleBlur}
      data-1p-ignore
      isDisabled={!editor}
      {...inlineStyles}
      {...rest}
    />
  )
}

export default TextInput
