import type { AvatarProps } from '@chakra-ui/react'
import { Avatar, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props extends AvatarProps {
  name: string
}

const TooltipAvatar: FC<Props> = ({ name, ...props }) => (
  <Tooltip hasArrow label={name}>
    <Avatar name={name} {...props} />
  </Tooltip>
)

export default TooltipAvatar
