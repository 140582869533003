import { useColorModeValue } from '@chakra-ui/react'
import type { UppyEventMap, UppyFile, UppyOptions } from '@uppy/core'
import Uppy from '@uppy/core'
import { Dashboard, FileInput } from '@uppy/react'
import XHR from '@uppy/xhr-upload'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/file-input/dist/style.css'
import type { FC } from 'react'

import type { FileTicketMeta } from '@app/shared/uploader/generateUploaderTicketPlugin'
import GenerateUploaderTicketPlugin from '@app/shared/uploader/generateUploaderTicketPlugin'
import { useUploadTicketGenerateMutation } from '@graphql/queries'

// This is the shape the doubleloop-uploader returns (i.e. it just returns the remote response body)
export type RemoteFile = {
  id: string // This is the REMOTE id, not our DB id
  filename: string
  bytes: number
  status: string
}

type Props = {
  uppyOptions?: UppyOptions
  onUploadSuccess?: UppyEventMap<FileTicketMeta>['upload-success']
  onComplete?: UppyEventMap<FileTicketMeta>['complete']
  fileInput?: boolean
  inputName?: string
}

const ENDPOINT = import.meta.env.PUBLIC_UPLOADER_ENDPOINT

const DoubleloopUploader: FC<Props> = ({
  uppyOptions = {},
  onUploadSuccess = () => {},
  onComplete = () => {},
  fileInput = false,
  inputName = undefined
}) => {
  const [, getTicket] = useUploadTicketGenerateMutation()
  const theme = useColorModeValue('light', 'dark')

  const uppy = new Uppy<FileTicketMeta, RemoteFile>(uppyOptions)

  uppy.use(GenerateUploaderTicketPlugin, { getTicket })

  uppy.use(XHR, {
    endpoint: ENDPOINT,
    formData: false,
    headers: (file: UppyFile<FileTicketMeta, RemoteFile>) => ({
      'Content-Type': file.type,
      'X-Filename': file.name,
      'X-Ticket': file.meta.ticket
    })
  })

  uppy.on('upload-success', onUploadSuccess)
  uppy.on('complete', onComplete)

  if (fileInput) {
    return <FileInput uppy={uppy} inputName={inputName} />
  }

  return <Dashboard uppy={uppy} theme={theme} />
}

export default DoubleloopUploader
