import { Alert, Stack, Text } from '@chakra-ui/react'

const WhatIsAPlaybook = () => (
  <Alert status="info">
    <Stack>
      <Text fontSize="sm" fontWeight="semibold" fontStyle="italic">
        What is a DoubleLoop playbook?
      </Text>
      <Text fontSize="sm">
        Playbooks in DoubleLoop are more than flowchart templates. With DoubleLoop, you can connect each node to your
        live data sources and workflow tools. The result is a revolutionary way for everyone on your team to understand
        the business impact of their work, stay aligned, and test their assumptions with real data.
      </Text>
    </Stack>
  </Alert>
)

export default WhatIsAPlaybook
