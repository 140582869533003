import type { FormLabelProps, InputProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import isEmpty from 'lodash/isEmpty'
import type { ChangeEvent, FC, ReactNode } from 'react'
import { useState, useRef, useEffect, useCallback } from 'react'

import { SELECTED_SLACK_CHANNEL_COOKIE } from '@app/lib/globals'
import type { Errors } from '@app/shared/rawForms/useForm'
import SlackChannelAutocomplete from '@app/shared/slack/slackChannelAutocomplete'
import type { SlackChannel } from '@graphql/types'

type Props = Omit<InputProps, 'defaultValue' | 'name'> & {
  name: string | null
  label?: ReactNode | null
  labelSize?: Pick<FormLabelProps, 'fontSize'>['fontSize']
  helperText?: ReactNode | null
  errors?: Errors
  prepopulateFromCookie?: boolean
  defaultValue?: Pick<SlackChannel, 'value' | 'label'>
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onSelect?: (channel: SlackChannel) => void
}

const SlackAutocompleteInput: FC<Props> = ({
  name,
  label = null,
  labelSize = 'md',
  placeholder = '',
  isRequired = false,
  helperText = null,
  errors = {},
  prepopulateFromCookie = false,
  defaultValue = null,
  onChange = () => {},
  ...rest
}: Props) => {
  const cookiesApi = Cookies.withAttributes({ sameSite: 'lax' })
  const ref = useRef()

  let initialValue = null

  if (defaultValue) {
    initialValue = defaultValue
  }

  if (prepopulateFromCookie && isEmpty(initialValue)) {
    const cookie = cookiesApi.get(SELECTED_SLACK_CHANNEL_COOKIE)
    if (cookie) {
      try {
        initialValue = JSON.parse(cookie)
      } catch (_e) {
        // ignore parse errors, and leave initialValue empty
      }
    }
  }

  const [slackChannelId, setSlackChannelId] = useState(initialValue?.value || '')

  const handleChange = (channel) => {
    setSlackChannelId(channel?.value || '')
    cookiesApi.set(SELECTED_SLACK_CHANNEL_COOKIE, JSON.stringify(channel))
  }

  const fireEvent: EventListener = useCallback(
    (event) => {
      const e = event as unknown as ChangeEvent<HTMLInputElement>
      onChange(e)
    },
    [onChange]
  )

  useEffect(() => {
    const input = ref.current as HTMLInputElement

    input.addEventListener('slackChange', fireEvent)

    return () => {
      input.removeEventListener('slackChange', fireEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const input = ref.current as HTMLInputElement
    const event = new CustomEvent('slackChange', {
      bubbles: true,
      composed: true,
      detail: { channelId: slackChannelId }
    })

    if (input) {
      input.value = slackChannelId
      input.dispatchEvent(event)
    }
  }, [slackChannelId])

  return (
    <FormControl id={name} isInvalid={!!errors[name]} isRequired={isRequired}>
      <VisuallyHiddenInput ref={ref} id={name} name={name} onChange={onChange} value={slackChannelId} />
      <Stack>
        {label && (
          <FormLabel fontSize={labelSize} htmlFor={`${name}-slack-autocomplete`}>
            {label}
          </FormLabel>
        )}
        <Stack w="100%">
          <SlackChannelAutocomplete
            id={`${name}-slack-autocomplete`}
            name="" // this can go away once all forms are converted to react-router-dom by setting the default name
            onChange={handleChange}
            inputRef={ref}
            placeholder={placeholder}
            defaultValue={initialValue}
            {...rest}
          />
          <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      </Stack>
    </FormControl>
  )
}

export default SlackAutocompleteInput
