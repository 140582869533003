import type { ComponentStyleConfig } from '@chakra-ui/react'

const MapShell: ComponentStyleConfig = {
  parts: ['outerShell', 'innerShell', 'mainContainer', 'sidebar', 'mobileTopBar', 'sidebarLink', 'sidebarIcon'],
  baseStyle: () => ({
    outerShell: {
      height: '100vh',
      flexDirection: 'column',
      display: 'flex',
      overflow: 'hidden'
    },
    innerShell: {
      flex: '1',
      overflow: 'hidden',
      maxW: '100%'
    },
    mainContainer: {
      flex: '1',
      overflowY: 'auto',
      overflowX: 'hidden',
      flexDirection: 'column'
    }
  })
}

export default MapShell
