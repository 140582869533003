import { createStandaloneToast } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import { defer, type LoaderFunction, Navigate, Outlet, type RouteObject } from 'react-router-dom'

import { CARD_FILTER_SIGIL } from '@app/lib/globals'
import AddBasicCardDrawer from '@app/pages/maps/components/addCards/addBasicCardDrawer'
import AddCardDrawer from '@app/pages/maps/components/addCards/addCardDrawer'
import AddMetricCardDrawer from '@app/pages/maps/components/addCards/addMetricCardDrawer'
import AddPlaybookDrawer from '@app/pages/maps/components/addPlaybooks/addPlaybookDrawer'
import EdgeDrawer from '@app/pages/maps/components/edgeDrawer/edgeDrawer'
import MapEducationDrawer from '@app/pages/maps/components/learn/mapEducationDrawer'
import PreviewModal from '@app/pages/maps/components/map/previewModal'
import StrategySettings from '@app/pages/maps/components/settings/strategySettings'
import StrategyDrawer from '@app/pages/maps/components/strategyDrawer'
import List from '@app/pages/maps/list'
import Map from '@app/pages/maps/map'
import Show from '@app/pages/maps/show'
import AiAssistantRoutes from '@app/routes/aiAssistants'
import { basicCardShowTabRoutes } from '@app/routes/basicCards'
import ChangelogRoutes from '@app/routes/changelogs'
import { entityShowTabRoutes, workShowTabRoutes } from '@app/routes/events'
import { mapRoutes as KeyResultRoutes } from '@app/routes/goals'
import queryExistingObjects from '@app/routes/lib/queryExistingObjects'
import { metricShowTabRoutes } from '@app/routes/metrics'
import ReportRoutes from '@app/routes/reports'
import ErrorPage from '@app/shared/errorPage'
import extractOrderings from '@app/shared/utils/extractOrderings'
import { useStore } from '@app/store'
import unpack from '@app/store/unpack'
import { requiresAuthorization } from '@app/utils/auth'
import { CardTypes } from '@graphql/documents/card_types.graphql'
import { Sources } from '@graphql/documents/integration.graphql'
import { Playbooks } from '@graphql/documents/playbook.graphql'
import {
  MapClone,
  Strategies,
  Strategy,
  StrategyFilterSelectOptions,
  StrategyReviewSend
} from '@graphql/documents/strategy.graphql'
import type { StrategiesQuery, StrategiesQueryVariables, StrategyQuery, StrategyQueryVariables } from '@graphql/queries'
import type { NodeFiltersInput } from '@graphql/types'

const extractCardFilters = (searchParams: URLSearchParams): NodeFiltersInput => ({
  name: searchParams.get(`${CARD_FILTER_SIGIL}name`),
  labels: searchParams.getAll(`${CARD_FILTER_SIGIL}labels`)
})

const { toast } = createStandaloneToast()

const setupMapData = (strategy) => {
  const { bulkAdd, setFilteredNodeIds } = useStore.getState()

  if (!strategy) {
    throw { statusText: 'Not found', status: 404, internal: false, data: null }
  }

  const combinedArray = unpack(strategy)
  bulkAdd(combinedArray)

  const filteredNodeIds = strategy.nodes?.map((node) => node.id)
  setFilteredNodeIds(() => filteredNodeIds)
}

const loadMap: LoaderFunction = async ({ params, request }) => {
  const { strategyId } = params
  const { loaderQuery } = useStore.getState()

  // Do not `await` this. The result will be handled by the exchange.
  // TODO: put this in an organization-level loader?
  loaderQuery(CardTypes)

  const url = new URL(request.url)
  const filters = extractCardFilters(url.searchParams)

  const resp = await loaderQuery<StrategyQuery, StrategyQueryVariables>(Strategy, { id: strategyId, filters })
  const strategy = resp?.data?.strategy

  setupMapData(strategy)

  ahoy.track('strategy:loaded', {
    strategy_id: strategy.id
  })

  return { strategy }
}

const loadEmbeddedMap = async ({ params }) => {
  const { strategyId } = params
  const { loaderQuery } = useStore.getState()

  loaderQuery(CardTypes)

  const resp = await loaderQuery<StrategyQuery, StrategyQueryVariables>(Strategy, {
    id: strategyId,
    loadAsAnonymous: true
  })
  const strategy = resp?.data?.strategy

  setupMapData(strategy)

  ahoy.track('strategy:embed:loaded', {
    strategy_id: strategy.id
  })

  return { strategy }
}

const loadMaps = async ({ request }) => {
  const url = new URL(request.url)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null
  const filter = url.searchParams.get('filter')
  const order = extractOrderings(url.searchParams)
  const { loaderQuery } = useStore.getState()

  const variables: StrategiesQueryVariables = omitBy({ page, limit, filter, order }, isNull)

  const result = await loaderQuery<StrategiesQuery, StrategiesQueryVariables>(Strategies, variables)

  const { addObjectPage } = useStore.getState()

  const { collection = [], metadata } = result.data.strategies
  addObjectPage('strategy', collection, metadata)

  return defer(result.data)
}

const sendStrategyReview = async (params) => {
  const { strategyId } = params
  const { actionMutation } = useStore.getState()

  return actionMutation(StrategyReviewSend, { strategyId }).then((resp) => {
    const errors = resp?.data?.strategyReviewSend?.errors

    if (errors?.length > 0) {
      toast({
        title: 'Error sending review',
        description: errors[0].message,
        position: 'bottom-right',
        status: 'error'
      })
    } else {
      toast({
        title: 'Review sent',
        position: 'bottom-right',
        status: 'success'
      })
    }

    return resp?.data?.strategyReviewSend?.success
  })
}

const cloneMap = async (request, strategyId) => {
  const formData = await request.formData()
  const input = { ...Object.fromEntries(formData.entries()), strategyId }
  const { actionMutation } = useStore.getState()

  return actionMutation(MapClone, input).then((resp) => resp?.data?.mapClone?.strategy || null)
}

const loadIntegrationInfo = async () => {
  const { loaderQuery } = useStore.getState()
  const IntegrationInfoPromise = loaderQuery(Sources)

  return defer({ integrationInfo: IntegrationInfoPromise })
}

const loadStrategyFilterOptions: LoaderFunction = async () => {
  const { loaderQuery } = useStore.getState()
  const result = await loaderQuery(StrategyFilterSelectOptions)

  const strategies = result?.data?.strategies?.collection

  return defer({ strategies })
}

const loadPlaybooks = async () => {
  await requiresAuthorization('update', 'strategy')
  const { loaderQuery } = useStore.getState()
  const result = await loaderQuery(Playbooks)

  return defer({ playbooks: result.data.playbooks })
}

const routes: RouteObject = {
  path: 'strategy',
  element: <Outlet />,
  children: [
    {
      index: true,
      loader: loadMaps,
      element: <List />,
      handle: {
        headData: () => ({ title: 'All maps' })
      }
    },
    {
      path: 'strategyFilterOptions',
      loader: loadStrategyFilterOptions,
      element: <Outlet />
    },
    {
      path: 'embed/:strategyId',
      element: <Show embedded />,
      errorElement: <ErrorPage isEmbedded />,
      loader: loadEmbeddedMap,
      children: [
        {
          index: true,
          element: <Navigate to="map" replace />
        },
        {
          path: 'map',
          element: <Map />
        }
      ]
    },
    {
      path: ':strategyId',
      element: <Show />,
      loader: loadMap,
      children: [
        {
          index: true,
          element: <Navigate to="map" replace />
        },
        {
          path: 'map',
          element: <Map />,
          children: [
            ReportRoutes,
            {
              path: 'edges/:edgeId',
              element: <EdgeDrawer />
            },
            {
              path: 'settings',
              element: <StrategySettings />
            },
            {
              path: 'learn',
              element: <MapEducationDrawer />
            },
            {
              path: 'add',
              action: ({ request }) => {
                switch (request.method) {
                  case 'POST':
                    return queryExistingObjects(request)
                  default:
                    return null
                }
              },
              children: [
                {
                  path: 'playbook',
                  loader: loadPlaybooks,
                  element: <AddPlaybookDrawer />
                },
                {
                  path: 'basicCard/:cardTypeId?',
                  loader: loadIntegrationInfo,
                  element: <AddBasicCardDrawer />
                },
                {
                  path: 'metric',
                  loader: loadIntegrationInfo,
                  element: <AddMetricCardDrawer />
                },
                {
                  path: ':type',
                  loader: loadIntegrationInfo,
                  element: <AddCardDrawer />
                }
              ]
            },
            {
              path: 'metric',
              loader: () => ({
                nodeType: 'metric'
              }),
              element: <StrategyDrawer />,
              children: metricShowTabRoutes('strategy')
            },
            {
              path: 'entity',
              loader: () => ({
                nodeType: 'entity'
              }),
              element: <StrategyDrawer />,
              children: entityShowTabRoutes('strategy')
            },
            {
              path: 'work',
              loader: () => ({
                nodeType: 'work'
              }),
              element: <StrategyDrawer />,
              children: workShowTabRoutes('strategy')
            },
            {
              path: 'basicCard',
              loader: () => ({
                nodeType: 'basicCard'
              }),
              element: <StrategyDrawer />,
              children: basicCardShowTabRoutes('strategy')
            },
            AiAssistantRoutes,
            {
              path: 'send-review',
              action: ({ request, params }) => {
                switch (request.method) {
                  case 'POST':
                    return sendStrategyReview(params)
                  default:
                    return Promise.resolve()
                }
              }
            },

            {
              path: 'preview/:submapId',
              element: <PreviewModal />
            }
          ]
        },
        {
          path: 'clone',
          action: ({ request, params }) => {
            const { strategyId } = params

            switch (request.method) {
              case 'POST':
                return cloneMap(request, strategyId)
              default:
                return Promise.resolve()
            }
          }
        },
        ChangelogRoutes,
        ReportRoutes,
        KeyResultRoutes
      ]
    }
  ]
}

export { routes as MapRoutes }

export default routes
