import {
  Box,
  Button,
  Center,
  Checkbox,
  HStack,
  Link as ChakraLink,
  Stack,
  Text,
  useCheckboxGroup
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import MetricDataPoints from './components/columns/metricDataPoints'
import MetricName from './components/columns/metricName'
import MetricSourceName from './components/columns/metricSourceName'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import ListActionsMenu from '@app/pages/metrics/components/listActionsMenu'
import MetricListActions from '@app/pages/metrics/components/metricListActions'
import MapsCell from '@app/pages/shared/mapsCell'
import MultiOps from '@app/pages/shared/multiOps'
import SearchRow from '@app/pages/shared/searchRow'
import SelectAllCheckboxHeader from '@app/pages/shared/selectAllCheckboxHeader'
import useGenerateSelectableTableRowFormatter from '@app/pages/shared/useGenerateSelectableTableRowFormatter'
import Can from '@app/shared/authorization/can'
import EmptyState from '@app/shared/emptyState/emptyState'
import MapFilterSelectInput from '@app/shared/forms/mapFilterSelectInput'
import { Card, PageHeader, PageStack } from '@app/shared/layout'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider } from '@app/shared/table/contexts/sortHeaderContext'
import type { Column } from '@app/shared/table/types'
import withAwait from '@app/shared/withAwait'
import type { MapDomainMetric } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'
import type { Metric } from '@graphql/types'

type MakeColumns = (args: {
  getCheckboxProps: ReturnType<typeof useCheckboxGroup>['getCheckboxProps']
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
}) => Column<Metric>[]

const makeColumns: MakeColumns = ({ getCheckboxProps, selected, setSelected }) => [
  {
    header: <SelectAllCheckboxHeader collectionType="metric" selected={selected} setSelected={setSelected} />,
    width: '5%',
    key: 'checkbox',
    cell: ({ data: metric }) => (
      <Center>
        <Checkbox {...getCheckboxProps({ value: metric.id })} />
      </Center>
    )
  },
  {
    header: <SortHeader field="name" title="Name" emptyState />,
    key: 'name',
    width: '40%',
    cell: MetricName
  },
  {
    header: <SortHeader field="metricDataPointsCount" title="Data Points" emptyState />,
    key: 'metricDataPointsCount',
    cell: MetricDataPoints
  },
  {
    header: <SortHeader field="sourceName" title="Source Name" emptyState />,
    key: 'sourceName',
    cell: MetricSourceName
  },
  {
    header: 'Maps',
    key: 'maps',
    cell: MapsCell<Metric>
  },
  {
    header: 'Labels',
    key: 'labels',
    cell: ({ data }) => <Text whiteSpace="normal">{data?.labels?.map((label) => label.name)?.join(', ')}</Text>
  },
  {
    header: '',
    cell: MetricListActions
  }
]

const Empty = ({ strategyId }) => (
  <Box p={2}>
    <SearchRow
      leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
      page={0}
      limitValue={0}
      totalCount={0}
    />
    <EmptyState title="What's the most important measurement you can think of for your work?" w="auto">
      <Button as={Link} to="new">
        Track it
      </Button>
    </EmptyState>
  </Box>
)

const Populated = ({ strategyId, collection, metadata }) => {
  const { page, totalCount } = metadata
  const {
    value: selected,
    getCheckboxProps,
    setValue: setSelected
  } = useCheckboxGroup({
    defaultValue: []
  })
  const columns = useMemo(
    () => makeColumns({ getCheckboxProps, selected, setSelected }),
    [getCheckboxProps, selected, setSelected]
  )
  const onBulkDelete = useCallback(() => {
    setSelected([])
  }, [setSelected])
  const baseRowProps = useGenerateSelectableTableRowFormatter<MapDomainMetric>('metric', selected, setSelected)
  const rowFormatter = useCallback(
    (metric: Metric) => {
      if (metric.metricSourceErrored) {
        return {
          ...baseRowProps(metric),
          bg: 'bg.subtle'
        }
      }

      return baseRowProps(metric)
    },
    [baseRowProps]
  )

  return (
    <Stack spacing="0">
      <SearchRow
        leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
        leftSummarySibling={<MultiOps type="metric" selected={selected} fontSize="sm" onDelete={onBulkDelete} />}
        page={page}
        limitValue={collection.length}
        totalCount={totalCount}
      />
      <Box overflow="auto">
        <SortHeaderContextProvider defaultState={{ name: 'asc' }}>
          <Table<(typeof collection)[0]> columns={columns} data={collection} rowFormatter={rowFormatter} />
        </SortHeaderContextProvider>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Box>
    </Stack>
  )
}

const List: FC = () => {
  const { collection, metadata } = useGetObjectPage('metric')

  const [searchParams] = useSearchParams()
  const strategyId = searchParams.get('strategyId')

  const subtitle = (
    <>
      Track the leading and lagging indicators that drive your business.{' '}
      <ChakraLink color="link" href={Routes.docsUsingMetrics} rel="noopener noreferrer" target="_blank">
        Learn more.
      </ChakraLink>
    </>
  )

  return (
    <PageStack>
      <PageHeader title="Metrics" subtitle={subtitle}>
        <Can I="create" a="metric">
          <HStack>
            <Button as={Link} to="new" variant="primary">
              Create Metric
            </Button>
            <ListActionsMenu />
          </HStack>
        </Can>
      </PageHeader>
      <Card p={0}>
        {collection.length ? (
          <Populated strategyId={strategyId} collection={collection} metadata={metadata} />
        ) : (
          <Empty strategyId={strategyId} />
        )}
      </Card>
    </PageStack>
  )
}

export default withAwait(List, 'metrics')
