import { Box, Icon, Link as ChakraLink, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiExternalLink } from 'react-icons/fi'

interface Props {
  title?: string
  url?: string
}

const ExternalLink: FC<Props> = ({ title = 'Where to see it', url = null }) => {
  if (!url) {
    return null
  }

  const displayTitle = title || 'Where to see it'

  return (
    <Box pos="relative" p={4} borderWidth={1} borderColor="link" borderRadius={3} bgColor="white">
      <Stack direction="column">
        <Text color="muted" size="sm">
          {displayTitle}
        </Text>
        <ChakraLink href={url} isExternal>
          <Text color="link">{url}</Text>
        </ChakraLink>
      </Stack>
      <Spacer />
      <Box pos="absolute" top={4} right={4}>
        <ChakraLink href={url} isExternal>
          <Icon as={FiExternalLink} color="link" />
        </ChakraLink>
      </Box>
    </Box>
  )
}

export default ExternalLink
