import { HStack, Progress, Stack, Text, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'

import { getProgressColor } from '@app/pages/goals/goalHelpers'
import type { MapDomainMetric } from '@app/types'
import { formatTotal } from '@app/utils/metricHelpers'
import type { Goal } from '@graphql/types'

interface Props {
  metric: MapDomainMetric
  goal: Goal
  fontSize?: string
  display?: 'card' | 'standalone'
}

const GoalProgress: FC<Props> = ({ metric, goal, fontSize = 'md', display = 'card' }) => (
  <Stack key={goal.id} pb={1}>
    <HStack spacing={2}>
      {display === 'card' ? (
        <Text fontSize={fontSize}>{formatTotal(goal.targetValue, metric.displayFormat, metric)}</Text>
      ) : (
        <Text>Time range:</Text>
      )}
      {display === 'card' ? <Text>•</Text> : null}
      <Text fontSize={fontSize}>{goal.label}</Text>
    </HStack>
    <Tooltip
      aria-label="Progress"
      hasArrow
      label={
        <Text>
          {`Starting: ${formatTotal(goal.startValue, metric.displayFormat, metric)}`}
          <br />
          {`Target: ${formatTotal(goal.targetValue, metric.displayFormat, metric)}`}
          <br />
          {`Actual: ${formatTotal(goal.currentValue, metric.displayFormat, metric)}`}
          <br />
          {Intl.NumberFormat(undefined, { style: 'percent' }).format(goal.percentageComplete / 100)} complete
        </Text>
      }
    >
      <HStack>
        <Progress
          w="100%"
          borderRadius="md"
          colorScheme={getProgressColor(
            goal.percentageComplete,
            goal.startDate,
            new Date(),
            goal.endDate,
            goal.oldestMetricDate,
            goal.newestMetricDate
          )}
          value={goal.percentageComplete}
        />
        {display === 'card' && (
          <Text fontSize="sm" fontWeight="semibold">
            {Intl.NumberFormat(undefined, { style: 'percent' }).format(goal.percentageComplete / 100)}
          </Text>
        )}
      </HStack>
    </Tooltip>
  </Stack>
)

export default GoalProgress
