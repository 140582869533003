import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import MetricForm from '@app/pages/metrics/components/metricForm'

const MetricFormTab = () => {
  const navigate = useNavigate()
  const { nodeId } = useParams()
  const outletContext = useOutletContext() as [() => void, () => void, () => void, Record<string, unknown>]

  let successHandler = () => navigate('..')
  let cancelHandler = null
  let deleteHandler = () => navigate('/metrics')
  let options = {}

  if (outletContext) {
    ;[successHandler, cancelHandler, deleteHandler, options] = outletContext
  }

  const metric = useGetObject(nodeId, 'metric')

  if (!metric) {
    return null
  }

  return (
    <MetricForm
      metric={metric}
      onCancel={() => {
        if (cancelHandler) {
          cancelHandler()
        } else {
          navigate(-1)
        }
      }}
      onDelete={deleteHandler}
      onSuccess={successHandler}
      {...options}
    />
  )
}

export default MetricFormTab
