import type { FlexProps } from '@chakra-ui/react'
import { Flex, useMultiStyleConfig } from '@chakra-ui/react'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { useDrawerContext } from '@app/pages/maps/drawerContext'
import DrawerSidebar from '@app/shared/layout/shells/components/drawerSidebar'
import GraphqlSubscriptionShell from '@app/shared/layout/shells/graphqlSubscriptionShell'

interface Props extends FlexProps {
  strategyId: string
  children?: React.ReactNode
  variant?: string
  colorScheme?: string
  size?: string
}

const MapShell: FC<Props> = ({ strategyId, children = null, ...rest }) => {
  const { variant, colorScheme, size } = rest
  const styles = useMultiStyleConfig('MapShell', { variant, colorScheme, size })
  const { isDrawerOpen, onDrawerToggle } = useDrawerContext()

  return (
    <GraphqlSubscriptionShell strategyId={strategyId}>
      <Flex sx={{ ...styles.outerShell }} {...rest}>
        <Flex sx={{ ...styles.innerShell }}>
          <Flex sx={{ ...styles.mainContainer }}>
            <DrawerSidebar isOpen={isDrawerOpen} onClose={onDrawerToggle} />
            {children || <Outlet />}
          </Flex>
        </Flex>
      </Flex>
    </GraphqlSubscriptionShell>
  )
}

export default MapShell
