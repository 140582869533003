import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton, Tooltip, VStack } from '@chakra-ui/react'
import type { FC } from 'react'

import { useDrawerContext } from '@app/pages/maps/drawerContext'
import ICON_MAP from '@app/utils/iconMap'

const DrawerCloseButton: FC<Omit<IconButtonProps, 'aria-label'>> = ({ ...rest }) => {
  const { onDrawerClose } = useDrawerContext()

  return (
    <Tooltip
      hasArrow
      label={
        <VStack spacing={0}>
          <div>Close</div>
          <div>(esc)</div>
        </VStack>
      }
    >
      <IconButton
        aria-label="close drawer"
        colorScheme="gray"
        icon={<ICON_MAP.Close />}
        onClick={onDrawerClose}
        {...rest}
      />
    </Tooltip>
  )
}

export default DrawerCloseButton
