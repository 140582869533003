import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Center } from '@chakra-ui/react'
import delay from 'lodash/delay'
import { useEffect } from 'react'

const IntegrationSuccess = () => {
  useEffect(() => {
    delay(() => {
      window.close()
    }, 2500)
  })

  return (
    <Box w="full" h="100%">
      <Center h="100%">
        <Box>
          <Alert
            alignItems="center"
            justifyContent="center"
            flexDir="column"
            h="200px"
            textAlign="center"
            border="1px"
            borderColor="bg.muted"
            status="success"
            variant="subtle"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Integration connected!
            </AlertTitle>
            <AlertDescription maxW="sm">
              Your integration has been connected, this window will close shortly.
            </AlertDescription>
          </Alert>
        </Box>
      </Center>
    </Box>
  )
}

export default IntegrationSuccess
