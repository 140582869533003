import { Divider, HStack, Link, Stack, Text } from '@chakra-ui/react'
import pluralize from 'pluralize'
import type { FC } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { UserData } from '@app/types'
import { useMetricSourceActivitiesQuery } from '@graphql/queries'
import type { MetricSource } from '@graphql/types'

interface Props {
  metricSource: Pick<MetricSource, 'id' | 'lastRunAt' | 'erroredAt' | 'errorMessage' | 'scheduled'> & {
    metric: Pick<MetricSource['metric'], 'id' | 'name'>
  }
}

const formatDate = (dateTime: string, formatDateTimeInUserTimeZone: UserData['formatDateTimeInUserTimeZone']) => {
  const date = formatDateTimeInUserTimeZone(dateTime, 'eee L/d/yyyy')
  const time = formatDateTimeInUserTimeZone(dateTime, 'h:mm a')

  return `on ${date} at ${time}`
}

const MetricSourceImportStatus: FC<Props> = ({ metricSource }) => {
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()
  const [{ data }] = useMetricSourceActivitiesQuery({
    variables: { metricSourceId: metricSource.id, page: 1, limit: 1 }
  })

  const activities = data?.metricSourceActivities?.collection

  const { id: importId, processedCount = 0, totalCount = 0 } = activities?.[0] || {}

  const { lastRunAt, erroredAt, errorMessage, metric } = metricSource
  const { id: metricId, name: metricName } = metric

  if (erroredAt) {
    const onErrorClick = () => {
      // DEV NOTE: the import ID can get out of sync with the record, if the previous
      // one was successful, and then it starts failing due to e.g. a bad credential.
      // Activities are not created if we can't even make the request, so it
      // might come up in a help request that the import mentioned in the message
      // was successful, and new ones just won't get created.
      window.Intercom(
        'showNewMessage',
        `Please help me with this metric import:\nMetric name: ${metricName}\nMetric id: ${metricId}${
          importId && `\nImport id: ${importId}\n\nError message:\n${errorMessage}`
        }`
      )
    }

    return (
      <Stack textAlign="center" spacing={0}>
        <Text color="error" fontSize="sm" textDecoration="underline" cursor="pointer" onClick={onErrorClick}>
          Import error
        </Text>
        <Text fontSize="sm">{formatDate(erroredAt, formatDateTimeInUserTimeZone)}</Text>
        <Text>{errorMessage}</Text>
      </Stack>
    )
  }

  if (lastRunAt) {
    const to = `/metrics/${metricId}/source/activities/${importId}`

    let count = 0
    if (totalCount > 0) {
      count = totalCount
    } else if (processedCount > 0) {
      count = processedCount
    }

    return (
      <Stack textAlign="center" spacing={0}>
        <HStack alignSelf="center" spacing={2}>
          <Text display="inline" fontSize="sm">
            Imported {count} data {pluralize('point', count)}
          </Text>
          <Divider h={4} orientation="vertical" />
          <Link as={ReactRouterLink} color="success" fontSize="sm" textDecoration="underline" cursor="pointer" to={to}>
            Preview
          </Link>
        </HStack>
        <Text fontSize="sm">{formatDate(lastRunAt, formatDateTimeInUserTimeZone)}</Text>
      </Stack>
    )
  }

  return null
}

export default MetricSourceImportStatus
