import { Image, Stack, Text } from '@chakra-ui/react'
import { SiJirasoftware } from 'react-icons/si'

import IntegrationConnection from './integrationConnection'
import IntegrationInstallation from './integrationInstallation'
import JiraImportHistory from './jira/jiraImportHistory'
import JiraRestrictedProjectsForm from './jira/jiraRestrictedProjectsForm'
import JiraWebhook from './jira/jiraWebhook'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import Card from '@app/shared/layout/card'
import CardHeader from '@app/shared/layout/cardHeader'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import withAwait from '@app/shared/withAwait'
import type { StepType } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'
import type { JiraIntegration } from '@graphql/types'
import { IntegrationSourceNamesEnum } from '@graphql/types'

const Jira = () => {
  const integration = useGetObjectsByProperties('integration', {
    sourceName: IntegrationSourceNamesEnum.Jira
  })?.[0] as JiraIntegration

  const steps: StepType<JiraIntegration>[] = [
    {
      title: 'Click connect',
      description: (
        <Text>
          Click the Connect button above to start authenticating with JIRA. Make sure the person clicking the button has
          JIRA access to add apps.
        </Text>
      ),
      completed: (jiraIntegration) => !!jiraIntegration.sourceId
    }
  ]

  if (integration) {
    steps.push(
      {
        title: 'Restrict projects',
        description: <JiraRestrictedProjectsForm integration={integration} />,
        completed: (jiraIntegration) =>
          jiraIntegration.restrictedProjects.length > 0 || !!jiraIntegration.importedHistory
      },
      {
        title: 'Import history',
        description: <JiraImportHistory integration={integration} />,
        completed: (jiraIntegration) => !!jiraIntegration.importedHistory
      },
      {
        title: 'Copy Webhook',
        description: <JiraWebhook />
      },
      {
        title: 'Add the Webhook to JIRA',
        description: (
          <Stack spacing={4}>
            <Text fontSize="sm">
              Login to Jira. Add the webhook to your Jira account by going to the &quot;Webhooks&quot; page in System
              Settings.
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              When you create the webhook, select the &quot;created&quot;, &quot;updated&quot;, and &quot;deleted&quot;
              checkboxes under &quot;Issue&quot; shown here:
            </Text>
            <Image src="https://files.gitbook.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-MHHueOqTqo2O69VPdaM%2F-MZPaxnf-ZuSjveihK5M%2F-MZPiC8aGbHdO3tXr9GL%2Fimage.png?alt=media&token=ae0d1dbd-a584-407a-baff-4342d47d6690" />
            <Text fontSize="sm">
              If you would like us to create a more comprehensive record, please select all of these checkboxes:
            </Text>
            <Image src="https://files.gitbook.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-MHHueOqTqo2O69VPdaM%2F-MbItoz_d6P1gIoAAfln%2F-MbIu66D7ztzoPbhsBHg%2FScreen%20Shot%202021-06-03%20at%203.02.26%20PM.png?alt=media&token=0d8f49a5-967a-4e77-b27b-47a713941f63" />
            <Text fontSize="sm">And these too:</Text>
            <Image
              w="fit-content"
              h={400}
              src="https://files.gitbook.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-MHHueOqTqo2O69VPdaM%2F-MbItoz_d6P1gIoAAfln%2F-MbIuDhwi6G02N-8IV6X%2FScreen%20Shot%202021-06-03%20at%203.02.33%20PM.png?alt=media&token=e13dc0f3-c7aa-4d8e-81e4-15d33fe7f999"
            />
          </Stack>
        )
      }
    )
  }

  return (
    <PageStack>
      <PageHeader title="JIRA" subtitle="Send your JIRA data to DoubleLoop" />
      <IntegrationConnection
        name="JIRA"
        icon={SiJirasoftware}
        items="epics, issues, and versions"
        integration={integration}
        connectUrl={Routes.jiraAuthentication}
      />
      <Card>
        <CardHeader title="Installation instructions" />
        <IntegrationInstallation integration={integration} steps={steps} skipSpinner />
      </Card>
    </PageStack>
  )
}

export default withAwait(Jira, 'integration')
