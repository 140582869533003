import { Link as ChakraLink, Center, useToken, Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { METRIC_SOURCES } from '@app/lib/globals'
import type { Metric } from '@graphql/types'

interface Props {
  data: Pick<Metric, 'id' | 'metricSourceErrored' | 'sourceName' | 'credential'>
}

const MetricSourceName: FC<Props> = ({ data: { id, metricSourceErrored, sourceName, credential } }) => {
  const color = metricSourceErrored ? 'error' : 'muted'
  const [iconColor] = useToken('colors', [color])
  const label = credential?.label

  return (
    <Center justifyContent="flex-start" data-testid="metric-source-name">
      {METRIC_SOURCES[sourceName]?.icon({ color: iconColor })}
      <ChakraLink as={Link} ml={1} color={color} fontSize="xs" to={`${id}/source`}>
        {metricSourceErrored ? 'Error: ' : ''}
        {METRIC_SOURCES[sourceName]?.display}
        {label && <Box>{label}</Box>}
      </ChakraLink>
    </Center>
  )
}

export default MetricSourceName
