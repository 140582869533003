import type { BoxProps, FlexProps, IconButtonProps } from '@chakra-ui/react'
import { Box, Flex, IconButton, useColorModeValue } from '@chakra-ui/react'
import type { KeenSliderOptions } from 'keen-slider/react'
import { useKeenSlider } from 'keen-slider/react'
import * as React from 'react'

export const Carousel = React.forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
  return <Flex className="chakra-carousel" ref={ref} pos="relative" overflow="hidden" userSelect="none" {...props} />
})

export const CarouselSlide = (props: BoxProps) => (
  <Box className="chakra-carousel__slide" pos="relative" overflow="hidden" w="100%" minH="100%" {...props} />
)

export const CarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    display="inline-flex"
    boxSize="auto"
    minW="auto"
    color="muted"
    fontSize="2xl"
    _hover={{
      color: useColorModeValue('blue.500', 'blue.300'),
      _disabled: { color: useColorModeValue('gray.600', 'gray.400') }
    }}
    _active={{ color: useColorModeValue('blue.600', 'blue.400') }}
    _focus={{ boxShadow: 'none' }}
    _focusVisible={{ boxShadow: 'tertiary' }}
    variant="unstyled"
    {...props}
  />
)

export const useCarousel = (options?: KeenSliderOptions) => {
  const defaultOptions = { selector: '.chakra-carousel__slide' }
  return useKeenSlider<HTMLDivElement>({ ...defaultOptions, ...options })
}
