import { Box, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { TextAreaInput } from '@app/shared/rawForms'
import ICON_MAP from '@app/utils/iconMap'
import type { MetricSource as BaseMetricSource } from '@graphql/types'

type Metric = BaseMetricSource['metric']
type MetricSource = Pick<BaseMetricSource, 'id' | 'configuration'> & {
  metric: Pick<Metric, 'id'>
}
export interface Props {
  metricSource: MetricSource | null
  isDisabled: boolean
}

const Calculated: FC<Props> = ({ metricSource, isDisabled }) => (
  <TextAreaInput
    w="100%"
    maxW="100%"
    name="configuration.formula"
    label="Formula"
    placeholder="Enter a formula..."
    isRequired
    isDisabled={isDisabled}
    defaultValue={metricSource?.configuration?.formula}
    helperText={
      <Stack>
        <HStack>
          <Icon as={ICON_MAP.Details} color="gray.500" fontSize="xl" />
          <Box>
            <Text>
              Use metric IDs to reference other metrics in the formula. IDs must begin with a non-numeric character, and
              may only contain alphanumeric characters and underscores. You can find a metric&apos;s ID on its
              &quot;Details&quot; page.
            </Text>
            <Text>
              Valid operations are <code>+</code>, <code>-</code>, <code>*</code>, <code>/</code>, and <code>^</code>.
              You can use parentheses to group operations, and formulae can span multiple lines.
            </Text>
          </Box>
        </HStack>
      </Stack>
    }
  />
)

export default Calculated
