import type { ComponentStyleConfig } from '@chakra-ui/react'

const Link: ComponentStyleConfig = {
  baseStyle: {
    _hover: {
      textDecoration: 'none'
    }
  }
}

export default Link
