import type { IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'

const Shortcut: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 1055.2 1030.2" {...props}>
    <g fill="currentColor">
      <path
        fill="currentColor"
        d="M1021.8,273.9L1021.8,273.9c0-136.5-110.6-247.1-247.1-247.1H280.5l0,0c0,0,0,0,0,0
		c-63.2,0-126.5,24.1-174.7,72.4l0,0c-96.5,96.5-96.5,252.9,0,349.4l88,88C100.1,571.8,33.4,662.2,33.4,768.1v0
		c0,136.5,110.6,247.1,247.1,247.1h494.2l0,0c63.2,0,126.5-24.1,174.7-72.4l0,0c96.5-96.5,96.5-252.9,0-349.4l-88-88
		C955.1,470.3,1021.8,379.9,1021.8,273.9z M173.6,875.1c-28.6-28.6-44.3-66.5-44.3-106.9c0-40.4,15.7-78.4,44.3-106.9
		c27.1-27.1,62.6-42.6,100.6-44.2l302.3,302.3h-296C240.1,919.3,202.2,903.6,173.6,875.1z M881.6,661.2
		c28.6,28.6,44.3,66.5,44.3,106.9c0,40.4-15.7,78.4-44.3,106.9c-28.6,28.6-66.5,44.3-106.9,44.3c-40.4,0-78.4-15.7-106.9-44.3
		L173.6,380.9c-28.6-28.6-44.3-66.5-44.3-106.9c0-40.4,15.7-78.4,44.3-106.9c28.6-28.6,66.5-44.3,106.9-44.3
		c40.4,0,78.4,15.7,106.9,44.3L881.6,661.2z M881.6,380.9c-27.1,27.1-62.6,42.6-100.6,44.2L478.7,122.7l0,0h296
		c40.4,0,78.4,15.7,106.9,44.3c28.6,28.6,44.3,66.5,44.3,106.9C925.9,314.3,910.2,352.3,881.6,380.9z"
      />
    </g>
  </Icon>
)

export default Shortcut
