import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import ReactJson from 'react-json-view'
import { useAsyncValue, useNavigate } from 'react-router-dom'

import withAwait from '@app/shared/withAwait'
import type { MetricSourceActivity } from '@graphql/queries'

type MetricSourceActivityResolved = {
  metricSourceActivity: MetricSourceActivity
}

interface MetricSourceActivityPromise {
  data?: MetricSourceActivityResolved
}

const MetricSourceActivityShow = () => {
  const navigate = useNavigate()
  const {
    data: { metricSourceActivity }
  }: MetricSourceActivityPromise = useAsyncValue()

  const handleClose = () => {
    navigate('../..')
  }

  const parsed = JSON.parse(metricSourceActivity.payload)
  const src = typeof parsed === 'object' ? parsed : { value: parsed }

  return (
    <Modal isCentered isOpen onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box overflow="auto" maxH="250px">
            <ReactJson displayDataTypes={false} enableClipboard={false} name={false} src={src} />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} colorScheme="blue" onClick={handleClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default withAwait(MetricSourceActivityShow, 'metricSourceActivity')
