import type { FC, ReactElement } from 'react'
import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'

import ErrorPage from '@app/shared/errorPage'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'

const withAwait =
  (Component: FC, field: string, fallback: ReactElement = <SuspenseLoader />) =>
  (props): ReactElement => {
    const loaderData = useLoaderData()
    const fieldData = loaderData[field]

    return (
      <Suspense fallback={fallback}>
        <Await resolve={fieldData} errorElement={<ErrorPage />}>
          <Component {...props} />
        </Await>
      </Suspense>
    )
  }

export default withAwait
