import { HStack, IconButton, Spacer, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiMail, FiTrash2 } from 'react-icons/fi'

import InvitationDeleteButton from '@app/pages/settings/organization/components/invitationDeleteButton'
import Can from '@app/shared/authorization/can'
import useToast from '@app/shared/toast'
import type { Invitation } from '@graphql/queries'
import { useInvitationResendMutation } from '@graphql/queries'

interface Props {
  invitation: Invitation
}

const InvitationsListActions: FC<Props> = ({ invitation }) => {
  const toast = useToast()
  const [, invitationResend] = useInvitationResendMutation()

  const resendInvitationHandler = async () => {
    await invitationResend({
      input: { invitationId: invitation.id }
    })

    toast({
      title: 'Invitation resent',
      description: 'We shot off another invite email, they should have it soon.',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
  }

  return (
    <Can I="update" an="account">
      <HStack spacing={0}>
        <Spacer />
        <Tooltip label="Resend invite">
          <IconButton aria-label="Resend invite" icon={<FiMail />} onClick={resendInvitationHandler} variant="ghost" />
        </Tooltip>
        <InvitationDeleteButton invitation={invitation}>
          <Tooltip label="Delete invite">
            <IconButton color="error" aria-label="Rescind invite" icon={<FiTrash2 />} variant="ghost" />
          </Tooltip>
        </InvitationDeleteButton>
      </HStack>
    </Can>
  )
}

export default InvitationsListActions
