import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'

import CardColor from '@app/pages/maps/components/nodes/components/toolbar/components/cardSettings/cardColor'
import CardFields from '@app/pages/maps/components/nodes/components/toolbar/components/cardSettings/cardFields'
import CardSize from '@app/pages/maps/components/nodes/components/toolbar/components/cardSettings/cardSize'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: MapDomainNode
}

const CardSettingsButton: FC<Props> = ({ node }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const openDrawer = () => {
    onToggle()
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <span>
          <Tooltip label="Display options">
            <IconButton borderRadius="sm" aria-label="settings" icon={<ICON_MAP.CardSettings />} onClick={openDrawer} />
          </Tooltip>
        </span>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Tabs isFitted variant="enclosed">
              <TabList>
                <Tab>Size</Tab>
                <Tab>Color</Tab>
                <Tab>Fields</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <CardSize node={node} />
                </TabPanel>
                <TabPanel>
                  <CardColor node={node} />
                </TabPanel>
                <TabPanel>
                  <CardFields node={node} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default CardSettingsButton
