import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { useStore } from '@app/store'

type Props = {
  reportId: string
}

const ReportDeleteButton: FC<Props> = ({ reportId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const navigate = useNavigate()
  const deleteObject = useStore.use.deleteObject()

  const deleteReport = () => {
    onClose()
    navigate('../..')
    return deleteObject({ id: reportId, classType: 'report' }, { updatePageCounts: true })
  }

  return (
    <>
      <Button w="100%" colorScheme="red" leftIcon={<Icon as={FiTrash2} color="red.500" />} onClick={onOpen}>
        Delete report
      </Button>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete report
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&#39;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} bgColor="error" onClick={deleteReport} variant="primary">
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default ReportDeleteButton
