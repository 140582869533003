import type { StackProps } from '@chakra-ui/react'
import { Divider, Stack } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { memo } from 'react'

type Props = PropsWithChildren<StackProps>

const MapDrawer: FC<Props> = (props) => (
  <Stack
    pos="absolute"
    top="24px"
    bottom={20}
    left="74px"
    w="385px"
    py={6}
    borderRadius="md"
    shadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
    bgColor="bg.surface"
    divider={<Divider />}
    {...props}
  />
)

export default memo(MapDrawer)
