import {
  Box,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Wrap,
  WrapItem,
  useColorModeValue as mode,
  useDisclosure
} from '@chakra-ui/react'
import Fuse from 'fuse.js'
import type { FC, ReactElement } from 'react'
import { cloneElement, useState } from 'react'
import { FiSearch, FiXCircle } from 'react-icons/fi'
import { FixedSizeList as List } from 'react-window'

import { iconArray } from '../customIcons'

interface Props {
  value: string
  color?: string
  onClick?: (arg0: string) => void
  children: ReactElement
}

const IconPickerPopover: FC<Props> = ({ value, color, onClick, children }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const { isOpen, onToggle, onClose } = useDisclosure()
  const trigger = cloneElement(children, { onClick: onToggle })
  const hoverColor = mode('gray.100', 'gray.700')
  const iconColor = color ? `${color}.500` : 'gray.500'
  const iconsPerRow = 6

  const filterSearch = new Fuse(iconArray, {
    threshold: 0.1,
    distance: 250,
    keys: [{ name: 'name', getFn: (icon) => icon[0] }]
  })

  const searchResults = filterSearch.search({ name: searchTerm })
  const searchResultsArray = searchResults.map((result) => result.item)

  const totalOptionsCount = iconArray.length
  const searchOptionsCount = searchResultsArray.length
  const optionCount = searchTerm !== '' ? searchOptionsCount : totalOptionsCount
  const rows = Math.ceil(optionCount / iconsPerRow)

  const handleClick = (e: string) => {
    if (onClick) {
      onClick(e)
    }

    onToggle()
  }

  const clearSearch = () => {
    setSearchTerm('')
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody h={283} m={0} px={0}>
          <Box p={4} borderBottom="1px solid rgba(0, 0, 0, 0.08)">
            <InputGroup>
              <InputLeftElement>
                <Icon as={FiSearch} />
              </InputLeftElement>
              <InputRightElement color="muted">
                <IconButton
                  aria-label="Clear search"
                  icon={<Icon as={FiXCircle} color="gray" />}
                  onClick={clearSearch}
                  variant="text"
                />
              </InputRightElement>
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for an icon"
                value={searchTerm}
              />
            </InputGroup>
          </Box>
          <Box h={200} m={0} p={0}>
            <List height={200} itemCount={rows} width="100%" itemSize={45}>
              {({ index, style }) => (
                <Wrap justify="center" pt={2} spacing={2} style={style}>
                  {(searchTerm !== '' ? searchResultsArray : iconArray)
                    .slice(index * iconsPerRow, index * iconsPerRow + iconsPerRow)
                    .map(([name, icon]) => (
                      <WrapItem
                        key={name}
                        p={2}
                        borderWidth={2}
                        borderColor={value === name ? 'teal.500' : 'transparent'}
                        borderRadius="md"
                        _hover={{ bgColor: hoverColor }}
                        cursor="pointer"
                        onClick={() => handleClick(name)}
                      >
                        <Icon as={icon} boxSize={5} color={iconColor} />
                      </WrapItem>
                    ))}
                </Wrap>
              )}
            </List>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default IconPickerPopover
