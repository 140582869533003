import { Button, Stack } from '@chakra-ui/react'
import type { ChangeEventHandler, FC, FormEventHandler } from 'react'
import { useState } from 'react'

import { ButtonRow } from '@app/shared/forms'
import { Form, TextInput } from '@app/shared/rawForms'
import { useStore } from '@app/store'
import type { JiraIntegration } from '@graphql/types'

type Props = {
  integration: Pick<JiraIntegration, 'id' | 'restrictedProjects'>
}

const JiraRestrictedProjectsForm: FC<Props> = ({ integration }) => {
  const [restrictedProjects, setRestrictedProjects] = useState(() => (integration?.restrictedProjects || []).join(', '))
  const [isLoading, setIsLoading] = useState(false)

  const updateIntegration = useStore.use.updateIntegration()

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    setRestrictedProjects(value)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    setIsLoading(true)

    return updateIntegration(integration.id, {
      restrictedProjects
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Stack spacing={4}>
        <TextInput
          type="text"
          value={restrictedProjects}
          placeholder="DOU, TST"
          helperText="Project keys to not import into DoubleLoop"
          onChange={onChange}
        />
        <ButtonRow bgColor="bg.subtle">
          <Button isLoading={isLoading} type="submit">
            Save
          </Button>
        </ButtonRow>
      </Stack>
    </Form>
  )
}

export default JiraRestrictedProjectsForm
