import { Box, Button, HStack, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import { useStore } from '@app/store'
import type { Color, MapDomainNode } from '@app/types'

interface Props {
  node: MapDomainNode
}

const CardColor: FC<Props> = ({ node }) => {
  const updateNode = useStore.use.updateNode()
  const currentColor = node?.metadata?.color || ''
  const { strategyId } = node

  const changeColor = (color: Color) => {
    updateNode(strategyId, node.id, { metadata: { ...node.metadata, color } })
  }

  const buttonParams = (color: Color) => ({
    colorScheme: color || 'gray',
    isActive: color === currentColor,
    size: 'xs',
    variant: 'primary',
    w: '100%',
    onClick: () => changeColor(color)
  })

  return (
    <Stack>
      <HStack>
        <Box w="100%">
          <Button {...buttonParams('red')}>Red</Button>
        </Box>
        <Box w="100%">
          <Button {...buttonParams('orange')}>Orange</Button>
        </Box>
        <Box w="100%">
          <Button {...buttonParams('yellow')}>Yellow</Button>
        </Box>
        <Box w="100%">
          <Button {...buttonParams('green')}>Green</Button>
        </Box>
      </HStack>
      <HStack>
        <Box w="100%">
          <Button {...buttonParams('teal')}>Teal</Button>
        </Box>
        <Box w="100%">
          <Button {...buttonParams('blue')}>Blue</Button>
        </Box>
        <Box w="100%">
          <Button {...buttonParams('cyan')}>Cyan</Button>
        </Box>
        <Box w="100%">
          <Button {...buttonParams('purple')}>Purple</Button>
        </Box>
        <Box w="100%">
          <Button {...buttonParams('pink')}>Pink</Button>
        </Box>
      </HStack>
      <Button w="100%" {...buttonParams('')}>
        None
      </Button>
    </Stack>
  )
}

export default CardColor
