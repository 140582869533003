import type { MenuItemProps } from '@chakra-ui/react'
import { Link as ChakraLink, MenuItem } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

interface SidebarMenuItemProps extends MenuItemProps {
  title: string
  target: string
  isExternal?: boolean
}

const SidebarMenuItem = ({ title, icon, target, isExternal = false }: SidebarMenuItemProps) => {
  let routeProps = {}

  if (isExternal) {
    routeProps = { as: ChakraLink, href: target }
  } else {
    routeProps = { as: Link, to: target }
  }

  return (
    <MenuItem icon={icon} rounded="md" {...routeProps}>
      {title}
    </MenuItem>
  )
}

export default SidebarMenuItem
