import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'

import Table from '@app/shared/table'
import type { MetricSourceTestPayload } from '@graphql/queries'

interface MetricSourceTestResultsProps {
  testData?: MetricSourceTestPayload
  isOpen?: boolean
  onClose?: () => void
}

const MetricSourceTestResults: FC<MetricSourceTestResultsProps> = ({
  testData,
  isOpen = false,
  onClose = () => {}
}) => {
  const closeRef = useRef()
  let results

  const { data, error } = testData?.results || {}

  if (data) {
    const columns = []
    const keys = Object.keys(data[0] || {})

    keys.forEach((key) => {
      columns.push({ header: key, accessor: key })
    })

    results = <Table variant="form" columns={columns} data={data.slice(0, 5)} />
  } else if (error) {
    results = (
      <Text pt={2} color="muted">
        {error}
      </Text>
    )
  }

  return (
    <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={closeRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Test Results
          </AlertDialogHeader>

          <AlertDialogBody>{results}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={closeRef} ml={3} onClick={onClose} variant="primary">
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default MetricSourceTestResults
