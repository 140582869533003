import { IconButton } from '@chakra-ui/react'
import { RichUtils } from 'draft-js'

function blockButtonMaker({ blockType, children }) {
  return function BlockStyleButton({ buttonProps = {}, getEditorState, setEditorState }) {
    const toggleStyle = (event) => {
      event.preventDefault()
      setEditorState(RichUtils.toggleBlockType(getEditorState(), blockType))
    }

    const preventBubblingUp = (event) => {
      event.preventDefault()
    }

    const blockTypeIsActive = () => {
      // if the button is rendered before the editor
      if (!getEditorState) {
        return false
      }

      const editorState = getEditorState()
      const type = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType()
      return type === blockType
    }

    return (
      <IconButton
        aria-label="block-button-marker"
        {...buttonProps}
        icon={children}
        isActive={blockTypeIsActive()}
        onClick={toggleStyle}
        onMouseDown={preventBubblingUp}
        role="button"
        type="button"
      />
    )
  }
}

export default blockButtonMaker
