import { FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import AWSMultipart from '@uppy/aws-s3-multipart'
import type { UppyOptions } from '@uppy/core'
import Uppy from '@uppy/core'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { FiUpload } from 'react-icons/fi'

import { MAX_AWS_FILE_SIZE } from '@app/lib/globals'
import type { OnUploadError, OnUploadSuccess } from '@app/types'

type Props = {
  autoProceed?: boolean
  errored?: boolean
  fileTypes: string[]
  isRequired?: boolean
  label?: string
  maxFileSize?: number
  name: string
  onUploadError?: OnUploadError
  onUploadSuccess?: OnUploadSuccess
  placeholder?: string
}

const UppyFileInput: FC<Props> = ({
  name,
  fileTypes,
  autoProceed = true,
  errored = false,
  isRequired = false,
  label = '',
  maxFileSize = MAX_AWS_FILE_SIZE,
  onUploadError = () => {},
  onUploadSuccess = () => {},
  placeholder = 'Choose a file to upload'
}) => {
  const [uploading, setUploading] = useState(false)
  const [fileName, setFileName] = useState('')
  const inputRef = useRef<HTMLInputElement>()

  const uppyOptions: UppyOptions = {
    id: `${label}-${name}`,
    autoProceed,
    onBeforeUpload: () => {
      setUploading(true)

      return true
    },
    locale: { strings: { chooseFiles: placeholder } },
    restrictions: {
      maxNumberOfFiles: 1,
      maxFileSize,
      allowedFileTypes: fileTypes
    }
  }

  const uppy = new Uppy(uppyOptions)

  uppy.use(AWSMultipart, {
    companionUrl: '/',
    companionHeaders: {
      'x-amz-server-side-encryption': 'AES256'
    }
  })

  uppy
    .on('file-added', (file) => {
      if (file.type.includes('image/')) {
        const { data } = file // is a Blob instance
        const url = URL.createObjectURL(data)
        const image = new Image()

        image.src = url
        image.onload = () => {
          uppy.setFileMeta(file.id, { width: image.width, height: image.height })
          URL.revokeObjectURL(url)
        }
      }

      setFileName(file.name)
      setUploading(true)
    })
    .on('upload-success', (file, response) => {
      let additionalMetadata = {}

      if (file.type.includes('image/') && file.meta?.height && file.meta?.width) {
        additionalMetadata = { height: file.meta.height, width: file.meta.width }
      }

      const uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\w*\/([^?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
          ...additionalMetadata
        }
      })

      onUploadSuccess(uploadedFileData, file, response)
      setUploading(false)
    })
    .on('file-removed', () => {
      inputRef.current.value = null
      setFileName('')
    })
    .on('complete', () => {
      inputRef.current.value = null
    })
    .on('upload-error', (file, error, response) => {
      onUploadError?.(file, error, response)
    })

  return (
    <FormControl isInvalid={errored && !uploading && !fileName} isRequired={isRequired}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FiUpload} />
        </InputLeftElement>
        <input
          type="file"
          accept={fileTypes.join(',')}
          name={name}
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={(event) => {
            const files = Array.from(event.target.files)

            files.forEach((file) => {
              try {
                uppy.addFile({
                  source: 'file input',
                  name: file.name,
                  type: file.type,
                  data: file
                })
              } catch (err) {
                Sentry.captureException(err)
              }
            })
          }}
        />

        <Input
          disabled={uploading}
          isReadOnly
          onClick={() => !uploading && inputRef.current.click()}
          placeholder={placeholder}
          value={fileName}
        />
      </InputGroup>
      <FormErrorMessage>A file is required to complete this form.</FormErrorMessage>
    </FormControl>
  )
}

export default UppyFileInput
