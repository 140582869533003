import { Button, ButtonGroup, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FC } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import DateInput from '@app/next/forms/dateInput'
import TextInput from '@app/next/forms/textInput'
import { ButtonRow } from '@app/shared/forms'
import { useStore } from '@app/store'
import type { MetricDataPoint } from '@graphql/queries'

interface Props {
  metricDataPoint?: MetricDataPoint
}

const MetricDataPointForm: FC<Props> = ({ metricDataPoint }: Props) => {
  const { metricId, nodeId } = useParams()
  const id = metricId || nodeId
  const addMetricDataPoint = useStore.use.addMetricDataPoint()
  const updateMetricDataPoint = useStore.use.updateMetricDataPoint()
  const navigate = useNavigate()

  const dateDefault = metricDataPoint?.date || format(new Date(), 'yyyy-MM-dd')

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const formProps = Object.fromEntries(formData)

    if (metricDataPoint) {
      updateMetricDataPoint(metricDataPoint.id, formProps.date as string, formProps.value as string)
    } else {
      addMetricDataPoint(id, formProps.date as string, formProps.value as string)
    }

    navigate('..')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack maxW="2xl" p={6} spacing={5}>
        <DateInput name="date" label="Date" defaultValue={dateDefault} isRequired />
        <TextInput
          name="value"
          label="Value"
          defaultValue={metricDataPoint?.value}
          // errors={errors}
          type="number"
          step="0.0001"
          isRequired
        />

        <ButtonRow>
          <ButtonGroup>
            <Button as={Link} to=".." variant="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </ButtonGroup>
        </ButtonRow>
      </Stack>
    </form>
  )
}

export default MetricDataPointForm
