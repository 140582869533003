import type { FC, ReactNode } from 'react'

import { IMAGE_FILE_TYPES, MAX_AWS_FILE_SIZE } from '@app/lib/globals'
import AwsUploaderModal from '@app/shared/uploader/awsUploaderModal'
import type { OnUploadSuccess } from '@app/types'

type Props = {
  children: ReactNode
  onUploadSuccess: OnUploadSuccess
}

const EntityAttachmentUploadButton: FC<Props> = ({ children, onUploadSuccess }) => {
  const uppyOptions = {
    restrictions: {
      maxFileSize: MAX_AWS_FILE_SIZE,
      allowedFileTypes: IMAGE_FILE_TYPES
    }
  }

  return (
    <AwsUploaderModal onUploadSuccess={onUploadSuccess} uppyOptions={uppyOptions}>
      {children}
    </AwsUploaderModal>
  )
}

export default EntityAttachmentUploadButton
