import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import ICON_MAP from '@app/utils/iconMap'
import { usePipelineRunMutation } from '@graphql/queries'
import { PipelineEnum } from '@graphql/types'

type Props = Partial<IconButtonProps> & {
  edgeId: string
  onRequestError?: (err: Error) => void
}

const PopulateEdgeInsightsButton: FC<Props> = ({
  edgeId,
  onClick: propOnClick = null,
  onRequestError = null,
  ...props
}) => {
  const edge = useGetObject(edgeId, 'edge')
  const { edgeId: dbId, insightsDetailsChatId: chatId } = edge || {}
  const [, runPipeline] = usePipelineRunMutation()
  const chat = useGetObject(chatId, 'chat')
  const isLoading = chat?.processedState === 'processing'

  const onClick = async (e) => {
    propOnClick?.(e)

    try {
      const response = await runPipeline({
        input: { chattable: { edge: { id: dbId } }, pipelineId: PipelineEnum.MetricRelationshipAnalyzer }
      })

      if (response.error) {
        onRequestError?.(response.error)

        return
      }

      const runResponse = response.data.pipelineRun

      if (runResponse.errors?.length > 0) {
        onRequestError?.(new Error(runResponse.errors[0].message))
      }
    } catch (err) {
      onRequestError?.(err)
    }
  }

  return (
    <IconButton
      aria-label="Retrieve AI insights"
      colorScheme="gray"
      icon={<ICON_MAP.AiPrompt />}
      isLoading={isLoading}
      onClick={onClick}
      variant="ghost"
      {...props}
    />
  )
}

export default PopulateEdgeInsightsButton
