import { Divider, HStack, Icon, Spacer, Stack } from '@chakra-ui/react'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo } from 'react'
import { FiCheck } from 'react-icons/fi'

import Nameplate from './nameplate'

import { METRIC_SOURCES } from '@app/lib/globals'

type Props = {
  value: string
  onClick: (newSource: string) => void
}

const toOmit = [
  'MetricSources::Calculated',
  'MetricSources::DummyData',
  'MetricSources::GoogleAnalyticsV4',
  'MetricSources::GoogleSheets',
  'MetricSources::Looker'
]

const SourceNameSelect: FC<Props> = ({ value, onClick: propOnClick }) => {
  const sortedSources = useMemo(() => sortBy(Object.entries(omit(METRIC_SOURCES, toOmit)), '1.display'), [])

  return (
    <Stack overflowY="auto" maxH="450px" divider={<Divider />} spacing={2}>
      {sortedSources.map(([sourceName]) => (
        <HStack key={sourceName} px="6" cursor="pointer" onClick={() => propOnClick(sourceName)}>
          <Nameplate sourceName={sourceName} />
          {value === sourceName && (
            <>
              <Spacer />
              <Icon as={FiCheck} />
            </>
          )}
        </HStack>
      ))}
    </Stack>
  )
}

export default SourceNameSelect
