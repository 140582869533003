import type { FormLabelProps, NumberInputProps } from '@chakra-ui/react'
import { NumberInput as ChakraNumberInput, NumberInputField } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import RootFormControl from '@app/shared/rawForms/rootFormControl'
import type { Errors } from '@app/shared/rawForms/useForm'

type Props = NumberInputProps & {
  name?: string
  label?: string
  labelSize?: FormLabelProps['fontSize']
  isRequired?: boolean
  helperText?: string
  errors?: Errors
  children?: ReactNode
}
const NumberInput: FC<Props> = ({ name, label, labelSize, isRequired, helperText, errors, children, ...rest }) => (
  <RootFormControl
    name={name}
    label={label}
    labelSize={labelSize}
    isRequired={isRequired}
    helperText={helperText}
    errors={errors}
  >
    <ChakraNumberInput clampValueOnBlur={false} name={name} {...rest}>
      <NumberInputField />
      {children}
    </ChakraNumberInput>
  </RootFormControl>
)

export default NumberInput
