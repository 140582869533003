import type { ComponentStyleConfig } from '@chakra-ui/react'

const Text: ComponentStyleConfig = {
  variants: {
    'section-header': {
      fontWeight: 'semibold',
      fontSize: 'sm'
    }
  }
}

export default Text
