import type { ThemingProps } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import type { useFetcher, FormProps as ReactRouterFormProps } from 'react-router-dom'
import { Form as ReactRouterForm } from 'react-router-dom'

export interface FormProps extends ThemingProps, ReactRouterFormProps {
  children: ReactNode
}

const Form = forwardRef<HTMLFormElement, FormProps>(({ children, ...rest }, ref) => (
  <ReactRouterForm {...rest} ref={ref}>
    {children}
  </ReactRouterForm>
))

export interface FetchFormProps extends FormProps {
  fetcher: ReturnType<typeof useFetcher>
  py?: number
}

export const FetchForm = forwardRef<HTMLFormElement, FetchFormProps>(({ fetcher, children, ...rest }, ref) => (
  <fetcher.Form {...rest} ref={ref}>
    {children}
  </fetcher.Form>
))

export const RawForm = forwardRef<HTMLFormElement, FormProps>(({ children, ...rest }, ref) => (
  <form {...rest} ref={ref}>
    {children}
  </form>
))

export default Form
