import type { DividerProps, PlacementWithLogical, StackProps } from '@chakra-ui/react'
import { Box, ButtonGroup, Divider, HStack, IconButton, Tooltip } from '@chakra-ui/react'
import { NodeToolbar } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'
import {} from // CgAlignBottom,
// CgAlignCenter,
// CgAlignLeft,
// CgAlignMiddle,
// CgAlignRight,
// CgAlignTop,
// CgDistributeHorizontal,
// CgDistributeVertical
'react-icons/cg'

// import { handleDistribution } from './utils'

// import AlignButton from '@app/pages/maps/components/multiSelectToolbar/alignButton'
import useGetEdges from '@app/hooks/useGetEdges'
import useGetNodes from '@app/hooks/useGetNodes'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import BulkDuplicateButton from '@app/pages/maps/components/multiSelectToolbar/bulkDuplicateButton'
import BulkLabelsButton from '@app/pages/maps/components/multiSelectToolbar/bulkLabelsButton'
import BulkColorButton from '@app/pages/maps/components/nodes/components/toolbar/components/bulkColorButton'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

interface TooltipProps {
  hasArrow: boolean
  placement: PlacementWithLogical
}

const tooltipProps: TooltipProps = { hasArrow: true, placement: 'top' }

const getStackProps = ({ orientation = 'vertical' }: DividerProps): StackProps => ({
  borderRadius: 4,
  shadow: 'sm',
  bgColor: 'bg.surface',
  [orientation === 'vertical' ? 'px' : 'py']: 0,
  divider: <Divider m={0} orientation={orientation} />
})

const toolbarButtonGroupProps = {
  size: 'xl',
  variant: 'ghost',
  colorScheme: 'gray'
}

interface MultiSelectToolbarProps {
  strategyId: string
  isSelecting: boolean
}

const MultiSelectToolbar: FC<MultiSelectToolbarProps> = ({ strategyId, isSelecting }) => {
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const nodes = useGetNodes(strategyId)
  const selectedNodes = nodes.filter((node) => node.selected)

  const edges = useGetEdges(strategyId)
  const selectedEdges = edges.filter((edge) => edge.selected)

  const onNodesDelete = useStore.use.onNodesDelete()
  // const updateNodes = useStore.use.updateNodes()
  const moveNodesToNewMap = useStore.use.moveNodesToNewMap()
  const collapseNodes = useStore.use.collapseNodes()

  const isVisible = selectedNodes.length > 1

  const handleNodesMoveToNewMap = () => {
    const positionSum = selectedNodes.reduce(
      (acc, node) => ({
        x: acc.x + node.position.x,
        y: acc.y + node.position.y
      }),
      { x: 0, y: 0 }
    )

    const position = {
      x: Math.floor(positionSum.x / selectedNodes.length),
      y: Math.floor(positionSum.y / selectedNodes.length)
    }

    moveNodesToNewMap(strategyId, selectedNodes, selectedEdges, position)
  }

  const handleCollapseNodes = () => {
    collapseNodes(strategyId, selectedNodes)
  }

  if (isSelecting || isAnonymousUser) {
    return null
  }

  return (
    <NodeToolbar nodeId={selectedNodes.map((selectedNode) => selectedNode.id)} isVisible={isVisible}>
      <HStack {...getStackProps({})} h={9} data-testid="multi-select-toolbar" id="multi-select-toolbar">
        {/* <ButtonGroup {...toolbarButtonGroupProps}> */}
        {/*  <AlignButton alignType="left" label="Align left" icon={<CgAlignLeft />} /> */}
        {/*  <AlignButton alignType="centerHorizontal" label="Align horizontally" icon={<CgAlignCenter />} /> */}
        {/*  <AlignButton alignType="right" label="Align right" icon={<CgAlignRight />} /> */}
        {/* </ButtonGroup> */}

        {/* <ButtonGroup {...toolbarButtonGroupProps}> */}
        {/*  <AlignButton alignType="bottom" label="Align bottom" icon={<CgAlignBottom />} /> */}
        {/*  <AlignButton alignType="centerVertical" label="Align vertically" icon={<CgAlignMiddle />} /> */}
        {/*  <AlignButton alignType="top" label="Align top" icon={<CgAlignTop />} /> */}
        {/* </ButtonGroup> */}

        {/* {selectedNodes.length >= 3 && ( */}
        {/*  <ButtonGroup {...toolbarButtonGroupProps}> */}
        {/*    <Tooltip label="Distribute horizontally" {...tooltipProps} data-testid="distribute-horizontally-tooltip"> */}
        {/*      <IconButton */}
        {/*        aria-label="Distribute horizontally" */}
        {/*        data-testid="distribute-horizontally-button" */}
        {/*        icon={<CgDistributeHorizontal />} */}
        {/*        onClick={() => updateNodes(strategyId, handleDistribution('x', selectedNodes))} */}
        {/*      /> */}
        {/*    </Tooltip> */}
        {/*    <Tooltip label="Distribute vertically" {...tooltipProps} data-testid="distribute-vertically-tooltip"> */}
        {/*      <IconButton */}
        {/*        aria-label="Distribute vertically" */}
        {/*        data-testid="distribute-vertically-button" */}
        {/*        icon={<CgDistributeVertical />} */}
        {/*        onClick={() => updateNodes(strategyId, handleDistribution('y', selectedNodes))} */}
        {/*      /> */}
        {/*    </Tooltip> */}
        {/*  </ButtonGroup> */}
        {/* )} */}
        <ButtonGroup {...toolbarButtonGroupProps}>
          <Tooltip label="Copy" {...tooltipProps}>
            <Box>
              <BulkDuplicateButton nodes={selectedNodes} />
            </Box>
          </Tooltip>
          <Tooltip label="Move to submap" {...tooltipProps}>
            <IconButton
              aria-label="submap-button"
              icon={<ICON_MAP.StrategyMap />}
              onClick={handleNodesMoveToNewMap}
              variant="ghost"
            />
          </Tooltip>
          <Tooltip label="Card color" {...tooltipProps}>
            <Box>
              <BulkColorButton strategyId={strategyId} />
            </Box>
          </Tooltip>
          <BulkLabelsButton strategyId={strategyId} {...tooltipProps} />
          <Tooltip label="Collapse cards downstream" {...tooltipProps}>
            <IconButton
              aria-label="collapse-button"
              icon={<ICON_MAP.CollapseAction />}
              onClick={handleCollapseNodes}
              variant="ghost"
            />
          </Tooltip>
          <Tooltip label="Delete" {...tooltipProps}>
            <IconButton
              aria-label="delete"
              data-testid="delete-button"
              icon={<ICON_MAP.Delete />}
              onClick={() => onNodesDelete(strategyId, selectedNodes)}
              variant="ghost"
            />
          </Tooltip>
        </ButtonGroup>
      </HStack>
    </NodeToolbar>
  )
}

MultiSelectToolbar.displayName = 'MultiSelectToolbar'
export default memo(MultiSelectToolbar)
