import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  title?: string
  description?: string
  status?: 'error' | 'success' | 'warning' | 'info'
}

const FormAlert: FC<Props> = ({ title = '', description = '', status = 'error' }) => {
  if (!description) {
    return null
  }

  return (
    <Alert status={status}>
      <AlertIcon />
      <Box flex="1">
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </Box>
    </Alert>
  )
}

export default FormAlert
