import { Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'

import { PasswordInput, TextInput } from '@app/shared/rawForms'
import type { Credential } from '@graphql/types'

interface Props {
  credential?: Pick<Credential, 'host' | 'port' | 'warehouse' | 'database' | 'username' | 'schema' | 'role'>
}

const SnowflakeAuth: FC<Props> = ({ credential }) => (
  <Stack>
    <TextInput
      name="host"
      label="Host"
      placeholder="<org>-<account>.snowflakecomputing.com"
      pattern="[a-zA-Z\d][\.a-zA-Z\d_\-]*(\.[a-zA-Z\d_\-])*\.snowflakecomputing\.com"
      isRequired
      autoComplete="off"
      defaultValue={credential?.host}
      helperText="Snowflake hosts should not include http(s)://, and end in `.snowflakecomputing.com`"
      data-1p-ignore
    />
    <TextInput
      name="port"
      label="Database port"
      placeholder="Snowflake port number"
      autoComplete="off"
      defaultValue={credential?.port || '443'}
      isRequired
      data-1p-ignore
    />
    <TextInput
      name="warehouse"
      label="Warehouse name"
      placeholder="Warehouse name"
      autoComplete="off"
      defaultValue={credential?.warehouse}
      isRequired
      data-1p-ignore
    />
    <TextInput
      name="database"
      label="Database name"
      placeholder="Database name"
      autoComplete="off"
      defaultValue={credential?.database}
      isRequired
      data-1p-ignore
    />
    <TextInput
      name="schema"
      label="Schema name"
      placeholder="Schema name"
      autoComplete="off"
      defaultValue={credential?.schema || 'public'}
      isRequired
      data-1p-ignore
    />
    <TextInput
      name="username"
      label="Username"
      placeholder="Snowflake user name"
      autoComplete="off"
      defaultValue={credential?.username}
      isRequired
      data-1p-ignore
    />
    <PasswordInput
      name="password"
      label="Password"
      placeholder="Snowflake user password"
      autoComplete="off"
      isRequired={!credential}
      helperText={credential ? 'Leave blank to keep the current password or fill in to set a new password' : null}
      data-1p-ignore
    />
    <TextInput
      name="role"
      label="Role"
      placeholder="(Optional) Snowflake role to use for the query"
      autoComplete="off"
      defaultValue={credential?.role}
      data-1p-ignore
    />
    {Object.keys(credential || {}).length && <VisuallyHiddenInput defaultValue="password" name="secretFields" />}
  </Stack>
)

export default SnowflakeAuth
