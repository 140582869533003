import {
  Button,
  ButtonGroup,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'

import { useStore } from '@app/store'
import type { Report } from '@graphql/types'

interface Props {
  report: Pick<Report, 'id' | 'emails'> & {
    slackChannel?: Pick<Report['slackChannel'], 'name'>
  }
  onSend?: () => void
}

const SendReportButton: FC<Props> = ({ report, onSend = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const sendReport = useStore.use.sendReport()
  const isDisabled = !report?.emails?.length && !report?.slackChannel

  const handleSend = () => {
    sendReport(report.id)
    onSend()

    onClose()
  }

  return (
    <>
      <Button isDisabled={isDisabled} onClick={onOpen} variant="primary">
        Send
      </Button>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Send report
            </AlertDialogHeader>

            <AlertDialogBody>
              {' '}
              <Stack>
                <Text>Are you sure? it will send to the following recipients immediately:</Text>
                {report.emails && (
                  <Stack spacing={0}>
                    <Text fontWeight="semibold">{report.emails.length > 1 ? 'Emails' : 'Email'}</Text>
                    <Text>{report.emails.split(',').join(', ')}</Text>
                  </Stack>
                )}
                {report.slackChannel && (
                  <Stack spacing={0}>
                    <Text fontWeight="semibold">Slack channel</Text>
                    <Text>{report.slackChannel.name}</Text>
                  </Stack>
                )}
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup>
                <Button ref={cancelRef} onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handleSend} variant="primary">
                  Send
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default SendReportButton
