import { type FC } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { TimeInput } from '@app/shared/rawForms'
import type { Props as TimeInputProps } from '@app/shared/rawForms/timeInput'
import type { MetricSource } from '@graphql/types'

type Props = Omit<TimeInputProps, 'ref'> & {
  metricSource: Pick<MetricSource, 'importTime'> | null
}

const ImportTimeInput: FC<Props> = ({ metricSource, ...props }) => {
  const { user } = useStoreCurrentUser()
  const defaultImportTime = user?.organization?.defaultImportTime
  const defaultValue = metricSource?.importTime || defaultImportTime || '08:00'

  return <TimeInput defaultValue={defaultValue} label="Daily import time" name="importTime" {...props} />
}

export default ImportTimeInput
