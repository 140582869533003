import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import IntegrationConnection from '@app/pages/settings/integrations/components/integrationConnection'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import withAwait from '@app/shared/withAwait'
import ICON_MAP from '@app/utils/iconMap'
import { Routes } from '@app/utils/routeHelpers'
import { IntegrationSourceNamesEnum } from '@graphql/types'

const Productboard = () => {
  const integration = useGetObjectsByProperties('integration', {
    sourceName: IntegrationSourceNamesEnum.Productboard
  })[0]

  return (
    <PageStack>
      <PageHeader title="Productboard" subtitle="Import products, components, and features from Productboard." />
      <IntegrationConnection
        name="Productboard"
        icon={ICON_MAP.Productboard}
        items="products, components, and features"
        integration={integration}
        connectUrl={Routes.productboardAuthentication}
      />
    </PageStack>
  )
}

export default withAwait(Productboard, 'integration')
