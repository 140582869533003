import type { TextareaProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, Textarea } from '@chakra-ui/react'
import type { FC } from 'react'

import type { Errors } from '@app/shared/rawForms/useForm'

interface Props extends TextareaProps {
  name: string
  label?: string
  labelSize?: string
  placeholder?: string
  isRequired?: boolean
  helperText?: React.ReactNode
  errors?: Errors
}

const TextAreaInput: FC<Props> = ({
  name,
  label = '',
  labelSize = 'md',
  placeholder = '',
  isRequired = false,
  helperText = null,
  errors = {},
  ...rest
}) => (
  <FormControl id={name} isInvalid={!!errors[name]} isRequired={isRequired}>
    <Stack>
      {label && (
        <FormLabel fontSize={labelSize} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <Stack w="100%">
        <Textarea name={name} placeholder={placeholder} {...rest} />
        <FormErrorMessage>{errors[name] && errors[name].message}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    </Stack>
  </FormControl>
)

export default TextAreaInput
