import { Stack, StackDivider, useBoolean } from '@chakra-ui/react'
import type { FC } from 'react'
import { RRule } from 'rrule'

import { RRuleInput, SlackAutocompleteInput, ToggleInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import type { MapDomainMetric } from '@app/types'

interface Props {
  defaultValue?: string
  metric?: MapDomainMetric
  errors?: Errors
}

const SubscriptionSection: FC<Props> = ({ metric = null, errors = null, defaultValue = null }) => {
  const [isChecked, setIsChecked] = useBoolean(metric?.sendsSubscriptions)

  return (
    <Stack divider={<StackDivider />} spacing="5">
      <ToggleInput
        name="sendsSubscriptions"
        label="Enable Slack digest"
        defaultValue={isChecked}
        isChecked={isChecked}
        onChange={setIsChecked.toggle}
      />
      {isChecked && (
        <SlackAutocompleteInput
          defaultValue={metric?.slackChannel}
          name="slackChannelId"
          label="Slack channel"
          helperText="Choose a Slack channel above to have a Slack digest delivered on the below schedule."
          errors={errors}
        />
      )}
      <RRuleInput
        name="subscriptionSchedule"
        disabled={!isChecked}
        availableFrequencies={[RRule.MONTHLY, RRule.WEEKLY, RRule.DAILY]}
        defaultValue={defaultValue}
      />
    </Stack>
  )
}

export default SubscriptionSection
