import type { FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { useAmplitudeMetricSourceEventNamesQuery } from '@graphql/queries'
import type { AmplitudeRegionEnum } from '@graphql/types'

type Props = Omit<SelectInputProps, 'children'> & {
  credentialId: string
  region: AmplitudeRegionEnum
}

const EventNamesInput: FC<Props> = ({ region, credentialId, isDisabled, ...selectInputProps }) => {
  const [{ data, fetching }] = useAmplitudeMetricSourceEventNamesQuery({
    variables: {
      region,
      credentialId
    }
  })

  const options = data?.amplitudeMetricSourceEventNames || []

  return (
    <SelectInput {...selectInputProps} isDisabled={isDisabled || fetching}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectInput>
  )
}

export default EventNamesInput
