import { Box, Button, HStack, Input, Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'

import { FormAlert } from '@app/shared/forms'
import { TextInput } from '@app/shared/rawForms'
import { RawForm } from '@app/shared/rawForms/form'
import useToast from '@app/shared/toast'
import type { MapDomainIntegration } from '@app/types'
import { useIntegrationCreateMutation } from '@graphql/queries'

interface Props {
  integration?: Pick<MapDomainIntegration, 'sourceId' | 'sourceName'>
}

const ShortcutTokenForm: FC<Props> = ({ integration = null }) => {
  const toast = useToast()
  const [error, setError] = useState<string | null>(null)
  const [, doCreate] = useIntegrationCreateMutation()
  const defaultSourceName = integration?.sourceName || 'shortcut'
  const sourceId = integration?.sourceId

  const createIntegration: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setError(null)

    const formData = new FormData(e.currentTarget)
    const formValues = Object.fromEntries(formData.entries())

    try {
      await doCreate({
        input: {
          sourceName: formValues.sourceName as string,
          sourceId: formValues.sourceId as string
        }
      })

      toast({
        title: 'Token updated',
        description: 'Your token has been added to the integration!',
        status: 'success'
      })
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <Stack shouldWrapChildren spacing="4">
      <Text fontSize="sm">
        Click here to go to your{' '}
        <ChakraLink color="link" href="https://app.shortcut.com/settings/account/api-tokens" isExternal target="_blank">
          Shortcut API Tokens
        </ChakraLink>{' '}
        page to create a new one. Copy it and paste it here.
      </Text>
      <RawForm onSubmit={createIntegration}>
        <FormAlert description={error} title="Failed to create the integration!" />
        <HStack align="end" p={4} border="1px" borderColor="bg.muted">
          <TextInput
            variant="tertiary"
            labelSize="sm"
            name="sourceId"
            label="API Token"
            placeholder="The API Token you got from Shortcut"
            isRequired
            defaultValue={sourceId}
            autoComplete="off"
          />
          <Box p={1}>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Box>
        </HStack>
        <Input defaultValue={defaultSourceName} name="sourceName" type="hidden" />
      </RawForm>
    </Stack>
  )
}

export default ShortcutTokenForm
