import { useDisclosure } from '@chakra-ui/react'
import { createContext, type FC, type ReactNode, useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  isDrawerOpen: boolean
  onDrawerToggle: () => void
  onDrawerClose: () => void
  children: ReactNode
}

interface DrawerContextWrapperProps {
  strategyId: string
  children: ReactNode
}

const DrawerContextProvider = createContext(undefined)

const DrawerContext: FC<Props> = ({ isDrawerOpen, onDrawerToggle, onDrawerClose, children }) => {
  const value = useMemo(
    () => ({
      isDrawerOpen,
      onDrawerToggle,
      onDrawerClose
    }),
    [isDrawerOpen, onDrawerToggle, onDrawerClose]
  )

  return <DrawerContextProvider.Provider value={value}>{children}</DrawerContextProvider.Provider>
}

const DrawerContextWrapper: FC<DrawerContextWrapperProps> = ({ strategyId, children }) => {
  const navigate = useNavigate()

  const { isOpen: isDrawerOpen, onToggle: onDrawerToggle } = useDisclosure()

  // This feels weird to be here. Its intended to be used for routed drawer usage.
  // Should those places be handling the route back behavior?
  // Can we get the '..' navigate helper to work?
  const onDrawerClose = useCallback(() => {
    navigate(`/strategy/${strategyId}/map`)
  }, [navigate, strategyId])

  return (
    <DrawerContext isDrawerOpen={isDrawerOpen} onDrawerToggle={onDrawerToggle} onDrawerClose={onDrawerClose}>
      {children}
    </DrawerContext>
  )
}

const useDrawerContext = () => {
  const context = useContext<Props>(DrawerContextProvider)

  if (context === undefined) {
    throw new Error('useDrawerContext must be used within DrawerContext.Provider')
  }

  return context
}

export { DrawerContext, useDrawerContext }

export default DrawerContextWrapper
