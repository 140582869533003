import capitalize from 'lodash/capitalize'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import Table from '@app/shared/table'
import type { Classification } from '@graphql/queries'

interface Props {
  classifications: Pick<Classification, 'name' | 'category'>[]
  restrictCategories?: string[]
}

const ClassificationsTable: FC<Props> = ({ classifications, restrictCategories = null }) => {
  if (!classifications.length) {
    return null
  }

  let classificationsToDisplay = classifications

  if (restrictCategories) {
    classificationsToDisplay = classifications.filter((classification) =>
      restrictCategories.includes(classification.category)
    )
  }

  if (!classificationsToDisplay.length) {
    return null
  }

  const columns = [
    {
      header: 'Category',
      cell: function ClassificationCategoryCell({ data }) {
        return capitalize(data.category.replace('_', ' '))
      }
    },
    {
      header: 'Value',
      accessor: 'name'
    }
  ]

  return (
    <Table
      columns={columns}
      data={sortBy(classificationsToDisplay, (classification) => `${classification.category}:${classification.name}`)}
      variant="compact"
    />
  )
}

export default ClassificationsTable
