import { VisuallyHiddenInput } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useState } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import { GOOGLE_ANALYTICS_V4 } from '@app/utils/googleAnalyticsV4'
import { useGoogleIntegrationQuery } from '@graphql/queries'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  isDisabled: boolean
}

const GoogleAnalyticsV4: FC<Props> = ({ metricSource, isDisabled }) => {
  const [{ data }] = useGoogleIntegrationQuery()
  const [gametricName, setGametricName] = useState('')
  const [propertyName, setPropertyName] = useState('')

  const { googleIntegration } = data || {
    googleIntegration: {
      id: '',
      propertiesV4: [
        {
          id: '',
          display_name: ''
        }
      ]
    }
  }

  const { metrics } = GOOGLE_ANALYTICS_V4

  return (
    <>
      <VisuallyHiddenInput name="integrationId" readOnly value={googleIntegration?.id} />
      <VisuallyHiddenInput name="configuration.gametric.name" readOnly value={gametricName} />
      <VisuallyHiddenInput name="configuration.property.name" readOnly value={propertyName} />
      <SelectInput
        label="Property"
        name="configuration.property.id"
        placeholder="Choose a property"
        isRequired
        isDisabled={isDisabled}
        onBlur={(e) => {
          setPropertyName(e.target.options[e.target.selectedIndex].text)
        }}
        defaultValue={metricSource?.configuration?.property?.id}
      >
        {googleIntegration?.propertiesV4.map((property) => (
          <option key={property.id} value={property.id}>
            {property.display_name} ({property.id})
          </option>
        ))}
      </SelectInput>

      <SelectInput
        name="configuration.gametric.id"
        label="Metric"
        placeholder="Choose a metric"
        onChange={(e) => {
          setGametricName(e.target.options[e.target.selectedIndex].text)
        }}
        isRequired
        isDisabled={isDisabled}
        defaultValue={metricSource?.configuration?.gametric?.id}
      >
        {sortBy(metrics, 'name').map((metric) => (
          <option key={metric.id} value={metric.id}>
            {metric.name}
          </option>
        ))}
      </SelectInput>
    </>
  )
}

export default GoogleAnalyticsV4
