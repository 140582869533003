import { Button, ButtonGroup, Checkbox, HStack, Icon, IconButton, Input, Text } from '@chakra-ui/react'
import type { ChangeEventHandler } from 'react'
import { useState } from 'react'
import { FiCheck, FiChevronDown, FiX } from 'react-icons/fi'
import { useFetcher } from 'react-router-dom'

import ColorPickerPopover from './colorPickerPopover'
import ColorSquare from './colorSquare'
import IconPickerPopover from './iconPickerPopover'

import { iconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import { useStore } from '@app/store'
import { CardTypeCreate } from '@graphql/documents/card_types.graphql'
import type { CardTypeCreateMutation, CardTypeCreateMutationVariables } from '@graphql/queries'

const CreateBasicCardTypeButton = () => {
  const [editing, setEditing] = useState(false)
  const [showInMenu, setShowInMenu] = useState(true)
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [icon, setIcon] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const typeCreateFetcher = useFetcher()

  const IconComponent = iconMap[icon]
  const iconColor = color ? `${color}.500` : 'gray.500'
  const renderedIcon = icon ? <Icon as={IconComponent} boxSize={5} color={iconColor} /> : <Text>None</Text>
  const actionMutation = useStore.use.actionMutation()

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      await actionMutation<CardTypeCreateMutation, CardTypeCreateMutationVariables>(CardTypeCreate, {
        name,
        color,
        icon,
        showInMenu
      })
      setEditing(false)
      setName('')
      setColor('')
      setIcon('')
      setShowInMenu(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleShowInMenuChange = (e: boolean) => {
    setShowInMenu(e)
  }

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value)
  }

  const handleColorChange = (e: string) => {
    setColor(e)
  }

  const handleIconChange = (e: string) => {
    setIcon(e)
  }

  if (editing) {
    return (
      <HStack>
        <Checkbox isChecked={showInMenu} onChange={(e) => handleShowInMenuChange(e.target.checked)} />
        <ColorPickerPopover onClick={handleColorChange} value={color}>
          <HStack w="50px" cursor="pointer">
            <ColorSquare color={color} />
            <Icon as={FiChevronDown} />
          </HStack>
        </ColorPickerPopover>
        <IconPickerPopover onClick={handleIconChange} value={icon} color={color}>
          <HStack cursor="pointer">
            {renderedIcon}
            <Icon as={FiChevronDown} />
          </HStack>
        </IconPickerPopover>
        <Input defaultValue={name} isRequired name="name" onChange={handleNameChange} placeholder="Name..." />
        <ButtonGroup isAttached variant="tertiary">
          <IconButton
            aria-label="Create card type"
            colorScheme="green"
            icon={<FiCheck />}
            isDisabled={!name}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <IconButton aria-label="Cancel" colorScheme="red" icon={<FiX />} onClick={() => setEditing(false)} />
        </ButtonGroup>
      </HStack>
    )
  }

  return (
    <Button w="100%" isLoading={typeCreateFetcher.state !== 'idle'} onClick={() => setEditing(true)} variant="tertiary">
      Create type
    </Button>
  )
}

export default CreateBasicCardTypeButton
