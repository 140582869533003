import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import type { FC, RefObject } from 'react'
import { useState } from 'react'
import { AiOutlineQuestion } from 'react-icons/ai'
import { FiCheck, FiX } from 'react-icons/fi'

import useToast from '@app/shared/toast'
import { actionMutation } from '@graphql/client'
import { CredentialAttributesTest } from '@graphql/documents/credential.graphql'
import type { CredentialAttributesTestMutation } from '@graphql/queries'

type Props = ButtonProps & {
  formRef: RefObject<HTMLFormElement>
}

enum CredentialState {
  Success,
  Failure,
  Indeterminate
}

const TestCredentialsButton: FC<Props> = ({ formRef, ...rest }) => {
  const [credentialState, setCredentialState] = useState(CredentialState.Indeterminate)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const onClick = async () => {
    setIsLoading(true)
    const formData = new FormData(formRef.current)

    const result = await actionMutation<CredentialAttributesTestMutation>(
      CredentialAttributesTest,
      Object.fromEntries(formData.entries())
    )

    setIsLoading(false)
    const success = result.data?.credentialAttributesTest?.success

    if (success) {
      setCredentialState(CredentialState.Success)
    } else {
      setCredentialState(CredentialState.Failure)
      const errors = result.data?.credentialAttributesTest?.errors
      const error = errors?.[0]?.message

      toast({
        title: 'Test failed',
        description: error || 'Unknown error'
      })
    }
  }

  let additional: Partial<ButtonProps>

  if (credentialState === CredentialState.Success) {
    additional = {
      leftIcon: <FiCheck />,
      colorScheme: 'green'
    }
  } else if (credentialState === CredentialState.Failure) {
    additional = {
      leftIcon: <FiX />,
      colorScheme: 'red'
    }
  } else {
    additional = {
      leftIcon: <AiOutlineQuestion />,
      colorScheme: 'gray'
    }
  }

  return (
    <Button isLoading={isLoading} onClick={onClick} {...additional} {...rest}>
      Test credentials
    </Button>
  )
}

export default TestCredentialsButton
