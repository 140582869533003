import type { AlertDialogProps } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Stack,
  Text
} from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { useRef } from 'react'

interface Props
  extends Partial<Pick<AlertDialogProps, 'isOpen' | 'onClose'> & { success?: boolean; status?: string }> {}

const runningText = (
  <Stack spacing={2}>
    <Text>
      Your metric source run has been scheduled and will run soon. We will also pull in any historical data if
      available.
    </Text>

    <Text>This may take a few minutes to complete.</Text>
  </Stack>
)

const skippedText = (
  <Stack spacing={2}>
    <Text>Your metric source run has been skipped because another import is already running.</Text>

    <Text>Please check back in a few minutes.</Text>
  </Stack>
)

const failedText = (
  <Stack spacing={2}>
    <Text>There was an error running your metric source.</Text>

    <Text>Please use the error information below or contact the DoubleLoop team for assistance.</Text>
  </Stack>
)

const MetricSourceRunResults: FC<Props> = ({ isOpen = false, onClose = () => {}, success = false, status }) => {
  const closeRef = useRef()
  let bodyText = failedText

  if (success) {
    bodyText = runningText
  } else if (status === 'skipped') {
    bodyText = skippedText
  }

  return (
    <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={closeRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Run {capitalize(status)}
          </AlertDialogHeader>

          <AlertDialogBody>{bodyText}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={closeRef} ml={3} onClick={onClose} variant="primary">
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default MetricSourceRunResults
