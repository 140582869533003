import { type ToastId } from '@chakra-ui/react'
import { useReactFlow } from '@xyflow/react'
import { useEffect, useRef, type FC } from 'react'

import useToast, { Notification } from '@app/shared/toast'
import type { MapDomainStrategy } from '@app/types'

interface Props {
  strategy?: MapDomainStrategy
}

const LoadingToast: FC<Props> = ({ strategy }) => {
  const toast = useToast()
  const toastRef = useRef<ToastId>()
  const reactFlow = useReactFlow()

  useEffect(() => {
    const id = `strategy-${strategy?.id}-toast`

    if (!toast.isActive(id) && strategy?.indicatorCounter > 0) {
      toastRef.current = toast({
        id,
        title: 'Loading the map',
        description: 'Please wait while your map finishes loading',
        position: 'top',
        status: 'success',
        duration: null,
        render: (props) => <Notification {...props} />
      })
    } else if (toastRef.current) {
      toast.close(toastRef.current)
      reactFlow.fitView()
    }
  }, [strategy])

  return null
}

export default LoadingToast
