import { Container } from '@chakra-ui/react'
import { useOutletContext, useParams } from 'react-router-dom'

import EntityForm from './components/entityForm'

import useGetObject from '@app/hooks/useGetObject'
import withAwait from '@app/shared/withAwait'
import type { Entity } from '@graphql/types'

const Edit = () => {
  const optionContext: unknown[] = useOutletContext()

  let options = {}

  if (optionContext) {
    ;[, , , options] = optionContext
  }

  const { entityId } = useParams()

  const entity = useGetObject(entityId, 'entity') as Entity

  return (
    <Container py={8}>
      <EntityForm entity={entity} {...options} />
    </Container>
  )
}

export default withAwait(Edit, 'entity')
