import {
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Icon,
  IconButton,
  Text,
  Tr,
  Td,
  Checkbox
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FiChevronDown, FiTrash2 } from 'react-icons/fi'

import { iconMap } from '../customIcons'

import CardTypeDeleteButton from './cardTypeDeleteButton'
import ColorPickerPopover from './colorPickerPopover'
import ColorSquare from './colorSquare'
import IconPickerPopover from './iconPickerPopover'

import { useStore } from '@app/store'
import type { CardType } from '@graphql/types'

interface Props {
  cardType: CardType
}

const CardTypeTableRow: FC<Props> = ({ cardType }) => {
  const [name, setName] = useState(cardType.name || '')
  const [color, setColor] = useState(cardType.color || '')
  const [icon, setIcon] = useState(cardType.icon || '')
  const [showInMenu, setShowInMenu] = useState<boolean>(cardType.showInMenu || false)
  const [deleting, setDeleting] = useState(false)
  const updateObject = useStore.use.updateObject()

  const IconComponent = iconMap[icon]
  const iconColor = color ? `${color}.500` : 'gray.500'
  const renderedIcon = icon ? <Icon as={IconComponent} boxSize={5} color={iconColor} /> : <Text>None</Text>

  const handleColorChange = (e: string) => {
    setColor(e)

    updateObject({ cardType: { id: cardType.id, color: e } })
  }

  const handleIconChange = (e: string) => {
    setIcon(e)

    updateObject({ cardType: { id: cardType.id, icon: e } })
  }

  const handleNameChange = (e: string) => {
    updateObject({ cardType: { id: cardType.id, name: e } })
  }

  const handleShowInMenuChange = (e: boolean) => {
    setShowInMenu(e)

    updateObject({ cardType: { id: cardType.id, showInMenu: e } })
  }

  return (
    <Tr>
      <Td w="55px">
        <ColorPickerPopover onClick={handleColorChange} value={color}>
          <HStack w="50px" cursor="pointer">
            <ColorSquare color={color} />
            <Icon as={FiChevronDown} />
          </HStack>
        </ColorPickerPopover>
      </Td>
      <Td w="55px" px={1}>
        <IconPickerPopover onClick={handleIconChange} value={icon} color={color}>
          <HStack cursor="pointer">
            {renderedIcon}
            <Icon as={FiChevronDown} />
          </HStack>
        </IconPickerPopover>
      </Td>
      <Td>
        <Editable
          fontSize="md"
          defaultValue={name}
          onChange={setName}
          onSubmit={handleNameChange}
          placeholder="Add a name"
        >
          <EditablePreview sx={{ textWrap: 'wrap' }} w="100%" color={`${color}.500`} lineHeight="1.2" />
          <EditableInput />
        </Editable>
      </Td>
      <Td w="60px" pr={0} textAlign="end">
        <HStack>
          <Checkbox isChecked={showInMenu} onChange={(e) => handleShowInMenuChange(e.target.checked)} />
          <CardTypeDeleteButton cardTypeId={cardType.id} onDelete={() => setDeleting(true)}>
            <IconButton
              color="red.500"
              fontSize="lg"
              aria-label="Delete type"
              icon={<FiTrash2 />}
              isLoading={deleting}
              variant="ghost"
            />
          </CardTypeDeleteButton>
        </HStack>
      </Td>
    </Tr>
  )
}

export default CardTypeTableRow
