import type { UseToastOptions } from '@chakra-ui/react'
import {
  ButtonGroup,
  Center,
  Flex,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useToast as useChakraToast
} from '@chakra-ui/react'
import pick from 'lodash/pick'
import type { FC, ReactNode } from 'react'
import { FiInfo } from 'react-icons/fi'

interface Props {
  title?: ReactNode
  description?: ReactNode
  status?: 'success' | 'info' | 'warning' | 'error' | 'loading'
  buttons?: ReactNode
}

export const Notification: FC<Props> = ({ title, description = '', buttons = null, status = 'info' }) => {
  let baseColor = 'blue'

  switch (status) {
    case 'info':
      baseColor = 'blue'
      break
    case 'warning':
      baseColor = 'yellow'
      break
    case 'success':
      baseColor = 'green'
      break
    case 'error':
      baseColor = 'red'
      break
    default:
  }

  const color = useColorModeValue(`${baseColor}.500`, `${baseColor}.300`)

  return (
    <Flex direction="row-reverse">
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        overflow="hidden"
        w={{ base: 'full', sm: 'md' }}
        bg={useColorModeValue('white', 'gray.700')}
        borderRadius="lg"
        shadow={useColorModeValue('md', 'md-dark')}
      >
        <Center display={{ base: 'none', sm: 'flex' }} px="5" bg={color}>
          <Icon as={FiInfo} boxSize="10" color="white" />
        </Center>
        <Stack direction="row" flex="1" p="4" spacing="3">
          <Stack flex="1" spacing="2.5">
            <Stack spacing="1">
              <Text fontSize="sm" fontWeight="medium">
                {title}
              </Text>
              <Text color="muted" fontSize="sm">
                {description || ' '}
              </Text>
            </Stack>
            <ButtonGroup spacing="3" variant="text">
              {buttons}
            </ButtonGroup>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  )
}

function useToast(options?: UseToastOptions): ReturnType<typeof useChakraToast> {
  return useChakraToast({
    position: 'bottom-right',
    render: (toastOptions) => <Notification {...pick(toastOptions, ['title', 'description', 'status', 'buttons'])} />,
    ...(options || {})
  })
}
export default useToast
