import { Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'

import { PasswordInput, TextInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'apiKey'>
}

const AmplitudeAuth: FC<Props> = ({ credential = {} }) => {
  const errors: Errors = {}
  const { apiKey } = credential

  return (
    <Stack>
      <TextInput
        name="apiKey"
        label="API Key"
        placeholder="Amplitude API key"
        autoComplete="off"
        errors={errors}
        defaultValue={apiKey}
        isRequired
        data-1p-ignore
      />
      <PasswordInput
        name="secretKey"
        label="Secret Key"
        placeholder="Amplitude secret key"
        autoComplete="off"
        errors={errors}
        helperText={credential ? 'Leave blank to keep the current secret or fill in to set a new one' : null}
        isRequired={!credential}
        data-1p-ignore
      />
      {Object.keys(credential || {}).length && <VisuallyHiddenInput defaultValue="secretKey" name="secretFields" />}
    </Stack>
  )
}

export default AmplitudeAuth
