import { Box, Flex, useColorModeValue, useDisclosure } from '@chakra-ui/react'

import DrawerSidebar from './drawerSidebar'
import { ToggleButton } from './toggleButton'

import Wordmark from '@app/images/wordmark'

const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <Box w="full" px={{ base: '4', md: '8' }} py="4" bg="bg.surface" shadow={useColorModeValue('sm', 'sm-dark')}>
      <Flex justify="space-between">
        <Wordmark w="100px" h="auto" color={useColorModeValue('blue.600', 'blue.200')} />
        <ToggleButton variant="ghost" isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
        <DrawerSidebar isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Box>
  )
}

export default Navbar
