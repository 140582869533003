import { Box, Button, Stack } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import upperFirst from 'lodash/upperFirst'
import type { FC } from 'react'
import { useState } from 'react'
import { FiSend } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import Comment from './components/comment'
import CommentList from './components/commentList'
import CommentTextArea from './components/commentTextArea'

import useGetComments from '@app/hooks/useGetComments'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useStore } from '@app/store'

interface Props extends BoxProps {
  commentableId: string
  commentableType: string
}

const Comments: FC<Props> = ({ commentableId, commentableType, ...rest }) => {
  const [body, setBody] = useState('')
  const { strategyId } = useParams()
  const { user } = useStoreCurrentUser()
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  const type = upperFirst(commentableType)

  const addComment = useStore.use.addComment()
  const comments = useGetComments(type, commentableId)

  const handleCommentCreate = (e) => {
    e.preventDefault()
    e.stopPropagation()

    addComment(
      {
        body,
        commentableId,
        commentableType: type
      },
      user
    )
    setBody('')
  }

  return (
    <Box p={4} bg="bg.canvas" {...rest}>
      <Stack spacing={6}>
        <CommentList>
          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}
        </CommentList>
        {user && !isAnonymousUser && (
          <Box as="form" pos="relative" pb="1" onSubmit={handleCommentCreate}>
            <CommentTextArea value={body} onChange={(e) => setBody(e.target.value)} onSubmit={handleCommentCreate} />
            <Box pos="absolute" zIndex="2" top="3" right="0">
              <Button colorScheme="gray" size="sm" type="submit" variant="text">
                <FiSend />
              </Button>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default Comments
