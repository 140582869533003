import groupBy from 'lodash/groupBy'

import client from '@graphql/client'
import { ContributorsAutocomplete } from '@graphql/documents/contributor.graphql'

export const contributorToPickerItem = (contributor) => {
  const contrib = contributor?.contributor ? contributor.contributor : contributor
  const { sourceName, name, id, searchableContributorId } = contrib

  return { value: id, label: name, sourceName, searchableId: searchableContributorId }
}

export const fetchContributors = async (inputValue) => {
  const { data } = await client.query(ContributorsAutocomplete, { query: inputValue }).toPromise()

  const formattedGroups = []

  if (data?.contributorsAutocomplete) {
    const groups = groupBy(data?.contributorsAutocomplete, 'sourceName')

    Object.keys(groups).forEach((key) => {
      const label = key === 'doubleloop' ? 'members' : key
      const options = groups[key].map(contributorToPickerItem).sort((a, b) => {
        const labelA = a.label.toLowerCase()
        const labelB = b.label.toLowerCase()

        if (labelA < labelB) {
          return -1
        }

        if (labelA > labelB) {
          return 1
        }

        return 0
      })

      formattedGroups.push({ label, options })
    })
  }

  return formattedGroups
}
