import { IconButton, useDisclosure } from '@chakra-ui/react'
import { type FC } from 'react'

import useGetSelectedNodes from '@app/hooks/useGetSelectedNodes'
import ColorButton from '@app/pages/shared/colorButton'
import { useStore } from '@app/store'
import type { Color } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

type Props = {
  strategyId: string
}

const BulkColorButton: FC<Props> = ({ strategyId }) => {
  const updateNodes = useStore.use.updateNodes()
  const nodes = useGetSelectedNodes(strategyId)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const changeColor = async (color: Color) => {
    await updateNodes(
      strategyId,
      nodes.map((node) => ({
        ...node,
        metadata: {
          ...node.metadata,
          color
        }
      }))
    )
    onClose()
  }

  return (
    <ColorButton selectedColor="" onColorSelect={changeColor} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {(props) => <IconButton aria-label="color-button" icon={<ICON_MAP.ColorAction />} variant="ghost" {...props} />}
    </ColorButton>
  )
}

export default BulkColorButton
