import { FormControl, FormLabel, HStack, Select } from '@chakra-ui/react'
import type { FC } from 'react'

import { ToggleInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import type { MapDomainMetric } from '@app/types'

const OPTIONS = [...Array(21)].map((_, i) => (
  // eslint-disable-next-line react/no-array-index-key
  <option key={i} value={i}>
    {i}
  </option>
))

interface Props {
  metric?: MapDomainMetric
  errors?: Errors
}

const MetricDisplay: FC<Props> = ({ metric = null, errors = null }) => (
  <HStack>
    <ToggleInput
      name="compactDisplay"
      label="Make number compact?"
      stackDirection="column"
      defaultValue={metric?.compactDisplay}
    />
    <FormControl id="minimumDisplayPrecision" isInvalid={!!errors?.minimumDisplayPrecision}>
      <FormLabel fontSize="md" htmlFor="minimumDisplayPrecision">
        Min. Decimal Places
      </FormLabel>
      <Select defaultValue={metric?.minimumDisplayPrecision} name="minimumDisplayPrecision">
        {OPTIONS}
      </Select>
    </FormControl>
    <FormControl id="maximumDisplayPrecision" isInvalid={!!errors?.maximumDisplayPrecision}>
      <FormLabel fontSize="md" htmlFor="maximumDisplayPrecision">
        Max. Decimal Places
      </FormLabel>
      <Select
        defaultValue={metric?.maximumDisplayPrecision ? metric.maximumDisplayPrecision : 2}
        name="maximumDisplayPrecision"
      >
        {OPTIONS}
      </Select>
    </FormControl>
  </HStack>
)

export default MetricDisplay
