import { HStack, Icon, IconButton, type IconProps, Spacer, Text, useColorModeValue } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import type { IconType } from 'react-icons'
import { useNavigate } from 'react-router-dom'

import cardFontSize from '@app/shared/cards/cardFontSize'
import cardIconBoxSize from '@app/shared/cards/cardIconBoxSize'
import CardSection from '@app/shared/cards/components/cardSection'
import type { CardSize } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props extends IconProps {
  title?: string
  icon?: IconType
  color?: string
  size?: CardSize
  route?: string
  commentsCount?: number
  children?: ReactNode
}

export const DomainObjectType: FC<Props> = ({ icon, boxSize, color, title, fontSize, children, ...rest }) => {
  const cardTypeColor = useColorModeValue(`${color}.600`, `${color}.300`)

  return (
    <HStack>
      {icon && <Icon as={icon} boxSize={boxSize} color={cardTypeColor} {...icon} {...rest} />}
      {title && (
        <Text color={cardTypeColor} fontSize={fontSize} fontWeight="semibold" whiteSpace="nowrap">
          {title}
        </Text>
      )}
      {children}
    </HStack>
  )
}

const CardTypeSection: FC<Props> = ({ title, icon, color = 'blue', size = 'normal', route, children, ...rest }) => {
  const navigate = useNavigate()
  const fontSize = cardFontSize('md', size)
  const boxSize = cardIconBoxSize(6, size)
  const sectionSpacing = size === 'normal' ? { px: 4, py: 2 } : { px: 6, py: 4 }

  return (
    <CardSection {...sectionSpacing} size={size} {...rest}>
      <HStack>
        <DomainObjectType icon={icon} boxSize={boxSize} color={color} title={title} fontSize={fontSize}>
          {children}
        </DomainObjectType>

        {route && (
          <>
            <Spacer />
            <IconButton
              color="fg.subtle"
              // fontSize={fontSize}
              aria-label="Details"
              icon={<Icon as={ICON_MAP.ExternalLink} boxSize={4} color="gray.600" />}
              // size="sm"
              onClick={() => navigate(route)}
              variant="ghost"
            />
          </>
        )}
      </HStack>
    </CardSection>
  )
}

export default CardTypeSection
