import { CgLinear } from 'react-icons/cg'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import IntegrationConnection from '@app/pages/settings/integrations/components/integrationConnection'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import withAwait from '@app/shared/withAwait'
import { Routes } from '@app/utils/routeHelpers'
import { IntegrationSourceNamesEnum } from '@graphql/types'

const Linear = () => {
  const integration = useGetObjectsByProperties('integration', { sourceName: IntegrationSourceNamesEnum.Linear })[0]

  return (
    <PageStack>
      <PageHeader title="Linear" subtitle="Send your Linear data to DoubleLoop." />
      <IntegrationConnection
        name="Linear"
        icon={CgLinear}
        items="issues"
        integration={integration}
        connectUrl={Routes.linearAuthentication}
      />
    </PageStack>
  )
}

export default withAwait(Linear, 'integration')
