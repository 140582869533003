import type { TagProps } from '@chakra-ui/react'
import { Tag } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import useGetCardTypeOptions from '@app/next/changelog/useGetCardTypeOptions'
import { DomainObjectType } from '@app/shared/cards/components/cardTypeSection'
import ICON_MAP from '@app/utils/iconMap'
import type { EntityContainment } from '@graphql/types'

type Props = TagProps & {
  containment: Pick<EntityContainment, 'containerId' | 'containerClassName'>
}

const ContainmentTag: FC<Props> = ({ containment, ...rest }) => {
  const container = useGetObject(
    containment.containerId,
    containment.containerClassName as 'metric' | 'entity' | 'basicCard'
  )

  const cardType = useGetCardTypeOptions([container])[0]

  if (!container) {
    return null
  }

  return (
    <Tag {...rest}>
      <DomainObjectType icon={cardType?.icon || ICON_MAP.BasicCard} color="gray" title={container.name} />
    </Tag>
  )
}

export default ContainmentTag
