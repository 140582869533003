import { theme as proTheme } from '@chakra-ui/pro-theme'
import { extendTheme, withDefaultProps, withDefaultSize } from '@chakra-ui/react'

import AggregatedEntities from './components/aggregatedEntiities'
import Form from './components/form'
import Link from './components/link'
import MapShell from './components/mapShell'
import Text from './components/text'
import styles from './styles'

const theme = extendTheme(
  {
    ...styles,
    components: {
      AggregatedEntities,
      Form,
      MapShell,
      Link,
      Text
    },
    config: {
      cssVarPrefix: 'dl',
      initialColorMode: 'light'
    }
  },
  proTheme,
  withDefaultSize({
    size: 'sm',
    components: ['Button', 'ButtonGroup', 'IconButton', 'Badge']
  }),
  withDefaultProps({
    defaultProps: {
      colorScheme: 'brand'
    },
    components: ['Button', 'ButtonGroup', 'IconButton']
  }),
  withDefaultProps({
    defaultProps: {
      colorScheme: 'gray',
      variant: 'ghost'
    },
    components: ['Link']
  })
)

// Uncomment to print combined theme to console
// console.dir(theme)

export default theme
