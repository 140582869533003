import type { FormControlProps, FormLabelProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack } from '@chakra-ui/react'
import type { FC, ReactNode, ForwardedRef } from 'react'

import type { Errors } from '@app/shared/rawForms/useForm'

export type Props = Omit<FormControlProps, 'label'> & {
  name?: string | null
  label?: ReactNode
  labelSize?: FormLabelProps['fontSize']
  isRequired?: boolean
  helperText?: ReactNode | null
  errors?: Errors
  children: ReactNode
  ref?: ForwardedRef<unknown>
}

const RootFormControl: FC<Props> = ({
  name = null,
  label = null,
  labelSize = 'md',
  helperText = null,
  errors = {},
  children,
  ...formControlProps
}) => (
  <FormControl id={name} isInvalid={!!errors[name]} {...formControlProps}>
    <Stack>
      {label && <FormLabel fontSize={labelSize}>{label}</FormLabel>}
      <Stack w="100%">
        {children}
        <FormErrorMessage>{errors[name] && errors[name].message}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    </Stack>
  </FormControl>
)

export default RootFormControl
