import { HStack, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'

import StrategyDeleteButton from './strategyDeleteButton'

import MapCloneButton from '@app/pages/maps/components/mapCloneButton'
import Can from '@app/shared/authorization/can'
import FavoriteToggleButton from '@app/shared/favorites/favoriteToggleButton'
import type { Strategy } from '@graphql/queries'

interface Props {
  strategy: Strategy
}

const StrategyListActions: FC<Props> = ({ strategy }) => {
  const { id, favoriteId } = strategy

  return (
    <Can I="update" a="strategy">
      <HStack>
        <Spacer />
        <FavoriteToggleButton favoritableType="Strategy" favoritableId={id} favoriteId={favoriteId} />
        <MapCloneButton strategyId={strategy.id} />
        <StrategyDeleteButton strategyId={strategy.id} />
      </HStack>
    </Can>
  )
}

export default StrategyListActions
