import { IconButton, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useNavigate, useParams } from 'react-router-dom'

import RecurringReportConfigurationForm from './components/recurringReportConfigurationForm'

import useGetObject from '@app/hooks/useGetObject'
import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import { ReportContext } from '@app/pages/reports/home'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import useToast from '@app/shared/toast'
import { useRecurringReportConfigurationCreateMutation } from '@graphql/queries'
import type { RecurringReportConfigurationServiceEnum } from '@graphql/types'

const backLink = '../..'

const Create: FC = () => {
  const { strategyId } = useParams()
  const strategy = useGetObject(strategyId, 'strategy')
  const navigate = useNavigate()
  const toast = useToast()
  const [, createRecurringReport] = useRecurringReportConfigurationCreateMutation()
  const onCancel = () => {
    navigate(backLink)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const input = Object.fromEntries(formData.entries()) as Record<string, string>

    const resp = await createRecurringReport({
      input: {
        ...input,
        strategyId,
        name: input.name || 'Untitled Report',
        schedule: input.schedule || 'FREQ=WEEKLY;BYDAY=MO',
        service: (input.service || 'emit_report') as RecurringReportConfigurationServiceEnum
      }
    })

    const id = resp?.data?.recurringReportConfigurationCreate?.recurringReportConfiguration?.id

    if (id) {
      toast({
        title: 'Report created!',
        description: 'Your recurring report was successfully created',
        status: 'success'
      })

      navigate(`../${id}`)
    } else {
      toast({
        title: 'Failed to create report',
        description: 'We were unable to create your report, please contact support.',
        status: 'error'
      })
    }
  }

  return (
    <ReportContext.Consumer>
      {() => (
        <Stack w="3xl" maxW="100%" mx="auto" spacing={4}>
          <DrawerHeader px={4}>
            <IconButton as={Link} aria-label="go back" icon={<FiArrowLeft />} to={backLink} variant="ghost" />
            <Text color="subtle" fontSize="sm">
              Create Recurring Report
            </Text>
            <Spacer />
            <DrawerCloseButton />
          </DrawerHeader>

          <Stack px={4} pb={4} spacing={4}>
            <RecurringReportConfigurationForm strategy={strategy} onCancel={onCancel} onSubmit={onSubmit} />
          </Stack>
        </Stack>
      )}
    </ReportContext.Consumer>
  )
}

export default Create
