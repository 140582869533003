import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['form', 'formControl', 'stack', 'formLabel'])

const Form = helpers.defineMultiStyleConfig({
  baseStyle: {
    stack: {
      justify: 'space-between',
      direction: { base: 'column', xl: 'column' },
      spacing: { base: '1.5', xl: '1.5' }
    },
    formLabel: {
      variant: 'inline',
      fontSize: 'md'
    }
  }
})

export default Form
