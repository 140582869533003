import { Box, Tooltip, keyframes } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  color?: string
  size?: string
  top?: number
  right?: number
  bottom?: number
  left?: number
  animationSpeed?: number
  label?: string
  link?: string
  isActive?: boolean
}

const PulsingCircle: FC<Props> = ({
  color = 'blue.500',
  size = '24px',
  top,
  right,
  bottom,
  left,
  animationSpeed = 2.25,
  label = 'Recent events within two weeks',
  link,
  isActive = false
}) => {
  const pulseDot = keyframes({
    '0%': {
      transform: 'scale(.8)'
    },
    '50%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(.8)'
    }
  })

  if (!isActive) {
    return null
  }

  const floatSize = parseFloat(size)
  const absoluteOffset = Math.floor(floatSize / 12) * -1
  const linkProps: { to?: string; cursor?: string } = {}

  if (link) {
    linkProps.to = link
    linkProps.cursor = 'pointer'
  }

  return (
    <Tooltip label={label} placement="top">
      <Box
        as={link ? Link : Box}
        pos="absolute"
        top={top || absoluteOffset}
        right={right || absoluteOffset}
        bottom={bottom}
        left={left}
        w={size}
        h={size}
        _after={{
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: color,
          borderRadius: '100px',
          boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
          animation: `${pulseDot} ${animationSpeed}s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`
        }}
        {...linkProps}
      />
    </Tooltip>
  )
}

export default PulsingCircle
