import { Box } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  text?: string
}

const Description: FC<Props> = ({ text = null }) => {
  if (!text) {
    return null
  }

  return <Box>{text}</Box>
}

export default Description
