import { Divider, HStack, Spacer, Stack } from '@chakra-ui/react'
import { type ElementType, type FC, useState } from 'react'
import { PiList, PiRows } from 'react-icons/pi'
import { useParams } from 'react-router-dom'

import BasicCardEntitiesFetcher from '@app/fetchers/basicCardEntitiesFetcher'
import EntityEntitiesFetcher from '@app/fetchers/entityEntitiesFetcher'
import MetricEntitiesFetcher from '@app/fetchers/metricEntitiesFetcher'
import useGetObject from '@app/hooks/useGetObject'
import MetricGraph from '@app/pages/metrics/components/metricGraph'
import Can from '@app/shared/authorization/can'
import AggregatedEntitiesList from '@app/shared/entities'
import CreateEntityButton from '@app/shared/entities/createEntityButton'
import { RadioIconButton, RadioIconButtonGroup } from '@app/shared/forms/radioIconButtonGroup'
import type { Nodes } from '@app/types'
import { EntityWorkflowStateEnum } from '@graphql/types'

interface EntitiesListProps {
  Fetcher: ElementType
  fetcherParams: object
  layout?: 'roadmap' | 'changelog'
}

const EntitiesList: FC<EntitiesListProps> = ({ Fetcher, fetcherParams, layout = 'roadmap' }) => (
  <Fetcher {...fetcherParams}>
    {(collection, metadata) => <AggregatedEntitiesList collection={collection} metadata={metadata} layout={layout} />}
  </Fetcher>
)

type FetcherParams = {
  id: string
  workflowState?: EntityWorkflowStateEnum
}

interface Props {
  nodeType: Nodes
}

const List: FC<Props> = ({ nodeType }) => {
  const { nodeId } = useParams()
  const [layout, setLayout] = useState<'changelog' | 'roadmap'>('roadmap')

  const domainObject = useGetObject(nodeId, nodeType as Exclude<Nodes, 'existing'>)

  let fetcher = null
  const fetcherParams: FetcherParams = { id: nodeId }

  if (layout === 'changelog') {
    fetcherParams.workflowState = EntityWorkflowStateEnum.Finished
  }

  switch (nodeType) {
    case 'metric':
      fetcher = MetricEntitiesFetcher
      break
    case 'entity':
      fetcher = EntityEntitiesFetcher
      break
    case 'basicCard':
      fetcher = BasicCardEntitiesFetcher
      break
    default:
      throw new Error(`Unknown entity list for type: ${nodeType}`)
  }

  return (
    <Stack px={4} spacing={0}>
      {nodeType === 'metric' && <MetricGraph metric={domainObject} />}
      <HStack p={4}>
        <Spacer />
        <Can I="create" an="event" passThrough>
          {(allowed) => <HStack>{allowed && <CreateEntityButton size="xs" text="Add work item" />}</HStack>}
        </Can>

        <Divider h="1.5rem" orientation="vertical" />
        <RadioIconButtonGroup defaultValue={layout} size="sm">
          <RadioIconButton
            tooltip="Changelog"
            value="changelog"
            aria-label="Changelog"
            icon={<PiRows />}
            onClick={() => {
              setLayout('changelog')
            }}
          />
          <RadioIconButton
            value="roadmap"
            tooltip="Roadmap"
            aria-label="Roadmap"
            icon={<PiList />}
            onClick={() => {
              setLayout('roadmap')
            }}
          />
        </RadioIconButtonGroup>
      </HStack>
      <EntitiesList Fetcher={fetcher} fetcherParams={fetcherParams} layout={layout} />
    </Stack>
  )
}

export default List
