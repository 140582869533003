import { HStack, IconButton, Text, type TextProps, Tooltip } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { type FC } from 'react'

import MultiDeleteButton from './multiDeleteButton'

import useGetObjectsByIds from '@app/hooks/useGetObjectsByIds'
import type { BulkLabelableObject } from '@app/pages/maps/components/nodes/components/toolbar/components/bulkLabels/bulkLabelsPopover'
import BulkLabelsPopover from '@app/pages/maps/components/nodes/components/toolbar/components/bulkLabels/bulkLabelsPopover'
import Can from '@app/shared/authorization/can'
import ICON_MAP from '@app/utils/iconMap'

type Props = TextProps & {
  type: 'basicCard' | 'entity' | 'metric' | 'strategy'
  selected: (string | number)[]
  onDelete?: () => void
}

const MultiOps: FC<Props> = ({ type, selected, onDelete, ...rest }) => {
  const count = selected.length

  const domainObjects = useGetObjectsByIds(
    type,
    selected.map((s) => s.toString())
  ) as BulkLabelableObject[]

  if (count === 0) {
    return null
  }

  return (
    <HStack>
      <Text whiteSpace="nowrap" {...rest}>
        {count} {pluralize('row', count)} selected
      </Text>
      <Can I="update" a={type}>
        <Tooltip hasArrow label="Add labels" shouldWrapChildren>
          <BulkLabelsPopover domainObjects={domainObjects}>
            <IconButton
              color="error"
              aria-label={`Delete ${type}`}
              data-cy={`${type}-delete-button`}
              icon={<ICON_MAP.LabelAction />}
              variant="ghost"
            />
          </BulkLabelsPopover>
        </Tooltip>
      </Can>
      <Can I="delete" a={type}>
        <Tooltip hasArrow label="Delete" shouldWrapChildren>
          <MultiDeleteButton type={type} selected={selected} onDelete={onDelete} />
        </Tooltip>
      </Can>
    </HStack>
  )
}

export default MultiOps
