import { Button, ButtonGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useEffect, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import SegmentFiltersModal from './segmentFiltersModal'

import useGetObject from '@app/hooks/useGetObject'
import MapSegmentFilter from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFilter'
import type { StrategyQueryStrategy } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { useSegmentFiltersCreateMutation, useSegmentFiltersDeleteMutation } from '@graphql/queries'

interface Props {
  strategyId: string
  canEdit?: boolean
}

const MapSegmentFilters: FC<Props> = ({ strategyId, canEdit = false }) => {
  const strategy = useGetObject(strategyId, 'strategy') as StrategyQueryStrategy

  const [isOpen, setIsOpen] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [isApplying, setIsApplying] = useState(false)
  const [, createSegmentFilters] = useSegmentFiltersCreateMutation()
  const [, deleteSegmentFilters] = useSegmentFiltersDeleteMutation()
  const revalidator = useRevalidator()

  useEffect(() => {
    if (revalidator.state === 'idle' && isApplying) {
      setIsApplying(false)
    }
  }, [revalidator.state, isApplying])

  if (!canEdit) {
    return null
  }

  const { showSegmentCreate } = strategy

  const onNewSegmentClick = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onFilter = (newFilter) => {
    setIsFiltering(true)
    let resp

    if (newFilter.active) {
      resp = createSegmentFilters({
        input: {
          filterableType: 'Strategy',
          filterableId: strategy.id,
          segmentValueIds: [newFilter.id]
        }
      })
    } else {
      const segmentFilterId = strategy.segmentFilters.find((filter) => filter.segmentValue.id === newFilter.id)?.id

      resp = deleteSegmentFilters({
        input: {
          filterableType: 'Strategy',
          filterableId: strategy.id,
          segmentFilterIds: [segmentFilterId]
        }
      })
    }

    return resp
      .then(() => {
        setIsApplying(true)
        revalidator.revalidate()
      })
      .finally(() => {
        setIsFiltering(false)
      })
  }

  const onRemoveClick = (segmentFilter) => {
    const resp = deleteSegmentFilters({
      input: { filterableType: 'Strategy', filterableId: strategy?.id, segmentFilterIds: [segmentFilter.id] }
    })

    return resp.then((r) => {
      setIsApplying(true)

      revalidator.revalidate()

      return r
    })
  }

  const filters = (strategy?.segmentFilters || []).map((filter) => ({
    id: filter.id,
    name: filter.segmentName.name,
    value: filter.segmentValue.value
  }))

  return (
    <ButtonGroup size="2xs">
      {showSegmentCreate && (
        <>
          <Button
            aria-label="Segments"
            colorScheme="gray"
            disabled={isApplying || isFiltering}
            isLoading={isApplying || isFiltering}
            leftIcon={<ICON_MAP.Segment />}
            loadingText={isApplying ? 'Applying' : 'Loading'}
            onClick={onNewSegmentClick}
            variant="ghost"
          >
            Segment
          </Button>
          <SegmentFiltersModal
            strategyId={strategyId}
            isOpen={isOpen}
            onClose={onClose}
            onFilter={onFilter}
            segmentFilters={strategy?.segmentFilters || []}
          />
        </>
      )}

      {filters.map((filter) => (
        <MapSegmentFilter
          key={filter.id}
          canEdit={canEdit}
          filter={filter}
          onRemoveClick={onRemoveClick}
          isApplying={isApplying}
        />
      ))}
    </ButtonGroup>
  )
}

MapSegmentFilters.displayName = 'MapSegmentFilters'

export default memo(MapSegmentFilters)
