import { HStack, Spacer, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import CommentsCount from '../commentsCount'

import useGetObject from '@app/hooks/useGetObject'
import CardConfidenceRating from '@app/next/domainObjects/confidenceRating/cardConfidenceRating'
import cardFontSize from '@app/shared/cards/cardFontSize'
import { CardAvatar } from '@app/shared/cards/components'
import type { CardSize, MapDomainBasicCard } from '@app/types'

type Props = {
  basicCard: MapDomainBasicCard
  size?: CardSize
}

const CollapsedBasicCard: FC<Props> = ({ basicCard, size = 'normal' }) => {
  const { commentsCount, name, ownerId, confidenceRating } = basicCard
  const owner = useGetObject(ownerId, 'user')
  const fontSize = cardFontSize('md', size)

  return (
    <HStack>
      {confidenceRating && (
        <CardConfidenceRating type={confidenceRating} fieldName="confidenceRating" collapse size={size} />
      )}
      {owner && <CardAvatar user={owner} size={size} />}
      <Text fontSize={fontSize} fontWeight="semibold">
        {name}
      </Text>
      <Spacer />
      <CommentsCount commentsCount={commentsCount} />
    </HStack>
  )
}

export default CollapsedBasicCard
