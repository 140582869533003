import type { ButtonGroupProps, IconButtonProps, UseRadioProps } from '@chakra-ui/react'
import { Tooltip, forwardRef, Box, ButtonGroup, IconButton, useId, useRadio, useRadioGroup } from '@chakra-ui/react'
import type { ReactElement } from 'react'
import { Children, cloneElement, isValidElement, useMemo } from 'react'

interface RadioIconButtonGroupProps<T> extends Omit<ButtonGroupProps, 'onChange' | 'variant' | 'isAttached'> {
  name?: string
  value?: T
  defaultValue?: string
  onChange?: (value: T) => void
}

export const RadioIconButtonGroup = <T extends string>(props: RadioIconButtonGroupProps<T>) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange
  })

  const { isDisabled } = props

  const buttons = useMemo(
    () =>
      Children.toArray(children)
        .filter<ReactElement<RadioIconButtonProps>>(isValidElement)
        .map((button, index, array) => {
          const isFirstItem = index === 0
          const isLastItem = array.length === index + 1

          const styleProps = {
            ...(isFirstItem && !isLastItem ? { borderRightRadius: 0 } : {}),
            ...(!isFirstItem && isLastItem ? { borderLeftRadius: 0 } : {}),
            ...(!isFirstItem && !isLastItem ? { borderRadius: 0 } : {}),
            ...(!isLastItem ? { mr: '-px' } : {})
          }

          return cloneElement(button, {
            ...styleProps,
            radioProps: getRadioProps({
              value: button.props.value,
              disabled: isDisabled || button.props.isDisabled
            })
          })
        }),
    [children, getRadioProps, isDisabled]
  )
  return (
    <ButtonGroup isAttached variant="secondary" {...getRootProps(rest)}>
      {buttons}
    </ButtonGroup>
  )
}

interface RadioIconButtonProps extends IconButtonProps {
  value: string
  radioProps?: UseRadioProps
  tooltip?: string
}

export const RadioIconButton: ReturnType<typeof forwardRef> = forwardRef((props: RadioIconButtonProps, ref) => {
  const { radioProps, tooltip, ...rest } = props
  const { getInputProps, getRadioProps, getLabelProps } = useRadio(radioProps)
  const id = useId(undefined, 'radio-button')

  const inputProps = getInputProps()
  const checkboxProps = getRadioProps()
  const labelProps = getLabelProps()

  const button = <IconButton as="div" _focus={{ boxShadow: 'none' }} id={id} {...checkboxProps} {...rest} />
  let tipped

  if (tooltip) {
    tipped = (
      <Tooltip aria-label={tooltip} label={tooltip} placement="top">
        {button}
      </Tooltip>
    )
  } else {
    tipped = button
  }

  return (
    <Box
      as="label"
      cursor="pointer"
      {...labelProps}
      ref={ref}
      sx={{
        '.focus-visible + [data-focus]': {
          boxShadow: 'outline',
          zIndex: 1
        }
      }}
    >
      <input {...inputProps} aria-labelledby={id} />
      {tipped}
    </Box>
  )
})
