import { Box, FormControl, FormLabel, HStack, Select, useDisclosure } from '@chakra-ui/react'
import type { FC } from 'react'

import CustomRangeModal from '@app/shared/graph/customRangeModal'

const CUSTOM = 'custom'

type Change = {
  startDate: string
  endDate: string
  range: string
}

interface Props {
  range: string
  startDate?: string
  endDate?: string
  setRange: (range: string) => void
  setStartDate: (startDate: string) => void
  setEndDate: (endDate: string) => void
  labelText?: string
  inline?: boolean
  onChange?: (arg0: Change) => void
  onCustomRangeCancel?: () => void
  selectProps?: Record<string, unknown>
}

const RangeSelect: FC<Props> = ({
  range,
  startDate = null,
  endDate = null,
  setRange,
  setStartDate,
  setEndDate,
  labelText = 'Date Range',
  inline = false,
  onChange = ({ startDate: sd, endDate: ed, range: r }) => ({ sd, ed, r }),
  onCustomRangeCancel = null,
  selectProps = {}
}) => {
  const onChangeProp = onChange
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = (reason, values = { startDate: null, endDate: null }) => {
    onClose()

    if (reason !== 'cancelled') {
      setStartDate(values.startDate)
      setEndDate(values.endDate)
      setRange(CUSTOM)
      onChangeProp?.({
        startDate: values.startDate,
        endDate: values.endDate,
        range: CUSTOM
      })
    } else if (onCustomRangeCancel) {
      onCustomRangeCancel()
    } else {
      setRange('trends')
    }
  }

  const customText = range === CUSTOM ? `${startDate || '(none)'} to ${endDate || '(none)'}` : 'Custom'

  const Stack = inline ? HStack : Box

  return (
    <FormControl data-testid="range-select">
      <CustomRangeModal isOpen={isOpen} onClose={handleClose} />
      <Stack>
        {labelText && <FormLabel requiredIndicator={null}>{labelText}</FormLabel>}
        <Select
          bgColor="bg.surface"
          name="range_select"
          onChange={(e) => {
            if (e.target.value === CUSTOM) {
              if (!isOpen) {
                onOpen()
              }
            } else {
              setStartDate(null)
              setEndDate(null)
              setRange(e.target.value)
              onChangeProp?.({
                startDate: null,
                endDate: null,
                range: e.target.value
              })
            }
          }}
          value={range}
          {...selectProps}
        >
          <option value="trends">Trends</option>
          <option value="30d">Last 30 days</option>
          <option value="3m">Last 3 months</option>
          <option value="6m">Last 6 months</option>
          <option value="1y">Last year</option>
          <option value="all">All time</option>
          <option value="custom">{customText}</option>
        </Select>
      </Stack>
    </FormControl>
  )
}

export default RangeSelect
