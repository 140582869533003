import { FormLabel, HStack, Spacer, Stack, StackDivider, Text, useToken } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import TextInput from '@app/next/forms/inline/textInput'
import CorrelationDetails from '@app/pages/maps/components/edgeDrawer/components/correlationDetails'
import InsightsDetails from '@app/pages/maps/components/edgeDrawer/components/insightsDetails'
import { displayNameAndType } from '@app/pages/maps/components/nodes/helpers'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import Drawer from '@app/shared/drawer/drawer'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import { useStore } from '@app/store'
import type { MapDomainEdge } from '@app/types'
import { COLORS } from '@app/utils/metricHelpers'

const EdgeDrawer: FC = () => {
  const { edgeId } = useParams()
  const { onDrawerClose } = useDrawerContext()
  const getNodeById = useStore.use.getNodeById()
  const [red, green, yellow] = useToken('colors', ['red.600', 'green.600', 'yellow.600'])

  const edge = useGetObject(edgeId, 'edge') satisfies MapDomainEdge
  const { insights, color } = edge

  const source = getNodeById(edge?.source)
  const target = getNodeById(edge?.target)

  const sourceDisplay = displayNameAndType(source.data)
  const targetDisplay = displayNameAndType(target.data)

  return (
    <Drawer onClose={onDrawerClose} isOpen>
      <Stack as="section" overflow="auto" h="100%" id="lazyload-container" spacing={0}>
        <Stack p={4} divider={<StackDivider />} spacing={8}>
          <Stack>
            <HStack>
              <Text fontWeight="semibold">
                {sourceDisplay.name} -- {targetDisplay.name}
              </Text>
              <Spacer />
              <DrawerCloseButton variant="ghost" />
            </HStack>
            <ViewerFieldWrapper value={edge.insights}>
              <TextInput
                domainObject={edge}
                name="insights"
                placeholder="Insight title (e.g., high confidence, low leverage)"
                domainObjectUpdateId="edgeId"
                defaultValue={insights || ''}
                fontWeight="bold"
              />
            </ViewerFieldWrapper>
            <InsightsDetails edge={edge} />
            <ViewerFieldWrapper value={edge.color}>
              <HStack>
                <FormLabel verticalAlign="middle" mb={0} fontSize="md" htmlFor="color">
                  Color
                </FormLabel>
                <TextInput
                  domainObject={edge}
                  name="color"
                  type="color"
                  defaultValue={color || COLORS.neutral.color}
                  placeholder="Color"
                  domainObjectUpdateId="edgeId"
                  width={6}
                  h={6}
                  list="presetColors"
                />
                <datalist id="presetColors">
                  <option>{green}</option>
                  <option>{yellow}</option>
                  <option>{red}</option>
                </datalist>
              </HStack>
            </ViewerFieldWrapper>
          </Stack>
          <CorrelationDetails edge={edge} />
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default memo(EdgeDrawer)
