import { memo } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { abilitiesFor } from '@app/shared/authorization/caslProvider'
import PageTabs from '@app/shared/pageTabs'

type Tab = {
  to: string
  label?: string
}

type Tabs = Record<string, Tab>

const Index = () => {
  const { '*': tab } = useParams()
  const outletContext = useOutletContext()

  const { user } = useStoreCurrentUser()
  const abilities = abilitiesFor(user)
  const canUpdateEntity = abilities.can('update', 'entity')

  const tabs: Tabs = {}

  tabs.events = { to: 'events' }
  tabs.details = { to: 'details' }
  tabs.impact = { to: 'impact' }
  tabs.comments = { to: 'comments' }

  if (canUpdateEntity) {
    tabs.settings = { to: 'settings' }
  }

  return <PageTabs outletContext={outletContext} tab={tab} tabs={tabs} />
}

export default memo(Index)
