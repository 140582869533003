import { Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import type { Metric } from '@graphql/queries'

interface Props {
  data: Metric
}

const MetricName: FC<Props> = ({ data: metric }) => {
  const { id, name, containsPlaceholderData, ownerId } = metric
  const owner = useGetObject(ownerId, 'user')

  return (
    <ChakraLink
      as={Link}
      data-cy="metric-name"
      onClick={(e) => {
        e.stopPropagation()
      }}
      to={id}
    >
      <Stack>
        <Text minW="xs" fontSize="md" whiteSpace="normal" wordBreak="break-all" title={name}>
          {truncate(name, { length: 50 })}
        </Text>
        {containsPlaceholderData && <Text color="muted">Placeholder data</Text>}
        {owner?.name && <Text color="muted">Owner: {owner.name}</Text>}
      </Stack>
    </ChakraLink>
  )
}

export default MetricName
