import { Button, Center, Container, Stack, Text } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import useToast from '@app/shared/toast'
import { useReportCreateMutation } from '@graphql/queries'

const ReportsEmpty: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { strategyId } = useParams()
  const [, createReport] = useReportCreateMutation()
  const navigate = useNavigate()
  const toast = useToast()

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    setIsLoading(true)

    createReport({ input: { strategyId, name: 'Untitled Report' } })
      .then((response) => {
        if (response?.data?.reportCreate?.report) {
          navigate(`${response.data.reportCreate.report.id}`)
        } else {
          const error =
            response.error.message || response?.data?.reportCreate?.errors[0]?.message || 'Failed to create the report'
          toast({ status: 'error', title: 'Error', description: error })
        }
      })
      .catch((error) => {
        toast({ status: 'error', title: 'Error', description: error })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Center w="100%">
      <Container maxW="lg" p={8}>
        <Stack spacing={6}>
          <Text align="center" fontSize="xl" fontWeight="semibold">
            Create your first report
          </Text>
          <Text align="center">
            Reports are snapshots of how your strategy is performing that you can share with your team or stakeholders.
          </Text>
          <Button as={Link} to="recurring/create">
            Create a recurring report
          </Button>
          <form onSubmit={onSubmit}>
            <Button w="100%" isLoading={isLoading} type="submit">
              Create a one-time summary
            </Button>
          </form>
        </Stack>
      </Container>
    </Center>
  )
}

export default ReportsEmpty
