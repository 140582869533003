import { Center, Spinner, type SpinnerProps } from '@chakra-ui/react'
import type { FC } from 'react'

const SuspenseLoader: FC<SpinnerProps> = ({ ...rest }) => (
  <Center h="full">
    <Spinner w={24} h={24} color="blue.500" emptyColor="gray.200" speed="0.65s" thickness="8px" {...rest} />
  </Center>
)

export default SuspenseLoader
