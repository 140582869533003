import { Box, Button, Stack, Text, useClipboard } from '@chakra-ui/react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { Paths } from '@app/utils/routeHelpers'

const JiraWebhook = () => {
  const { user } = useStoreCurrentUser()
  const webhookUrl = Paths.jiraWebhookUrlPath({ apiToken: user.organization.apiToken })
  const { hasCopied, onCopy } = useClipboard(webhookUrl)

  return (
    <Stack shouldWrapChildren spacing="4">
      <Text color="error" fontSize="sm" fontWeight="bold">
        Important: you must add the webhook for your new data to be recorded in DoubleLoop.
      </Text>
      <Text fontSize="sm">To install the webhook copy this webhook:</Text>
      <Box p={4} border="1px" borderColor="bg.muted" wordBreak="break-all">
        <Stack direction="column" spacing={4}>
          <Text fontSize="sm">{webhookUrl}</Text>
          <Box>
            <Button onClick={onCopy}>{hasCopied ? 'Copied!' : 'Copy to Clipboard'}</Button>
          </Box>
        </Stack>
      </Box>
    </Stack>
  )
}

export default JiraWebhook
