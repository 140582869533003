import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import type { FC } from 'react'

import { IntervalEnum } from '@graphql/types'

type Props = {
  interval: IntervalEnum
  setInterval: (interval: IntervalEnum) => void
}

const IntervalSelect: FC<Props> = ({ interval, setInterval }) => (
  <FormControl data-testid="interval-select">
    <FormLabel htmlFor="interval_select" requiredIndicator={null}>
      Group by
    </FormLabel>
    <Select
      key="interval"
      name="interval_select"
      onChange={(e) => setInterval(e.target.value as IntervalEnum)}
      value={interval}
    >
      <option value={IntervalEnum.Day}>Day</option>
      <option value={IntervalEnum.Week}>Week</option>
      <option value={IntervalEnum.Month}>Month</option>
      <option value={IntervalEnum.Quarter}>Quarter</option>
      <option value={IntervalEnum.Year}>Year</option>
    </Select>
  </FormControl>
)

export default IntervalSelect
