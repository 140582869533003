import { Button, ButtonGroup, Spacer, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { CARD_FILTER_SIGIL } from '@app/lib/globals'
import type { Props as ContainersSelectProps } from '@app/next/changelog/components/containersSelect'
import ContainersSelect from '@app/next/changelog/components/containersSelect'
import MapDrawer from '@app/pages/maps/components/drawer/mapDrawer'
import EntityForm from '@app/pages/shared/entities/components/entityForm'

const param = `${CARD_FILTER_SIGIL}containerNodeIds`

const AddWorkItemDrawer = () => {
  const [searchParams] = useSearchParams()
  const defaultContainerNodeIds = searchParams.get(param)?.split(',')
  const [containerNodeIds, setContainerNodeIds] = useState<string[]>(defaultContainerNodeIds || [])
  const [step, setStep] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const onCancel = () => navigate(`..${location.search}`)

  const onChange: ContainersSelectProps['onChange'] = (selected) => {
    setContainerNodeIds(selected.map((s) => s.id))
  }

  return (
    <MapDrawer p={4} top="24px" right="74px" left="auto" bottom="auto">
      {step === 0 && (
        <Stack spacing={4}>
          <ContainersSelect
            onChange={onChange}
            placeholder="Select containing works"
            defaultContainerNodeIds={defaultContainerNodeIds}
          />
          <ButtonGroup>
            <Spacer />
            <Button onClick={() => setStep(1)}>Next</Button>
          </ButtonGroup>
        </Stack>
      )}
      {step === 1 && (
        <EntityForm onCancel={onCancel}>
          <input type="hidden" name="containerNodeIds" value={containerNodeIds.join(',')} />
        </EntityForm>
      )}
    </MapDrawer>
  )
}

export default AddWorkItemDrawer
