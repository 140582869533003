import { IconButton, Tooltip, VStack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { PiArrowSquareOut } from 'react-icons/pi'
import { Link, useNavigate } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

interface Props {
  objId: string
  objType: string
}

const GoToNodeButton: FC<Props> = ({ objId, objType }) => {
  const { user } = useStoreCurrentUser()
  const navigate = useNavigate()

  const nodePath = `/${objType}s/${objId}`

  useHotkeys('ctrl+g', () => navigate(nodePath))

  if (!user || ['entity', 'comments'].includes(objType)) {
    return null
  }

  return (
    <Tooltip
      hasArrow
      label={
        <VStack spacing={0}>
          <div>Go to {objType}</div>
          <div>(ctrl+g)</div>
        </VStack>
      }
    >
      <IconButton
        as={Link}
        aria-label={`Go to ${objType}`}
        colorScheme="gray"
        icon={<PiArrowSquareOut />}
        to={nodePath}
        variant="ghost"
      />
    </Tooltip>
  )
}

export default GoToNodeButton
