import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import type { ChangeEventHandler, FC, MutableRefObject } from 'react'

import { ChakraAsyncSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import client from '@graphql/client'
import { SlackChannelsAutocomplete } from '@graphql/documents/slack_channel.graphql'

const slackChannelToPickerItem = (slackChannel) => ({
  value: slackChannel.id,
  label: slackChannel.name,
  channelId: slackChannel.channelId,
  type: slackChannel.isIm ? 'Direct Message' : 'Channel'
})

type Props = {
  name: string
  defaultValue?: { value?: string; label?: string; channelId?: string; type?: string }
  onChange: ChangeEventHandler<HTMLInputElement>
  inputRef?: MutableRefObject<undefined>
  placeholder?: string
}

const SlackChannelAutocomplete: FC<Props> = ({ name, defaultValue = null, onChange = () => {}, ...rest }) => {
  const fetchSlackChannels = async (inputValue) => {
    const { data } = await client.query(SlackChannelsAutocomplete, { query: inputValue }).toPromise()
    const channelsWithoutArchived = data?.slackChannelsAutocomplete.filter((channel) => !channel.isArchived)

    const options = channelsWithoutArchived?.map(slackChannelToPickerItem)
    const groups = groupBy(options, 'type')

    const groupedOptions = []
    Object.keys(groups).forEach((group) => {
      groupedOptions.push({ label: group, options: sortBy(groups[group], ['label', 'toLowerCase']) })
    })

    return groupedOptions
  }

  return (
    <ChakraAsyncSelect
      isClearable
      name={name}
      aria-label="slack-channel-autocomplete"
      asyncFetch={fetchSlackChannels}
      placeholder="Start typing to find a channel"
      onChange={onChange}
      initialValue={defaultValue}
      {...rest}
    />
  )
}

export default SlackChannelAutocomplete
