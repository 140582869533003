import { Stack } from '@chakra-ui/react'
import type { ChangeEvent, FC, FocusEventHandler } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import useCurrentUser from '@app/hooks/useCurrentUser'
import { EmailInput, SlackAutocompleteInput, ToggleInput } from '@app/shared/rawForms'
import type { MapDomainReport } from '@app/types'
import type { SlackChannel } from '@graphql/types'

interface ReportFacade extends Pick<MapDomainReport, 'sendToEmail' | 'sendToSlack' | 'emails'> {
  id: string | null
  sendToEmail: boolean
  sendToSlack: boolean
  emails: string
  slackChannel?: Pick<SlackChannel, 'label' | 'value'>
}

interface Props {
  report?: ReportFacade
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const ChannelInput: FC<Props> = ({ report, onChange }) => {
  const { user } = useCurrentUser()
  const { state } = useLocation()
  const [showEmail, setShowEmail] = useState<boolean>(report?.sendToEmail || state?.reportType)
  const [showSlack, setShowSlack] = useState<boolean>(report?.sendToSlack)
  let defaultEmails = report?.emails || ''

  if (state && state.reportType) {
    defaultEmails = user?.email || ''
  }

  const blurHandler: FocusEventHandler<HTMLInputElement> = onChange

  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <ToggleInput
          labelSize="sm"
          label="Send email"
          name="sendToEmail"
          defaultValue={showEmail}
          onChange={setShowEmail}
        />
        {showEmail && (
          <EmailInput
            labelSize="sm"
            label="Email"
            name="emails"
            type="email"
            placeholder="Add an email or multiple emails seperated by a comma"
            onChange={(e) => {
              e.preventDefault()
              e.stopPropagation()

              return false
            }}
            onBlur={blurHandler}
            multiple
            defaultValue={defaultEmails}
          />
        )}
      </Stack>
      <Stack spacing={2}>
        <ToggleInput
          labelSize="sm"
          label="Send slack"
          name="sendToSlack"
          defaultValue={showSlack}
          onChange={setShowSlack}
        />
        {showSlack && (
          <SlackAutocompleteInput
            labelSize="sm"
            name="slackChannelId"
            label="Slack channel"
            defaultValue={report?.slackChannel}
            onChange={onChange}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default ChannelInput
