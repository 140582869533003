import { Box, Divider, Flex } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  children: React.ReactNode
}

const TextDivider: FC<Props> = ({ children, ...rest }) => (
  <Flex align="center" color="gray.300" {...rest}>
    <Box flex="1">
      <Divider borderColor="currentcolor" />
    </Box>
    <Box as="span" px="3" color="subtle" fontWeight="normal">
      {children}
    </Box>
    <Box flex="1">
      <Divider borderColor="currentColor" />
    </Box>
  </Flex>
)

export default TextDivider
