import { Icon } from '@chakra-ui/react'
import { AiOutlineUnorderedList } from 'react-icons/ai'

import blockButtonMaker from './blockButtonMaker'

const UnorderedListButton = blockButtonMaker({
  blockType: 'unordered-list-item',
  children: <Icon as={AiOutlineUnorderedList} boxSize={6} />
})

export default UnorderedListButton
