import type { TextareaProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState, useEffect } from 'react'

import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize, MapDomainObject } from '@app/types'

interface Props extends TextareaProps {
  domainObject: MapDomainObject
  name?: string
  size?: CardSize
}

const CardTitle: FC<Props> = ({ domainObject, name, size = 'normal', ...rest }) => {
  // This forces a re-render on card size change otherwise we get a weird cutoff text effect.
  const [cardSize, setCardSize] = useState<CardSize>('normal')

  useEffect(() => {
    if (cardSize !== size) {
      setCardSize(size)
    }
  }, [cardSize, size])

  return (
    <TextAreaInput
      key={cardSize}
      domainObject={domainObject}
      name={name}
      title={domainObject[name]}
      fontWeight="bold"
      wordBreak="break-word"
      fontSize={cardFontSize('xl', size)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur()
        }
      }}
      {...rest}
    />
  )
}

export default CardTitle
