import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

interface Props extends BoxProps {
  children: ReactNode
}

const ButtonWrapper: FC<Props> = ({ children, ...boxProps }) => (
  <Box
    borderRadius="md"
    shadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);"
    bgColor="bg.surface"
    {...boxProps}
  >
    {children}
  </Box>
)

export default ButtonWrapper
