import capitalize from 'lodash/capitalize'
import type { ChangeEvent, FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'

interface Props extends Partial<SelectInputProps> {
  value: string
  sources?: string[]
  show?: boolean
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const ForeignTypeInput: FC<Props> = ({ value, show = false, sources = [], onChange = () => {}, ...rest }) => {
  if (!show) {
    return null
  }

  return (
    <SelectInput
      value={value}
      name="scope.foreign_type"
      placeholder=""
      onChange={onChange}
      data-testid="show-sources-input"
      {...rest}
    >
      <option value="">All types</option>
      {sources.map((source) => (
        <option key={source} value={source}>
          {capitalize(source)}
        </option>
      ))}
    </SelectInput>
  )
}

export default ForeignTypeInput
