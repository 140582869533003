import type { FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  isDisabled: boolean
}

enum StripeCalculation {
  MRR = 'mrr',
  ARR = 'arr',
  PAYING_CUSTOMERS = 'paying_customers',
  CANCELED_SUBSCRIPTIONS = 'canceled_subscriptions'
}

const Stripe: FC<Props> = ({ metricSource, isDisabled }) => (
  <SelectInput
    name="configuration.calculation"
    label="Calculation"
    placeholder={null}
    isDisabled={isDisabled}
    defaultValue={metricSource?.configuration?.calculation}
  >
    <option value={StripeCalculation.MRR}>MRR</option>
    <option value={StripeCalculation.ARR}>ARR</option>
    <option value={StripeCalculation.PAYING_CUSTOMERS}>Paying Customers</option>
    <option value={StripeCalculation.CANCELED_SUBSCRIPTIONS}>Canceled Subscriptions</option>
  </SelectInput>
)

export default Stripe
