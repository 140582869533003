import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  InputGroup,
  FormLabel,
  RadioGroup,
  Radio,
  HStack
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FcGoogle } from 'react-icons/fc'

import ProviderForm from '@app/pages/sessions/components/providerForm'
import LimitedUseDisclosure from '@app/pages/settings/integrations/components/google/limitedUseDisclosure'
import { Paths } from '@app/utils/routeHelpers'

// used in GoogleSheetsController#callback
type GoogleOauthState = {
  redirect_to?: string
  metric_id?: string
  type: 'source' | 'organization'
}

interface Props {
  isOpen: boolean
  onClose: () => void
  state?: GoogleOauthState
}

const GoogleSheetsConnectModal: FC<Props> = ({ isOpen, onClose, state = null }) => {
  const [segmented, setSegmented] = useState(false)
  const url = Paths.authPath(
    { provider: 'google_sheets' },
    state ? { search: `?state=${btoa(JSON.stringify({ ...state, segmented }))}` } : {}
  )

  const onChange = (value: string) => setSegmented(!!value)

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect to Google Sheets</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text pb={4}>
            After authorizing DoubleLoop to read and write data to Google Sheets, we’ll automatically create a Google
            Sheet that you can use to update and import metrics into DoubleLoop. No other spreadsheets will be affected.
          </Text>
          {state?.type === 'organization' && (
            <InputGroup pb={4}>
              <FormLabel>Select template</FormLabel>
              <RadioGroup defaultValue="" onChange={onChange}>
                <HStack>
                  <Radio value="">Simple</Radio>
                  <Radio value="segmented">Segmented</Radio>
                </HStack>
              </RadioGroup>
            </InputGroup>
          )}
          <ProviderForm url={url} buttonTitle="Continue with Google" buttonIcon={FcGoogle} />
          <LimitedUseDisclosure py={4} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default GoogleSheetsConnectModal
