import { Button, FormLabel, HStack, Icon, Spacer, Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { PiDownload } from 'react-icons/pi'

import fetchDownload from '@app/lib/fetchDownload'
import type { MapDomainStrategy } from '@app/types'
import { Paths } from '@app/utils/routeHelpers'

type Props = {
  strategy: Pick<MapDomainStrategy, 'id'>
}

const DownloadDataButtons: FC<Props> = ({ strategy }) => {
  const [isDownloadingPlaybook, setIsDownloadingPlaybook] = useState(false)
  const [isDownloadingWorks, setIsDownloadingWorks] = useState(false)
  const [isDownloadingMetrics, setIsDownloadingMetrics] = useState(false)

  if (!strategy) {
    return null
  }

  const strategyId = strategy.id

  const downloadPlaybook = async () => {
    setIsDownloadingPlaybook(true)

    try {
      return await fetchDownload(Paths.downloadStrategyPlaybookPath({ strategyId }))
    } finally {
      setIsDownloadingPlaybook(false)
    }
  }

  const downloadWorks = async () => {
    setIsDownloadingWorks(true)

    try {
      return await fetchDownload(Paths.downloadStrategyWorksCSVPath({ strategyId }))
    } finally {
      setIsDownloadingWorks(false)
    }
  }

  const downloadMetrics = async () => {
    setIsDownloadingMetrics(true)

    try {
      return await fetchDownload(Paths.downloadStrategyMetricsCSVPath({ strategyId }))
    } finally {
      setIsDownloadingMetrics(false)
    }
  }

  return (
    <Stack divider={<StackDivider />} spacing={4}>
      <HStack>
        <FormLabel fontSize="md">Download playbook</FormLabel>
        <Spacer />
        <Button
          aria-label="Download playbook"
          isLoading={isDownloadingPlaybook}
          leftIcon={<Icon as={PiDownload} />}
          onClick={downloadPlaybook}
        >
          Download
        </Button>
      </HStack>
      <HStack>
        <FormLabel fontSize="md">Download work</FormLabel>
        <Spacer />
        <Button
          aria-label="Download work"
          isLoading={isDownloadingWorks}
          leftIcon={<Icon as={PiDownload} />}
          onClick={downloadWorks}
        >
          Download
        </Button>
      </HStack>
      <HStack>
        <FormLabel fontSize="md">Download metric data</FormLabel>
        <Spacer />
        <Button
          aria-label="Download metric data"
          isLoading={isDownloadingMetrics}
          leftIcon={<Icon as={PiDownload} />}
          onClick={downloadMetrics}
        >
          Download
        </Button>
      </HStack>
    </Stack>
  )
}

export default DownloadDataButtons
