import { Button, Center, Heading, Link as ChakraLink, HStack, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import WordmarkColor from '@app/images/wordmarkColor'

interface Props {
  title: string
  subtitle?: string
  linkText?: string
  linkUrl?: string
}

const SessionHeader: FC<Props> = ({ title, subtitle, linkText, linkUrl }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Stack spacing="6">
      {isMobile && (
        <Center>
          <ChakraLink href="https://www.doubleloop.app">
            <WordmarkColor w="200px" h="auto" />
          </ChakraLink>
        </Center>
      )}
      <Stack textAlign="center" spacing={{ base: '2', md: '3' }}>
        <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>{title}</Heading>
        <HStack justify="center" spacing="1">
          {subtitle && <Text color="muted">{subtitle}</Text>}
          {linkText && linkUrl && (
            <Button as={Link} color="link" size="md" to={linkUrl} variant="text">
              {linkText}
            </Button>
          )}
        </HStack>
      </Stack>
    </Stack>
  )
}

export default SessionHeader
