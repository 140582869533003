import type { TableProps as ChakraTableProps } from '@chakra-ui/react'
import { Table as ChakraTable, Tbody, Thead, Tr } from '@chakra-ui/react'
import uniqueId from 'lodash/uniqueId'
import type { FC } from 'react'

import HeaderColumn from './components/headerColumn'
import RowColumn from './components/rowColumn'
import type { Data, TableProps } from './types'

type Props<TCellData extends Data> = ChakraTableProps & TableProps<TCellData>

const Table = <TCellData extends Data = Data>({
  columns,
  data,
  rowFormatter = (_data) => ({}),
  showHeader = true,
  ...rest
}: Props<TCellData>): ReturnType<FC<Props<TCellData>>> => (
  <ChakraTable {...rest}>
    {showHeader && (
      <Thead>
        <Tr>
          {columns.map((column) => (
            <HeaderColumn key={column.key || column.header.toString() || uniqueId()} column={column} />
          ))}
        </Tr>
      </Thead>
    )}
    <Tbody>
      {data.map((row) => (
        <Tr key={`row-${row.id}`} _hover={{ bgColor: 'bg.subtle' }} bgColor="bg.surface" {...rowFormatter(row)}>
          {columns.map((column) => (
            <RowColumn<TCellData>
              key={`${column.key || column.header.toString() || uniqueId()}-${row.id}`}
              column={column}
              row={row}
            />
          ))}
        </Tr>
      ))}
    </Tbody>
  </ChakraTable>
)

export default Table
