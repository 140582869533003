import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'

import { useMetricSourceDeleteMutation } from '@graphql/queries'

interface Props {
  metricSourceId?: string
  onDelete?: () => void
}

const MetricSourceDeleteButton: FC<Props> = ({ metricSourceId = null, onDelete = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [isLoading, setIsLoading] = useState(false)

  const [, metricSourceDelete] = useMetricSourceDeleteMutation()

  const deleteMetricSource = (clearExistingData: boolean) => {
    if (!metricSourceId) {
      return false
    }

    onClose()
    setIsLoading(true)
    return metricSourceDelete({ input: { metricSourceId, clearExistingData } })
      .then(() => {
        onDelete()
        return true
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button aria-label="Disconnect metric source" colorScheme="red" isLoading={isLoading} onClick={onOpen}>
        Disconnect
      </Button>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Disconnect metric source
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&#39;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} onClick={() => deleteMetricSource(false)} variant="tertiary">
                Keep Data
              </Button>
              <Button ml={3} bgColor="error" onClick={() => deleteMetricSource(true)} variant="primary">
                Discard Data
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default MetricSourceDeleteButton
