import { Link, MenuGroup, MenuItem, useDisclosure, useToast, useToken } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { BiUnlink, BiSync } from 'react-icons/bi'
import { SiGooglesheets } from 'react-icons/si'
import { useLocation } from 'react-router-dom'

import GoogleSheetsConnectModal from '@app/pages/metrics/components/googleSheetsConnectModal'
import Can from '@app/shared/authorization/can'
import { useGoogleSheetImportMutation, GoogleSheetStatusEnum, useGoogleSheetDeleteMutation } from '@graphql/queries'
import type { GoogleSheet } from '@graphql/types'

type Props = {
  googleSheet: GoogleSheet | null
}

const calculateButtonText = (status: GoogleSheetStatusEnum) => {
  switch (status) {
    case GoogleSheetStatusEnum.Importing:
      return 'Importing latest data...'
    case GoogleSheetStatusEnum.Exporting:
      return 'Exporting latest data...'
    case GoogleSheetStatusEnum.Queued:
      return 'Queued...'
    default:
      return 'Import latest data'
  }
}

const GoogleSheetsMenuGroup: FC<Props> = ({ googleSheet }) => {
  const toast = useToast()
  const [, googleSheetImport] = useGoogleSheetImportMutation()
  const [, googleSheetDelete] = useGoogleSheetDeleteMutation()
  const [isLoading, setIsLoading] = useState(false)

  const green = useToken('colors', 'green.500')
  const disclosure = useDisclosure()
  const location = useLocation()

  if (!googleSheet) {
    const oauthState = {
      redirect_to: location.pathname,
      type: 'organization' as const
    }

    return (
      <Can I="create" a="googleSheet">
        <GoogleSheetsConnectModal {...disclosure} state={oauthState} />
        <MenuItem icon={<SiGooglesheets color={green} />} onClick={() => disclosure.onOpen()}>
          Connect to Google Sheets
        </MenuItem>
      </Can>
    )
  }

  const { status } = googleSheet

  const isDisabled = [
    GoogleSheetStatusEnum.Importing,
    GoogleSheetStatusEnum.Exporting,
    GoogleSheetStatusEnum.Queued
  ].includes(status)

  const deleteSheet = () => {
    setIsLoading(true)

    googleSheetDelete({
      input: {
        googleSheetId: googleSheet.id
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const importSheet = () => {
    setIsLoading(true)

    googleSheetImport({
      input: {
        googleSheetId: googleSheet.id
      }
    }).finally(() => {
      setIsLoading(false)
    })

    toast({
      title: 'Importing started',
      description: 'We are importing your data from Google Sheets. This may take a few minutes.'
    })
  }

  const buttonText = calculateButtonText(status)

  return (
    <MenuGroup title="Google Sheets">
      <MenuItem
        as={Link}
        disabled={isLoading}
        href={googleSheet.spreadsheetUrl}
        icon={<SiGooglesheets />}
        target="_blank"
      >
        Open Linked Google Sheet
      </MenuItem>
      <Can I="update" a="metric">
        <MenuItem icon={<BiSync />} isDisabled={isDisabled} onClick={importSheet}>
          {buttonText}
        </MenuItem>
      </Can>
      <Can I="delete" a="googleSheet">
        <MenuItem icon={<BiUnlink />} isDisabled={isDisabled} onClick={deleteSheet}>
          Unlink
        </MenuItem>
      </Can>
    </MenuGroup>
  )
}

export default GoogleSheetsMenuGroup
