import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import type { Playbook } from '@graphql/queries'

interface Props {
  playbook: Playbook
}

const PlaybookCreator: FC<Props> = ({ playbook }) => {
  const { organization, user } = playbook

  let userText = user.name

  if (organization) {
    userText = `${userText} - ${organization.name}`
  }

  return <Text color="muted">{userText}</Text>
}

const PlaybookMeta: FC<Props> = ({ playbook }) => {
  const { createdAt, organization, user } = playbook
  const details = []

  if (!user && !organization) {
    return null
  }

  if (user) {
    details.push(
      <Stack key="user_meta" spacing={1}>
        <PlaybookCreator playbook={playbook} />
        <Text color="muted">
          {new Intl.DateTimeFormat(navigator.languages[0], {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }).format(new Date(createdAt))}
        </Text>
      </Stack>
    )
  }

  return (
    <Stack>
      <Text color="emphasized" fontWeight="semibold">
        Submitted by
      </Text>
      {details}
    </Stack>
  )
}

export default PlaybookMeta
