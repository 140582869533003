import type { FC } from 'react'
import { useParams, useOutletContext } from 'react-router-dom'

import PageTabs from '@app/shared/pageTabs'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'

const Index: FC = () => {
  const { '*': tab } = useParams()
  const outletContext = useOutletContext()

  const tabs = {} as Record<string, { to: string }>

  tabs.details = { to: 'details' }
  tabs.events = { to: 'events' }
  tabs.impact = { to: 'impact' }
  tabs.comments = { to: 'comments' }

  return <PageTabs outletContext={outletContext} tab={tab} tabs={tabs} />
}

export default withSuspenseWrapper(Index)
