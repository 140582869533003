import type { FC, ReactNode } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import type { UseQueryArgs, UseQueryResponse } from 'urql'

import { usePage } from '@app/shared/pagination'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import type { BasicCardEntitiesQueryVariables, StrategyBasicCardEntitiesQueryVariables } from '@graphql/queries'
import { useStrategyBasicCardEntitiesQuery, useBasicCardEntitiesQuery } from '@graphql/queries'
import type { CollectionMetadata, Entity, EntityCollection } from '@graphql/types'

type Variables = StrategyBasicCardEntitiesQueryVariables | BasicCardEntitiesQueryVariables

type Props = Variables & {
  id: string
  children: (collection: Entity[], metadata: CollectionMetadata | Record<string, never>) => ReactNode
}

const BasicCardEntitiesFetcher: FC<Props> = ({ id, children, ...rest }) => {
  const { strategyId } = useParams()
  const page = usePage()
  const updateObject = useStore.use.updateObject()

  let query: (options: Omit<UseQueryArgs, 'query'>) => UseQueryResponse
  let variables: Variables

  if (strategyId) {
    query = useStrategyBasicCardEntitiesQuery
    variables = { id: strategyId, basicCardId: id, page, ...rest }
  } else {
    query = useBasicCardEntitiesQuery
    variables = { id, page, ...rest }
  }

  const [{ data }] = query({ variables })

  const { collection = [], metadata = {} } = (data?.strategy?.basicCardEntities ||
    data?.basicCardEntities ||
    {}) as EntityCollection

  useEffect(() => {
    // @ts-expect-error containee_ids is not a valid field for updateObject, do use without skipMutation
    updateObject({ basicCard: { id, containeeIds: collection?.map((containment) => containment.id) } }, true)
  }, [])

  return children(collection, metadata)
}

export default withSuspenseWrapper(BasicCardEntitiesFetcher)
