import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { abilitiesFor } from '@app/shared/authorization/caslProvider'

const CreateMapButton = () => {
  const { user } = useStoreCurrentUser()

  const abilities = abilitiesFor(user)
  const canUpdateStrategy = abilities.can('update', 'strategy')

  if (canUpdateStrategy) {
    return (
      <Button as={Link} to="/playbooks" variant="primary">
        Create map
      </Button>
    )
  }

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Button variant="primary">Create map</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Editor Required</PopoverHeader>
        <PopoverBody>Please contact a workspace admin, to have editor credentials set on your account.</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default CreateMapButton
