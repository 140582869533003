import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import WorkDeleteButton from '@app/pages/entities/components/workDeleteButton'
import Can from '@app/shared/authorization/can'
import type { Entity } from '@graphql/types'

interface Props {
  data: Entity
}

const WorkListActions: FC<Props> = ({ data }) => (
  <HStack spacing={0}>
    <Spacer />
    <Can I="update" a="metric">
      <WorkDeleteButton workId={data.id}>
        {(props) => (
          <IconButton color="error" aria-label="Delete work" icon={<FiTrash2 />} variant="ghost" {...props} />
        )}
      </WorkDeleteButton>
    </Can>
  </HStack>
)

export default WorkListActions
