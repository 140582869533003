import { forwardRef } from 'react'

import BaseInput, { type Props as BaseInputProps } from '@app/shared/rawForms/baseInput'

export type Props = BaseInputProps

const TimeInput: ReturnType<typeof forwardRef<HTMLInputElement, Props>> = forwardRef((props, ref) => (
  <BaseInput type="time" ref={ref} pattern="[0-9]{1,2}:[0-9]{2}(\s?[aApP]\.?[mM]\.?)?" {...props} />
))

export default TimeInput
