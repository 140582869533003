import { Box, Link as ChakraLink } from '@chakra-ui/react'
import type { ReactElement } from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import type { ColumnCellProps, Data } from '@app/shared/table/types'
import type { Strategy } from '@graphql/types'

interface Strategies extends Data {
  strategies: Pick<Strategy, 'id' | 'name'>[]
}

const MapsCell = <T extends Strategies = Strategies>({ data }: ColumnCellProps<T>): ReactElement => (
  <Box whiteSpace="normal">
    {data?.strategies?.map((strategy, index) => (
      <Fragment key={strategy.id}>
        <ChakraLink
          key={strategy.id}
          as={Link}
          _hover={{ textDecoration: 'underline' }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          to={`/strategy/${strategy.id}`}
        >
          {strategy.name}
        </ChakraLink>
        {index < data.strategies.length - 1 && ', '}
      </Fragment>
    ))}
  </Box>
)

export default MapsCell
