import { useMemo } from 'react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { buildMetricSources } from '@app/pages/maps/components/addCards/helpers'
import SourceNameInput from '@app/pages/maps/components/addCards/queryExisting/sourceNameInput'
import type { SourcesMetricSource } from '@graphql/types'

type Props = {
  sources: SourcesMetricSource[]
}

const QueryExistingMetricFields: FC<Props> = ({ sources }) => {
  const { type } = useParams()

  const inputSources = useMemo(() => buildMetricSources(sources), [sources])

  return <SourceNameInput value={type} typeValue="metric" sources={inputSources} />
}

export default QueryExistingMetricFields
