import { Box, Center, HStack, Icon, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useContext } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

import MapSegmentFiltersModalContext from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFiltersModalContext'

const OptionIcon = () => (
  <Box w={4}>
    <Center>
      <Icon as={HiOutlineSearch} boxSize="4" color="gray.500" />
    </Center>
  </Box>
)

interface Props {
  option: {
    id: string
    name: string
  }
}

const FilterSegmentNameOption: FC<Props> = ({ option }) => {
  const { setActiveSegmentName } = useContext(MapSegmentFiltersModalContext) as {
    setActiveSegmentName: (segmentName: { id: string; name: string }) => void
  }

  const handleClick = () => {
    setActiveSegmentName(option)
  }

  return (
    <Box
      h="45px"
      borderColor="transparent"
      borderLeftWidth="2px"
      _hover={{ borderColor: 'blue.600', bgColor: 'gray.50' }}
      onClick={handleClick}
    >
      <Box w="100%" px={4} py={3}>
        <HStack spacing={3}>
          <OptionIcon />
          <Text
            overflow="hidden"
            maxW="325px"
            color="gray.500"
            fontSize="sm"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            title={option.name}
          >
            Segment by {option.name}
          </Text>
        </HStack>
      </Box>
    </Box>
  )
}

export default FilterSegmentNameOption
