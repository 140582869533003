import type { FC } from 'react'

import ConfirmPopover from '@app/shared/confirmPopover'
import { useAccountDeleteMutation } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  account: Account
  children?: React.ReactNode
}

const AccountDeleteButton: FC<Props> = ({ account, children = null }) => {
  const [, deleteAccount] = useAccountDeleteMutation()

  const handleDeleteAccount = async () => {
    await deleteAccount({
      input: {
        accountId: account.id
      }
    })
  }

  return (
    <ConfirmPopover
      onConfirm={() => handleDeleteAccount()}
      header="Delete account"
      body={"Are you sure you'd like to delete this account?"}
      confirmButtonText="Confirm"
    >
      {children}
    </ConfirmPopover>
  )
}

export default AccountDeleteButton
