import type { InputProps } from '@chakra-ui/react'
import { Icon, InputGroup, InputRightElement, IconButton } from '@chakra-ui/react'
import type { ChangeEvent, MouseEvent } from 'react'
import { forwardRef, useState } from 'react'
import { FiXCircle, FiSearch } from 'react-icons/fi'

import BaseInput from './baseInput'
import type { Props as RootFormControlProps } from './rootFormControl'

type Props = Omit<RootFormControlProps, 'children'> &
  InputProps & {
    onClear?: (e: MouseEvent<HTMLButtonElement>) => void
    onChange?: (value: string) => void
  }

const SearchInput: ReturnType<typeof forwardRef<HTMLInputElement, Props>> = forwardRef<HTMLInputElement, Props>(
  function TextInput({ onClear = () => {}, onChange = () => {}, ...rest }, ref) {
    const [inputValue, setInputValue] = useState('')

    return (
      <InputGroup>
        <BaseInput
          ref={ref}
          type="text"
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
            onChange(e)
          }}
          {...rest}
        />
        <InputRightElement color="muted">
          {inputValue ? (
            <IconButton
              aria-label="Clear search"
              icon={<Icon as={FiXCircle} color="gray" />}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                setInputValue('')
                onClear(e)
              }}
              variant="text"
            />
          ) : (
            <IconButton
              cursor="default"
              aria-label="search"
              icon={<Icon as={FiSearch} color="gray" />}
              variant="text"
            />
          )}
        </InputRightElement>
      </InputGroup>
    )
  }
)

export default SearchInput
