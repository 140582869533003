import type { TagProps } from '@chakra-ui/react'
import { Tag, HStack, Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiCaretDown } from 'react-icons/pi'

import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize } from '@app/types'
import { DomainObjectStatusEnum } from '@graphql/queries'

const statusDisplay = (status: string) => {
  let displayStatus: string
  let backgroundColor: string
  let textColor: string

  switch (status) {
    case DomainObjectStatusEnum.Idea:
      displayStatus = 'Idea'
      backgroundColor = 'yellow.500'
      textColor = 'white'
      break
    case DomainObjectStatusEnum.Next:
      displayStatus = 'Next'
      backgroundColor = 'orange.500'
      textColor = 'white'
      break
    case DomainObjectStatusEnum.Active:
      displayStatus = 'Active'
      backgroundColor = 'green.500'
      textColor = 'white'
      break
    case DomainObjectStatusEnum.LearningReview:
      displayStatus = 'Learning Review'
      backgroundColor = 'teal.500'
      textColor = 'white'
      break
    case DomainObjectStatusEnum.Done:
      displayStatus = 'Done'
      backgroundColor = 'blue.500'
      textColor = 'white'
      break
    default:
      displayStatus = 'Idea'
      backgroundColor = 'yellow.500'
      textColor = 'white'
  }

  return { displayStatus, backgroundColor, textColor }
}

export interface Props extends TagProps {
  status?: string
  cardSize?: CardSize
  showChevron?: boolean
}

const CardStatusTag: FC<Props> = ({
  status,
  bgColor = null,
  color = null,
  cardSize = 'normal',
  showChevron = false,
  ...rest
}) => {
  const { displayStatus, backgroundColor, textColor } = statusDisplay(status)

  if (!status) {
    return null
  }

  const tagSize = cardSize === 'normal' ? 'md' : 'lg'

  return (
    <Tag
      color={color || textColor}
      fontSize={cardFontSize('sm', cardSize)}
      bgColor={bgColor || backgroundColor}
      size={tagSize}
      {...rest}
    >
      <HStack>
        <span>{displayStatus}</span>
        {showChevron && <Icon as={PiCaretDown} />}
      </HStack>
    </Tag>
  )
}

export default CardStatusTag
