import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import CardTypeTable from './basicTypeModal/cardTypeTable'
import CreateBasicCardTypeButton from './basicTypeModal/createBasicCardTypeButton'

import type { CardType } from '@graphql/types'

interface Props {
  cardTypes: CardType[]
  isOpen: boolean
  onClose: () => void
}

const BasicTypeModal: FC<Props> = ({ cardTypes, isOpen, onClose }) => (
  <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Manage card types</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={4}>
        <Stack spacing={6}>
          <CardTypeTable cardTypes={cardTypes} />
          <CreateBasicCardTypeButton />
        </Stack>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default BasicTypeModal
