import { Box, Button, HStack } from '@chakra-ui/react'
import type { FC } from 'react'

import { useStore } from '@app/store'
import type { CardSize as CardSizeType, MapDomainNode } from '@app/types'

interface Props {
  node: MapDomainNode
}

const CardSize: FC<Props> = ({ node }) => {
  const updateNode = useStore.use.updateNode()
  const currentSize = node?.metadata?.size || 'normal'
  const { strategyId } = node

  const changeSize = (size: CardSizeType) => {
    updateNode(strategyId, node.id, { metadata: { ...node.metadata, size } })
  }

  const sizeButtonParams = (size: CardSizeType) => ({
    colorScheme: 'gray',
    isActive: currentSize === size,
    size: 'xs',
    w: '100%',
    variant: 'primary',
    onClick: () => changeSize(size)
  })

  return (
    <HStack>
      <Box w="100%">
        <Button {...sizeButtonParams('large')}>Large</Button>
      </Box>
      <Box w="100%">
        <Button {...sizeButtonParams('normal')}>Normal</Button>
      </Box>
    </HStack>
  )
}

export default CardSize
