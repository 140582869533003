import { Button, type ModalProps, useDisclosure } from '@chakra-ui/react'
import type { FC } from 'react'

import MetricIntegrationModal from './metricIntegrationModal'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

type Props = Partial<ModalProps>

const MetricIntegrationCreateModal: FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { user } = useStoreCurrentUser()

  if (!user) {
    return null
  }

  return (
    <>
      <Button onClick={onOpen} variant="primary">
        + Add Integration
      </Button>

      <MetricIntegrationModal isOpen={isOpen} onClose={onClose} {...props} />
    </>
  )
}

export default MetricIntegrationCreateModal
