import type { FC } from 'react'

import type { WORK_SOURCES_TO_SHOW } from '@app/pages/maps/components/addCards/helpers'
import { showSourceNameInput } from '@app/pages/maps/components/addCards/helpers'
import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'

interface Props extends Pick<SelectInputProps, 'value' | 'onChange'> {
  sources?: { value: string; display: string }[]
  typeValue: 'metric' | 'entity' | (typeof WORK_SOURCES_TO_SHOW)[number]
}

const SourceNameInput: FC<Props> = ({ value, typeValue, sources = [], onChange = () => {} }) => {
  if (!showSourceNameInput(typeValue)) {
    return null
  }

  return (
    <SelectInput
      value={value}
      name="scope.source_name"
      placeholder=""
      onChange={onChange}
      data-testid="show-sources-input"
    >
      <option value="">All sources</option>
      {sources.map((source) => (
        <option key={source.value} value={source.value}>
          {source.display}
        </option>
      ))}
    </SelectInput>
  )
}

export default SourceNameInput
