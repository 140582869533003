import { Stack } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import { useViewport } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import { Card } from '../components'
import CardLabels from '../components/cardLabels'
import { CardProvider, useCardContext } from '../contexts/cardContext'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import { aiInsightsShadow } from '@app/lib/globals'
import BasicCardTypePopover from '@app/next/basicCardTypePopover'
import DomainObjectStatusPopover from '@app/next/domainObjectStatusPopover'
import WithLabel from '@app/next/forms/withLabel'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import cardFontSize from '@app/shared/cards/cardFontSize'
import cardIconBoxSize from '@app/shared/cards/cardIconBoxSize'
import CardDetailsLink from '@app/shared/cards/components/cardDetailsLink'
import CardSection from '@app/shared/cards/components/cardSection'
import CardSharedActions from '@app/shared/cards/components/cardSharedActions'
import CardTitle from '@app/shared/cards/components/cardTitle'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import PulsingCircle from '@app/shared/pulsingCircle'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainNode } from '@app/types'
import type { BasicCard as BasicCardType } from '@graphql/types'

interface Props extends BoxProps {
  strategyId: string
  basicCard?: BasicCardType
  node?: MapDomainNode
  onClick?: () => void
  onDoubleClick?: () => void
}

export const BasicCard: FC<Props> = ({ basicCard, node, strategyId, ...rest }) => {
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const { size } = useCardContext()
  const {
    aiGenerated,
    description,
    externalUrl,
    externalUrlTitle,
    goalHypothesis,
    id,
    labels,
    recentActivity,
    results,
    showDescription,
    showGoalHypothesis,
    showResults
  } = basicCard
  const { zoom } = useViewport()

  const route = isAnonymousUser ? null : `/strategy/${strategyId}/map/basicCard/${id}`

  const pulseSize = scaleSizeToMapZoom(24, zoom, 44)
  const fontSize = cardFontSize('md', size)
  const boxSize = cardIconBoxSize(5, size)

  const boxProps: BoxProps = {}
  if (aiGenerated) {
    boxProps.boxShadow = aiInsightsShadow
  }

  return (
    <Card {...boxProps} {...rest}>
      <CardTypeSection route={route} size={size}>
        <BasicCardTypePopover basicCard={basicCard} fontSize={fontSize} boxSize={boxSize} />
      </CardTypeSection>
      <CardSection size={size}>
        <PulsingCircle isActive={!!recentActivity} size={pulseSize} link={`basicCard/${id}/events`} />
        <Stack spacing={size === 'large' ? 6 : 2}>
          <CardTitle domainObject={basicCard} name="name" size={size} />
          {showDescription && description && <MarkdownDisplay text={description} fontSize={fontSize} />}
          {showGoalHypothesis && goalHypothesis && (
            <WithLabel showLabel={!!goalHypothesis} label="Goal / Hypothesis">
              <MarkdownDisplay text={goalHypothesis} fontSize={fontSize} />
            </WithLabel>
          )}
          {showResults && results && (
            <WithLabel showLabel={!!results} label="Results">
              <MarkdownDisplay text={results} fontSize={fontSize} />
            </WithLabel>
          )}
          {externalUrl && <CardDetailsLink url={externalUrl} text={externalUrlTitle} size={size} />}
        </Stack>
      </CardSection>
      {labels?.length && <CardLabels fieldName="labels" domainObject={basicCard} size={size} />}
      <CardSharedActions domainObject={basicCard} size={size} route={route}>
        <DomainObjectStatusPopover domainObject={basicCard} mx={2} />
      </CardSharedActions>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export const BasicCardWithContext = memo(withBasicProvider(CardProvider)(BasicCard))
