import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import EntityForm from '@app/pages/shared/entities/components/entityForm'
import { type Entity } from '@graphql/types'

const EntityFormTab: FC = () => {
  const navigate = useNavigate()
  const outletContext = useOutletContext()
  const { nodeId } = useParams()

  let successHandler = () => navigate('..')
  let cancelHandler = null
  let deleteHandler
  let options = {}

  if (outletContext) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ;[successHandler, cancelHandler, deleteHandler, options] = outletContext
  }
  const entity = useGetObject(nodeId, 'entity') as Entity

  return (
    <Box p={4} data-testid="entity-form-tab">
      <EntityForm
        entity={entity}
        onCancel={() => {
          if (cancelHandler) {
            cancelHandler()
          } else {
            navigate(-1)
          }
        }}
        onDelete={deleteHandler}
        onSuccess={successHandler}
        {...options}
      />
    </Box>
  )
}

export default EntityFormTab
