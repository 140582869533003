import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom'

import EmbedTest from './pages/embedTest'
import BasicCardRoutes from './routes/basicCards'
import CorrelationRoutes from './routes/correlations'
import WorkRoutes from './routes/events'
import { MapRoutes } from './routes/maps'
import MetricRoutes from './routes/metrics'
import OnboardingRoutes from './routes/onboarding'
import PlaybookRoutes from './routes/playbooks'
import SessionRoutes from './routes/sessions'
import SettingsRoutes from './routes/settings'
import TaskRoutes from './routes/tasks'

import Root from '@app/root'
import ErrorPage from '@app/shared/errorPage'
import { AppShell } from '@app/shared/layout'
import { useStore } from '@app/store'
import { currentUser, requireOrganization, requiresNoUser } from '@app/utils/auth'
import { loaderQuery } from '@graphql/client'
import { Favorites } from '@graphql/documents/favorite.graphql'
import { OrganizationGoogleSheet } from '@graphql/documents/google_sheet.graphql'
import { CustomerSubscription } from '@graphql/documents/organization.graphql'

const router = createBrowserRouter([
  {
    id: 'user',
    loader: async () => {
      const { setCurrentUser, loaderQuery: storeLoaderQuery } = useStore.getState()

      const user = await currentUser()
      setCurrentUser(user)

      storeLoaderQuery(Favorites)
      storeLoaderQuery(OrganizationGoogleSheet)

      return user
    },
    children: [
      {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        handle: {
          headData: () => ({
            title: 'DoubleLoop',
            description: 'Stay in the loop with our product updates and metrics',
            url: 'https://doubleloop.app'
          })
        },
        children: [
          {
            id: 'loggedIn',
            loader: requireOrganization,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Navigate to="strategy" replace />
              },
              {
                path: 'customer-subscription',
                id: 'customer-subscription',
                loader: async () => {
                  const result = await loaderQuery(CustomerSubscription, {})

                  return {
                    customerSubscription: result?.data?.organization?.activeSubscription
                  }
                }
              },
              {
                element: <AppShell />,
                children: [...BasicCardRoutes, CorrelationRoutes, MetricRoutes, SettingsRoutes, TaskRoutes, WorkRoutes]
              }
            ]
          },
          {
            id: 'loggedOut',
            loader: requiresNoUser,
            children: [SessionRoutes]
          },
          {
            id: 'mixedAccess',
            children: [
              MapRoutes,
              OnboardingRoutes,
              PlaybookRoutes,
              {
                path: 'embed-test',
                element: <EmbedTest />
              }
            ]
          }
        ]
      }
    ]
  }
])

export default router
