import { Link as ChakraLink, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import ActionBar from './actionBar'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Gallery from '@app/shared/gallery'
import type { GalleryImage } from '@app/shared/gallery/gallery'
import type { MapDomainEntity } from '@app/types'
import { pathWithSearchParams } from '@app/utils/routeHelpers'

export interface Props {
  entity: Pick<MapDomainEntity, 'entityAttachmentsCount' | 'id'> & {
    thumbnail?: GalleryImage | null
    entityAttachments?: GalleryImage[] | null
  }
  scrollContainer?: string | object
  isListView?: boolean
}

const EntityImages: FC<Props> = ({ entity, scrollContainer = '', isListView = false }) => {
  const { thumbnail, entityAttachments } = entity
  const { user } = useStoreCurrentUser()

  // Don't love this, im wondering if we update our underlying modeling and work on the queries instead of splitting
  // thumbnail from the rest. Out of scope of this story though
  let images = []
  if (entityAttachments?.length) {
    images = entityAttachments
  } else if (thumbnail) {
    images = [thumbnail]
  }

  if (!images.length) {
    return null
  }
  const entityPath = pathWithSearchParams(`${entity.id}`)
  const actionComponent = user ? ActionBar : null

  return (
    <Stack>
      <Gallery images={images} scrollContainer={scrollContainer} ImageActionsComponent={actionComponent} />)
      {isListView && entity.entityAttachmentsCount > 1 && (
        <ChakraLink as={Link} to={entityPath}>
          See all {entity.entityAttachmentsCount} images
        </ChakraLink>
      )}
    </Stack>
  )
}

export default EntityImages
