import { Icon } from '@chakra-ui/react'
import { MdFormatQuote } from 'react-icons/md'

import blockButtonMaker from './blockButtonMaker'

const BlockquoteButton = blockButtonMaker({
  blockType: 'blockquote',
  children: <Icon as={MdFormatQuote} boxSize={6} />
})

export default BlockquoteButton
