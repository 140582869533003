import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FC } from 'react'
import { useRef } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import { useEntityDeleteMutation } from '@graphql/queries'

interface Props {
  entityId: string
  onDelete?: () => void
}

const EntityDeleteButton: FC<Props> = ({ entityId, onDelete = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const [, entityDelete] = useEntityDeleteMutation()

  const deleteEntity = () => {
    onClose()

    entityDelete({ input: { entityId } })
      .then(() => {
        ahoy.track('entity:deleted', {
          entity_id: entityId
        })
      })
      .then(onDelete)
  }

  return (
    <>
      <Tooltip label="Delete event">
        <IconButton aria-label="Delete event" colorScheme="gray" icon={<FiTrash2 />} onClick={onOpen} />
      </Tooltip>
      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete event
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete all the data for this metric? This can&apos;t be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} bgColor="error" onClick={deleteEntity} variant="primary">
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default EntityDeleteButton
