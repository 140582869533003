import type { InputProps } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import type { ChangeEvent, FC } from 'react'

// https://gist.github.com/dperini/729294
// Note that this pattern is modified to integrate case-insensitivity,
// as the `pattern` attribute does not support the `i` flag.
const PATTERN =
  '^' +
  // protocol identifier (optional)
  // short syntax // *not* required
  '(?:(?:[Hh][Tt][Tt][Pp][Ss]?:)?\\/\\/)?' +
  // user:pass BasicAuth (optional)
  '(?:\\S+(?::\\S*)?@)?' +
  '(?:' +
  // IP address exclusion
  // private & local networks
  '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
  '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
  '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
  '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
  '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
  '|' +
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  '(?:' +
  '(?:' +
  '[A-Za-z0-9\\u00a1-\\uffff]' +
  '[A-Za-z0-9\\u00a1-\\uffff_-]{0,62}' +
  ')?' +
  '[A-Za-z0-9\\u00a1-\\uffff]\\.' +
  ')+' +
  // TLD identifier name, may end with dot
  '(?:[A-Za-z\\u00a1-\\uffff]{2,}\\.?)' +
  ')' +
  // port number (optional)
  '(?::\\d{2,5})?' +
  // resource path (optional)
  '(?:[/?#]\\S*)?' +
  '$'

const HELP = 'Please enter a URL. You can omit the "https://" at the start if you choose.'

const UrlInput: FC<InputProps> = (props) => {
  const onInvalid = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.setCustomValidity(HELP)
  }

  return <Input onInvalid={onInvalid} pattern={PATTERN} title={HELP} type="text" {...props} />
}

export default UrlInput
