import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import Header from '@app/pages/metrics/components/header'
import { PageStack } from '@app/shared/layout'
import withAwait from '@app/shared/withAwait'

const Show = () => {
  const outletContext = useOutletContext()
  const { nodeId } = useParams()

  const metric = useGetObject(nodeId, 'metric')

  if (!metric) {
    return null
  }

  return (
    <PageStack bg="bg.surface">
      <Header metric={metric} />
      <Outlet context={outletContext} />
    </PageStack>
  )
}

export default withAwait(Show, 'metric')
