import { IconButton, forwardRef } from '@chakra-ui/react'
import type { FC } from 'react'

import useDuplicateNodes from '@app/hooks/useDuplicateNodes'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  nodes: Parameters<typeof useDuplicateNodes>[0]
}

const BulkDuplicateButton: FC<Props> = forwardRef(({ nodes }, ref) => {
  const { duplicate, isLoading } = useDuplicateNodes(nodes)
  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const handleClick = () => {
    duplicate()
  }

  return (
    <IconButton
      ref={ref}
      aria-label="Duplicate cards"
      icon={<ICON_MAP.Copy />}
      isLoading={isLoading}
      onClick={handleClick}
    />
  )
})

export default BulkDuplicateButton
