import { Box, ScaleFade, useColorModeValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Outlet } from 'react-router-dom'

interface Props {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}

const Drawer: FC<Props> = ({ isOpen, onClose, children = null, ...rest }) => {
  useHotkeys('esc', () => onClose())

  return (
    <ScaleFade in={isOpen} initialScale={0.95}>
      <Box
        sx={{
          transition: 'visibility 1s',
          opacity: '1s'
        }}
        pos="absolute"
        zIndex="docked"
        top={0}
        right={0}
        overflow="hidden"
        w={{ base: '100%', md: '45%', lg: '35%' }}
        h="100%"
        bg="bg.surface"
        shadow={useColorModeValue('md', 'md-dark')}
        {...rest}
      >
        {children || <Outlet />}
      </Box>
    </ScaleFade>
  )
}

export default Drawer
