import { ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react'
import { useReactFlow } from '@xyflow/react'

import ICON_MAP from '@app/utils/iconMap'

const ZoomButtons = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow()

  const commonProps = {
    size: '2xs',
    variant: 'ghost',
    colorScheme: 'gray'
  }

  return (
    <ButtonGroup isAttached>
      <Tooltip aria-label="Zoom in" label="Zoom in">
        <IconButton
          aria-label="Zoom in"
          icon={<ICON_MAP.ZoomIn />}
          onClick={() => zoomIn()}
          title="Zoom in"
          {...commonProps}
        />
      </Tooltip>
      <Tooltip aria-label="Zoom out" label="Zoom out">
        <IconButton
          aria-label="Zoom out"
          icon={<ICON_MAP.ZoomOut />}
          onClick={() => zoomOut()}
          title="Zoom out"
          {...commonProps}
        />
      </Tooltip>
      <Tooltip aria-label="Fit view" label="Fit view">
        <IconButton
          aria-label="Fit view"
          icon={<ICON_MAP.FitView />}
          onClick={() => fitView()}
          title="Fit view"
          {...commonProps}
        />
      </Tooltip>
    </ButtonGroup>
  )
}

export default ZoomButtons
