import { Modal, ModalBody, ModalContent } from '@chakra-ui/react'
import delay from 'lodash/delay'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import MapSegmentFiltersModalContext from './mapSegmentFiltersModalContext'
import SegmentFilters from './segmentFilters'

import type { SegmentFilter, SegmentName, SegmentValue } from '@graphql/types'

interface Props {
  strategyId: string
  segmentFilters?: (Pick<SegmentFilter, 'id'> & {
    segmentName: Pick<SegmentName, 'id' | 'name'>
    segmentValue: Pick<SegmentValue, 'id' | 'value'>
  })[]
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  onFilter?: (segmentFilter: { id: string; value: string; active: boolean }) => void
}

const SegmentFiltersModal: FC<Props> = ({
  strategyId,
  segmentFilters = [],
  isOpen,
  onOpen = () => {},
  onClose,
  onFilter = () => {}
}) => {
  const [activeSegmentName, setActiveSegmentName] = useState(null)

  const handleClose = useCallback(() => {
    onClose()
    delay(() => {
      setActiveSegmentName(null)
    }, 500)
  }, [onClose])

  const providerValue = useMemo(
    () => ({
      segmentFilters,
      isOpen,
      onOpen,
      onClose: handleClose,
      activeSegmentName,
      setActiveSegmentName,
      onFilter
    }),
    [segmentFilters, isOpen, onOpen, handleClose, activeSegmentName, onFilter]
  )

  return (
    <MapSegmentFiltersModalContext.Provider value={providerValue}>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalContent shadow="dark-lg">
          <ModalBody p={0}>
            <SegmentFilters strategyId={strategyId} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </MapSegmentFiltersModalContext.Provider>
  )
}

export default SegmentFiltersModal
