import { HStack, IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import GoalDeleteButton from '@app/pages/metrics/components/goals/goalDeleteButton'
import Can from '@app/shared/authorization/can'
import type { Goal } from '@graphql/queries'

type Props = {
  goal: Pick<Goal, 'id'>
}

const GoalActions: FC<Props> = ({ goal }) => (
  <HStack data-testid="goal-actions">
    <Can I="update" a="goal">
      <Tooltip label="Edit key result">
        <IconButton as={Link} aria-label="Edit key result" icon={<FiEdit2 />} to={`${goal.id}/edit`} />
      </Tooltip>
    </Can>
    <Can I="delete" a="goal">
      <GoalDeleteButton goalId={goal.id}>
        <Tooltip label="Delete key result">
          <IconButton aria-label="Delete key result" icon={<FiTrash2 />} />
        </Tooltip>
      </GoalDeleteButton>
    </Can>
  </HStack>
)

export default GoalActions
