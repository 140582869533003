import { HStack, Spacer, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import type { ReactNode, FC } from 'react'

interface PageHeaderProps {
  title: string
  pretitle?: ReactNode
  subtitle?: ReactNode
  children?: ReactNode
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  pretitle = null,
  subtitle = null,
  children = null
}: PageHeaderProps) => (
  <Stack spacing="2">
    <Stack direction={{ base: 'column', md: 'row' }} verticalAlign="top" spacing="3">
      <HStack>
        {pretitle}
        <Text fontSize={useBreakpointValue({ base: 'lg', md: 'xl' })} fontWeight="medium" wordBreak="break-word">
          {title}
        </Text>
      </HStack>
      <Spacer />
      <HStack>{children}</HStack>
    </Stack>
    {subtitle && (
      <Text color="muted" fontSize="sm">
        {subtitle}
      </Text>
    )}
  </Stack>
)

export default PageHeader
