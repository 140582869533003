import { IconButton, Stack, Text, Tooltip } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'

import ConfidenceRatingTag from '@app/next/domainObjects/confidenceRating/confidenceRatingTag'
import SelectInput from '@app/next/forms/selectInput'
import { domainObjectConfidenceRatingOptionsFor } from '@app/pages/maps/components/helpers'
import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import type { ConfidenceRatingEnum } from '@graphql/queries'

const displayName = (type?: ConfidenceRatingEnum) => {
  if (!type) {
    return ''
  }

  return capitalize(type?.replace('_', ' '))
}

interface Props {
  type: ConfidenceRatingEnum
  fieldName?: string
  editing?: boolean
  size?: CardSize
  collapse?: boolean
  showTitle?: boolean
  showPlaceholder?: boolean
  showChevron?: boolean
  onChange?: (e) => void
}

const CardConfidenceRating: FC<Props> = ({
  type,
  fieldName,
  editing = false,
  size = 'normal',
  collapse = false,
  showTitle = true,
  showPlaceholder = false,
  showChevron = false,
  onChange = () => {}
}) => {
  const displayType = displayName(type)
  const fontSize = cardFontSize('sm', size) as CardSize

  if (editing) {
    return (
      <Stack>
        {showTitle && (
          <Text fontSize="xs" fontWeight="bold">
            Confidence
          </Text>
        )}
        <SelectInput
          minW="100px"
          name={fieldName}
          defaultValue={type}
          placeholder=""
          onChange={onChange}
          onBlur={onChange}
        >
          <option value="" />
          {domainObjectConfidenceRatingOptionsFor()}
        </SelectInput>
      </Stack>
    )
  }

  if (!type && !showPlaceholder) {
    return null
  }

  const color = COLOR_MAP[`Confidence-${type}`] || 'gray'
  const tagSize = collapse ? 'sm' : 'md'
  const displayText = collapse ? '' : displayType
  let tag

  const placeholder = (
    <Tooltip aria-label="Confidence level" label="Confidence level">
      <IconButton aria-label="Confidence level" colorScheme="gray" icon={<ICON_MAP.Confidence />} variant="ghost" />
    </Tooltip>
  )

  if (showPlaceholder && !type) {
    tag = placeholder
  } else {
    tag = (
      <ConfidenceRatingTag
        showChevron={showChevron}
        rating={displayText}
        fontSize={fontSize}
        color={color}
        tagSize={tagSize}
        tooltip={collapse ? displayType : ''}
      />
    )
  }

  return tag
}

export default CardConfidenceRating
