import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { type FC, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useRevalidator } from 'react-router-dom'

import ConfirmPopover from '@app/shared/confirmPopover'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'

interface Props {
  metricId?: string
  onDelete?: () => void
  children?: FC<ButtonProps>
}

const DefaultChild: FC<ButtonProps> = (props) => (
  <Button color="error" aria-label="Delete metric" leftIcon={<FiTrash2 />} variant="tertiary" {...props}>
    Delete
  </Button>
)

const MetricDeleteButton: FC<Props> = ({ metricId = null, onDelete = () => {}, children = DefaultChild }) => {
  const toast = useToast()
  const revalidator = useRevalidator()
  const deleteObject = useStore.use.deleteObject()
  const [isLoading, setIsLoading] = useState(false)
  const Child = children || DefaultChild

  const deleteMetric = async () => {
    if (!metricId) {
      return false
    }

    setIsLoading(true)

    try {
      const result = await deleteObject({ id: metricId, classType: 'metric' }, { updatePageCounts: true })
      toast({ title: 'Deleted metric' })
      onDelete()
      revalidator.revalidate()
      return result
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ConfirmPopover
      onConfirm={deleteMetric}
      header="Delete metric"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      <Child isLoading={isLoading} />
    </ConfirmPopover>
  )
}

export default MetricDeleteButton
