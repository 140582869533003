import { Button, type ButtonProps, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

interface Props extends ButtonProps {
  text?: string
}

const MetricCreateButton: FC<Props> = ({ text = 'New Data Point', ...rest }) => {
  const { user } = useStoreCurrentUser()
  const navigate = useNavigate()

  if (!user) {
    return null
  }

  const onClick = () => {
    navigate('new')
  }

  return (
    <Tooltip label="Create data point">
      <Button onClick={onClick} {...rest}>
        {text}
      </Button>
    </Tooltip>
  )
}

export default MetricCreateButton
