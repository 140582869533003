import { Box, ButtonGroup } from '@chakra-ui/react'
import type { NodeToolbarProps } from '@xyflow/react'
import { NodeToolbar } from '@xyflow/react'
import type { FC, ReactNode } from 'react'

import useGetIsSelecting from '@app/hooks/useGetIsSelecting'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'

interface Props extends NodeToolbarProps {
  strategyId: string
  children: ReactNode
}

const Toolbar: FC<Props> = ({ strategyId, children, ...rest }) => {
  const isSelecting = useGetIsSelecting()
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  return (
    !isSelecting &&
    !isAnonymousUser && (
      <NodeToolbar {...rest}>
        <Box bg="bg.surface" borderRadius="md" shadow="sm">
          <ButtonGroup colorScheme="gray" isAttached size="sm" variant="outline">
            {children}
          </ButtonGroup>
        </Box>
      </NodeToolbar>
    )
  )
}

export default Toolbar
