import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { usePage } from '@app/shared/pagination'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import { useTasksQuery } from '@graphql/queries'
import type { Task } from '@graphql/types'

interface Props {
  isLoading: boolean
  setIsLoading?: (isLoading: boolean) => void
}

const TasksFetcher: FC<Props> = ({ isLoading = false, setIsLoading = (_value) => {} }) => {
  const { addObjectPage } = useStore.getState()
  const page = usePage()
  const [taskData, setTaskData] = useState(null)
  const [internalPage, setInternalPage] = useState(page)

  const [{ data }] = useTasksQuery({
    variables: { page },
    pause: !!taskData && page === internalPage
  })

  useEffect(() => {
    if (!isLoading && !data) {
      setIsLoading(true)
    }
  }, [isLoading])

  useEffect(() => {
    if (!data) {
      return
    }

    const { collection = [], metadata } = data.tasks
    addObjectPage('task', collection as Task[], metadata)

    setInternalPage(page)
    setTaskData(data)
    setIsLoading(false)
  }, [isLoading, page, data])

  return null
}

export default withSuspenseWrapper(TasksFetcher)
