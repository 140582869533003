import { Button } from '@chakra-ui/react'
import type { FC, MutableRefObject, PropsWithChildren } from 'react'

export type Props = {
  isLoading: boolean
  onConfirm: () => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  onClose: () => void
  cancelRef: MutableRefObject<null>
}

const ConfirmPopoverFooter2Button: FC<PropsWithChildren<Props>> = ({
  children,
  isLoading,
  onConfirm,
  onClose,
  cancelRef
}) => {
  const confirm = () => onConfirm().then(onClose)

  return (
    <>
      <Button ref={cancelRef} isDisabled={isLoading} onClick={onClose}>
        Cancel
      </Button>
      <Button ml={3} colorScheme="red" isLoading={isLoading} onClick={confirm}>
        {children}
      </Button>
    </>
  )
}

export default ConfirmPopoverFooter2Button
