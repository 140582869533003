import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FC } from 'react'
import { useState } from 'react'
import { FiMail } from 'react-icons/fi'

import { RawForm } from '@app/shared/rawForms/form'
import type { Contributor } from '@graphql/queries'
import { useInvitationCreateMutation } from '@graphql/queries'
import { RoleEnum } from '@graphql/types'

interface InviteFormProps {
  contributor: Contributor
  setInvited?: (invited: boolean) => void
}

const InviteForm: FC<InviteFormProps> = ({ contributor, setInvited = () => {} }) => {
  const [error, setError] = useState(null)
  const [, createInvitation] = useInvitationCreateMutation()

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const values = Object.fromEntries(formData.entries())

    try {
      const input = {
        email: values.email as string,
        role: RoleEnum.Viewer
      }

      await createInvitation({ input })

      ahoy.track('user:sent_invitation', {
        email: input.email
      })

      setInvited(true)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <RawForm onSubmit={onSubmit}>
      <FormControl isInvalid={!!error} isRequired>
        <InputGroup>
          <Input
            defaultValue={contributor.email || ''}
            id="email"
            name="email"
            placeholder="Email address"
            type="email"
          />
          <InputRightElement>
            <IconButton aria-label="Send Invite" icon={<FiMail />} type="submit" variant="primary" />
          </InputRightElement>
        </InputGroup>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </RawForm>
  )
}

interface InviteButtonProps {
  setInviting?: (invited: boolean) => void
}

const InviteButton: FC<InviteButtonProps> = ({ setInviting = () => {} }) => (
  <Button
    w="full"
    _hover={{
      transform: 'translateY(-2px)',
      boxShadow: 'lg'
    }}
    onClick={() => setInviting(true)}
    rounded="md"
    variant="primary"
  >
    Invite
  </Button>
)

interface ContributorInviteButtonProps {
  contributor: Contributor
}

const ContributorInviteButton: FC<ContributorInviteButtonProps> = ({ contributor }) => {
  const [inviting, setInviting] = useState(false)
  const [invited, setInvited] = useState(false)
  let buttonComponent

  if (contributor.sourceName === 'doubleloop') {
    return null
  }

  if (invited) {
    buttonComponent = (
      <Button w="full" isDisabled rounded="md">
        Invite Sent!
      </Button>
    )
  } else if (inviting) {
    buttonComponent = <InviteForm contributor={contributor} setInvited={setInvited} />
  } else {
    buttonComponent = <InviteButton setInviting={setInviting} />
  }

  return <Box mt={8}>{buttonComponent}</Box>
}

export default ContributorInviteButton
