import { Icon, Link, Text } from '@chakra-ui/react'
import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { SelectInput, TextInput } from '@app/shared/rawForms'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  isDisabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
}

// e.g. 'https://mixpanel.com/project/123/view/456/app/boards#id=777&editor-card-id=%22report-88888%22'
// const INSIGHTS_REGEX = /https:\/\/mixpanel\.com\/project\/\d+\/view\/\d+\/app\/boards.+editor-card-id=.+report-\d+.+/

const EventFields: FC<Props> = ({ apiRef: _, metricSource, isDisabled }) => (
  <>
    <TextInput
      name="configuration.event"
      label="Event name"
      placeholder="Mixpanel event name"
      isDisabled={isDisabled}
      isRequired
      defaultValue={metricSource?.configuration?.event}
    />
    <TextInput
      name="configuration.where"
      label={
        <>
          <Text display="inline">Mixpanel &quot;where&quot; field (optional) </Text>

          <Link
            verticalAlign="middle"
            color="link"
            href="https://developer.mixpanel.com/reference/segmentation-expressions"
            isExternal
          >
            <Icon as={FiHelpCircle} boxSize={4} />
          </Link>
        </>
      }
      placeholder={'Mixpanel "where" field'}
      isDisabled={isDisabled}
      defaultValue={metricSource?.configuration?.where}
    />
  </>
)

const InsightsFields: FC<Props> = ({ apiRef, metricSource, isDisabled }) => {
  const defaultUrl = metricSource?.configuration?.url || ''
  const [url, setUrl] = useState<string>(defaultUrl)

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => setUrl(defaultUrl)
    }),
    [url]
  )

  return (
    <TextInput
      name="configuration.url"
      label="Insights report URL"
      placeholder={'https://mixpanel.com/project/1111/view/2222/app/boards#id=3333&editor-card-id="report-12345"'}
      isDisabled={isDisabled}
      isRequired
      value={url}
      onChange={(e) => setUrl(e.target.value)}
    />
  )
}

type MixpanelDataSource = 'event' | 'insights'

const Mixpanel: FC<Props> = ({ apiRef, metricSource, isDisabled }) => {
  const [dataSource, setDataSource] = useState<MixpanelDataSource>(metricSource?.configuration?.data_source || 'event')
  const Fields = dataSource === 'insights' ? InsightsFields : EventFields

  return (
    <>
      <SelectInput
        name="configuration.data_source"
        label="Report Type"
        isDisabled={isDisabled}
        value={dataSource}
        placeholder={null}
        isRequired
        onChange={(e) => setDataSource(e.target.value as MixpanelDataSource)}
      >
        <option value="event">Event Data</option>
        <option value="insights">Insights Report</option>
      </SelectInput>
      <Fields metricSource={metricSource} isDisabled={isDisabled} apiRef={apiRef} />
      <SelectInput
        name="configuration.region"
        label="Region"
        placeholder={null}
        isDisabled={isDisabled}
        defaultValue={metricSource?.configuration?.region}
      >
        <option value="us">US</option>
        <option value="eu">EU</option>
      </SelectInput>
    </>
  )
}

export default Mixpanel
