import type { ButtonGroupProps } from '@chakra-ui/react'
import { ButtonGroup, IconButton, Link } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiDownload, FiTrash2 } from 'react-icons/fi'

import { useUploadsContext } from '../entities/uploadsContext'

import type { Upload } from '@app/types'

type Props = ButtonGroupProps & {
  attachment: Upload
}

const NewEntityActionBar: FC<Props> = ({ attachment, ...rest }) => {
  const { dispatch } = useUploadsContext()
  const onClick = () => {
    dispatch({ type: 'remove', payload: attachment })
  }

  return (
    <ButtonGroup size="xs" {...rest}>
      <IconButton as={Link} aria-label="Download image" href={attachment.url} icon={<FiDownload />} isExternal />
      <IconButton aria-label="Delete Attachment" icon={<FiTrash2 />} onClick={onClick} />
    </ButtonGroup>
  )
}

export default NewEntityActionBar
