import { MenuItem, type ModalProps, useDisclosure } from '@chakra-ui/react'
import type { FC } from 'react'

import MetricIntegrationModal from './metricIntegrationModal'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { Credential } from '@graphql/types'

type Props = Partial<ModalProps> & {
  credential: Credential
}

const MetricIntegrationEditModal: FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { user } = useStoreCurrentUser()

  if (!user) {
    return null
  }

  return (
    <>
      <MenuItem onClick={onOpen}>Edit integration...</MenuItem>

      <MetricIntegrationModal isOpen={isOpen} onClose={onClose} {...props} />
    </>
  )
}

export default MetricIntegrationEditModal
