import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'

import KeyboardShortcutsTooltip from '@app/shared/keyboardShortcutsTooltip'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'sm'
}

interface Props {
  node: MapDomainNode
}

const DeleteButton: FC<Props> = ({ node }) => {
  const { strategyId } = node
  const onNodesDelete = useStore.use.onNodesDelete()

  const deleteCard = () => {
    onNodesDelete(strategyId, [node])
  }

  return (
    <Tooltip label={<KeyboardShortcutsTooltip title="Delete card" shortcut="del" />}>
      <IconButton {...buttonStyles} aria-label="Delete card" icon={<ICON_MAP.Delete />} onClick={deleteCard} />
    </Tooltip>
  )
}

export default DeleteButton
