import { createStandaloneToast } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import type { RouteObject } from 'react-router-dom'
import { Navigate, Outlet, defer, redirect } from 'react-router-dom'

import ListPage from '@app/pages/entities/list'
import StrategyShow from '@app/pages/entities/show'
import Tabs from '@app/pages/entities/tabs'
import EntityDetails from '@app/pages/entities/tabs/entityDetails'
import EntityFormTab from '@app/pages/entities/tabs/entityFormTab'
import Create from '@app/pages/shared/entities/create'
import Edit from '@app/pages/shared/entities/edit'
import List from '@app/pages/shared/entities/list'
import Show from '@app/pages/shared/entities/show'
import ImpactGraphs from '@app/pages/shared/impactGraphs/impactGraphs'
import CommentsFetcher from '@app/shared/comments/commentsFetcher'
import { Notification } from '@app/shared/toast'
import extractOrderings from '@app/shared/utils/extractOrderings'
import { useStore } from '@app/store'
import type { MapDomainEntity, Nodes } from '@app/types'
import { requiresAuthorization } from '@app/utils/auth'
import { Entities, EntityCreate, EntityForm, EntityUpdate } from '@graphql/documents/entity.graphql'
import type { EntitiesQuery, EntitiesQueryVariables, EntityCreateMutation } from '@graphql/queries'

const { toast } = createStandaloneToast()

const parseEventInput = (input) => {
  const eventInput = { ...input }

  eventInput.dueDate &&= new Date(eventInput.dueDate)
  if (eventInput.dueDate === '') {
    eventInput.dueDate = null
  }

  eventInput.happenedAt &&= new Date(eventInput.happenedAt)
  eventInput.labels &&= JSON.parse(eventInput.labels)
  eventInput.contributors &&= JSON.parse(eventInput.contributors)
  eventInput.attachmentHashes &&= JSON.parse(eventInput.attachmentHashes)
  if (eventInput.confidenceRating === '') {
    eventInput.confidenceRating = null
  }

  return eventInput
}

const loadEntities = async ({ request }) => {
  const url = new URL(request.url)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null
  const filter = url.searchParams.get('filter')
  const strategyId = url.searchParams.get('strategyId')
  const order = extractOrderings(url.searchParams, ['name', 'sourceName', 'foreignType'])
  const { loaderQuery } = useStore.getState()

  const variables: EntitiesQueryVariables = omitBy({ page, limit, filter, order, strategyId }, isNull)

  const result = await loaderQuery<EntitiesQuery, EntitiesQueryVariables>(Entities, variables)
  const { addObjectPage } = useStore.getState()

  const { collection = [], metadata } = result.data.entities
  addObjectPage('entity', collection as unknown as MapDomainEntity[], metadata)

  return defer(result.data)
}

const loadEntity = async ({ params }) => {
  const { loaderQuery } = useStore.getState()

  const { data } = await loaderQuery(EntityForm, {
    id: params.entityId || params.workId || params.nodeId
  })

  const { entity } = data

  return { entity }
}

type CreateEvent = (input: {
  request: Request
  params: Record<string, string>
  additionalData?: Record<string, string>
  redirectTo?: (entity: EntityCreateMutation['entityCreate']['entity']) => string
}) => Promise<void | Response>

export const createEvent: CreateEvent = async ({ request, params, additionalData = {}, redirectTo = null }) => {
  const { strategyId } = params
  const formData = await request.formData()
  const input = { ...parseEventInput(Object.fromEntries(formData.entries())), ...additionalData }
  const { actionMutation } = useStore.getState()

  return actionMutation<EntityCreateMutation>(EntityCreate, input).then((resp) => {
    ahoy.track('entity:created', input)

    if (resp.error) {
      throw resp
    }

    const { entity, errors } = resp.data.entityCreate

    if (errors.length) {
      throw errors
    }

    toast({
      title: 'Creating your event',
      position: 'bottom-right',
      status: 'success',
      render: (props) => <Notification {...props} />
    })

    const target = redirectTo?.(entity) || (strategyId ? '../../events' : `../../events/${entity.id}`)

    return redirect(target)
  })
}

export const updateEvent = async ({ request, params }) => {
  const { entityId, nodeId } = params
  const formData = await request.formData()
  const input = { ...Object.fromEntries(formData.entries()), entityId: entityId || nodeId || null }
  const parsedInput = parseEventInput(input)
  const { actionMutation } = useStore.getState()

  return actionMutation(EntityUpdate, parsedInput).then((resp) => {
    ahoy.track('entity:updated', input)

    if (resp.error) {
      throw resp
    }

    const { entity, errors } = resp.data.entityUpdate

    if (errors.length) {
      throw errors
    }

    toast({
      title: 'Updating your event.',
      position: 'bottom-right',
      status: 'success',
      render: (props) => <Notification {...props} />
    })

    return entity
  })
}

const SharedEventRoutes = (nodeType: Nodes) => [
  {
    index: true,
    element: <List nodeType={nodeType} />
  },
  {
    path: 'new',
    loader: async () => requiresAuthorization('create', 'event'),
    element: <Create />,
    action: ({ request, params }) => {
      let additionalData = {}

      switch (nodeType) {
        case 'entity':
        case 'metric':
        case 'basicCard':
          additionalData = { containerId: params.nodeId, containerType: nodeType }
          break
        default:
          break
      }

      switch (request.method) {
        case 'POST':
          return createEvent({ request, params, additionalData })
        default:
          return null
      }
    }
  },
  {
    path: ':entityId',
    element: <Show />
  },
  {
    // Should this be nested under <Show />?
    path: ':entityId/edit',
    loader: async (params) => {
      await requiresAuthorization('update', 'event')

      return loadEntity(params)
    },
    element: <Edit />,
    action: ({ request, params }) => {
      switch (request.method) {
        case 'POST':
          return updateEvent({ request, params })
        default:
          return null
      }
    }
  }
]

const entityShowTabRoutes = (namespace = null) => [
  {
    path: ':nodeId/*',
    id: namespace ? `${namespace}-entity` : 'entity',
    loader: loadEntity,
    element: <StrategyShow />,
    children: [
      {
        index: true,
        element: <Navigate to="events" replace />
      },
      {
        element: <Tabs />,
        children: [
          {
            path: 'details',
            element: <EntityDetails />
          },
          {
            path: 'events',
            children: SharedEventRoutes('entity')
          },
          {
            path: 'impact',
            children: [
              {
                index: true,
                element: <ImpactGraphs nodeType="entity" />
              }
            ]
          },
          {
            path: 'comments',
            element: <CommentsFetcher commentableType="entity" />
          },
          {
            path: 'settings',
            loader: async () => requiresAuthorization('update', 'entity'),
            element: <EntityFormTab />,
            action: ({ request, params }) => {
              switch (request.method) {
                case 'POST':
                  return updateEvent({ request, params })
                default:
                  return null
              }
            }
          }
        ]
      }
    ]
  }
]

const workShowTabRoutes = (namespace = null) => [
  {
    path: ':nodeId/*',
    id: namespace ? `${namespace}-work` : 'work',
    loader: loadEntity,
    element: <StrategyShow />,
    children: [
      {
        index: true,
        element: <Navigate to="events" replace />
      },
      {
        element: <Tabs />,
        children: [
          {
            path: 'events',
            children: SharedEventRoutes('entity')
          }
        ]
      }
    ]
  }
]

const routes: RouteObject = {
  path: 'work',
  element: <Outlet />,
  children: [
    {
      index: true,
      loader: loadEntities,
      element: <ListPage />
    }
  ]
}

export { SharedEventRoutes, entityShowTabRoutes, workShowTabRoutes }

export default routes
