import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { FiUpload } from 'react-icons/fi'

import type { Errors } from '@app/shared/rawForms/useForm'

interface Props {
  name?: string
  label?: string
  labelSize?: string
  placeholder?: string
  isRequired?: boolean
  acceptedFileTypes?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors?: Errors
}

const FileInput: FC<Props> = ({
  name = '',
  label = '',
  labelSize = 'md',
  isRequired = false,
  placeholder = 'Your file...',
  onChange = () => {},
  errors = {},
  acceptedFileTypes = '',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>()

  return (
    <FormControl isInvalid={!!errors[name]} isRequired={isRequired}>
      <Stack justify="space-between" direction={{ base: 'column', form: 'row' }} spacing={{ base: '1.5', md: '8' }}>
        {label && (
          <FormLabel fontSize={labelSize} htmlFor={name || 'file-upload'} variant="inline">
            {label}
          </FormLabel>
        )}
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={FiUpload} />
          </InputLeftElement>
          <input
            id="file-upload"
            type="file"
            accept={acceptedFileTypes}
            name={name}
            ref={inputRef}
            {...rest}
            onChange={(e) => {
              onChange(e)
            }}
            style={{ display: 'none' }}
          />
          <Input
            isReadOnly
            onClick={() => inputRef.current && inputRef.current.click()}
            placeholder={placeholder}
            // value={value ? value.replace('\\', '\\\\').split('\\').slice(-1) : ''}
          />
        </InputGroup>
        <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
      </Stack>
    </FormControl>
  )
}

export default FileInput
