import type { ChangeEvent, FocusEvent, FormEvent } from 'react'
import { useCallback } from 'react'

import { useStore } from '@app/store'
import type { MapDomainObject } from '@app/types'
import type { NodeObjectInput } from '@graphql/types'

interface EventHandlers<T extends HTMLDivElement | HTMLInputElement | HTMLTextAreaElement> {
  onChange: (event: FormEvent<T> | ChangeEvent<T>) => void
  onBlur: (event: FocusEvent<T>) => void
}

type UseInlineEditorProps = (
  domainObject: MapDomainObject,
  domainObjectUpdateId?: string
) => EventHandlers<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>

// TODO: DEBOUNCE THIS
const useInlineEditorProps: UseInlineEditorProps = (domainObject, domainObjectUpdateId = 'id') => {
  const updateObject = useStore.use.updateObject()

  const onBlur = useCallback(
    (event) => {
      const updatedObject = {
        [domainObject.classType]: {
          id: domainObject.id,
          [event.target.name]: event.target.value
        }
      }

      updateObject(updatedObject as NodeObjectInput)

      // THIS IS CRAPPY. IN THE CASE THIS IS SET ITS GOING TO DO A FAILED UPDATE TO THE BACKEND IN THE FIRST ONE TO ONLY DO IT RIGHT THE SECOND TIME
      if (domainObjectUpdateId !== 'id') {
        updateObject({
          [domainObject.classType]: {
            id: domainObject[domainObjectUpdateId],
            [event.target.name]: event.target.value
          }
        } as NodeObjectInput)
      }
    },
    [domainObject, updateObject]
  )

  const onChange = useCallback(
    (event) => {
      const updatedObject = {
        [domainObject.classType]: {
          id: domainObject.id,
          [event.target.name]: event.target.value
        }
      }

      updateObject(updatedObject as NodeObjectInput, true)
    },
    [domainObject, updateObject]
  )

  return { onChange, onBlur }
}

export default useInlineEditorProps
