import { Icon } from '@chakra-ui/react'
import { FiCode } from 'react-icons/fi'

import blockButtonMaker from './blockButtonMaker'

const CodeBlockButton = blockButtonMaker({
  blockType: 'code-block',
  children: <Icon as={FiCode} boxSize={6} />
})

export default CodeBlockButton
