import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import { useEntityDeleteAttachmentMutation } from '@graphql/queries'

interface Props {
  entityId: string
  attachmentId: string
  onDelete?: () => void
}

const EntityDeleteAttachmentButton: FC<Props> = ({ entityId, attachmentId, onDelete = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const [, entityDeleteAttachment] = useEntityDeleteAttachmentMutation()

  const deleteEntity = () => {
    onClose()
    entityDeleteAttachment({ input: { entityId, attachmentId } }).then(onDelete)
  }

  return (
    <>
      <IconButton aria-label="Delete Attachment" icon={<FiTrash2 />} onClick={onOpen} />

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete attachment
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&#39;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} bgColor="error" onClick={deleteEntity} variant="primary">
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default EntityDeleteAttachmentButton
