import isEmpty from 'lodash/isEmpty'
import { type FC, memo, useRef } from 'react'

import useGetNode from '@app/hooks/useGetNode'
import Node from '@app/pages/maps/components/nodes/components/node'
import SubmapToolbar from '@app/pages/maps/components/nodes/components/toolbar/submapToolbar'
import { CardProvider } from '@app/shared/cards/contexts/cardContext'
import { SubMapCard } from '@app/shared/cards/subMap/subMapCard'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainNode } from '@app/types'

const StrategyNode: FC<MapDomainNode> = (rfNode) => {
  const { id } = rfNode
  const node = useGetNode(id)
  const ref = useRef()

  const strategy = node?.data // the node's domain object

  if (!strategy || isEmpty(strategy)) {
    return null
  }

  const { strategyId } = node // the currently-viewed strategy, NOT the node's domain object

  return (
    <Node
      topHandle={{ type: 'source' }}
      leftHandle={{ type: 'source' }}
      rightHandle={{ type: 'source' }}
      bottomHandle={{ type: 'source' }}
      nodeRef={ref}
      node={node}
    >
      <SubmapToolbar strategyId={strategyId} node={node} />
      <SubMapCard strategy={strategy} node={node} />
    </Node>
  )
}

export default memo(withBasicProvider(CardProvider)(StrategyNode))
