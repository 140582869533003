import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { TextAreaInput } from '@app/shared/rawForms'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  isDisabled: boolean
}

const QueryEditor: FC<Props> = ({ metricSource, isDisabled }) => (
  <TextAreaInput
    name="configuration.query"
    label="Query"
    placeholder="Query"
    rows={5}
    helperText={
      <Text>
        Your query must return the following columns: <strong>date</strong> and <strong>value</strong>.
      </Text>
    }
    isDisabled={isDisabled}
    isRequired
    defaultValue={metricSource?.configuration?.query}
  />
)

export default QueryEditor
