import { SiAsana } from 'react-icons/si'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import IntegrationConnection from '@app/pages/settings/integrations/components/integrationConnection'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import withAwait from '@app/shared/withAwait'
import { Routes } from '@app/utils/routeHelpers'
import { IntegrationSourceNamesEnum } from '@graphql/types'

const Asana = () => {
  const integration = useGetObjectsByProperties('integration', { sourceName: IntegrationSourceNamesEnum.Asana })[0]

  return (
    <PageStack>
      <PageHeader
        title="Asana"
        subtitle="Import issues from Asana to save a record of your completed projects and tasks."
      />
      <IntegrationConnection
        name="Asana"
        icon={SiAsana}
        items="projects and tasks"
        integration={integration}
        connectUrl={Routes.asanaAuthentication}
      />
    </PageStack>
  )
}

export default withAwait(Asana, 'integration')
