import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FC } from 'react'
import { useState, useRef } from 'react'

import type { Integration } from '@graphql/queries'
import { useDomainObjectsDeleteMutation } from '@graphql/queries'

interface Props {
  integration: Pick<Integration, 'id'>
  onDelete?: () => void
}

const IntegrationDeleteButton: FC<Props> = ({ integration, onDelete = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [, deleteIntegration] = useDomainObjectsDeleteMutation()
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    onClose()

    setIsLoading(true)

    try {
      await deleteIntegration({
        input: {
          objects: [
            {
              integration: [integration.id]
            }
          ]
        }
      })
    } finally {
      ahoy.track('integration:deleted', { integrationId: integration.id })
      setIsLoading(false)
      onDelete()
    }

    return true
  }

  return (
    <>
      <Button colorScheme="red" isLoading={isLoading} onClick={onOpen}>
        Disconnect
      </Button>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove integration
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You will need to reconnect the integration to have new data flow in and out of DoubleLoop.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} bgColor="error" onClick={onClick} variant="primary">
                Disconnect
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default IntegrationDeleteButton
