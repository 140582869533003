import type { ButtonProps, IconButtonProps } from '@chakra-ui/react'
import { ButtonGroup, Button, Stack } from '@chakra-ui/react'
import { type FC } from 'react'

import type { Color } from '@app/types'

interface Props extends Omit<ButtonProps, 'children'> {
  selectedColor: Color
  onColorSelect: (color: Color) => void
  isOpen: boolean
  onOpen?: () => void
  onClose?: () => void
  children: FC<ButtonProps | IconButtonProps>
}

const ColorButton: FC<Props> = ({
  selectedColor,
  onColorSelect,
  isOpen,
  onOpen = () => {},
  onClose = () => {},
  children,
  ...buttonProps
}) => {
  const buttonParams = (color: Color) => ({
    colorScheme: color || 'gray',
    isActive: color === selectedColor,
    size: '2xs',
    onClick: () => (selectedColor === color ? onClose() : onColorSelect(color))
  })

  const Child = children

  return (
    <>
      {isOpen && (
        <Stack pos="absolute" top="-150px" right="40px" p={3} bg="bg.surface" borderRadius="md">
          <ButtonGroup>
            <Button {...buttonParams('red')}>Red</Button>
            <Button {...buttonParams('orange')}>Orange</Button>
            <Button {...buttonParams('yellow')}>Yellow</Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button {...buttonParams('green')}>Green</Button>
            <Button {...buttonParams('teal')}>Teal</Button>
            <Button {...buttonParams('blue')}>Blue</Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button {...buttonParams('cyan')}>Cyan</Button>
            <Button {...buttonParams('purple')}>Purple</Button>
            <Button {...buttonParams('pink')}>Pink</Button>
          </ButtonGroup>
          <Button {...buttonParams('')}>None</Button>
        </Stack>
      )}
      <Child
        aria-label="Change color"
        isActive={isOpen}
        onClick={() => (isOpen ? onClose() : onOpen())}
        {...buttonProps}
      />
    </>
  )
}

export default ColorButton
