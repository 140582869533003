import type { StackProps } from '@chakra-ui/react'
import { Box, Stack, useMultiStyleConfig } from '@chakra-ui/react'
import type { FC, ReactElement, ReactNode } from 'react'

interface Props extends StackProps {
  date?: string
  children: ReactNode
  variant?: string
  colorScheme?: string
  size?: string
}

const LayoutRow: FC<Props> = ({ date = null, children, ...rest }) => {
  const { variant, colorScheme, size } = rest
  const styles = useMultiStyleConfig('AggregatedEntities', { variant, colorScheme, size })

  let dateDisplay: string | ReactElement = ''
  if (date) {
    dateDisplay = <Box>Week of {date}</Box>
  }

  return (
    <Stack sx={{ ...styles.layoutRow }} {...rest} data-testid="layout-row">
      <Box sx={{ ...styles.layoutRowDate }}>{dateDisplay}</Box>
      <Box sx={{ ...styles.layoutRowEntitiesContainer }}>
        <Box sx={{ ...styles.layoutRowEntities }}>{children}</Box>
      </Box>
    </Stack>
  )
}

export default LayoutRow
