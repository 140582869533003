import type { IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'

const GoogleBigQuery: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M5.67578 10.5957V13.7516C6.17321 14.6091 6.87898 15.3272 7.72772 15.8395V10.5957H5.67578Z"
      fill="currentColor"
    />
    <path
      d="M9.46875 7.69165V16.7272C9.91039 16.7836 10.3556 16.8077 10.8007 16.7992C11.2003 16.8243 11.6011 16.8243 12.0007 16.7992V7.69165H9.46875Z"
      fill="currentColor"
    />
    <path
      d="M13.9688 11.8315V15.7794C14.822 15.2351 15.5238 14.4838 16.0087 13.5955V11.8315H13.9688Z"
      fill="currentColor"
    />
    <path
      d="M18.8865 17.183L17.1825 18.8869C17.1346 18.9339 17.0966 18.9899 17.0706 19.0517C17.0446 19.1135 17.0313 19.1799 17.0312 19.2469C17.0313 19.314 17.0446 19.3803 17.0706 19.4422C17.0966 19.504 17.1346 19.56 17.1825 19.6069L21.4304 23.8547C21.5247 23.9478 21.6519 24 21.7844 24C21.9169 24 22.0441 23.9478 22.1384 23.8547L23.8543 22.1388C23.9474 22.0445 23.9996 21.9173 23.9996 21.7848C23.9996 21.6523 23.9474 21.5251 23.8543 21.4308L19.6065 17.183C19.5595 17.1351 19.5035 17.0971 19.4417 17.0711C19.3799 17.0451 19.3135 17.0317 19.2465 17.0317C19.1794 17.0317 19.113 17.0451 19.0512 17.0711C18.9894 17.0971 18.9334 17.1351 18.8865 17.183"
      fill="currentColor"
    />
    <path
      d="M10.7997 0C8.66372 0 6.57571 0.633381 4.79971 1.82005C3.02371 3.00671 1.63949 4.69337 0.822082 6.66672C0.00467928 8.64008 -0.209191 10.8115 0.207518 12.9064C0.624226 15.0013 1.6528 16.9256 3.16316 18.4359C4.67353 19.9463 6.59785 20.9748 8.69278 21.3915C10.7877 21.8082 12.9592 21.5944 14.9326 20.777C16.9059 19.9596 18.5926 18.5754 19.7793 16.7994C20.966 15.0234 21.5994 12.9355 21.5994 10.7995C21.5994 9.38131 21.32 7.97698 20.7773 6.66672C20.2346 5.35646 19.4391 4.16593 18.4362 3.16311C17.4334 2.16028 16.2428 1.36479 14.9326 0.822064C13.6223 0.279338 12.2179 0 10.7997 0V0ZM10.7997 18.8272C9.21535 18.8248 7.66724 18.353 6.3509 17.4713C5.03455 16.5896 4.00901 15.3376 3.4038 13.8734C2.7986 12.4093 2.64087 10.7986 2.95054 9.24481C3.26021 7.69105 4.0234 6.2639 5.1437 5.14361C6.264 4.02333 7.69117 3.26016 9.24496 2.95049C10.7988 2.64082 12.4095 2.79855 13.8737 3.40374C15.3379 4.00894 16.5899 5.03446 17.4716 6.35079C18.3533 7.66712 18.8251 9.2152 18.8275 10.7995C18.829 11.8542 18.6225 12.8988 18.2196 13.8734C17.8167 14.8481 17.2255 15.7337 16.4797 16.4794C15.7339 17.2252 14.8483 17.8164 13.8737 18.2193C12.899 18.6222 11.8544 18.8287 10.7997 18.8272"
      fill="currentColor"
    />
  </Icon>
)

export default GoogleBigQuery
