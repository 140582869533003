import { Button, Stack, StackDivider, VisuallyHiddenInput } from '@chakra-ui/react'
import { useNavigation } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { ButtonRow, FormAlert, FormContainer } from '@app/shared/forms'
import { EmailInput, Form, SelectInput, TextInput, ToggleInput, useForm } from '@app/shared/rawForms'
import timezones from '@app/utils/timezones'

const humanizeTimezone = (tz) => tz.replaceAll('_', ' ').replaceAll('/', ' - ')

const UserForm = () => {
  const { user } = useStoreCurrentUser()
  const navigation = useNavigation()
  const { errors } = useForm({})

  return (
    <Form method="post" id="user-form">
      <VisuallyHiddenInput defaultValue={user.id} name="userId" />
      <FormContainer>
        <FormAlert
          description={(errors as { global: { message: string } })?.global?.message}
          title="Failed to update your profile!"
        />
        <Stack divider={<StackDivider />} spacing="5">
          <TextInput
            label="Name"
            name="name"
            isRequired
            placeholder="Whats your name?"
            defaultValue={user.name}
            errors={errors}
          />
          <EmailInput
            name="email"
            label="Email"
            placeholder="Email address"
            defaultValue={user.email}
            isRequired
            errors={errors}
          />
          <TextInput
            label="Job title"
            name="jobTitle"
            placeholder="Whats your job title?"
            defaultValue={(user as unknown as { jobTitle: string }).jobTitle}
            errors={errors}
          />
          <SelectInput
            name="timeZone"
            label="Time zone"
            placeholder={null}
            defaultValue={user.timeZone}
            errors={errors}
          >
            {timezones.map((timezone) => (
              <option key={timezone} value={timezone}>
                {humanizeTimezone(timezone)}
              </option>
            ))}
          </SelectInput>
          <ToggleInput
            name="optedInToAnalytics"
            label="Opt into platform analytics"
            defaultValue={user.optedInToAnalytics}
          />
        </Stack>
        <ButtonRow bg="bg.subtle">
          <Button isLoading={navigation.state === 'submitting'} type="submit" variant="primary">
            Save changes
          </Button>
        </ButtonRow>
      </FormContainer>
    </Form>
  )
}

export default UserForm
