import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { cloneElement } from 'react'

const modalTrigger = (children, onOpen) => {
  if (children) {
    return cloneElement(children, { onClick: onOpen })
  }

  return <Button onClick={onOpen}>Trigger modal</Button>
}

type Props = {
  title?: string
  children?: ReactNode
  uploaderBody: ReactNode
}

const UploaderModal: FC<Props> = ({ uploaderBody, title = 'Upload Photos', children = null }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {modalTrigger(children, onOpen)}

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{uploaderBody}</ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UploaderModal
