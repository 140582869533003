import { Box, Center, Image, Spinner } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'
import { memo } from 'react'
import Img from 'react-cloudimage-responsive'

import useGetNode from '@app/hooks/useGetNode'
import NodeResizer from '@app/pages/maps/components/nodes/components/nodeResizer'
import { CardProvider } from '@app/shared/cards/contexts/cardContext'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainNode } from '@app/types'

const LoadingImage = ({ temporaryNode }) => (
  <Box pos="relative" w={`${temporaryNode.data.width}px`} h={`${temporaryNode.data.height}px`}>
    <Image w="100%" h="100%" opacity={0.5} src={temporaryNode.data.url} />
    <Center pos="absolute" top={0} right={0} bottom={0} left={0}>
      <Spinner size="xl" />
    </Center>
  </Box>
)

const ImageNode: FC<MapDomainNode> = (rfNode) => {
  const { id, selected: nodeSelected } = rfNode
  // AT SOME POINT THIS STARTED COMING BACK AS UNDEFINED BY DEFAULT. FIGURING THAT OUT WILL ELIMINATE THIS HACK
  const selected = nodeSelected === undefined ? false : nodeSelected

  const node = useGetNode(id)

  if (rfNode?.data?.loading) {
    return <LoadingImage temporaryNode={rfNode} />
  }

  if (!node || !node.data || isEmpty(node.data)) {
    return null
  }

  const width = `${node.width}px`
  const height = `${node.height}px`

  return (
    <Box>
      <NodeResizer strategyId={node.strategyId} nodeId={id} keepAspectRatio isVisible={selected} />
      <Box w={width} h={height}>
        {/* eslint-disable-next-line chakra-ui/props-shorthand */}
        <Image as={Img} width={width} height={height} src={node.data.url} />
      </Box>
    </Box>
  )
}

export default memo(withBasicProvider(CardProvider)(ImageNode))
