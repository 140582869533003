import type { ToastId } from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import useToast from '@app/shared/toast'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import type { NodesDuplicateInput } from '@graphql/types'

type DupeNode = Pick<MapDomainNode, 'nodeId'>

type DuplicateFunction = (
  nodeOverride?: DupeNode[],
  strategyId?: string,
  options?: { variables?: Partial<NodesDuplicateInput> }
) => Promise<void>
type UseDuplicateNodes = (nodes: DupeNode | DupeNode[]) => {
  duplicate: DuplicateFunction
  isLoading: boolean
}

const useDuplicateNodes: UseDuplicateNodes = (nodes) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const { revalidate } = useRevalidator()
  const nodeArray = useMemo(() => (Array.isArray(nodes) ? nodes : [nodes]), [nodes])

  const duplicateNodes = useStore.use.duplicateNodes()

  const duplicate: DuplicateFunction = useCallback(
    async (nodeOverride, strategyId, options = {}) => {
      let duplicatedNodes = []

      if (nodeOverride) {
        duplicatedNodes = Array.isArray(nodeOverride) ? nodeOverride : [nodeOverride]
      } else {
        duplicatedNodes = nodeArray
      }

      setIsLoading(true)

      let toastId: ToastId = null
      let timerId

      try {
        timerId = setTimeout(() => {
          const title = `Duplicating ${duplicatedNodes.length} cards`

          toastId = toast({
            title,
            status: 'info',
            duration: null
          })
        }, 2000)

        await duplicateNodes(
          duplicatedNodes.map((n) => n.nodeId),
          strategyId,
          options
        )
        revalidate()
      } finally {
        clearTimeout(timerId)

        if (toastId !== null) {
          toast.close(toastId)
        }
        setIsLoading(false)
      }
    },
    [duplicateNodes, nodeArray, revalidate, toast]
  )

  return {
    duplicate,
    isLoading
  }
}

export default useDuplicateNodes
