import type { As, ButtonProps } from '@chakra-ui/react'
import { Button, Icon } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props extends ButtonProps {
  icon: As
  label: string
  to?: string
  href?: string
  isExternal?: boolean
}

const NavButton: FC<Props> = ({ icon, label, ...buttonProps }) => {
  const iconSize = buttonProps?.size === 'sm' ? '4' : '5'

  return (
    <Button
      justifyContent="start"
      color="fg.muted"
      _activeLink={{ bg: 'bg.muted' }}
      whiteSpace="normal"
      wordBreak="break-word"
      iconSpacing={3}
      leftIcon={<Icon as={icon} boxSize={iconSize} color="fg.muted" />}
      title={label}
      variant="ghost"
      {...buttonProps}
    >
      {label}
    </Button>
  )
}

export default NavButton
