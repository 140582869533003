import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo } from 'react'
import type { IconType } from 'react-icons'
import { useParams } from 'react-router-dom'

import useGetContainersSelectOptions from '@app/next/changelog/useGetContainersSelectOptions'
import type { ChakraReactSelectProps } from '@app/shared/autocomplete/chakraAutocompletes'
import { ChakraReactSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import { DomainObjectType } from '@app/shared/cards/components/cardTypeSection'

type Option = {
  id: string
  name: string
  type: string
  icon: IconType
  color: string
}

type OptionGroup = {
  label: string
  options: Option[]
}

export type Props = ChakraReactSelectProps<Option, true> & {
  strategyId?: string
  defaultContainerNodeIds?: string[]
}

const groupLabel = (g: string) => (/basiccard/i.test(g) ? 'Card' : g)

const formatOptionLabel: Props['formatOptionLabel'] = (o) => {
  const { icon, color, name } = o

  return <DomainObjectType icon={icon} color={color} title={name} />
}

const formatGroupLabel: Props['formatGroupLabel'] = (g) => {
  const { icon, color } = g.options[0] || {}
  const title = g.label === 'Entity' ? 'Work' : g.label

  return <DomainObjectType icon={icon} color={color} title={title} />
}

const ContainersSelect: FC<Props> = (props) => {
  const params = useParams()
  const { strategyId: paramStrategyId } = params
  const { strategyId: propStrategyId, defaultContainerNodeIds } = props
  const strategyId = propStrategyId || paramStrategyId

  const options = useGetContainersSelectOptions(strategyId)

  const groups = useMemo(() => {
    const unsortedGroups: OptionGroup[] = Object.values(
      options.reduce((acc, o) => {
        acc[o.type] ||= {
          label: groupLabel(o.type),
          options: []
        }

        acc[o.type].options.push(o)

        return acc
      }, {})
    )

    return sortBy(unsortedGroups, [(g) => g.label.toLowerCase()])
  }, [options])

  const defaultValue = defaultContainerNodeIds?.map((id) => options.find((o) => o.id === id))?.filter(Boolean)

  return (
    <ChakraReactSelect
      className="container-filters"
      options={groups}
      multiple
      isClearable
      isSearchable
      formatOptionLabel={formatOptionLabel}
      formatGroupLabel={formatGroupLabel}
      getOptionLabel={(o: Option) => o.name}
      getOptionValue={(o: Option) => o.id}
      placeholder="Filter work"
      defaultValue={defaultValue}
      {...props}
    />
  )
}

export default ContainersSelect
