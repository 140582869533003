import { Button, ButtonGroup, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import type { OperationResult } from 'urql'

import ICON_MAP from '@app/utils/iconMap'

type Filter = {
  id: string
  name: string
  value: string
}

interface Props {
  filter: Filter
  onRemoveClick: (filter: Filter) => Promise<OperationResult>
  canEdit: boolean
  isApplying?: boolean
}

const MapSegmentFilter: FC<Props> = ({ filter, onRemoveClick, canEdit = false, isApplying = false, ...rest }) => {
  const [loading, setLoading] = useState(false)
  const { name, value } = filter

  const title = `${name || '(none)'}: ${value || '(none)'}`

  return (
    <ButtonGroup colorScheme="blue" isAttached size="2xs" {...rest}>
      {canEdit && (
        <IconButton
          aria-label="Remove filter"
          icon={<ICON_MAP.Close />}
          isLoading={isApplying || loading}
          onClick={() => {
            setLoading(true)
            return onRemoveClick(filter).finally(() => setLoading(false))
          }}
        />
      )}
      <Button userSelect="auto" pointerEvents="none" isDisabled={isApplying || loading} title={title}>
        {title}
      </Button>
    </ButtonGroup>
  )
}

export default MapSegmentFilter
