import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useState } from 'react'
import { BiSync } from 'react-icons/bi'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import Can from '@app/shared/authorization/can'
import useToast from '@app/shared/toast'
import ICON_MAP from '@app/utils/iconMap'
import { useGoogleSheetImportMutation } from '@graphql/queries'

const GoogleSheetSidebarMenu = () => {
  const googleSheet = useGetObjectsByProperties('googleSheet', { organizationLevel: true })?.[0]

  const [, importSheet] = useGoogleSheetImportMutation()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  if (!googleSheet) {
    return null
  }

  const onImport = () => {
    setIsLoading(true)

    importSheet({
      input: {
        googleSheetId: googleSheet?.id
      }
    }).finally(() => {
      setIsLoading(false)
    })

    toast({
      title: 'Importing started',
      description: 'We are importing your data from Google Sheets. This may take a few minutes.'
    })
  }

  return (
    <Can I="update" a="metric">
      <Menu>
        <MenuButton fontSize="sm" aria-label="Additional actions">
          <Icon as={ICON_MAP.Ellipsis} _hover={{ bgColor: 'bg.subtle' }} />
        </MenuButton>
        <MenuList>
          <MenuItem icon={<BiSync />} isDisabled={isLoading} onClick={onImport}>
            Import latest data from Google Sheets
          </MenuItem>
        </MenuList>
      </Menu>
    </Can>
  )
}

export default GoogleSheetSidebarMenu
