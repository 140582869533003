import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'

import { useStore } from '@app/store'
import { MetricCorrelations } from '@graphql/documents/metric.graphql'

type ChildFunc = (props: Partial<ButtonProps>) => ReactNode

interface Props {
  metricId: string
  strategyId: string | null
  children?: ChildFunc
}

const defaultChildren: ChildFunc = (buttonProps) => (
  <Button aria-label="load correlations" {...buttonProps}>
    Load correlations
  </Button>
)

const LoadCorrelationsButton: FC<Props> = ({ metricId, strategyId, children = defaultChildren, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false)
  const loaderQuery = useStore.use.loaderQuery()

  const onClick = async () => {
    setIsLoading(true)

    try {
      await loaderQuery(MetricCorrelations, { id: metricId, strategyId })
    } finally {
      setIsLoading(false)
    }
  }

  return children({ isLoading, onClick, ...rest })
}

export default LoadCorrelationsButton
