import { Box, Button, Center, Container, Heading, Link, Stack, Text } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import type { FC } from 'react'
import { isRouteErrorResponse, Navigate, useLocation, useRouteError } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Error from '@app/images/error'
import NotAuthorized from '@app/images/notAuthorized'
import NotFound from '@app/images/notFound'

export const RegisterSentryUser: FC = () => {
  const { user } = useStoreCurrentUser()

  if (user) {
    Sentry.setUser({ id: user.id, email: user.email })
  } else {
    Sentry.getCurrentScope().setUser(null)
  }

  return null
}

type Props = {
  isEmbedded?: boolean
}

const ErrorPage: FC<Props> = ({ isEmbedded = false }) => {
  const error = useRouteError()
  const location = useLocation()

  let errorHeader = 'Oops!'
  let errorContent = "Sorry, an unexpected error has occurred. Don't worry, we've already been notified"

  let ErrorImage = Error

  if (isRouteErrorResponse(error)) {
    errorHeader = error.statusText

    if (error.status === 401) {
      return <Navigate to="/sign_in" state={{ from: location }} replace />
    }

    if (error.status === 403) {
      errorContent =
        "You don't have permission to access this page. Please let us know if you think you should have access."
      ErrorImage = NotAuthorized
    } else if (error.status === 404) {
      errorContent = "We couldn't find what you're looking for. If you think it should exist, please let us know."
      ErrorImage = NotFound
    }
  } else {
    Sentry.captureException(error)
  }

  // eslint-disable-next-line no-console
  console.error(error)

  return (
    <Center h="100vh">
      <Container maxW={['container.xs', 'container.xs', 'container.md']}>
        <Stack direction={['column', 'column', 'row']} spacing={[0, 0, 8]}>
          <ErrorImage maxH="36rem" />
          <Center>
            <Stack>
              <Heading as="h1" fontSize="4xl">
                {errorHeader}
              </Heading>
              <Text>{errorContent}</Text>
              {!isEmbedded && (
                <Box pt={8}>
                  <Button as={Link} w="full" href="/" variant="primary">
                    Back to the app
                  </Button>
                </Box>
              )}
            </Stack>
          </Center>
        </Stack>
      </Container>
    </Center>
  )
}

export default ErrorPage
