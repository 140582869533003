import { Button } from '@chakra-ui/react'
import type { ButtonProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import type { RecurringReportConfigurationServiceEnum } from '@graphql/types'

interface Props extends ButtonProps {
  strategyId: string
  reportType: RecurringReportConfigurationServiceEnum
}

const SendReportButton: FC<Props> = ({ strategyId, reportType, ...rest }) => {
  const link = `/strategy/${strategyId}/map/reports/recurring/create`

  return (
    <Button as={Link} size="xs" to={link} variant="secondary" {...rest} state={{ reportType }}>
      Send recurring report
    </Button>
  )
}

export default SendReportButton
