import { HStack, Icon, Kbd, Spacer, Text } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { PiCheck } from 'react-icons/pi'

interface Props {
  icon?: ReactNode
  text: string
  color?: string
  value: string
  isSelected?: boolean
  isHighlighted?: boolean
  hotkey?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const SearchListItem: FC<Props> = ({
  icon,
  text,
  value,
  isSelected = false,
  isHighlighted = false,
  hotkey,
  onClick = () => {},
  color
}) => {
  const checkbox = isSelected ? <Icon as={PiCheck} color="fg.subtle" /> : null

  return (
    <HStack
      p={2}
      _hover={{ bgColor: 'bg.muted' }}
      cursor="pointer"
      bgColor={isHighlighted ? 'bg.muted' : ''}
      onClick={onClick}
    >
      {icon || null}
      <Text overflow="hidden" color={color} fontSize="sm" whiteSpace="nowrap" textOverflow="ellipsis" title={value}>
        {text}
      </Text>
      {checkbox}
      <Spacer />
      {/* This is hidden for now, not ready to implement it. */}
      <Kbd display="none">{hotkey}</Kbd>
    </HStack>
  )
}

export default SearchListItem
