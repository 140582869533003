import { Avatar, Box, Flex, forwardRef, HStack } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { HiSelector } from 'react-icons/hi'

import profileImage from '@app/utils/profileImage'
import { type Organization as OrganizationType, type User as UserType } from '@graphql/types'

type Organization = Pick<OrganizationType, 'name'>
type User = Pick<UserType, 'name' | 'email' | 'role'> & { organization?: Organization }
export interface UserButtonProps {
  user: User
}

const UserButton: FC<UserButtonProps> = forwardRef<UserButtonProps, 'div'>(({ user }: UserButtonProps, ref) => (
  <Flex
    ref={ref}
    align="center"
    display="flex"
    m={2}
    px="3"
    py="2"
    fontSize="sm"
    bg="bg.muted"
    borderWidth={1}
    borderRadius="base"
    _active={{ bg: 'bg.subtle' }}
    _focus={{ shadow: 'tertiary' }}
    userSelect="none"
    cursor="pointer"
    transition="all 0.2s"
    outline="0"
    rounded="lg"
  >
    <HStack as={Flex} flex="1" spacing="3">
      <Avatar w="8" h="8" name={user.name} size="sm" src={profileImage(user.email)} />
      <Box w="100%" textAlign="start">
        <Box fontWeight="semibold" noOfLines={1}>
          {user.name}
        </Box>
        <Box color="muted" fontSize="xs" wordBreak="break-all" noOfLines={1}>
          {user.email}
        </Box>
        {user.organization && (
          <Box color="muted" fontSize="xs" wordBreak="break-all" noOfLines={1}>
            {user.organization.name} {capitalize(user.role)}
          </Box>
        )}
      </Box>
    </HStack>
    <Flex color="accent" fontSize="lg">
      <HiSelector />
    </Flex>
  </Flex>
))

export default UserButton
