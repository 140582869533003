import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import type { Props as EntitiesByWeekProps } from './entitiesByWeek'
import EntitiesByWeek from './entitiesByWeek'
import { groupEntitiesByWeek } from './utils'

import Can from '@app/shared/authorization/can'
import EmptyState from '@app/shared/emptyState/emptyState'
import CreateEntityButton from '@app/shared/entities/createEntityButton'

type Props = Omit<EntitiesByWeekProps, 'week'>

const AggregatedEntities: FC<Props> = ({ entities = [], ...rest }) => {
  const { nodeType } = useParams()

  if (!entities.length) {
    return (
      <EmptyState title="No events yet">
        <Can I="create" an="event" passThrough>
          {(allowed) => {
            if (allowed && ['entity'].includes(nodeType)) {
              return <CreateEntityButton text="Create your first one" variant="tertiary" />
            }

            return null
          }}
        </Can>
      </EmptyState>
    )
  }

  const entitiesByWeek = groupEntitiesByWeek(entities)
  const sortedEntities = Object.keys(entitiesByWeek).reverse()

  return (
    <>
      {sortedEntities.map((week) => (
        <EntitiesByWeek key={week} week={week} entities={entitiesByWeek[week]} {...rest} />
      ))}
    </>
  )
}

export default AggregatedEntities
