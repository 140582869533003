import { Box, Center, Checkbox, HStack, Icon, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useContext, useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

import MapSegmentFiltersModalContext from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFiltersModalContext'

const OptionIcon = () => (
  <Box w={4}>
    <Center>
      <Icon as={HiOutlineSearch} boxSize="4" color="gray.500" />
    </Center>
  </Box>
)

interface Props {
  option: {
    id: string
    value: string
  }
}

const FilterSegmentValueOption: FC<Props> = ({ option }) => {
  const { onClose, onFilter, segmentFilters } = useContext(MapSegmentFiltersModalContext) as {
    onClose: () => void
    onFilter: (segmentFilter: { id: string; value: string; active: boolean }) => void
    segmentFilters: { segmentValue: { id: string; value: string } }[]
  }
  const { id, value } = option
  const [checked, setChecked] = useState(segmentFilters.some((filter) => filter.segmentValue.id === id))

  const handleClick = (e, active = true) => {
    onFilter({ ...option, active })
    onClose()
  }

  const handleChecked = (e) => {
    const isChecked = e.target.checked
    setChecked(isChecked)
    handleClick(e, isChecked)
  }

  return (
    <Box
      h="45px"
      borderColor="transparent"
      borderLeftWidth="2px"
      _hover={{ borderColor: 'blue.600', bgColor: 'gray.50' }}
    >
      <Checkbox w="100%" px={4} py={3} isChecked={checked} onChange={handleChecked} onClick={handleClick} size="sm">
        <HStack spacing={3}>
          <OptionIcon />
          <Text
            overflow="hidden"
            maxW="325px"
            color="gray.500"
            fontSize="sm"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            title={value}
          >
            {value}
          </Text>
        </HStack>
      </Checkbox>
    </Box>
  )
}

export default FilterSegmentValueOption
