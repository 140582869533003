import { Box, Button, Stack, Text } from '@chakra-ui/react'
import type { FC, MouseEventHandler } from 'react'
import { useState } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useInteraction from '@app/pages/maps/useInteraction'
import LoadCorrelationsButton from '@app/pages/metrics/components/loadCorrelationsButton'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider, useSortHeaderContext } from '@app/shared/table/contexts/sortHeaderContext'
import type { MapDomainMetric } from '@app/types'
import { correlationScoreColorNew } from '@app/utils/metricHelpers'
import type { CorrelationPair } from '@graphql/types'

type Props = {
  metric: MapDomainMetric
  strategyId?: string | null
}

const NameCell = ({ data, onDragStart }) => {
  const target = useGetObject(data.target.id, 'metric')

  return (
    <Box
      key={`correlative-metric-${target?.id}`}
      p={1}
      _hover={{ cursor: 'grab' }}
      _grabbed={{ cursor: 'grabbing' }}
      draggable
      onDragStart={(e) => onDragStart(e, target)}
    >
      <Text whiteSpace="normal">{target?.name}</Text>
    </Box>
  )
}

const makeColumns = (metric: MapDomainMetric, onDragStart) => [
  {
    header: 'Metric',
    key: 'metric',
    cell: ({ data }) => <NameCell data={data} onDragStart={onDragStart} />
  },
  {
    header: <SortHeader title="Score" field="score" emptyState={false} />,
    key: 'score',
    cell: ({ data }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const target = useGetObject(data.target.id, 'metric')
      const { color, selectedColor } = correlationScoreColorNew(data.score, metric, target)

      return (
        <Text color={color} fontWeight="bold" _hover={{ color: selectedColor }}>
          {parseFloat(data.score)?.toFixed(3)}
        </Text>
      )
    }
  }
]

const MetricCorrelationsTableInner: FC<Props> = ({ metric, strategyId = null }) => {
  const { onDragStart } = useInteraction(strategyId)
  const [loaded, setLoaded] = useState(false)
  const unfilteredPairs = useGetObjectsByProperties('correlationPair', { sourceId: metric.id })
  const unsortedPairs = unfilteredPairs.filter((pair) => typeof pair.score === 'number')
  const {
    state: { score: scoreSort }
  } = useSortHeaderContext()

  const pairs =
    scoreSort === 'desc'
      ? unsortedPairs.sort((a, b) => b.score - a.score)
      : unsortedPairs.sort((a, b) => a.score - b.score)

  if (!loaded && pairs.length === 0) {
    return (
      <Box mx={4} mt={4} mb={2}>
        <LoadCorrelationsButton metricId={metric.id} strategyId={strategyId}>
          {({ onClick, isLoading }) => {
            const clicker: MouseEventHandler<HTMLButtonElement> = (e) => {
              onClick(e)
              setLoaded(true)
            }

            return (
              <Button isLoading={isLoading} onClick={clicker} size="xs">
                Load correlations
              </Button>
            )
          }}
        </LoadCorrelationsButton>
      </Box>
    )
  }

  return (
    <Stack>
      <Box mx={4} mt={4} mb={2}>
        <LoadCorrelationsButton metricId={metric.id} strategyId={strategyId}>
          {({ onClick, isLoading }) => (
            <Button isLoading={isLoading} onClick={onClick} size="xs">
              Refresh correlations
            </Button>
          )}
        </LoadCorrelationsButton>
      </Box>

      <Table<CorrelationPair>
        mb={4}
        columns={makeColumns(metric, onDragStart)}
        data={pairs}
        rowFormatter={() => ({ _hover: { bgColor: 'bg.subtle' } })}
      />
    </Stack>
  )
}

const MetricCorrelationsTable: typeof MetricCorrelationsTableInner = (props) => (
  <SortHeaderContextProvider defaultState={{ score: 'desc' }} storageType="state">
    <MetricCorrelationsTableInner {...props} />
  </SortHeaderContextProvider>
)

export default MetricCorrelationsTable
