import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'

import useDuplicateNodes from '@app/hooks/useDuplicateNodes'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  nodes: Parameters<typeof useDuplicateNodes>[0]
}

const DuplicateButton: FC<Props> = ({ nodes }) => {
  const { duplicate, isLoading } = useDuplicateNodes(nodes)

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const handleClick = () => duplicate()

  return (
    <Tooltip label="Copy">
      <IconButton
        borderRadius="none"
        aria-label="copy"
        icon={<ICON_MAP.Copy />}
        isLoading={isLoading}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default DuplicateButton
