import tokens from './tokens.json'

const exportedColors = tokens.global.palette
const chakraColorMap = { brand: null }

// This pulls out the colors from the palette
Object.keys(exportedColors)
  .filter((color) => color !== 'white' && color !== 'black')
  .forEach((key) => {
    const colors = {}

    Object.keys(exportedColors[key]).forEach((colorKey) => {
      colors[colorKey] = exportedColors[key][colorKey].value
    })

    if (key === 'blue') {
      chakraColorMap.brand = colors
    }

    chakraColorMap[key] = colors
  })

// This pulls out the background, success, and error colors
Object.keys(tokens.global)
  .filter((key) => ['background', 'success', 'error'].includes(key))
  .forEach((key) => {
    chakraColorMap[key] = tokens.global[key].value
  })

export default {
  breakpoints: {
    // form - Used to make forms behave a bit better in the drawer, hoping we can find a better way.
    form: '90em'
  },
  styles: {
    global: () => ({
      'input[type="color"]::-webkit-color-swatch-wrapper': {
        padding: 0
      },
      'input[type="color"]::-webkit-color-swatch': {
        border: 'none'
      },
      '*::placeholder': {
        opacity: 1,
        // gray.500 - github-markdown uses the same value and is not dynamic or i'd just use "subtle"
        color: '#718096'
      }
    })
  },
  colors: {
    link: '#3182CE',
    impact: {
      background: {
        positive: '#D7E8C6',
        negative: '#F8D3D3'
      },
      indicator: {
        positive: '#36A118',
        negative: '#DC2426'
      }
    },
    importance: {
      major: '#2D2D2D',
      medium: '#717171',
      minor: '#AFAFAF'
    },
    ...chakraColorMap
  }
}
