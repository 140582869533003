import { Container } from '@chakra-ui/react'
import { FiArrowLeft } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import NavigationButton from '@app/pages/changelogs/components/navigationButton'
import Entity from '@app/shared/entity'
import { pathWithSearchParams } from '@app/utils/routeHelpers'
import { useEntityQuery, useStrategyEntityQuery } from '@graphql/queries'

const queryTypeWithVariables = (entityId, strategyId, nodeId, nodeType) => {
  let query
  let variables = {}

  if (nodeId && nodeType) {
    query = useStrategyEntityQuery
    variables = {
      id: strategyId,
      entityId,
      metricId: nodeType === 'metric' ? nodeId : null
    }
  } else {
    query = useEntityQuery
    variables = { id: entityId }
  }

  return [query, variables]
}

const Show = () => {
  const { entityId, strategyId, nodeId, nodeType } = useParams()
  const [query, variables] = queryTypeWithVariables(entityId, strategyId, nodeId, nodeType)

  const [{ data: entityData }] = query({
    variables
  })

  const { entity } = entityData?.strategy || entityData || {}

  if (!entity) {
    return <>Not found</>
  }

  const backButtonPath = pathWithSearchParams('..')

  return (
    <Container maxW="3xl" py={8}>
      <Entity entity={entity} variant="full" />
      <NavigationButton url={backButtonPath} label="Back to all events" leftIcon={<FiArrowLeft />} mt={6} />
    </Container>
  )
}

export default Show
