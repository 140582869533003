import { Box, Button, HStack, IconButton, Spacer, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiEdit2, FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import MetricDataPointDeleteButton from './metricDataPointDeleteButton'

import MetricCreateButton from '@app/pages/metrics/components/metricCreateButton'
import MetricCSVModal from '@app/pages/metrics/components/metricCSVModal'
import MetricDownloadCSVButton from '@app/pages/metrics/components/metricDownloadCSVButton'
import MetricDataPointsClearButton from '@app/pages/metrics/metricDataPoints/components/metricDataPointsClearButton'
import Can from '@app/shared/authorization/can'
import useToast from '@app/shared/toast'
import type { MapDomainMetric } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import type { MetricDataPoint } from '@graphql/queries'

interface MetricDataPointListActionsHeaderProps {
  metric: MapDomainMetric
}

export const MetricDataPointListActionsHeader: FC<MetricDataPointListActionsHeaderProps> = ({ metric }) => (
  <HStack>
    <Spacer />
    <Menu>
      <MenuButton as={Button} borderRadius="sm" rightIcon={<ICON_MAP.MoreOptions />} size="xs">
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem as={Box}>
          <MetricCreateButton as={MenuItem} leftIcon={<FiPlus />} text="Add" />
        </MenuItem>
        <MenuItem as={Box}>
          {(metric.metricDataPointsCount || 0) > 0 && <MetricDownloadCSVButton metric={metric} />}
        </MenuItem>
        <MenuItem as={Box}>
          <MetricCSVModal metric={metric} />
        </MenuItem>
        <MenuItem as={Box}>
          <MetricDataPointsClearButton metric={metric} />
        </MenuItem>
      </MenuList>
    </Menu>
  </HStack>
)

interface MetricDataPointListActionsProps {
  data: Pick<MetricDataPoint, 'id' | 'metricId'>
}

const MetricDataPointListActions: FC<MetricDataPointListActionsProps> = ({ data: metricDataPoint }) => {
  const { id } = metricDataPoint
  const toast = useToast()

  const onDelete = () => {
    toast({ title: 'Deleted metric data point' })
  }

  return (
    <Can I="update" a="metricDataPoint">
      <HStack>
        <Spacer />
        <IconButton as={Link} aria-label="Edit data point" icon={<FiEdit2 />} to={`${id}/edit`} variant="ghost" />
        <MetricDataPointDeleteButton metricDataPoint={metricDataPoint} onDelete={onDelete} />
      </HStack>
    </Can>
  )
}

export default MetricDataPointListActions
