import omitBy from 'lodash/omitBy'
import type { RouteObject } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

import IntegrationSuccess from '@app/pages/settings/integrations/integrationSuccess'
import List from '@app/pages/settings/integrations/list'
import Show from '@app/pages/settings/integrations/show'
import { useStore } from '@app/store'
import { requiresAuthorization } from '@app/utils/auth'
import { loaderQuery, actionMutation } from '@graphql/client'
import { CredentialCreate, CredentialUpdate } from '@graphql/documents/credential.graphql'
import {
  GoogleAnalyticsPropertyImport,
  GoogleIntegration,
  Integration,
  Integrations
} from '@graphql/documents/integration.graphql'
import type { IntegrationQuery, IntegrationsQuery } from '@graphql/queries'

const loadIntegration = async ({ params }) => {
  const { addObject } = useStore.getState()

  if (params.name === 'google') {
    const { data } = await loaderQuery(GoogleIntegration, {
      sourceName: params.name
    })

    return { integration: data?.googleIntegration }
  }

  const { data } = await loaderQuery<IntegrationQuery>(Integration, {
    sourceName: params.name
  })

  const integration = data?.integration

  if (integration) {
    addObject(integration, { typeOverride: 'integration' })
  }

  return { integration }
}

const loadIntegrations = async () => {
  await requiresAuthorization('update', 'organization')

  const { data } = await loaderQuery<IntegrationsQuery>(Integrations)

  const integrations = data?.integrations

  if (integrations) {
    const { addObject } = useStore.getState()

    integrations.forEach((integration) => {
      addObject(integration, { typeOverride: 'integration' })
    })
  }

  return { integrations }
}

const createCredential = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())

  return actionMutation(CredentialCreate, input)
}

const updateCredential = async (request: Request) => {
  const formData = await request.formData()
  let input = Object.fromEntries(formData.entries())

  // secretFields is only used for testing with an existing credential
  const fields = input.secretFields as string
  delete input.secretFields
  const secrets = fields?.split(',') || []
  input = omitBy(input, (value, key) => !value && secrets.includes(key))

  return actionMutation(CredentialUpdate, input)
}

const importMetrics = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())

  return actionMutation(GoogleAnalyticsPropertyImport, input)
}

const routes: RouteObject = {
  path: 'integrations',
  element: <Outlet />,
  loader: loadIntegrations,
  children: [
    {
      index: true,
      element: <List />
    },
    {
      path: 'metrics',
      element: <Outlet />,
      children: [
        {
          path: 'credentials',
          element: <Outlet />,
          action: ({ request }) => {
            switch (request.method) {
              case 'POST':
                return createCredential(request)
              case 'PATCH':
                return updateCredential(request)
              default:
                return null
            }
          }
        }
      ]
    },
    {
      path: ':name',
      id: 'integration',
      element: <Show />,
      loader: loadIntegration,
      children: [
        {
          path: 'import-metrics',
          id: 'integration-import-metrics',
          element: <Outlet />,
          action: ({ request }) => {
            if (request.method === 'POST') {
              return importMetrics(request)
            }

            return null
          },
          loader: async () => requiresAuthorization('create', 'metric')
        }
      ]
    },
    {
      path: 'success',
      element: <IntegrationSuccess />
    }
  ]
}

export default routes
