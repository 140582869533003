import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useActionData, useNavigation } from 'react-router-dom'

import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import { FormAlert } from '@app/shared/forms'
import { Form, useForm } from '@app/shared/rawForms'

interface ResetPasswordActionData {
  success?: boolean
}

const ForgotPassword: FC = () => {
  const [emailSent, setEmailSent] = useState(false)
  const { errors } = useForm({})
  const navigation = useNavigation()
  const actionData: ResetPasswordActionData = useActionData()

  useEffect(() => {
    if (navigation.state === 'loading' && actionData?.success === true) {
      setEmailSent(true)
    }
  }, [actionData?.success, navigation.state])

  return (
    <Stack w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} spacing="8">
      <SessionHeader
        title="Forgot your password?"
        subtitle="Remembered your password?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack spacing="6">
        <FormAlert description={errors?.global?.message} status="success" />
        <Form id="forgot-password-form" method="post">
          <Stack spacing="5">
            <FormControl id="email" isInvalid={!!errors?.email} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                autoComplete="email"
                id="email"
                name="email"
                placeholder="Enter the email you used to sign up with"
                type="email"
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Form>
        <Stack spacing={4}>
          <Button
            form="forgot-password-form"
            isDisabled={emailSent}
            isLoading={navigation.state === 'submitting' || navigation.state === 'loading'}
            size="md"
            type="submit"
            variant="primary"
          >
            {emailSent ? 'Email sent, check your inbox' : 'Send reset email'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ForgotPassword
