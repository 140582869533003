import type { ChakraProps, StackProps } from '@chakra-ui/react'
import { Box, HStack, Image, Skeleton, Stack, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import Img from 'react-cloudimage-responsive'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'

import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from './carousel'

import type { Upload } from '@app/types'
import type { Attachment } from '@graphql/types'

export type GalleryImage = Pick<Attachment, 'id' | 'url' | 'entityId'> | Upload

const isUpload = (attachment: GalleryImage): attachment is Upload => !!(attachment as Upload).shrineHash

interface GalleryProps {
  images: GalleryImage[]
  aspectRatio?: number
  rootProps?: StackProps
  scrollContainer?: string | object
  ImageActionsComponent?: FC<ChakraProps & { attachment }>
}

const Gallery: FC<GalleryProps> = ({ images, rootProps, ImageActionsComponent = null }) => {
  const [index, setIndex] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const slidesPerView = useBreakpointValue({ base: 3, md: 4 })
  const multiPage = images.length > slidesPerView

  const [ref, slider] = useCarousel({
    slides: {
      perView: slidesPerView,
      spacing: useBreakpointValue({ base: 16, md: 24 })
    },
    slideChanged: (s) => setCurrentSlide(s.track.details.rel)
  })

  useEffect(() => {
    if (index >= images.length) {
      setIndex(images.length - 1)
    }
  }, [images.length, index])

  if (index >= images.length) {
    return null
  }

  const showCarousel = images.length > 1

  return (
    <Stack spacing="4" {...rootProps} id="">
      <Stack pos="relative" spacing={0}>
        <Image
          as={Img}
          boxSize="100%"
          minW="100%"
          objectFit="cover"
          alt=""
          doNotReplaceURL={false}
          fallback={<Skeleton />}
          src={images[index].url}
        />
        {ImageActionsComponent && <ImageActionsComponent attachment={images[index]} pos="absolute" top={1} right={1} />}
      </Stack>
      {showCarousel && (
        <HStack spacing="4">
          {multiPage && (
            <CarouselIconButton
              onClick={() => slider.current?.prev()}
              icon={<IoChevronBackOutline />}
              aria-label="Previous slide"
              disabled={currentSlide === 0}
            />
          )}
          <Carousel ref={ref} direction="row" width="full">
            {images.map((image, i) => (
              <CarouselSlide key={isUpload(image) ? image.url : image.id} onClick={() => setIndex(i)} cursor="pointer">
                <Box maxW={32} opacity={index === i ? 1 : 0.4} _hover={{ opacity: 1 }} transition="all 200ms">
                  <Image
                    as={Img}
                    objectFit="cover"
                    alt=""
                    doNotReplaceURL={false}
                    fallback={<Skeleton />}
                    src={image.url}
                  />
                </Box>
              </CarouselSlide>
            ))}
          </Carousel>
          {multiPage && (
            <CarouselIconButton
              onClick={() => slider.current?.next()}
              icon={<IoChevronForwardOutline />}
              aria-label="Next slide"
              disabled={currentSlide + Number(slidesPerView) === images.length}
            />
          )}
        </HStack>
      )}
    </Stack>
  )
}

export default Gallery
