import type { ReactSelectAutocompleteValue } from '@app/shared/autocomplete/chakraAutocompletes'
import type { AutocompleteLabel } from '@app/shared/rawForms/labelAutocompleteInput'
import client from '@graphql/client'
import { LabelsAutocomplete } from '@graphql/documents/label.graphql'
import type { LabelsAutocompleteQuery, LabelsAutocompleteQueryVariables } from '@graphql/queries'

type LabelToPickerItem = (label: AutocompleteLabel) => ReactSelectAutocompleteValue
export const labelToPickerItem: LabelToPickerItem = (label) => ({ value: label?.id?.toString(), label: label.name })

export const fetchLabels = async (inputValue: LabelsAutocompleteQueryVariables['query']) => {
  const { data } = await client
    .query<LabelsAutocompleteQuery, LabelsAutocompleteQueryVariables>(LabelsAutocomplete, { query: inputValue })
    .toPromise()

  return data?.labelsAutocomplete?.map(labelToPickerItem)
}
