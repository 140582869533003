import { AsyncCreatableSelect, AsyncSelect, Select } from 'chakra-react-select'
import type { ReactElement } from 'react'
import type { Props as SelectProps } from 'react-select'
import { type AsyncProps } from 'react-select/async'
import { type AsyncCreatableProps } from 'react-select/async-creatable'
import type { GroupBase, StylesConfig } from 'react-select/dist/declarations/src'

import theme from '@app/theme'

const styles: StylesConfig = {
  placeholder: (provided) => ({
    ...provided
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: theme.zIndices.modal
  })
}

export type ReactSelectAutocompleteValue = { label: string; value: string }

type RestAsyncCreatableProps<T = unknown> = Omit<AsyncCreatableProps<T, boolean, GroupBase<T>>, 'noOptionsMessage'>

export interface ChakraAsyncCreatableSelectProps<OptionType = unknown> extends RestAsyncCreatableProps<OptionType> {
  asyncFetch?: (inputValue: string) => void
  initialValues?: object
  noOptionsMessage?: string
  onItemClick?: (value: OptionType) => void
}

const ChakraAsyncCreatableSelect = <OptionType = unknown,>({
  asyncFetch = () => {},
  initialValues = [],
  noOptionsMessage = 'Start typing',
  // onItemClick = () => {},
  ...rest
}: ChakraAsyncCreatableSelectProps<OptionType>): ReactElement => (
  <AsyncCreatableSelect
    isMulti
    isSearchable
    defaultValue={initialValues}
    isClearable={false} // we can set this to true, but would need to loop the handleChange or create batch
    loadOptions={asyncFetch}
    noOptionsMessage={() => noOptionsMessage}
    variant="outline"
    styles={styles}
    menuPortalTarget={document.body}
    {...rest}
  />
)

type RestAsyncProps<OptionType = unknown, IsMulti extends boolean = false> = Omit<
  AsyncProps<OptionType, IsMulti, GroupBase<OptionType>>,
  'noOptionsMessage'
>

export interface ChakraAsyncSelectProps<OptionType = ReactSelectAutocompleteValue, IsMulti extends boolean = false>
  extends RestAsyncProps<OptionType, IsMulti> {
  asyncFetch?: (inputValue: string) => void
  initialValue?: object
  multiple?: IsMulti
  noOptionsMessage?: string
}

const ChakraAsyncSelect = <OptionType = ReactSelectAutocompleteValue, IsMulti extends boolean = false>({
  multiple,
  asyncFetch = () => {},
  initialValue = null,
  noOptionsMessage = 'Start typing',
  isClearable = false,
  ...rest
}: ChakraAsyncSelectProps<OptionType, IsMulti>): ReactElement => (
  <AsyncSelect
    variant="outline"
    isMulti={multiple}
    isClearable={isClearable}
    isSearchable
    defaultValue={initialValue}
    loadOptions={asyncFetch}
    noOptionsMessage={() => noOptionsMessage}
    {...rest}
  />
)

type RestSelectProps<OptionType = unknown, IsMulti extends boolean = false> = Omit<
  SelectProps<OptionType, IsMulti, GroupBase<OptionType>>,
  'noOptionsMessage'
>

export interface ChakraReactSelectProps<OptionType, IsMulti extends boolean>
  extends Partial<RestSelectProps<OptionType, IsMulti>> {
  initialValue?: object
  multiple?: IsMulti
  noOptionsMessage?: string
}

const ChakraReactSelect = <OptionType = unknown, IsMulti extends boolean = false>({
  initialValue = null,
  multiple,
  noOptionsMessage = 'Start typing',
  ...rest
}: ChakraReactSelectProps<OptionType, IsMulti>) => (
  <Select
    variant="outline"
    isMulti={!!multiple}
    isSearchable
    defaultValue={initialValue || ''}
    noOptionsMessage={() => noOptionsMessage}
    styles={styles}
    {...rest}
  />
)

export { ChakraReactSelect, ChakraAsyncSelect, ChakraAsyncCreatableSelect }
