import { Button, Stack } from '@chakra-ui/react'
import type { ChangeEventHandler, FC, FormEventHandler } from 'react'
import { useCallback, useState } from 'react'

import { useMapCardFiltersModalContext } from './mapCardFiltersModalContext'

import { ButtonRow } from '@app/shared/forms'
import { Form, TextInput } from '@app/shared/rawForms'

type Props = {
  defaultValue: string
}

const FilterNodeName: FC<Props> = ({ defaultValue }) => {
  const { onFilter, setActiveFacetName, onClose } = useMapCardFiltersModalContext()
  const [name, setName] = useState(defaultValue || '')

  const onCancel = useCallback(() => {
    setActiveFacetName(null)
  }, [setActiveFacetName])

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setName(e.target.value)
  }, [])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      onFilter('name', name)
      onClose()
    },
    [name, onFilter, onClose]
  )

  return (
    <Form onSubmit={onSubmit} variant="drawer">
      <Stack px={4} py={2}>
        <TextInput autoFocus name="name" label="Name" value={name} onChange={onChange} />
        <ButtonRow>
          <Button onClick={onCancel} variant="secondary">
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </ButtonRow>
      </Stack>
    </Form>
  )
}

export default FilterNodeName
