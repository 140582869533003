import { Button } from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc'

const GoogleIntegrationButton = ({ ...rest }) => (
  <Button leftIcon={<FcGoogle />} variant="tertiary" {...rest}>
    Continue with Google
  </Button>
)

export default GoogleIntegrationButton
