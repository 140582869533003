import { HStack, Link as ChakraLink, Text } from '@chakra-ui/react'
import type { BaseEntitlement, Customer } from '@stigg/react-sdk'
import { useStiggContext } from '@stigg/react-sdk'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type GenericGateProps = {
  effect?: () => void
}

export const GenericGate: FC<GenericGateProps> = ({ effect }) => {
  useEffect(() => {
    effect?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // This runs exactly *once*

  return (
    <HStack>
      <Text>You&apos;ve reached your plans limit. To continue, please </Text>
      <ChakraLink as={Link} color="link" to="/settings/plan">
        Upgrade your account
      </ChakraLink>
    </HStack>
  )
}

type ChildrenProps = {
  entitlement: BaseEntitlement | null
  customer: Customer | null
}

type Props = {
  featureId: string
  children?: FC<ChildrenProps>
}

const GatedFeature: FC<Props> = ({ featureId, children = () => null }) => {
  const { stigg } = useStiggContext()
  const [entitlement, setEntitlement] = useState<BaseEntitlement>()
  const [customer, setCustomer] = useState<Customer>()

  useEffect(() => {
    const getEntitlement = async () => {
      await stigg.waitForInitialization()

      if (['feature-connected-metrics', 'feature-editors'].includes(featureId)) {
        setEntitlement(await stigg.getMeteredEntitlement({ featureId }))
      } else {
        setEntitlement(await stigg.getBooleanEntitlement({ featureId }))
      }
    }

    const getCustomer = async () => {
      await stigg.waitForInitialization()
      setCustomer(await stigg.getCustomer())
    }

    getEntitlement()
    getCustomer()
  }, [featureId, stigg])

  // TODO: Remove the length check once every account has a subscription
  if (!entitlement || !customer || !customer?.subscriptions?.length) {
    return children({ entitlement: null, customer: null })
  }

  return children({ entitlement, customer })
}

export default GatedFeature
