import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'sm'
}

interface Props {
  path: string
}

const KeyResultsButton: FC<Props> = ({ path }) => {
  const navigate = useNavigate()

  const openDrawer = () => {
    navigate(path)
  }

  return (
    <Tooltip label="Key results">
      <IconButton {...buttonStyles} aria-label="key results" icon={<ICON_MAP.OKR />} onClick={openDrawer} />
    </Tooltip>
  )
}

export default KeyResultsButton
