import { Box, Button, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import { useStore } from '@app/store'
import { IntegrationImportHistory } from '@graphql/documents/integration.graphql'
import type { IntegrationImportHistoryMutation } from '@graphql/queries'
import type { JiraIntegration } from '@graphql/types'

type Props = {
  integration: Pick<JiraIntegration, 'id' | 'importedHistory'>
}
const JiraImportHistory: FC<Props> = ({ integration }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { id: integrationId, importedHistory } = integration || {}
  const actionMutation = useStore.use.actionMutation()

  const onClick = async () => {
    setIsLoading(true)

    try {
      await actionMutation<IntegrationImportHistoryMutation>(IntegrationImportHistory, {
        integrationId
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Stack spacing={4}>
      <Text fontSize="sm">Click the button below to import your Jira history into DoubleLoop.</Text>
      <Text color="text.muted" fontSize="xs">
        Data from restricted projects will not be imported.
      </Text>
      <Box>
        <Button isDisabled={!!importedHistory} isLoading={isLoading} onClick={onClick}>
          Import History
        </Button>
      </Box>
    </Stack>
  )
}

export default JiraImportHistory
