import type { RadioGroupProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, RadioGroup, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import type { Errors } from '@app/shared/rawForms/useForm'

interface Props extends RadioGroupProps {
  name: string
  label?: string
  labelSize?: string
  isRequired?: boolean
  helperText?: React.ReactNode
  onChange: (value: unknown) => void
  defaultValue?: string
  errors?: Errors
  children: React.ReactNode
}

const RadioInput: FC<Props> = ({
  name,
  label = null,
  labelSize = 'md',
  isRequired = false,
  helperText = null,
  errors = {},
  children,
  defaultValue = '',
  onChange = (value) => value,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue)
  const handleChange = (radioValue) => {
    setValue(radioValue)
    onChange(radioValue)
  }

  return (
    <FormControl as="fieldset" id={name} isInvalid={!!errors[name]} isRequired={isRequired} name={name}>
      <Stack>
        {label && (
          <FormLabel fontSize={labelSize} htmlFor={name}>
            {label}
          </FormLabel>
        )}

        <Stack w="100%">
          <RadioGroup onChange={handleChange} value={value} {...rest}>
            {children}
          </RadioGroup>

          <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      </Stack>
    </FormControl>
  )
}

export default RadioInput
