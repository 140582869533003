import { IconButton, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'

import RecurringReportConfigurationForm from './components/recurringReportConfigurationForm'

import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import { ReportContext } from '@app/pages/reports/home'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import useToast from '@app/shared/toast'
import type { RecurringReportConfigurationQuery } from '@graphql/queries'
import { useRecurringReportConfigurationUpdateMutation } from '@graphql/queries'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

const backLink = '../../..'

const Edit: FC = () => {
  const { recurringReportConfiguration } = useLoaderData() as {
    recurringReportConfiguration: RecurringReportConfigurationQuery['recurringReportConfiguration']
  }
  const [, updateRecurringReport] = useRecurringReportConfigurationUpdateMutation()
  const navigate = useNavigate()
  const toast = useToast()

  const onCancel = () => {
    navigate(backLink)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const input = Object.fromEntries(formData.entries()) as Record<string, string>

    await updateRecurringReport({
      input: {
        ...input,
        recurringReportConfigurationId: recurringReportConfiguration.id,
        name: input.name || 'Untitled Report',
        schedule: input.schedule || 'FREQ=WEEKLY;BYDAY=MO',
        service:
          (input.service as RecurringReportConfigurationServiceEnum) ||
          RecurringReportConfigurationServiceEnum.EmitReport
      }
    })

    toast({
      title: 'Report updated!',
      description: 'Your recurring report was successfully saved',
      status: 'success'
    })

    navigate(backLink)
  }

  return (
    <ReportContext.Consumer>
      {() => (
        <Stack w="3xl" maxW="100%" mx="auto" spacing={4}>
          <DrawerHeader px={4}>
            <IconButton as={Link} aria-label="go back" icon={<FiArrowLeft />} to={backLink} variant="ghost" />
            <Text>Editing {recurringReportConfiguration.name}</Text>
            <Spacer />
            <DrawerCloseButton />
          </DrawerHeader>

          <Stack px={4} pb={4} spacing={4}>
            <RecurringReportConfigurationForm
              onCancel={onCancel}
              recurringReportConfiguration={recurringReportConfiguration}
              onSubmit={onSubmit}
            />
          </Stack>
        </Stack>
      )}
    </ReportContext.Consumer>
  )
}

export default Edit
