import type { PopoverProps } from '@chakra-ui/react'
import {
  Button,
  ButtonGroup,
  Checkbox,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text
} from '@chakra-ui/react'
import type { ChangeEvent, FC, PropsWithChildren } from 'react'
import { useState } from 'react'

import { BulkLabelsContextProvider, useBulkLabelsContext } from './bulkLabelsContext'

import LabelsAutocomplete from '@app/shared/autocomplete/labels/labelsAutocomplete'
import { useStore } from '@app/store'
import type { MapDomainBasicCard, MapDomainEntity, MapDomainMetric, MapDomainStrategy } from '@app/types'
import type { Label } from '@graphql/types'

export type BulkLabelableObject = Omit<
  MapDomainBasicCard | MapDomainEntity | MapDomainMetric | MapDomainStrategy,
  'labels'
> & {
  labels: Pick<Label, 'name'>[]
}

const Checkboxes: FC = () => {
  const { labels, dispatch } = useBulkLabelsContext()

  return Object.keys(labels)
    .sort()
    .map((label) => {
      const state = labels[label]
      const isChecked = state === 'checked'
      const isIndeterminate = state === 'indeterminate'

      const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target

        dispatch({
          type: checked ? 'check' : 'uncheck',
          payload: label
        })
      }

      return (
        <Checkbox key={label} isChecked={isChecked} isIndeterminate={isIndeterminate} onChange={onChange}>
          <Text>{label}</Text>
        </Checkbox>
      )
    })
}

type BodyProps = {
  domainObjects: BulkLabelableObject[]
  onClose: () => void
}
const Body: FC<BodyProps> = ({ domainObjects, onClose }) => {
  const bulkLabel = useStore.use.bulkLabel()
  const [isLoading, setIsLoading] = useState(false)
  const { labels, dispatch } = useBulkLabelsContext()

  const updateLabels = async () => {
    const added = Object.keys(labels).filter((label) => labels[label] === 'checked')
    const removed = Object.keys(labels).filter((label) => labels[label] === 'unchecked')

    setIsLoading(true)

    try {
      await bulkLabel(domainObjects, added, removed)
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  const onChange = (newValue) => {
    dispatch({
      type: 'check',
      payload: newValue.label
    })
  }

  return (
    <Stack>
      <LabelsAutocomplete
        placeholder={null}
        onChange={onChange}
        value={null}
        menuPortalTarget={document.body}
        isMulti={false}
      />

      <Checkboxes />
      <ButtonGroup justifyContent="flex-end" display="flex">
        <Button onClick={onClose}>Cancel</Button>
        <Button isLoading={isLoading} onClick={updateLabels} variant="primary">
          Update
        </Button>
      </ButtonGroup>
    </Stack>
  )
}

type Props = PropsWithChildren<Omit<PopoverProps, 'children'>> & Pick<BodyProps, 'domainObjects'>

const BulkLabelsPopover: FC<Props> = ({ domainObjects, children, ...rest }) => (
  <Popover isLazy {...rest}>
    {({ onClose }) => (
      <>
        <PopoverTrigger>{children}</PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Labels</PopoverHeader>
            <PopoverBody>
              <BulkLabelsContextProvider domainObjects={domainObjects}>
                <Body domainObjects={domainObjects} onClose={onClose} />
              </BulkLabelsContextProvider>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </>
    )}
  </Popover>
)

export default BulkLabelsPopover
