import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  VisuallyHiddenInput
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { parse, serialize } from 'tinyduration'

import type { Errors } from '@app/shared/rawForms/useForm'

interface Props {
  name: string
  label?: string
  labelSize?: string
  isRequired?: boolean
  helperText?: React.ReactNode
  errors?: Errors
  defaultValue?: string
}

const DurationInput: FC<Props> = ({
  name,
  label = '',
  labelSize = 'md',
  errors = {},
  isRequired = false,
  helperText = null,
  defaultValue = null
}) => {
  let defaultAmount = 1
  let defaultInterval = 'days'

  if (defaultValue) {
    const parsedDuration = parse(defaultValue)
    ;[defaultInterval, defaultAmount] = Object.entries(parsedDuration).find(([, v]) => v > 0)
  }

  const [amount, setAmount] = useState(defaultAmount || 1)
  const [interval, setInterval] = useState(defaultInterval || 'days')

  const defaultDuration = serialize({
    [interval]: amount
  })
  const [outputDuration, setOutputDuration] = useState(defaultDuration)

  const onAmountChange = (e) => {
    const newAmount = e?.target?.value

    setAmount(newAmount)

    if (!newAmount) {
      return
    }

    const duration = serialize({
      [interval]: newAmount
    })

    setOutputDuration(duration)
  }

  const onIntervalChange = (e) => {
    const newInterval = e?.target?.value

    if (!newInterval) {
      return
    }

    const duration = serialize({
      [newInterval]: amount
    })

    setInterval(newInterval)
    setOutputDuration(duration)
  }

  return (
    <FormControl id={name} isInvalid={!!errors[name]} isRequired={isRequired}>
      <VisuallyHiddenInput name={name} readOnly value={outputDuration} />
      <Stack>
        {label && <FormLabel fontSize={labelSize}>{label}</FormLabel>}
        <Flex maxW={{ md: '3xl' }}>
          <Input flex="10" w="5rem" min={1} onChange={onAmountChange} type="number" value={amount} />
          <Select flex="30" ml={2} onChange={onIntervalChange} placeholder="" value={interval}>
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
            <option value="years">Years</option>
          </Select>
        </Flex>
        <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    </FormControl>
  )
}

export default DurationInput
