import { Box, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import type { FC } from 'react'

import type { MapDomainMetric } from '@app/types'

interface Props {
  metric?: Pick<MapDomainMetric, 'id' | 'rollUp'>
  cumulative?: boolean
  setCumulative?: (cumulative: boolean) => void
}

const CumulativeToggle: FC<Props> = ({ metric = null, cumulative = false, setCumulative = () => {}, ...rest }) => {
  if (metric && metric.rollUp === 'sum') {
    return (
      <Box {...rest}>
        <FormControl alignItems="center" display="flex" mr={2}>
          <FormLabel mb={0} htmlFor="cumulative-toggle">
            Show cumulative total
          </FormLabel>
          <Switch
            id="cumulative-toggle"
            isChecked={cumulative}
            onChange={() => {
              setCumulative(!cumulative)
            }}
          />
        </FormControl>
      </Box>
    )
  }

  return null
}

export default CumulativeToggle
