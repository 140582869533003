import { Link, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { TextInput } from '@app/shared/rawForms'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  isDisabled: boolean
}

const Looker: FC<Props> = ({ metricSource, isDisabled }) => (
  <>
    <Text py={2} color="subtle" size="sm">
      For more information on configuring Looker, please visit{' '}
      <Link color="link" href="https://docs.doubleloop.app/measure/looker-integration" isExternal>
        our Looker Doc
      </Link>
      .
    </Text>
    <TextInput
      name="configuration.date_field"
      label="Date field"
      placeholder="Column name containing dates"
      isDisabled={isDisabled}
      isRequired
      defaultValue={metricSource?.configuration?.date_field}
    />
    <TextInput
      name="configuration.value_field"
      label="Value field"
      placeholder="Column name containing values"
      isDisabled={isDisabled}
      isRequired
      defaultValue={metricSource?.configuration?.value_field}
    />
  </>
)

export default Looker
