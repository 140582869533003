import { Button, type ButtonProps, Icon, useClipboard } from '@chakra-ui/react'
import { type FC } from 'react'
import { FiClipboard } from 'react-icons/fi'

import useToast from '@app/shared/toast'
import type { MapDomainStrategy } from '@app/types'
import { SharingTypesEnum } from '@graphql/types'

type Props = ButtonProps & {
  strategy: MapDomainStrategy
}

const CopyEmbedCodeButton: FC<Props> = ({ strategy, ...buttonProps }) => {
  const toast = useToast()
  const { id, sharing } = strategy
  const baseUrl = `${window.location.protocol}//${window.location.host}/strategy/embed/${id}/map`
  const strategyToken = sharing === SharingTypesEnum.TokenAccess ? strategy.token : null

  const url = strategyToken ? `${baseUrl}?token=${strategyToken}` : baseUrl

  const embedCode = `<iframe src="${url}" width="100%" height="500" frameborder="0" allowfullscreen></iframe>`
  const { onCopy } = useClipboard(embedCode)
  const copyToClipboard = () => {
    onCopy()

    toast({
      title: 'Embed code copied!',
      description: 'The embed code has been copied to your clipboard.',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
  }

  return (
    <Button leftIcon={<Icon as={FiClipboard} />} onClick={copyToClipboard} variant="primary" {...buttonProps}>
      Copy embed code
    </Button>
  )
}

export default CopyEmbedCodeButton
