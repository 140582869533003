import { HStack, Text, Spacer } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import FieldSearchInput from '@app/shared/forms/fieldSearchInput'
import { paginationSummary } from '@app/shared/pagination'

interface Props {
  page?: number
  limitValue?: number
  totalCount?: number
  leftChild?: ReactNode
  leftSummarySibling?: ReactNode
}

const SearchRow: FC<Props> = ({ page, limitValue, totalCount, leftChild, leftSummarySibling }) => (
  <HStack p={4} spacing={4}>
    {leftChild}
    <Spacer />
    {leftSummarySibling}
    <Text color="muted" fontSize="xs" whiteSpace="nowrap">
      {paginationSummary(page, limitValue, totalCount)}
    </Text>
    <FieldSearchInput field="filter" w="xs" placeholder="Search" size="sm" />
  </HStack>
)

export default SearchRow
