import type { StackProps, TextProps } from '@chakra-ui/react'
import { HStack, Icon, Text } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import { METRIC_SOURCES } from '@app/lib/globals'

type Props = StackProps & {
  sourceName: string
  children?: ReactNode
  fontSize?: TextProps['fontSize']
}

const Nameplate: FC<Props> = ({ sourceName, children, fontSize = 'md', ...rest }) => {
  const { display, icon, color } = METRIC_SOURCES[sourceName] || {}

  return (
    <HStack {...rest}>
      {icon && <Icon as={icon} boxSize="16px" color={color} />}
      <Text fontSize={fontSize}>{display}</Text>
      {children}
    </HStack>
  )
}

export default Nameplate
