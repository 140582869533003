import type { FormLabelProps, InputProps, SelectProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Select, Stack } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import type { Errors } from '@app/shared/rawForms/useForm'

export interface Props extends SelectProps {
  children: ReactNode
  name?: SelectProps['name']
  label?: ReactNode | string
  labelSize?: FormLabelProps['fontSize']
  isRequired?: boolean
  placeholder?: InputProps['placeholder'] | null
  helperText?: ReactNode | null
  errors?: Errors
}

const SelectInput: ReturnType<typeof forwardRef<HTMLSelectElement, Props>> = forwardRef(
  (
    {
      name = '',
      label = '',
      labelSize = 'md',
      placeholder = 'Select an option',
      isRequired = false,
      helperText = null,
      errors = {},
      children,
      isDisabled = false,
      ...rest
    },
    ref
  ) => (
    <FormControl id={name} isDisabled={isDisabled} isInvalid={!!errors[name]} isRequired={isRequired}>
      <Stack>
        {label && (
          <FormLabel fontSize={labelSize} htmlFor={name}>
            {label}
          </FormLabel>
        )}
        <Stack w="100%">
          <Select
            isDisabled={isDisabled}
            name={name}
            placeholder={placeholder}
            {...rest}
            ref={ref}
            w="100%"
            bgColor="bg.surface"
          >
            {children}
          </Select>
          <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      </Stack>
    </FormControl>
  )
)

export default SelectInput
