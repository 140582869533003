import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import Header from '@app/pages/basicCards/components/header'
import { PageStack } from '@app/shared/layout'

const Show = () => {
  const outletContext = useOutletContext()
  const { nodeId } = useParams()

  const basicCard = useGetObject(nodeId, 'basicCard')

  return (
    <PageStack bg="bg.surface">
      <Header basicCard={basicCard} />
      <Outlet context={outletContext} />
    </PageStack>
  )
}

export default Show
