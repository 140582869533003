import { Button, Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { RRule } from 'rrule'

import ChannelInput from '../../components/channelInput'

import ServiceSelect from '@app/pages/reports/recurring/components/serviceSelect'
import { ButtonRow } from '@app/shared/forms'
import { Form, RRuleInput, TextAreaInput, TextInput, useForm } from '@app/shared/rawForms'
import type { FormProps } from '@app/shared/rawForms/form'
import type { MapDomainStrategy } from '@app/types'
import type { RecurringReportConfigurationQuery } from '@graphql/queries'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

type Props = Omit<FormProps, 'children'> & {
  strategy?: MapDomainStrategy
  recurringReportConfiguration?: RecurringReportConfigurationQuery['recurringReportConfiguration']
  onCancel?: () => void
}

const RecurringReportConfigurationForm: FC<Props> = ({
  strategy,
  recurringReportConfiguration,
  onCancel,
  ...formProps
}) => {
  const { state } = useLocation()
  const { errors } = useForm({})
  const [service, setService] = useState<RecurringReportConfigurationServiceEnum | null>(null)
  // const serviceRef = useRef<HTMLSelectElement>(null)
  let defaultNameValue = recurringReportConfiguration?.name
  let defaultServiceSelectValue = recurringReportConfiguration?.service

  if (state && state.reportType) {
    defaultNameValue = `${strategy?.name} - ${state.reportType === RecurringReportConfigurationServiceEnum.SendGoals ? 'Key Results' : 'Changelog'}`
    defaultServiceSelectValue = state.reportType
  }

  const handleFormChange = (e) => {
    if (e.target.name === 'service') {
      setService(e.target.value)
    }
  }

  return (
    <Form variant="drawer" {...formProps} onChange={(e) => handleFormChange(e)}>
      <Stack pt={4} divider={<StackDivider />} spacing={4}>
        <TextInput
          name="name"
          label="Report title"
          placeholder="Untitled"
          autoComplete="off"
          errors={errors}
          defaultValue={defaultNameValue}
          isRequired
        />
        <RRuleInput
          defaultValue={recurringReportConfiguration?.schedule}
          name="schedule"
          availableFrequencies={[RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY]}
        />
        <ServiceSelect defaultValue={defaultServiceSelectValue} isRequired />
        {[null, RecurringReportConfigurationServiceEnum.EmitReport].includes(service) && (
          <TextAreaInput
            name="aiPrompt"
            label="AI Prompt"
            placeholder="Summarize the work and metrics that are on the map."
            autoComplete="off"
            errors={errors}
            defaultValue={recurringReportConfiguration?.aiPrompt}
            isRequired
          />
        )}
        <ChannelInput report={recurringReportConfiguration} onChange={() => {}} />
        <ButtonRow>
          <Button onClick={onCancel} variant="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </ButtonRow>
      </Stack>
    </Form>
  )
}

export default RecurringReportConfigurationForm
