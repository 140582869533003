import { forwardRef } from 'react'

import type { Props as BaseInputProps } from '@app/shared/rawForms/baseInput'
import BaseInput from '@app/shared/rawForms/baseInput'

type Props = BaseInputProps

const EmailInput = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <BaseInput {...props} type="email" autoComplete="email" ref={ref} />
))

export default EmailInput
