import sortBy from 'lodash/sortBy'

import SelectInput from './selectInput'

import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useAccountsQuery } from '@graphql/queries'

const OwnerSelect = (props) => {
  const [
    {
      data: { accounts }
    }
  ] = useAccountsQuery()

  return (
    <SelectInput {...props}>
      {sortBy(accounts, 'user.name').map((account) => (
        <option key={account.id} value={account.user.id}>
          {account.user.name}
        </option>
      ))}
    </SelectInput>
  )
}

export default withSuspenseWrapper(OwnerSelect, null)
