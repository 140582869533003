import { Button, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useRef } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { Form, useSubmit } from 'react-router-dom'

import useFormSubmitAction from '@app/hooks/useFormSubmitAction'
import ConfirmPopover from '@app/shared/confirmPopover'

type Props = {
  goalId: string
  children?: ReactNode
}

const defaultChildren = (
  <Button color="error" aria-label="Delete goal" leftIcon={<FiTrash2 />} variant="tertiary">
    Delete
  </Button>
)

const GoalDeleteButton: FC<Props> = ({ goalId, children = defaultChildren }) => {
  const action = useFormSubmitAction()
  const ref = useRef()
  const submit = useSubmit()

  const deleteGoal = async () => {
    submit(ref.current, {
      method: 'delete',
      action
    })

    return false
  }

  return (
    <ConfirmPopover
      onConfirm={deleteGoal}
      header="Delete goal"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      <Form method="delete">
        <VisuallyHiddenInput name="goalId" readOnly value={goalId} />
        {children}
        <VisuallyHiddenInput ref={ref} type="submit" />
      </Form>
    </ConfirmPopover>
  )
}

export default GoalDeleteButton
