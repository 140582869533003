import { Text, Th } from '@chakra-ui/react'
import type { FC } from 'react'

import type { Column } from '../types'

type Props = {
  column: Column
}

const HeaderColumn: FC<Props> = ({ column }) =>
  typeof column?.header === 'string' ? (
    <Th w={column?.width || null} p={column?.padding}>
      <Text>{column.header}</Text>
    </Th>
  ) : (
    column.header
  )

export default HeaderColumn
