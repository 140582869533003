import { Stack, useBoolean } from '@chakra-ui/react'
import type { FC } from 'react'

import { DurationInput, ToggleInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import TextDivider from '@app/shared/textDivider'
import type { MapDomainMetric } from '@app/types'

interface Props {
  metric?: MapDomainMetric
  errors?: Errors
}

const StatIntervals: FC<Props> = ({ metric = null, errors = null }) => {
  const [isChecked, setIsChecked] = useBoolean(metric?.periodToDate)

  if (metric?.rollUp === 'last_month_available') {
    return null
  }

  return (
    <Stack spacing={4}>
      <ToggleInput
        isChecked={isChecked}
        label="Month to date (MTD), Quarter to date (QTD), Year to date (YTD)"
        name="periodToDate"
        onChange={setIsChecked.toggle}
        defaultValue={isChecked}
      />
      {!isChecked && (
        <>
          <TextDivider>or</TextDivider>
          <DurationInput
            label="Interval Period 1"
            name="strategyStatPeriod0"
            defaultValue={metric?.strategyStats?.[0]?.period || 'P7D'}
            errors={errors}
          />
          <DurationInput
            label="Interval Period 2"
            name="strategyStatPeriod1"
            defaultValue={metric?.strategyStats?.[1]?.period || 'P6W'}
            errors={errors}
          />
          <DurationInput
            label="Interval Period 3"
            name="strategyStatPeriod2"
            defaultValue={metric?.strategyStats?.[2]?.period || 'P12M'}
            errors={errors}
          />
        </>
      )}
    </Stack>
  )
}

export default StatIntervals
