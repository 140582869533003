import type { ButtonProps } from '@chakra-ui/react'
import {
  useDisclosure,
  Portal,
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  IconButton,
  Tooltip
} from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import track from '@app/lib/analyticsHelper'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'
import type { MapDomainNode, MapDomainObject } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { ProductInitiativesSuggest } from '@graphql/documents/ai_assistant.graphql'
import type { ProductInitiativesSuggestMutation, ProductInitiativesSuggestMutationVariables } from '@graphql/queries'
import type { NodeObjectInput } from '@graphql/types'

type ChildFunc = (props: Partial<ButtonProps>) => ReactNode

interface Props {
  node: Pick<MapDomainNode, 'nodeId' | 'data'>
  children?: ChildFunc
  onComplete?: () => void
}

const buttonStyles = {
  borderRadius: 'sm'
}

const defaultChildren: ChildFunc = ({ isLoading, onClick }) => (
  <IconButton
    {...buttonStyles}
    aria-label="Suggest new name"
    icon={<ICON_MAP.AiPrompt />}
    isLoading={isLoading}
    onClick={onClick}
  />
)

const SuggestProductInitiativesButton: FC<Props> = ({ node, children = defaultChildren, onComplete = () => {} }) => {
  const { isOpen, onToggle } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const actionMutation = useStore.use.actionMutation()
  const toast = useToast()
  const { user } = useStoreCurrentUser()
  const updateObject = useStore.use.updateObject()
  const domainObject = useGetObject(node.data.id, node.data.classType) as MapDomainObject

  const loader = async () => {
    onToggle()
    setIsLoading(true)

    try {
      const result = await actionMutation<
        ProductInitiativesSuggestMutation,
        ProductInitiativesSuggestMutationVariables
      >(ProductInitiativesSuggest, {
        nodeId: node.nodeId
      })

      if (result.error) {
        if (result.error.networkError) {
          toast({ title: 'Error', description: result.error.networkError.message, status: 'error' })
        } else {
          const errors = result.error.graphQLErrors.map((e) => e.message).join(', ')
          toast({ title: 'Error', description: errors, status: 'error' })
        }
      } else {
        setSuggestions(result.data?.productInitiativesSuggest?.result)
      }
    } catch (e) {
      toast({ title: 'Error', description: e.message, status: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const onSelect = (name) => {
    track('aiselect:product_initiative', user, { nodeId: node.nodeId, name })

    onToggle()
    onComplete()

    return updateObject({
      [node.data.classType]: {
        id: domainObject.id,
        name
      }
    } as NodeObjectInput)
  }

  const body = () => {
    if (isLoading) {
      return <Box>Loading...</Box>
    }

    return suggestions?.map((name) => (
      <Box key={name}>
        <Button key={name} onClick={() => onSelect(name)} variant="ghost">
          {name}
        </Button>
      </Box>
    ))
  }

  return (
    <Popover isOpen={isOpen} onOpen={loader}>
      <PopoverTrigger>
        <Box>
          <Tooltip label="Suggest product initiatives">{children({ isLoading })}</Tooltip>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="fit-content" minW="xs">
          <PopoverCloseButton />
          <PopoverHeader>Suggested product initiatives</PopoverHeader>
          <PopoverBody>{body()}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default SuggestProductInitiativesButton
