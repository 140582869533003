import { createContextualCan } from '@casl/react'
import { createContext, memo } from 'react'

export const AbilityContext = createContext(null)
// https://casl.js.org/v6/en/package/casl-react
const Can = createContextualCan(AbilityContext.Consumer)

Can.displayName = 'Can'

export default memo(Can)
