/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FormErrorMessageProps, InputProps } from '@chakra-ui/react'
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'

interface Props extends InputProps {
  name?: string
  label?: string
  labelSize?: string
  placeholder?: string
  type?: string
  isRequired?: boolean
  errors?: FormErrorMessageProps
  inlineLink?: React.ReactNode
}

const PasswordInput: FC<Props> = ({
  name = 'password',
  label = 'Password',
  labelSize = 'md',
  placeholder = 'Enter a password',
  type = 'password',
  isRequired = false,
  errors = {},
  inlineLink = null,
  ...rest
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const inputRef = useRef(null)

  const onClickReveal = () => {
    onToggle()
    const input = inputRef.current
    if (input) {
      input.focus({ preventScroll: true })
      const length = input.value.length * 2
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length)
      })
    }
  }

  return (
    <FormControl id={name} isInvalid={errors[name]} isRequired={isRequired}>
      {label && (
        <Flex justify="space-between">
          <FormLabel htmlFor={name}>{label}</FormLabel>
          {/* {inlineLink} */}
        </Flex>
      )}
      <InputGroup>
        <InputRightElement>
          <IconButton
            bg="transparent !important"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <FiEyeOff /> : <FiEye />}
            onClick={onClickReveal}
            tabIndex={-1}
            variant="ghost"
          />
        </InputRightElement>
        <Input
          ref={inputRef}
          autoComplete="current-password"
          name={name}
          placeholder={placeholder}
          required
          type={isOpen ? 'text' : 'password'}
          variant="outline"
          {...rest}
        />
      </InputGroup>
      <FormErrorMessage>{errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  )
}

export default PasswordInput
