import type { InputProps } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FC } from 'react'

import BaseInput from '@app/shared/rawForms/baseInput'

interface Props extends InputProps {
  label?: string
}

const DateTimeInput: FC<Props> = ({ defaultValue, ...rest }) => {
  const defaultDate = defaultValue === undefined ? new Date() : defaultValue
  const parseValue = typeof defaultDate === 'string' ? new Date(defaultDate) : defaultDate
  // eslint-disable-next-line quotes
  const dateTimeLocalDefaultValue = format(parseValue as Date, "yyyy-MM-dd'T'HH:mm")

  return <BaseInput type="datetime-local" defaultValue={dateTimeLocalDefaultValue} {...rest} />
}

export default DateTimeInput
