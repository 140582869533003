import type { ButtonProps } from '@chakra-ui/react'
import { Box, Button, useDisclosure } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FiPlay } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import MetricSourceRunResults from './metricSourceRunResults'

import { METRIC_SOURCES } from '@app/lib/globals'
import ClearDataPointsPopover from '@app/pages/metrics/components/clearDataPointsPopover'
import useRunMetricSource from '@app/pages/metrics/components/metricSource/useRunMetricSource'
import type { MetricSource } from '@graphql/queries'

type Props = ButtonProps & {
  metricSource: Pick<MetricSource, 'id' | 'sourceName'> | null
}

const MetricSourceRunButton: FC<Props> = ({ metricSource, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [status, setStatus] = useState('failed')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const runMetricSource = useRunMetricSource()
  const { nodeId: metricId } = useParams()

  if (!metricSource || !METRIC_SOURCES[metricSource.sourceName].runnable) {
    return null
  }

  const runSource = async (clearDataPoints: boolean) => {
    setIsLoading(true)

    try {
      const { data } = await runMetricSource({
        metricId,
        metricSourceId: metricSource.id,
        clearDataPoints
      })

      setSuccess(data?.metricSourceRun?.success || false)
      setStatus(data?.metricSourceRun?.status || 'failed')
    } finally {
      setIsLoading(false)
      onOpen()
    }
  }

  const run = async () => {
    await runSource(false)
  }

  const clearAndRun = async () => {
    await runSource(true)
  }

  return (
    <Box>
      <MetricSourceRunResults isOpen={isOpen} onClose={onClose} success={success} status={status} />
      <ClearDataPointsPopover onYes={clearAndRun} onNo={run}>
        <Button aria-label="Run metric source" isLoading={isLoading} leftIcon={<FiPlay />} variant="primary" {...rest}>
          Run now
        </Button>
      </ClearDataPointsPopover>
    </Box>
  )
}

export default MetricSourceRunButton
