import { Button, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import PasswordInput from '@app/pages/sessions/components/passwordInput'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import { FormAlert } from '@app/shared/forms'
import { Form, useForm } from '@app/shared/rawForms'

const ResetPassword: FC = () => {
  const { passwordResetToken } = useParams()
  const { errors } = useForm({})

  return (
    <Stack w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} spacing="8">
      <SessionHeader
        title="Reset your password"
        subtitle="Remembered your password?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack spacing="6">
        <Form id="reset-password-form" method="post">
          <VisuallyHiddenInput defaultValue={passwordResetToken} name="passwordResetToken" />
          <FormAlert description={errors?.global?.message} />
          <Stack spacing="5">
            <PasswordInput
              name="password"
              label="New Password"
              placeholder="Enter new password"
              autoComplete="new-password"
              isRequired
              errors={errors}
            />
          </Stack>
        </Form>
        <Stack spacing={4}>
          <Button form="reset-password-form" size="md" type="submit" variant="primary">
            Set new password
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ResetPassword
