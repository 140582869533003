import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import type { FC, FormEventHandler, PropsWithChildren } from 'react'
import { memo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FormAlert } from '@app/shared/forms'
import { SlackAutocompleteInput } from '@app/shared/rawForms'
import { useEntitySlackMutation } from '@graphql/queries'

type Props = PropsWithChildren<{
  entityId: string
}>

const SlackSendPopover: FC<Props> = ({ entityId, children }) => {
  const toast = useToast()
  const [error, setError] = useState<Error | null>(null)

  const { nodeId, strategyId: sId } = useParams()
  const strategyId = sId || nodeId

  const [, sendToSlack] = useEntitySlackMutation()

  const sendEntityToSlack = async (e: Parameters<FormEventHandler<HTMLFormElement>>[0], onClose: () => void) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formValues = Object.fromEntries(formData)

    try {
      await sendToSlack({
        input: { channelId: formValues.channelId as string, entityId, strategyId }
      })

      toast({
        title: 'Sent to Slack',
        position: 'top'
      })
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  const errorMessage = error?.message

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <Tooltip label="Share to Slack" placement="top">
            <Box display="inline-block">
              <PopoverTrigger>{children}</PopoverTrigger>
            </Box>
          </Tooltip>
          <PopoverContent overflow="unset">
            <form onSubmit={(e) => sendEntityToSlack(e, onClose)}>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Share</PopoverHeader>
              <PopoverBody py={4}>
                <FormAlert description={errorMessage} title="Failed to send to Slack!" />
                <SlackAutocompleteInput
                  name="channelId"
                  label="Slack Channel"
                  isRequired
                  errors={{
                    global: {
                      message: errorMessage
                    }
                  }}
                  prepopulateFromCookie
                />
              </PopoverBody>
              <PopoverFooter>
                <ButtonGroup justifyContent="flex-end" display="flex">
                  <Button onClick={onClose} variant="ghost">
                    Cancel
                  </Button>
                  <Button ml={3} type="submit" variant="primary">
                    Send to Slack
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </form>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default memo(SlackSendPopover)
