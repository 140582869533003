import type { RouteObject } from 'react-router-dom'
import { defer, Outlet } from 'react-router-dom'

import List from '@app/pages/credentials/list'
import { loaderQuery } from '@graphql/client'
import { Credentials } from '@graphql/documents/credential.graphql'
import type { CredentialsQuery } from '@graphql/queries'

const loadCredentials = async () => {
  const { data } = await loaderQuery<CredentialsQuery>(Credentials)

  return defer({ credentials: data?.credentials })
}

const routes: RouteObject = {
  path: 'credentials',
  id: 'credentials',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <List />,
      loader: loadCredentials
    }
  ]
}

export default routes
