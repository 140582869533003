import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  FormLabel,
  Spacer,
  useDisclosure
} from '@chakra-ui/react'
import { useState } from 'react'
import type { FC } from 'react'

import { RANDOM_DATA_STD_DEV, RANDOM_DATA_UPWARD_MOVE_PERCENTAGE } from '@app/lib/globals'
import NumberInput from '@app/next/forms/numberInput'
import { useStore } from '@app/store'
import { MetricPopulateEmpty } from '@graphql/documents/metric.graphql'
import type { Strategy } from '@graphql/types'

interface Props {
  strategy: Pick<Strategy, 'id'>
}

const PopulateMetricsWithDummyData: FC<Props> = ({ strategy }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { actionMutation } = useStore.getState()
  const { id: strategyId } = strategy

  const onSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const formData = new FormData(e.target)
    const formObject = Object.fromEntries(formData.entries())
    const input = {}

    // This only works for the two fields in the form, if more are added, this will need to be updated
    Object.keys(formObject).forEach((key) => {
      const value = formObject[key] as string

      input[key] = parseFloat(value)
    })

    actionMutation(MetricPopulateEmpty, { strategyId, ...input }, {}, { strategyId }).finally(() => {
      setIsLoading(false)

      onClose()
    })
  }

  return (
    <>
      <HStack>
        <FormLabel fontSize="md">Populate metrics with random data</FormLabel>
        <Spacer />
        <Button onClick={onOpen}>Populate</Button>
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>Populate metrics with random data</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <NumberInput
                name="stdDeviation"
                label="Standard deviation"
                isRequired
                defaultValue={RANDOM_DATA_STD_DEV}
              />
              <NumberInput
                name="upwardMovePercentage"
                label="Upward move percentage"
                isRequired
                defaultValue={RANDOM_DATA_UPWARD_MOVE_PERCENTAGE}
              />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button isLoading={isLoading} type="submit" variant="primary">
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PopulateMetricsWithDummyData
