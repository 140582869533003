import { Box, Container, Stack } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import Hero from '@app/next/changelog/components/hero'
import EmptyState from '@app/shared/emptyState/emptyState'
import AggregatedEntities from '@app/shared/entities/aggregatedEntities'
import { PageStack } from '@app/shared/layout'
import Pagination from '@app/shared/pagination'
import withAwait from '@app/shared/withAwait'

const Page: FC<PropsWithChildren> = ({ children }) => (
  <PageStack>
    <Container>
      <Hero />
      <Stack spacing={6}>{children}</Stack>
    </Container>
  </PageStack>
)

const List: FC = () => {
  const changelog = useGetObjectPage('entity')
  const { collection, metadata } = changelog || {}

  if (collection.length === 0) {
    return (
      <Page>
        <EmptyState title="No changelog entries found" />
      </Page>
    )
  }

  return (
    <Page>
      <Box>
        <AggregatedEntities entities={collection} />
      </Box>
      <Pagination px={6} pb={4} {...metadata} />
    </Page>
  )
}

export default withAwait(List, 'changelog')
