import type { InputProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

import BaseInput from './baseInput'
import type { Props as RootFormControlProps } from './rootFormControl'

export type Props = Omit<RootFormControlProps, 'children'> & InputProps

const TextInput: ReturnType<typeof forwardRef<HTMLInputElement, Props>> = forwardRef<HTMLInputElement, Props>(
  function TextInput(props, ref) {
    return <BaseInput ref={ref} type="text" {...props} />
  }
)

export default TextInput
