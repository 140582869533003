import { Box, HStack, Link as ChakraLink, Spacer, Stack, StackDivider, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link, useParams } from 'react-router-dom'

import { groupEntitiesBy } from './utils'

import OwnerPopover from '@app/next/ownerPopover'
import WorkflowStateIcon from '@app/next/workflowStateIcon'
import WorkflowStatePopover from '@app/next/workflowStatePopover'
import Can from '@app/shared/authorization/can'
import EmptyState from '@app/shared/emptyState/emptyState'
import CreateEntityButton from '@app/shared/entities/createEntityButton'
import { pathWithSearchParams } from '@app/utils/routeHelpers'
import { toSentenceCase } from '@app/utils/stringHelpers'
import type { Entity } from '@graphql/types'

const EntityGroupHeader = ({ groupName, entityCount }) => (
  <HStack p={2} bg="bg.muted">
    <WorkflowStateIcon workflowState={groupName} />
    <Text fontSize="sm" fontWeight="semibold">
      {toSentenceCase(groupName)}
    </Text>
    <Text color="fg.subtle" fontSize="sm">
      {entityCount}
    </Text>
  </HStack>
)

interface EntityGroupRowProps {
  entity: Entity
}

const EntityGroupRow: FC<EntityGroupRowProps> = ({ entity }) => {
  const { id, name } = entity
  const entityPath = pathWithSearchParams(`${id}`)

  return (
    <HStack px={2} py={3} _hover={{ bgColor: 'bg.muted' }}>
      <WorkflowStatePopover entity={entity} />
      <ChakraLink
        as={Link}
        overflow="hidden"
        fontSize="sm"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        title={name}
        to={entityPath}
      >
        {name}
      </ChakraLink>
      <Spacer />
      <OwnerPopover object={entity} />
      {/* <Avatar cursor="pointer" name={user?.name} size="2xs" src={user?.email ? profileImage(user.email) : null} /> */}
    </HStack>
  )
}

interface EntityGroupProps {
  group: Entity[]
}

const EntityGroup: FC<EntityGroupProps> = ({ group }) => (
  <Stack divider={<StackDivider />} spacing={0}>
    {group.map((entity) => (
      <EntityGroupRow key={entity.id} entity={entity} />
    ))}
  </Stack>
)

interface Props {
  entities: Entity[]
  group?: 'workflowState'
}

const GroupedEntities: FC<Props> = ({ entities = [], group = 'workflowState', ...rest }) => {
  const { nodeType } = useParams()

  if (!entities.length) {
    return (
      <EmptyState title="No events yet">
        <Can I="create" an="event" passThrough>
          {(allowed) => {
            if (allowed && ['entity'].includes(nodeType)) {
              return <CreateEntityButton text="Create your first one" variant="tertiary" />
            }

            return null
          }}
        </Can>
      </EmptyState>
    )
  }

  const groupedEntities = groupEntitiesBy(entities, group)
  const entityGroups = Object.keys(groupedEntities)
  const groupOrder = ['finished', 'reviewing', 'started', 'not_started']

  return (
    <Stack pt={4} {...rest}>
      {groupOrder.map((groupKey) => {
        if (!entityGroups.includes(groupKey)) {
          return null
        }

        return (
          <Box key={groupKey}>
            <EntityGroupHeader key={groupKey} groupName={groupKey} entityCount={groupedEntities[groupKey].length} />
            <EntityGroup group={groupedEntities[groupKey] as Entity[]} />
          </Box>
        )
      })}
    </Stack>
  )
}

export default GroupedEntities
