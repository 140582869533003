import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import type { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface Props extends ButtonProps {
  label: string
  url: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
}

const NavigationButton: FC<Props> = ({ label, url, leftIcon = null, rightIcon = null, ...buttonProps }) => (
  <Button
    as={Link}
    aria-label={label}
    leftIcon={leftIcon}
    rightIcon={rightIcon}
    size="xs"
    to={url}
    variant="secondary"
    {...buttonProps}
  >
    {label}
  </Button>
)

export default NavigationButton
