import type { FC } from 'react'

import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import SelectInput from '@app/shared/rawForms/selectInput'

type Props = Omit<SelectInputProps, 'children'> & {
  startYear?: number
  endYear?: number
}

const YearSelectInput: FC<Props> = ({ startYear = 2000, endYear = new Date().getFullYear(), ...rest }: Props) => {
  const length = endYear - startYear + 1

  return (
    <SelectInput {...rest}>
      {Array.from({ length }, (_, i) => (
        <option key={i} value={startYear + i}>
          {startYear + i}
        </option>
      )).reverse()}
    </SelectInput>
  )
}

export default YearSelectInput
