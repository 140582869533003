import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { Form, useNavigation } from 'react-router-dom'

import { FormAlert } from '@app/shared/forms'
import { CardHeader } from '@app/shared/layout'
import { useForm } from '@app/shared/rawForms'
import UrlInput from '@app/shared/rawForms/urlInput'

const OrganizationForm: FC = () => {
  const { errors } = useForm({}) as { errors: { global?: { message?: string }; name?: string; homepageUrl?: string } }
  const navigation = useNavigation()

  return (
    <Stack spacing="6">
      <CardHeader title="Almost there!" subtitle="Add your organization details to finish setting up your account." />

      <Form id="organization-form" method="post">
        <Stack spacing="5">
          <FormAlert description={errors?.global?.message} title="Failed to create the organization!" />

          <FormControl id="name" isInvalid={!!errors.name} isRequired>
            <FormLabel htmlFor="name">Organization name</FormLabel>
            <Input id="name" name="name" placeholder="Enter the name of your organization" />
          </FormControl>
          <FormControl id="homepageUrl" isInvalid={!!errors.homepageUrl}>
            <FormLabel htmlFor="homepageUrl">Company website</FormLabel>
            <UrlInput id="homepageUrl" name="homepageUrl" placeholder="https://website.com" />
          </FormControl>
        </Stack>
      </Form>
      <Stack spacing={4}>
        <Button
          form="organization-form"
          isLoading={navigation.state === 'submitting' || navigation.state === 'loading'}
          size="md"
          type="submit"
          variant="primary"
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}

export default OrganizationForm
