import type { BoxProps } from '@chakra-ui/react'
import { Box, Stack, StackDivider, useColorModeValue } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

export interface Props extends BoxProps {
  isStack?: boolean
  children?: ReactNode
  minH?: number
}

const Card: FC<Props> = ({ isStack = false, children, ...rest }) => {
  const stackProps = { as: Stack, spacing: { base: 4, sm: 6 }, divider: <StackDivider /> }

  return (
    <Box
      p={{ base: '4', md: '6' }}
      bg="bg.subtle"
      borderRadius="lg"
      shadow={useColorModeValue('sm', 'sm-dark')}
      {...(isStack ? stackProps : null)}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Card
