import { Box, IconButton, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import ICON_MAP from '@app/utils/iconMap'

interface Props {
  commentsCount: number
  showWhenNoComments?: boolean
  size?: 'sm' | 'md' | 'lg'
}

const sizeMap = {
  sm: {
    buttonSize: 'sm',
    fontSize: '2xs'
  },
  md: {
    buttonSize: 'sm',
    fontSize: '3xs'
  },
  lg: {
    buttonSize: 'md',
    fontSize: '3xs'
  }
}

const CommentsCount: FC<Props> = ({ commentsCount, showWhenNoComments = false, size = 'md', ...rest }) => {
  let count = `${commentsCount}`
  const { fontSize } = sizeMap[size]

  if (!showWhenNoComments && (!commentsCount || commentsCount === 0)) {
    return null
  }

  if (commentsCount > 9) {
    count = '9+'
  }

  return (
    <Box pos="relative" alignItems="center" justifyContent="center" display="inline-flex" role="group">
      <IconButton
        color="fg.subtle"
        aria-label="Comments"
        icon={<ICON_MAP.Comments />}
        // size={buttonSize}
        variant="ghost"
        {...rest}
      />
      <Text
        pos="absolute"
        top="50%"
        left="50%"
        alignItems="center"
        justifyContent="center"
        display="flex"
        fontSize={fontSize}
        lineHeight="1"
        transform="translate(-50%, -50%)"
        colorScheme="gray" // Example effect on hover
      >
        {count !== '0' ? count : ''}
      </Text>
    </Box>
  )
}

export default CommentsCount
