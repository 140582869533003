import { createStandaloneToast } from '@chakra-ui/react'
import pick from 'lodash/pick'
import { defer } from 'react-router-dom'

import ForgotPassword from '@app/pages/sessions/forgotPassword'
import ResetPassword from '@app/pages/sessions/resetPassword'
import SamlOrganization from '@app/pages/sessions/samlOrganization'
import SignIn from '@app/pages/sessions/signIn'
import SignUp from '@app/pages/sessions/signUp'
import SessionShell from '@app/shared/layout/shells/sessionShell'
import { Notification } from '@app/shared/toast'
import { actionMutation, loaderQuery } from '@graphql/client'
import {
  InvitationDocument,
  UserAuthenticateDocument,
  UserCreateDocument,
  UserResetPasswordDocument,
  UserResetPasswordRequestDocument
} from '@graphql/queries'

const { toast } = createStandaloneToast()

const resetPassword = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())
  const validFields = ['password', 'passwordResetToken']

  return actionMutation(UserResetPasswordDocument, pick(input, validFields))
    .then((response) => {
      if (response.error) {
        throw response
      }

      const { success, errors } = response.data.userResetPassword

      if (errors?.length) {
        throw errors
      }

      toast({
        title: 'Password reset',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      window.location.href = '/'

      return { success }
    })
    .catch((resp) => ({ error: resp }))
}

const sendPasswordResetEmail = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())
  const validFields = ['email']

  return actionMutation(UserResetPasswordRequestDocument, pick(input, validFields))
    .then((response) => {
      if (response.error) {
        throw response
      }

      const { success, errors } = response.data.userResetPasswordRequest

      if (errors?.length) {
        throw errors
      }

      toast({
        title: 'Sending password reset email',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      return { success }
    })
    .catch((resp) => ({ error: resp }))
}

const signIn = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())
  const { from } = input
  const validFields = ['email', 'password']

  return actionMutation(UserAuthenticateDocument, pick(input, validFields))
    .then((response) => {
      if (response.error) {
        throw response
      }

      const { redirect, errors } = response.data.userAuthenticate

      if (errors?.length) {
        throw errors
      }

      toast({
        title: 'Signing in',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      window.location.href = redirect !== '/' ? redirect : from

      return { redirect }
    })
    .catch((resp) => ({ error: resp }))
}

const signUp = async (request: Request) => {
  const formData = await request.formData()
  const input: Record<string, unknown> = Object.fromEntries(formData.entries())

  const validFields = ['email', 'inviteCode', 'name', 'password', 'passwordConfirmation', 'timeZone']

  return actionMutation(UserCreateDocument, pick(input, validFields))
    .then((response) => {
      if (response.error) {
        throw response
      }

      const { user, errors } = response.data.userCreate

      if (errors?.length) {
        throw errors
      }

      toast({
        title: 'Creating your account',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      window.location.href = '/'

      return { user }
    })
    .catch((resp) => ({ error: resp }))
}

const loadInvitationCode = async ({ params }) => {
  const invitationPromise = loaderQuery(InvitationDocument, {
    inviteCode: params.inviteCode
  })

  return defer({ invitation: invitationPromise })
}

const routes = {
  element: <SessionShell />,
  children: [
    {
      path: 'sign_in',
      element: <SignIn />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return signIn(request)
          default:
            return null
        }
      }
    },
    {
      path: 'sign_up',
      element: <SignUp />,
      loader: () =>
        defer({
          invitation: new Promise((resolve) => {
            resolve({ data: { invitation: null } })
          })
        }),
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return signUp(request)
          default:
            return null
        }
      }
    },
    {
      path: 'sign_up/:inviteCode',
      loader: loadInvitationCode,
      element: <SignUp />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return signUp(request)
          default:
            return null
        }
      }
    },
    {
      path: 'forgot_password',
      element: <ForgotPassword />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return sendPasswordResetEmail(request)
          default:
            return null
        }
      }
    },
    {
      path: 'reset_password/:passwordResetToken',
      element: <ResetPassword />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return resetPassword(request)
          default:
            return null
        }
      }
    },
    {
      path: 'sso',
      element: <SamlOrganization />
    },
    {
      path: 'sso/:ssoIdentifier',
      element: <SamlOrganization />
    }
  ]
}

export default routes
