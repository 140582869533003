import { IconButton, Text, Tooltip, useClipboard } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect } from 'react'

import useToast from '@app/shared/toast'
import type { MapDomainNode } from '@app/types'

interface Props {
  node: MapDomainNode
}

const IDButton: FC<Props> = ({ node }) => {
  const { hasCopied, onCopy } = useClipboard(node?.data?.slug || '')
  const toast = useToast()

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Copied!',
        description: 'Metric ID copied to clipboard',
        status: 'success'
      })
    }
  }, [hasCopied])

  return (
    <Tooltip hasArrow label="Copy metric id">
      <IconButton
        aria-label="Copy metric id"
        icon={
          <Text pl="2px" fontSize="md" fontWeight="lighter">
            ID
          </Text>
        }
        onClick={onCopy}
      />
    </Tooltip>
  )
}

export default IDButton
