import type { ButtonProps } from '@chakra-ui/react'
import {
  useDisclosure,
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip
} from '@chakra-ui/react'
import type { FC } from 'react'

import SuggestDownstreamMetricsButton from './suggestDownstreamMetricsButton'
import SuggestProductInitiativesButton from './suggestProductInitiativesButton'

import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'sm'
}

const bodyButtonStyles: Partial<ButtonProps> = {
  w: '100%',
  px: 2,
  outline: 'none',
  variant: 'ghost',
  textAlign: 'start'
}

type Props = {
  node: Pick<MapDomainNode, 'nodeId' | 'data'>
}

const AiAssistantButton: FC<Props> = ({ node }) => {
  // The popover is controlled because PopoverTrigger and Tooltip will not work together by default, and
  // putting another component around it to catch the Tooltip ref will break the ButtonGroup isAttached behavior
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <Popover isOpen={isOpen}>
      <PopoverTrigger>
        <Tooltip as={Button} label="AI Assistant">
          <IconButton aria-label="AI Assistant" icon={<ICON_MAP.AiPrompt />} onClick={onToggle} {...buttonStyles} />
        </Tooltip>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="fit-content">
          <PopoverBody>
            <Box w="fit-content">
              <SuggestProductInitiativesButton node={node} onComplete={onClose}>
                {({ onClick }) => (
                  <Button onClick={onClick} {...bodyButtonStyles}>
                    Suggest initiative name
                  </Button>
                )}
              </SuggestProductInitiativesButton>
              <SuggestDownstreamMetricsButton node={node}>
                {({ onClick }) => {
                  const closer: typeof onClick = (e) => {
                    onClick(e)
                    onClose()
                  }

                  return (
                    <Button onClick={closer} {...bodyButtonStyles}>
                      Suggest downstream metrics
                    </Button>
                  )
                }}
              </SuggestDownstreamMetricsButton>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default AiAssistantButton
