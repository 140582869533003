import type { InputProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState, useCallback } from 'react'

import type { Props as LabelsAutocompleteProps } from '@app/shared/autocomplete/labels/labelsAutocomplete'
import LabelsAutocomplete from '@app/shared/autocomplete/labels/labelsAutocomplete'
import type { Errors } from '@app/shared/rawForms/useForm'

export type AutocompleteLabel = { id: string | number; name: string }

export interface Props extends LabelsAutocompleteProps {
  name: string
  label?: string
  labelSize?: string
  // placeholder?: string
  defaultValue?: AutocompleteLabel[]
  valueTransformer?: (labels: AutocompleteLabel[]) => InputProps['value']
  isRequired?: boolean
  helperText?: React.ReactNode
  errors?: Errors
}

const LabelAutocompleteInput: FC<Props> = ({
  name,
  label = null,
  labelSize = 'md',
  placeholder = '',
  helperText = null,
  errors = {},
  defaultValue = [] as AutocompleteLabel[],
  valueTransformer = (labels: AutocompleteLabel[]) => JSON.stringify(labels.map((lbl) => lbl.name)),
  ...rest
}) => {
  const [labels, setLabels] = useState(defaultValue)

  const handleChange: LabelsAutocompleteProps['onChange'] = useCallback((newLabels) => {
    setLabels(newLabels.map((newLabel) => ({ id: newLabel.value, name: newLabel.label })))
  }, [])

  return (
    <FormControl id={name} isInvalid={!!errors[name]}>
      <VisuallyHiddenInput id={name} name={name} readOnly value={valueTransformer(labels)} />
      <Stack>
        {label && (
          <FormLabel fontSize={labelSize} htmlFor={`${name}-autocomplete`}>
            {label}
          </FormLabel>
        )}
        <Stack w="100%">
          <LabelsAutocomplete
            id={`${name}-autocomplete`}
            onChange={handleChange}
            placeholder={placeholder}
            defaultValue={defaultValue}
            {...rest}
          />
          <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      </Stack>
    </FormControl>
  )
}

export default LabelAutocompleteInput
