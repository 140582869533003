import { Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import MetricFormatSelect from './metricFormatSelect'

import { ToggleInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import type { MapDomainMetric } from '@app/types'
import { DisplayFormatEnum } from '@graphql/queries'

type Props = {
  metric: MapDomainMetric
  errors: Errors
}

const MetricDisplayFormatSection: FC<Props> = ({ metric, errors }) => {
  const [displayFormat, setDisplayFormat] = useState(metric?.displayFormat)
  const displayRedecimalPercentages = displayFormat === DisplayFormatEnum.Percent

  return (
    <Stack divider={<StackDivider />} spacing="5">
      <MetricFormatSelect
        name="displayFormat"
        label="Format"
        placeholder={null}
        errors={errors}
        isRequired
        defaultValue={metric?.displayFormat}
        onChange={(e) => setDisplayFormat(e.target.value as DisplayFormatEnum)}
      />
      {displayRedecimalPercentages && (
        <ToggleInput
          label="Multiply values by 100"
          name="redecimalPercentages"
          errors={errors}
          defaultValue={metric?.redecimalPercentages}
        />
      )}
    </Stack>
  )
}

export default MetricDisplayFormatSection
