import { Flex, IconButton, Spacer, Tooltip } from '@chakra-ui/react'
import { PiArrowLeftBold } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'

const ChatDrawerHeader = () => (
  <Flex p={2}>
    <Tooltip label="Go back">
      <IconButton
        as={Link}
        aria-label="Go back"
        colorScheme="gray"
        icon={<PiArrowLeftBold />}
        to=".."
        variant="ghost"
      />
    </Tooltip>
    <Spacer />
    <DrawerCloseButton variant="ghost" />
  </Flex>
)

export default ChatDrawerHeader
