import { HStack, Icon, Spacer, Text, useColorModeValue as mode } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { FiCheck } from 'react-icons/fi'

import ColorSquare from './colorSquare'

interface Props {
  color: string
  label?: string
  value?: string
  isActive?: boolean
  onClick?: (arg0: string) => void
}

const ColorOptionLabel: FC<Props> = ({ color, label, value, isActive = false, onClick }) => {
  const hoverColor = mode('gray.50', 'gray.600')

  const handleClick = () => {
    if (onClick) {
      onClick(value || color)
    }
  }

  return (
    <HStack px={2} py={2} _hover={{ bgColor: hoverColor }} cursor="pointer" onClick={handleClick}>
      <ColorSquare color={color} />
      <Text>{capitalize(label || color)}</Text>
      <Spacer />
      {isActive && <Icon as={FiCheck} boxSize={5} color="teal.500" />}
    </HStack>
  )
}

export default ColorOptionLabel
