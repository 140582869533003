import type { IconButton, IconButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import type { FC, ReactElement } from 'react'

import Can from '@app/shared/authorization/can'
import useToast from '@app/shared/toast'
import type { Playbook } from '@graphql/queries'
import { PublicationStateEnum, usePlaybookSubmitForReviewMutation } from '@graphql/queries'

interface Props extends IconButtonProps {
  playbook: Playbook
  children?: React.ReactNode
  buttonType?: typeof Button | typeof IconButton
  leftIcon?: ReactElement
}

const DEFAULT_HANDLER = () => {}

const SubmitForReviewButton: FC<Props> = ({ playbook, children = null, buttonType = Button, ...buttonProps }) => {
  const ButtonType = buttonType
  const [, submitForReview] = usePlaybookSubmitForReviewMutation()
  const toast = useToast()

  const { id: playbookId, publicationState } = playbook

  const buttonText = () => {
    switch (publicationState) {
      case PublicationStateEnum.Published:
        return 'Published to marketplace'
      case PublicationStateEnum.PendingReview:
        return 'Pending review'
      default:
        return children
    }
  }

  const handler = () =>
    submitForReview({
      input: {
        playbookId
      }
    }).then(() => {
      toast({
        title: 'Playbook submitted!',
        description: 'Successfully submitted the playbook for review',
        status: 'success'
      })
    })

  const onClick = publicationState === PublicationStateEnum.Private ? handler : DEFAULT_HANDLER
  const isDisabled = publicationState !== PublicationStateEnum.Private

  if (isDisabled) {
    return null
  }

  return (
    <Can I="submit" a="playbook">
      <ButtonType onClick={onClick} disabled={isDisabled} aria-label="Submit playbook for review" {...buttonProps}>
        {buttonText()}
      </ButtonType>
    </Can>
  )
}

export default SubmitForReviewButton
