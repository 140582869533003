import type { InputProps } from '@chakra-ui/react'
import type { FC } from 'react'

import useInlineEditorProps from '@app/hooks/useInlineEditorProps'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import RawTextInput from '@app/next/forms/dateInput'
import type { MapDomainObject } from '@app/types'

interface Props extends InputProps {
  domainObject: MapDomainObject
  name: string
}

const DateInput: FC<Props> = ({ domainObject, name, defaultValue, ...rest }) => {
  const { onChange, onBlur } = useInlineEditorProps(domainObject)
  const { user } = useStoreCurrentUser()
  const editor = ['admin', 'editor'].includes(user?.role)

  const inlineStyles = {
    width: '100%',
    _disabled: {
      cursor: 'default'
    }
  }
  return (
    <RawTextInput
      name={name}
      value={defaultValue}
      variant="unstyled"
      onChange={onChange}
      onBlur={onBlur}
      data-1p-ignore
      isDisabled={!editor}
      maxW="fit-content"
      {...inlineStyles}
      {...rest}
    />
  )
}

export default DateInput
