import type { ButtonProps } from '@chakra-ui/react'
import { Button, useDisclosure } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import MetricSourceTestResults from './metricSourceTestResults'

import { METRIC_SOURCES } from '@app/lib/globals'
import type { MetricSource, MetricSourceTestPayload } from '@graphql/queries'
import { useMetricSourceTestMutation } from '@graphql/queries'

interface TestMetricSourceButtonProps extends ButtonProps {
  metricSource?: Pick<MetricSource, 'id' | 'sourceName'> | null
}

const TestMetricSourceButton: FC<TestMetricSourceButtonProps> = ({ metricSource = undefined, ...buttonProps }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [{ data: testData }, testQuery] = useMetricSourceTestMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!metricSource || !METRIC_SOURCES[metricSource.sourceName].testable) {
    return null
  }

  const handleTest = () => {
    setIsLoading(true)
    testQuery({ input: { metricSourceId: metricSource.id } }).finally(() => {
      setIsLoading(false)
      onOpen()
    })
  }

  return (
    <>
      <MetricSourceTestResults
        testData={testData?.metricSourceTest as MetricSourceTestPayload}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Button aria-label="Test metric source" isLoading={isLoading} onClick={handleTest} {...buttonProps}>
        Test connection
      </Button>
    </>
  )
}

export default TestMetricSourceButton
