import type { ComponentStyleConfig } from '@chakra-ui/react'

const AggregatedEntities: ComponentStyleConfig = {
  parts: [
    'layoutRow',
    'layoutRowDate',
    'layoutRowEntitiesContainer',
    'layoutRowEntities',
    'entityContainer',
    'entityHeading'
  ],
  baseStyle: {
    layoutRow: {
      mx: 12,
      pt: 8,
      mt: 8,
      borderTop: '1px',
      borderColor: 'bg.muted',
      _first: { border: 'none', mt: 0 },
      flexDirection: { base: 'column', lg: 'row' }
    },
    layoutRowDate: {
      width: { base: '100%', lg: '20%' },
      pt: 2,
      mt: 4,
      color: 'gray.500',
      fontSize: 'sm',
      fontWeight: 500
    },
    layoutRowEntitiesContainer: {
      w: { base: '100%', lg: '80%' },
      ml: 0,
      px: { base: 0, lg: 4 }
    },
    layoutRowEntities: {
      maxW: { base: '100%', lg: '600px' }
    },
    entityContainer: {
      mb: 6,
      py: 2,
      _last: { mb: 0 },
      flexDirection: 'column'
    },
    entityHeading: {
      color: 'muted',
      fontSize: 'xl',
      fontWeight: 'bold',
      lineHeight: '32px',
      wordBreak: 'break-word'
    },
    entityThumbnail: {
      borderRadius: 'base',
      shadow: 'base'
    },
    minorEntitiesHeading: {
      py: 2,
      color: 'gray.600',
      fontSize: 'lg'
    }
  },
  variants: {
    naked: {
      layoutRow: {
        pt: 0,
        mt: 0,
        mb: 2,
        borderTop: 'none'
      },
      layoutRowDate: {
        pt: 0,
        mt: 0
      }
    },
    drawer: {
      layoutRow: {
        mx: 2,
        pt: 0,
        mt: 2,
        borderTop: '1px',
        borderColor: 'gray.200',
        _first: { border: 'none' },
        flexDirection: { base: 'column', lg: 'column' }
      },
      layoutRowDate: {
        width: '100%',
        color: 'gray.500',
        fontSize: { base: 'md', lg: 'sm' },
        fontWeight: 500,
        pb: 2,
        pr: 4,
        pt: 0
      },
      layoutRowEntitiesContainer: {
        w: '100%',
        ml: 0,
        px: 0
      },
      layoutRowEntities: {
        // maxW: '100%'
      },
      entityContainer: {
        mb: 6,
        _last: { mb: 0 },
        flexDirection: 'column'
      },
      entityHeading: {
        color: 'muted',
        fontSize: 'lg ',
        lineHeight: 'sm',
        wordBreak: 'break-word'
      },
      entityThumbnail: {
        borderRadius: 'base',
        shadow: 'base'
      },
      minorEntitiesHeading: {
        color: 'muted',
        fontSize: 'md'
      }
    }
  }
}

export default AggregatedEntities
