import {
  Button,
  Divider,
  Stack,
  StackDivider,
  VisuallyHiddenInput,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import toLower from 'lodash/toLower'
import type { FC } from 'react'
import { useState } from 'react'

import { ButtonRow, FormContainer } from '@app/shared/forms'
import { FileInput, SelectInput, TextAreaInput, TextInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import IdPMetadataReader from '@app/utils/idpMetadataReader'
import { origin, Paths } from '@app/utils/routeHelpers'
import type { Organization } from '@graphql/queries'

interface SSOUrlProps {
  label: string
  value: string
}

const SSOUrl: FC<SSOUrlProps> = ({ label, value }) => (
  <FormControl>
    <Stack justify="space-between" direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }}>
      <FormLabel fontSize="sm" fontWeight="semibold" variant="inline">
        {label}
      </FormLabel>
      <Input maxW={{ md: '3xl' }} isReadOnly size="sm" value={value} variant="unstyled" />
    </Stack>
  </FormControl>
)

interface SsoFormProps {
  organization: Organization
  errors?: Errors
  loading?: boolean
}

const SsoForm: FC<SsoFormProps> = ({ organization, errors = {}, loading = false }) => {
  const [idpEntityId, setIdpEntityId] = useState(organization.idpEntityId || '')
  const [idpSsoServiceUrl, setIdpSsoServiceUrl] = useState(organization.idpSsoServiceUrl || '')
  const [idpCert, setIdpCert] = useState(organization.idpCert || '')

  const handleMetadataUpload = (e) => {
    if (!(e.target?.files?.length > 0)) {
      return
    }

    const file = e.target.files[0]

    const reader = new FileReader()
    const listener = (event) => {
      const metadata = new IdPMetadataReader(event.target.result)

      setIdpEntityId(metadata.entityId)
      setIdpSsoServiceUrl(metadata.identityProviderUrl)
      setIdpCert(metadata.signingCert)
      reader.removeEventListener('load', listener)
    }

    reader.addEventListener('load', listener)

    reader.readAsText(file)
  }

  return (
    <>
      <VisuallyHiddenInput defaultValue={organization?.id} name="organizationId" />
      <FormContainer title="Single Sign On" subtitle="SAML based SSO configuration">
        <SSOUrl
          label="SSO Login URL"
          value={Paths.samlOrganizationParamPath({ ssoIdentifier: organization.ssoIdentifier }, { fullPath: true })}
        />
        <SSOUrl
          label="ACS URL"
          value={Paths.acsUrlPath({ organization: toLower(organization.name) }, { fullPath: true })}
        />
        <SSOUrl label="Audience URI / SP Entity ID" value={origin()} />
        <Divider />
        <Stack divider={<StackDivider />} spacing="5">
          <SelectInput label="Provider" name="ssoProvider" defaultValue={organization.ssoProvider} errors={errors}>
            <option value="azure">Azure</option>
            <option value="google">Google</option>
            <option value="okta">Okta</option>
            <option value="custom">Custom</option>
          </SelectInput>
          <TextInput
            label="Issuer"
            name="issuer"
            defaultValue={organization.issuer}
            errors={errors}
            placeholder="Issuer (required for Azure)"
          />
          <TextInput
            label="Entity ID"
            name="idpEntityId"
            value={idpEntityId}
            onChange={(e) => setIdpEntityId(e.target.value)}
            errors={errors}
            placeholder="Entity ID"
          />
          <TextInput
            label="SSO URL"
            name="idpSsoServiceUrl"
            value={idpSsoServiceUrl}
            onChange={(e) => setIdpSsoServiceUrl(e.target.value)}
            errors={errors}
            placeholder="SSO URL"
          />
          <TextAreaInput
            label="X.509 certificate"
            name="idpCert"
            value={idpCert}
            onChange={(e) => setIdpCert(e.target.value)}
            errors={errors}
            placeholder="X.509 Certificate"
            isRequired
            rows={10}
          />
          <FileInput
            label="Fill in via IdP metadata file"
            placeholder="IdP metadata.xml file"
            onChange={(e) => handleMetadataUpload(e)}
          />
        </Stack>
        <ButtonRow>
          <Button isDisabled={loading} type="submit" variant="primary">
            Save settings
          </Button>
        </ButtonRow>
      </FormContainer>
    </>
  )
}

export default SsoForm
