import {
  Box,
  Center,
  DarkMode,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'

import Wordmark from '@app/images/wordmark'

const SessionShell: FC = () => (
  <Flex
    minH={{ base: 'auto', md: '100vh' }}
    bgGradient={useBreakpointValue({
      md: useColorModeValue('linear(to-r, blue.600 50%, white 50%)', 'linear(to-r, blue.600 50%, gray.900 50%)')
    })}
  >
    <Flex w="full" maxW="8xl" mx="auto">
      <Box flex="1" display={{ base: 'none', md: 'block' }}>
        <DarkMode>
          <Flex direction="column" h="full" px={{ base: '4', md: '8' }} color="on-accent">
            <Flex align="center" h="24">
              <Link href="https://www.doubleloop.app">
                <Wordmark boxSize={48} color="white" />
              </Link>
            </Flex>
            <Flex align="center" flex="1">
              <Stack spacing="8">
                <Stack spacing="6">
                  <Heading color="white" size={useBreakpointValue({ md: 'lg', xl: 'xl' })}>
                    Orient your strategy around impact.
                  </Heading>
                  <Text maxW="md" fontSize="lg" fontWeight="medium">
                    DoubleLoop is a strategy development platform for teams to maximize and show impact.
                  </Text>
                </Stack>
              </Stack>
            </Flex>
            <Flex align="center" h="24">
              <Text color="on-accent-subtle" fontSize="sm">
                © {new Date().getFullYear()} DoubleLoop. All rights reserved.
              </Text>
            </Flex>
          </Flex>
        </DarkMode>
      </Box>
      <Center flex="1">
        <Outlet />
      </Center>
    </Flex>
  </Flex>
)

export default SessionShell
