import { Button } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const AddWorkItemButton = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const to = location.pathname.endsWith('/add') ? '..' : 'add'

  const cb = useCallback(() => {
    if (to === '..') {
      navigate(to, { relative: 'path' })
    }
  }, [navigate, to])

  useHotkeys('esc', cb)

  return (
    <Button as={Link} size="xs" to={`${to}${location.search}`} variant="secondary">
      Add work item
    </Button>
  )
}

export default AddWorkItemButton
