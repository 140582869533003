import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import ICON_MAP from '@app/utils/iconMap'

type Props = Omit<IconButtonProps, 'aria-label'> & {
  'aria-label'?: string
}

const AiFieldUpdateButton: FC<Props> = ({ onClick, isLoading, ...buttonProps }) => (
  <IconButton
    as={Link}
    aria-label="Populate from an AI suggestion"
    icon={<ICON_MAP.AiPrompt />}
    isLoading={isLoading}
    onClick={onClick}
    variant="ghost"
    {...buttonProps}
  />
)

export default AiFieldUpdateButton
