import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Stack,
  VisuallyHiddenInput
} from '@chakra-ui/react'
import type { FC } from 'react'
import { Link, useLocation, useNavigation } from 'react-router-dom'

import AuthProviders from '@app/pages/sessions/components/authProviders'
import PasswordInput from '@app/pages/sessions/components/passwordInput'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import { FormAlert } from '@app/shared/forms'
import { Form, useForm } from '@app/shared/rawForms'

const SignIn: FC = () => {
  const location = useLocation()
  const navigation = useNavigation()
  const from = location.state?.from?.pathname || '/'
  const { errors } = useForm({ globalError: 'Invalid username or password.' }) as {
    errors: { [key: string]: { message: string } }
  }

  return (
    <Stack w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} spacing="8">
      <SessionHeader
        title="Sign in to your account"
        // subtitle="Don't have an account?"
        // linkText="Sign up"
        // linkUrl="/sign_up"
      />

      <Stack spacing="6">
        <FormAlert description={errors?.global?.message} />
        <Form id="sign-in-form" method="post">
          <VisuallyHiddenInput defaultValue={from} name="from" />
          <Stack spacing="5">
            <FormControl id="email" isInvalid={!!errors?.email} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input autoComplete="email" id="email" name="email" placeholder="Enter your email" type="email" />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <PasswordInput
              name="password"
              label="Password"
              placeholder=""
              autoComplete="current-password"
              isRequired
              errors={errors}
            />
          </Stack>
          <HStack justify="space-between" mt={2}>
            <Spacer />
            <Button as={Link} color="link" to="/forgot_password" variant="text">
              Forgot your password?
            </Button>
          </HStack>
        </Form>
        <Stack spacing="4">
          <Button
            form="sign-in-form"
            isLoading={navigation.state === 'submitting'}
            size="md"
            type="submit"
            variant="primary"
          >
            Sign in
          </Button>
          <AuthProviders text="Sign in with" />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SignIn
