import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import ShowFieldToggle from '@app/pages/shared/showFieldToggle'
import type { MapDomainBasicCard, MapDomainEntity, MapDomainMetric, MapDomainNode, MapDomainStrategy } from '@app/types'

interface BasicCardFieldsProps {
  basicCard?: MapDomainBasicCard
}

const BasicCardFields: FC<BasicCardFieldsProps> = ({ basicCard }) => {
  if (!basicCard) {
    return null
  }

  return (
    <Stack spacing={4}>
      <ShowFieldToggle
        domainObject={basicCard}
        label="Show description?"
        name="showDescription"
        defaultValue={basicCard?.showDescription}
      />
      <ShowFieldToggle
        domainObject={basicCard}
        label="Show goal / hypothesis?"
        name="showGoalHypothesis"
        defaultValue={basicCard?.showGoalHypothesis}
      />
      <ShowFieldToggle
        domainObject={basicCard}
        label="Show results?"
        name="showResults"
        defaultValue={basicCard?.showResults}
      />
    </Stack>
  )
}

interface EntityCardFieldsProps {
  entity?: MapDomainEntity
}

const EntityCardFields: FC<EntityCardFieldsProps> = ({ entity }) => {
  if (!entity) {
    return null
  }

  return (
    <Stack spacing={4}>
      <ShowFieldToggle
        domainObject={entity}
        label="Show summary?"
        name="showSummary"
        defaultValue={entity?.showSummary}
      />
      <ShowFieldToggle
        domainObject={entity}
        label="Show goal / hypothesis?"
        name="showGoalHypothesis"
        defaultValue={entity?.showGoalHypothesis}
      />
      <ShowFieldToggle
        domainObject={entity}
        label="Show results?"
        name="showResults"
        defaultValue={entity?.showResults}
      />
    </Stack>
  )
}

interface MetricCardFieldsProps {
  metric?: MapDomainMetric
}

const MetricCardFields: FC<MetricCardFieldsProps> = ({ metric }) => {
  if (!metric) {
    return null
  }

  return (
    <Stack spacing={4}>
      <ShowFieldToggle
        domainObject={metric}
        label="Show description?"
        name="showDescription"
        defaultValue={metric?.showDescription}
      />
      <ShowFieldToggle
        domainObject={metric}
        label="Show technical description?"
        name="showTechnicalDescription"
        defaultValue={metric?.showTechnicalDescription}
      />
    </Stack>
  )
}

interface StrategyCardFieldsProps {
  strategy?: MapDomainStrategy
}

const StrategyCardFields: FC<StrategyCardFieldsProps> = ({ strategy }) => {
  if (!strategy) {
    return null
  }

  return <Text>No fields to toggle for Submaps</Text>
}

interface Props {
  node: MapDomainNode
}

const CardFields: FC<Props> = ({ node }) => {
  const { data } = node
  const { id, classType } = data
  const domainObject = useGetObject(id, classType)

  switch (node.type) {
    case 'basicCard':
      return <BasicCardFields basicCard={domainObject} />
    case 'entity':
      return <EntityCardFields entity={domainObject} />
    case 'metric':
      return <MetricCardFields metric={domainObject} />
    case 'strategy':
      return <StrategyCardFields strategy={domainObject} />
    default:
  }

  return null
}

export default CardFields
