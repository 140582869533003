import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { format } from 'date-fns-tz'
import type { FC, MouseEventHandler } from 'react'
import { useState, useRef } from 'react'
import * as yup from 'yup'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { FormAlert } from '@app/shared/forms'
import { DateInput } from '@app/shared/rawForms'
import { RawForm } from '@app/shared/rawForms/form'

const emptyStringToNil = (current, original) => (original === '' ? null : current)

const validationSchema = yup.object({
  startDate: yup.date().transform(emptyStringToNil),
  endDate: yup
    .date()

    .transform(emptyStringToNil)
    .when('startDate', {
      is: (startDate) => startDate !== null,
      then: () =>
        yup
          .date()
          .nullable()
          .transform(emptyStringToNil)
          .min(yup.ref('startDate'), 'end date can not be before start date')
    })
})
// interface DateRange extends yup.InferType<typeof validationSchema> {}

const formatFieldDate = (fieldDate, timeZone) => {
  if (!fieldDate) {
    return undefined
  }

  return format(fieldDate, 'uuuu-MM-dd', { timeZone })
}

interface Props {
  isOpen?: boolean
  onClose?: (status?: 'cancelled' | 'success', data?: { startDate?: string; endDate?: string }) => void
}

type OnCancel = (e?: Parameters<MouseEventHandler>[0]) => void

const CustomRangeModal: FC<Props> = ({ isOpen = false, onClose = () => {} }) => {
  const { user } = useStoreCurrentUser()
  const now = formatFieldDate(new Date(), user.timeZone)
  const formRef = useRef<HTMLFormElement>()
  const [error, setError] = useState<string | null>(null)

  const onCancel: OnCancel = (e = null): void => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    onClose('cancelled')
  }

  const onSubmit = async (e) => {
    setError(null)
    e.preventDefault()

    const formData = new FormData(formRef.current)
    const startDate = formatFieldDate(formData.get('startDate'), user.timeZone)
    const endDate = formatFieldDate(formData.get('endDate'), user.timeZone)

    try {
      await validationSchema.validate({ startDate, endDate })

      onClose('success', { startDate, endDate })
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onCancel}
      onOverlayClick={onCancel}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Custom Date Filter</ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <ModalBody>
          <RawForm ref={formRef} onSubmit={onSubmit}>
            <FormAlert title="Unable to update" description={error} />
            <Stack>
              <DateInput name="startDate" label="Start Date" defaultValue={now} />
              <DateInput name="endDate" label="End Date" defaultValue={now} />
            </Stack>
          </RawForm>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onCancel} variant="ghost">
            Cancel
          </Button>
          <Button ml={3} onClick={onSubmit} variant="primary">
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CustomRangeModal
