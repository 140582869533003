import type { ChakraProps } from '@chakra-ui/react'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  Textarea,
  VisuallyHidden
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { forwardRef, useState } from 'react'

import type { MarkdownEditorRef } from '@app/shared/rawForms/markdownEditor/markdownEditor'
import MarkdownEditor from '@app/shared/rawForms/markdownEditor/markdownEditor'
import type { Errors } from '@app/shared/rawForms/useForm'

interface Props extends ChakraProps {
  name: string
  label?: ReactNode
  labelSize?: string
  placeholder?: string
  defaultValue?: string
  isRequired?: boolean
  helperText?: ReactNode
  errors?: Errors
  className?: string
}

const RichInput: ReturnType<typeof forwardRef<MarkdownEditorRef, Props>> = forwardRef<MarkdownEditorRef, Props>(
  (
    {
      name,
      label = '',
      labelSize = 'md',
      placeholder = '',
      isRequired = false,
      helperText = null,
      errors = {},
      defaultValue = '',
      ...rest
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue)

    return (
      <FormControl cursor="text" isInvalid={!!errors[name]} isRequired={isRequired}>
        <VisuallyHidden>
          <Textarea name={name} readOnly value={value} />
        </VisuallyHidden>
        <Stack>
          {label && <FormLabel fontSize={labelSize}>{label}</FormLabel>}
          <MarkdownEditor
            placeholder={placeholder}
            initialValue={defaultValue}
            onChange={setValue}
            ref={ref}
            {...rest}
          />
          <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      </FormControl>
    )
  }
)

export default RichInput
