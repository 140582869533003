import { HStack, Text, useColorModeValue as mode } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'

import Logo from '@app/images/logo'
import type { Playbook } from '@graphql/queries'

interface Props {
  playbook: Playbook
}

const PlaybookCardAttribution: FC<Props> = ({ playbook, ...rest }) => {
  const { organization, user } = playbook
  const featured = ['doubleloop']

  const attribution = []

  const commonStyles = {
    color: 'subtle',
    fontSize: 'sm'
  }

  if (organization) {
    if (featured.includes(organization.name.toLowerCase())) {
      attribution.push(<Logo key="playbook_logo" color={mode('blue.600', 'blue.400')} h="3" />)
    } else {
      attribution.push(
        <Text key="playbook_org" {...commonStyles} title={organization.name}>
          {truncate(organization.name, { length: 40 })}
        </Text>
      )
    }
  }

  if (user) {
    if (organization && !featured.includes(organization.name.toLowerCase())) {
      attribution.push(
        <Text key="playbook_separator" {...commonStyles}>
          -
        </Text>
      )
    }

    attribution.push(
      <Text key="playbook_user" {...commonStyles} title={user.name}>
        {truncate(user.name, { length: 40 })}
      </Text>
    )
  }

  if (!user && !organization) {
    attribution.push(
      <Text key="playbook_no_attribution" {...commonStyles}>
        &nbsp;
      </Text>
    )
  }

  return (
    <HStack spacing={1} {...rest}>
      {attribution}
    </HStack>
  )
}

export default PlaybookCardAttribution
