import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { useStore } from '@app/store'
import { Metric } from '@graphql/documents/metric.graphql'
import type { MetricDataPoint } from '@graphql/types'

interface Props {
  metricDataPoint?: Pick<MetricDataPoint, 'id' | 'metricId'>
  onDelete?: () => void
}

const MetricDataPointDeleteButton: FC<Props> = ({ metricDataPoint = null, onDelete = () => {} }) => {
  const { strategyId } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const metricDataPointDelete = useStore.use.deleteMetricDataPoint()
  const { loaderQuery: storeLoaderQuery } = useStore.getState()
  const { id } = metricDataPoint || {}

  const deleteMetricDataPoint = () => {
    if (!id) {
      return false
    }

    metricDataPointDelete(id).then(() => {
      // TODO: This is a stupid way to do this, but it works until we move the stats onto the node
      if (strategyId) {
        storeLoaderQuery(Metric, { id: metricDataPoint.metricId, strategyId })
      }

      onDelete()
    })

    onClose()

    return true
  }

  return (
    <>
      <IconButton color="error" aria-label="Delete data point" icon={<FiTrash2 />} onClick={onOpen} variant="ghost" />

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete metric data point
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&#39;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} bgColor="error" onClick={deleteMetricDataPoint} variant="primary">
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default MetricDataPointDeleteButton
