import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'sm'
}

interface Props {
  path: string
}

const EventsButton: FC<Props> = ({ path }) => {
  const navigate = useNavigate()

  const openDrawer = () => {
    navigate(path)
  }

  return (
    <Tooltip label="Work events">
      <IconButton {...buttonStyles} aria-label="work events" icon={<ICON_MAP.Work />} onClick={openDrawer} />
    </Tooltip>
  )
}

export default EventsButton
