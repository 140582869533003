import { Button, HStack, Icon, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import { useEffect, useMemo } from 'react'
import { useFetcher } from 'react-router-dom'

import MetricIntegrationDeleteButton from './metricIntegrationDeleteButton'
import MetricIntegrationEditModal from './metricIntegrationEditModal'

import { METRIC_SOURCES } from '@app/lib/globals'
import Can from '@app/shared/authorization/can'
import EmptyState from '@app/shared/emptyState/emptyState'
import Table from '@app/shared/table'
import type { Column } from '@app/shared/table/types'
import type { CredentialsQuery } from '@graphql/queries'

type Credential = CredentialsQuery['credentials'][0]

const columns: Column<Credential>[] = [
  {
    header: '',
    key: 'name',
    cell: ({ data }) => {
      const { icon, color, display } = METRIC_SOURCES[data.sourceName] || {}

      if (!icon) {
        return data.displayName
      }

      return (
        <HStack>
          <Icon as={icon} boxSize="16px" color={color} />
          <Text>
            {display}
            {data.label ? <span> &mdash; {data.label}</span> : ''}
          </Text>
        </HStack>
      )
    }
  },
  {
    header: '',
    key: 'actions',
    cell: ({ data }) => (
      <Can I="update" a="credential">
        <Menu>
          <MenuButton as={Button}>...</MenuButton>
          <MenuList>
            <MetricIntegrationEditModal credential={data} />
            <MetricIntegrationDeleteButton credential={data} />
          </MenuList>
        </Menu>
      </Can>
    )
  }
]

const MetricIntegrationsSection = () => {
  const fetcher = useFetcher<CredentialsQuery>()

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/metrics/credentials?index')
    }
  }, [fetcher])

  const fetchData = fetcher.data?.credentials

  const creds = useMemo(() => {
    if (!Array.isArray(fetchData) || fetchData.length === 0) {
      return null
    }

    const sortable = fetchData.map((cred) => {
      const { display = '' } = METRIC_SOURCES[cred.sourceName] || {}
      return { ...cred, display }
    })

    return sortBy(sortable, ['display', 'label'])
  }, [fetchData])

  if (!fetcher.data) {
    return null
  }

  if (!creds || creds.length === 0) {
    return <EmptyState title="No metric integrations" />
  }

  return <Table<Credential> showHeader={false} columns={columns} data={creds} />
}

export default MetricIntegrationsSection
