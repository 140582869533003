import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'sm'
}

interface Props {
  path: string
}

const SourceButton: FC<Props> = ({ path }) => {
  const navigate = useNavigate()

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const openDrawer = () => {
    navigate(path)
  }

  return (
    <Tooltip label="Data source">
      <IconButton {...buttonStyles} aria-label="data source" icon={<ICON_MAP.DataSource />} onClick={openDrawer} />
    </Tooltip>
  )
}

export default SourceButton
