import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

const VersionCheck = () => {
  const { user } = useStoreCurrentUser()
  const [outOfDate, setOutOfDate] = useState<boolean>(false)

  useEffect(() => {
    const handleVersionCheck = (_event: CustomEvent) => {
      if (user) {
        setOutOfDate(true)
      }
    }

    window.addEventListener('outdatedClient', handleVersionCheck)

    return () => {
      window.removeEventListener('outdatedClient', handleVersionCheck)
    }
  }, [user])

  if (!outOfDate) {
    return null
  }

  return (
    <Modal isOpen onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <Alert flexDir="column" p={8} borderRadius="lg">
          <AlertTitle fontSize="xl">New version available!</AlertTitle>
          <AlertDescription mt={4}>
            <Stack spacing={8}>
              <Text fontSize="md">
                We&apos;ve made some updates to the app. Please refresh the page to get the latest version.
              </Text>
              <Button onClick={() => window.location.reload()} size="xl">
                Refresh
              </Button>
            </Stack>
          </AlertDescription>
        </Alert>
      </ModalContent>
    </Modal>
  )
}

export default VersionCheck
