import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import type { FC, MouseEventHandler } from 'react'
import { useRef, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import useToast from '@app/shared/toast'
import { useStore } from '@app/store'

interface Props {
  strategyId?: string
}

const StrategyDeleteButton: FC<Props> = ({ strategyId = null }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen: disclosureOnOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const toast = useToast()
  const deleteObject = useStore.use.deleteObject()

  const deleteStrategy = async () => {
    if (!strategyId) {
      return false
    }

    setIsLoading(true)
    const result = await deleteObject({ id: strategyId, classType: 'strategy' }, { updatePageCounts: true })
    setIsLoading(false)

    onClose()
    toast({ title: 'Deleted strategy' })

    return result
  }

  const onOpen: MouseEventHandler = (e) => {
    e.stopPropagation()
    disclosureOnOpen()
  }

  return (
    <>
      <Tooltip hasArrow label="Delete map">
        <IconButton
          color="error"
          aria-label="Delete map"
          data-cy="strategy-delete-button"
          icon={<FiTrash2 />}
          onClick={onOpen}
          variant="ghost"
        />
      </Tooltip>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete strategy
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&#39;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} bgColor="error" isLoading={isLoading} onClick={deleteStrategy} variant="primary">
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default StrategyDeleteButton
