import { Box, Button, Link as ChakraLink, Stack, Text, useClipboard } from '@chakra-ui/react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { Paths } from '@app/utils/routeHelpers'

const ShortcutWebhook = () => {
  const { user } = useStoreCurrentUser()
  const webhookUrl = Paths.shortcutWebhookUrlPath({ apiToken: user.organization.apiToken })
  const { hasCopied, onCopy } = useClipboard(webhookUrl)

  return (
    <Stack shouldWrapChildren spacing="4">
      <Text fontSize="sm">To install the webhook copy this webhook:</Text>
      <Box p={4} border="1px" borderColor="bg.muted" wordBreak="break-all">
        <Stack direction="column" spacing={4}>
          <Text fontSize="sm">{webhookUrl}</Text>
          <Box>
            <Button onClick={onCopy}>{hasCopied ? 'Copied!' : 'Copy to Clipboard'}</Button>
          </Box>
        </Stack>
      </Box>
      <Text fontSize="sm">
        Once you have the webhook copied, go back to Shortcut add it here:{' '}
        <ChakraLink color="link" href="https://app.shortcut.com/settings/integrations/outgoing-webhook" target="_blank">
          Shortcut Outgoing Webhooks
        </ChakraLink>
      </Text>
    </Stack>
  )
}

export default ShortcutWebhook
