import type { FC } from 'react'

import { RANDOM_DATA_STD_DEV, RANDOM_DATA_UPWARD_MOVE_PERCENTAGE } from '@app/lib/globals'
import { NumberInput } from '@app/shared/rawForms'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'id' | 'configuration'> | null
  isDisabled: boolean
}

const DummyData: FC<Props> = ({ metricSource, isDisabled }) => {
  const {
    std_deviation: stdDev = RANDOM_DATA_STD_DEV,
    upward_move_percentage: upwardMovePercentage = RANDOM_DATA_UPWARD_MOVE_PERCENTAGE
  } = metricSource?.configuration || {}

  return (
    <>
      <NumberInput
        isDisabled={isDisabled}
        name="configuration.std_deviation"
        label="Standard deviation"
        isRequired
        defaultValue={stdDev}
      />
      <NumberInput
        isDisabled={isDisabled}
        name="configuration.upward_move_percentage"
        label="Upward move percentage"
        isRequired
        defaultValue={upwardMovePercentage}
      />
    </>
  )
}

export default DummyData
