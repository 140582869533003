import { VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import EntityAttachmentUploadButton from './entityAttachmentUploadButton'
import { useUploadsContext } from './uploadsContext'

import type { OnUploadSuccess, ShrineHash } from '@app/types'

type Props = {
  children: ReactNode
  onUploadSuccess?: OnUploadSuccess
}

const NewEntityAttachmentUploadButton: FC<Props> = ({ children, onUploadSuccess }) => {
  const { uploads, dispatch } = useUploadsContext()

  const onUpload: OnUploadSuccess = (shrineHashString, file, response) => {
    const { uploadURL: url } = response

    if (url) {
      const shrineHash: ShrineHash = JSON.parse(shrineHashString)
      dispatch({ type: 'append', payload: { shrineHash, url } })
    }

    onUploadSuccess?.(shrineHashString, file, response)
  }

  return (
    <>
      <VisuallyHiddenInput
        name="attachmentHashes"
        readOnly
        value={JSON.stringify(uploads.map((upload) => upload.shrineHash))}
      />
      <EntityAttachmentUploadButton onUploadSuccess={onUpload}>{children}</EntityAttachmentUploadButton>
    </>
  )
}

export default NewEntityAttachmentUploadButton
