import type { FC, MutableRefObject } from 'react'

import type { Props as AmplitudeProps } from './amplitude/amplitude'
import Amplitude from './amplitude/amplitude'
import type { Props as CalculatedProps } from './calculated/calculated'
import Calculated from './calculated/calculated'
import type { Props as DummyDataProps } from './dummyData/dummyData'
import DummyData from './dummyData/dummyData'
import type { Props as GoogleAnalyticsV4Props } from './google/googleAnalyticsV4'
import GoogleAnalyticsV4 from './google/googleAnalyticsV4'
import type { Props as GoogleSheetsProps } from './google/googleSheets'
import GoogleSheets from './google/googleSheets'
import type { Props as LookerProps } from './looker/looker'
import Looker from './looker/looker'
import type { Props as LookerApiProps } from './lookerApi/lookerApi'
import LookerApi from './lookerApi/lookerApi'
import type { Props as MixpanelProps } from './mixpanel/mixpanel'
import Mixpanel from './mixpanel/mixpanel'
import type { Props as QueryEditorProps } from './query/queryEditor'
import QueryEditor from './query/queryEditor'
import type { Props as StripeProps } from './stripe/stripe'
import Stripe from './stripe/stripe'

import type { MetricSourceFormApi } from '@app/types'

type MetricSource = AmplitudeProps['metricSource'] &
  CalculatedProps['metricSource'] &
  DummyDataProps['metricSource'] &
  GoogleAnalyticsV4Props['metricSource'] &
  GoogleSheetsProps['metricSource'] &
  LookerProps['metricSource'] &
  LookerApiProps['metricSource'] &
  MixpanelProps['metricSource'] &
  QueryEditorProps['metricSource'] &
  StripeProps['metricSource']

interface Props {
  apiRef: MutableRefObject<MetricSourceFormApi>
  isDisabled: boolean
  metricSource: MetricSource | null
  sourceName: string
  credentialId: string
}

const ConfigureMetricSource: FC<Props> = ({ credentialId, apiRef, isDisabled, sourceName, metricSource }) => {
  switch (sourceName) {
    case 'MetricSources::Amplitude':
      return (
        <Amplitude credentialId={credentialId} apiRef={apiRef} isDisabled={isDisabled} metricSource={metricSource} />
      )
    case 'MetricSources::Calculated':
      return <Calculated metricSource={metricSource} isDisabled={isDisabled} />
    case 'MetricSources::DummyData':
      return <DummyData metricSource={metricSource} isDisabled={isDisabled} />
    case 'MetricSources::GoogleAnalyticsV4':
      return <GoogleAnalyticsV4 metricSource={metricSource} isDisabled={isDisabled} />
    case 'MetricSources::GoogleSheets':
      return <GoogleSheets apiRef={apiRef} metricSource={metricSource} isDisabled={isDisabled} />
    case 'MetricSources::Looker':
      return <Looker metricSource={metricSource} isDisabled={isDisabled} />
    case 'MetricSources::LookerApi':
      return (
        <LookerApi credentialId={credentialId} metricSource={metricSource} isDisabled={isDisabled} apiRef={apiRef} />
      )
    case 'MetricSources::Mixpanel':
      return <Mixpanel metricSource={metricSource} isDisabled={isDisabled} apiRef={apiRef} />
    case 'MetricSources::Stripe':
      return <Stripe metricSource={metricSource} isDisabled={isDisabled} />
    case 'MetricSources::GoogleBigQuery':
    case 'MetricSources::Mysql':
    case 'MetricSources::Mssql':
    case 'MetricSources::Postgres':
    case 'MetricSources::Redshift':
    case 'MetricSources::Snowflake':
      return <QueryEditor metricSource={metricSource} isDisabled={isDisabled} />
    default:
      return null
  }
}

export default ConfigureMetricSource
