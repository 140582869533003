import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react'
import type { ChangeEvent } from 'react'

import type { Metric } from '@graphql/queries'

type M = Pick<Metric, 'id' | 'name'>
type Props = {
  source: M
  target: M
  laggedId?: string | null
  setLaggedId?: (id: string) => void
  lagDays?: number | string
  setLagDays?: (days: string) => void
}

const LagInput: (props: Props) => JSX.Element = ({
  source,
  target,
  laggedId = null,
  setLaggedId = () => {},
  lagDays = 0,
  setLagDays = () => {}
}) => {
  const onChangeLaggedId = (e: ChangeEvent<HTMLSelectElement>) => {
    setLaggedId(e.target.value)
  }

  const onChangeLagDays = (e: ChangeEvent<HTMLInputElement>) => {
    setLagDays(e.target.value)
  }

  return (
    <>
      <FormControl key="lagged-id-field" data-testid="lag-input">
        <FormLabel htmlFor="lagged-id" requiredIndicator={null}>
          Lagging metric
        </FormLabel>
        <Select bgColor="bg.surface" name="lagged-id" onChange={onChangeLaggedId} value={laggedId}>
          <option value="">Do not apply lag</option>
          <option value={source.id}>{source.name}</option>
          <option value={target.id}>{target.name}</option>
        </Select>
      </FormControl>
      <FormControl key="lagged-days-field">
        <FormLabel htmlFor="lag-days-input" requiredIndicator={null}>
          Days of lag
        </FormLabel>
        <Input id="lag-days-input" onChange={onChangeLagDays} step="1" type="number" value={lagDays.toString()} />
      </FormControl>
    </>
  )
}

export default LagInput
