import { Icon, Box, Link as ChakraLink, Heading, Image, Skeleton, Stack, HStack, Text } from '@chakra-ui/react'
import Img from 'react-cloudimage-responsive'
import { FiArrowLeft } from 'react-icons/fi'
import { useAsyncValue, Link } from 'react-router-dom'

import Header from './components/header'
import PlaybookButtons from './components/playbookButtons'
import PlaybookMeta from './components/playbookMeta'

import WhatIsAPlaybook from '@app/pages/playbooks/components/whatIsAPlaybook'
import { PageHeader, PageStack } from '@app/shared/layout'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import withAwait from '@app/shared/withAwait'
import type { Playbook } from '@graphql/queries'

const Show = () => {
  const {
    data: { playbook }
  } = useAsyncValue() as { data: { playbook: Playbook } }
  const { description, hero, name, shortDescription } = playbook

  return (
    <PageStack pb={{ base: 12, md: 24, lg: 48 }}>
      <Header showPlaybooksCta={false} />
      <PageHeader title={name} subtitle={shortDescription}>
        <PlaybookButtons playbook={playbook} size="lg" />
      </PageHeader>
      <ChakraLink as={Link} to="/playbooks">
        <HStack>
          <Icon as={FiArrowLeft} />
          <Text>Playbook gallery</Text>
        </HStack>
      </ChakraLink>
      <Box
        p={{ base: 4, md: 8, lg: 10 }}
        bgGradient="linear(to-r, blue.100, blue.50)"
        borderRadius="sm"
        bgColor="bg.muted"
      >
        <Image
          as={Img}
          borderRadius={5}
          objectFit="cover"
          alt={name}
          // disableAnimation
          doNotReplaceURL={false}
          draggable={false}
          fallback={<Skeleton />}
          lazyLoading={false}
          src={hero}
        />
      </Box>
      <Heading color="emphasized" fontSize="2xl" fontWeight="medium">
        About this playbook
      </Heading>
      <Stack direction={{ base: 'column', md: 'row' }} spacing="16">
        <Box flex="1">
          <Stack spacing="8">
            <MarkdownDisplay text={description} />
            <WhatIsAPlaybook />
          </Stack>
        </Box>
        <Stack flex="1" maxW="sm" spacing={8}>
          <PlaybookMeta playbook={playbook} />
          <PlaybookButtons playbook={playbook} />
        </Stack>
      </Stack>
    </PageStack>
  )
}

export default withAwait(Show, 'playbook')
