import { Stack, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import LabelsInput from '@app/next/forms/inline/labelsInput'
import SelectInput from '@app/next/forms/inline/selectInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import OwnerPopover from '@app/next/ownerPopover'
import DetailsTable from '@app/pages/shared/detailsTable'
import Can from '@app/shared/authorization/can'
import CardEntityStatus from '@app/shared/cards/entity/cardEntityStatus'

const EntityDetails = () => {
  const { nodeId } = useParams()
  const entity = useGetObject(nodeId, 'entity')
  const { workflowState, foreignState, aiGenerated } = entity

  return (
    <Stack p={4} spacing={4}>
      <TableContainer>
        <DetailsTable variant="unstyled">
          <Tbody>
            <ViewerFieldWrapper value={entity.owner}>
              <Tr>
                <Td pt={0} pl={0} fontWeight="semibold">
                  Owner
                </Td>
                <Td pt={0} pr={0}>
                  <OwnerPopover object={entity} showName />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={entity.labels}>
              <Tr>
                <Td pl={0} fontWeight="semibold">
                  Labels
                </Td>
                <Td pr={0}>
                  <LabelsInput domainObject={entity} name="labels" />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={typeof entity.confidenceRating === 'number'}>
              <Tr>
                <Td pl={0} fontWeight="semibold">
                  Confidence
                </Td>
                <Td pr={0}>
                  <ConfidenceRatingPopover domainObject={entity} />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={foreignState}>
              <Tr>
                <Td pb={0} pl={0} fontWeight="semibold">
                  Status
                </Td>
                <Td pr={0} pb={0}>
                  <CardEntityStatus status={foreignState} workflowState={workflowState} />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <Can I="view" an="admin">
              <Tr>
                <Td pb={0} pl={0} fontWeight="semibold">
                  AI Generated
                </Td>
                <Td pr={0} pb={0}>
                  <SelectInput
                    domainObject={entity}
                    name="aiGenerated"
                    defaultValue={aiGenerated?.toString()}
                    placeholder={null}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </SelectInput>
                </Td>
              </Tr>
            </Can>
          </Tbody>
        </DetailsTable>
      </TableContainer>
    </Stack>
  )
}

export default EntityDetails
