import type { ButtonProps, IconProps } from '@chakra-ui/react'
import { Box, Button, Icon } from '@chakra-ui/react'
import type { FC, FormEvent } from 'react'
import type { IconType } from 'react-icons'

import { CSRFInput } from '@app/shared/forms'

type Props = {
  url?: string
  buttonTitle: string
  buttonIcon: IconType | FC<IconProps>
  buttonDisabled?: boolean
  buttonProps?: ButtonProps
  iconProps?: IconProps
}

const ProviderForm: FC<Props> = ({
  buttonTitle,
  buttonIcon,
  url = '',
  buttonDisabled = false,
  buttonProps = {},
  iconProps = {}
}) => {
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    // allow this to be used within another form, as it's fully self-contained
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.submit()
  }

  return (
    <Box as="form" action={url} method="post" onSubmit={onSubmit}>
      <CSRFInput />

      <Button
        w="100%"
        disabled={buttonDisabled}
        iconSpacing={3}
        leftIcon={<Icon as={buttonIcon} boxSize={4} {...iconProps} />}
        size="md"
        type="submit"
        variant="secondary"
        {...buttonProps}
      >
        {buttonTitle}
      </Button>
    </Box>
  )
}

export default ProviderForm
