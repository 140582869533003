import type { NodeTypes } from '@xyflow/react'

import BasicCardNode from './basicCardNode'
import CommentThreadNode from './commentThreadNode'
import EntityNode from './entityNode'
import ImageNode from './imageNode'
import MetricNode from './metricNode'
import NoteNode from './noteNode'
import SectionNode from './sectionNode'
import StrategyNode from './strategyNode'

const nodeTypes: NodeTypes = {
  basicCard: BasicCardNode,
  commentThread: CommentThreadNode,
  entity: EntityNode,
  metric: MetricNode,
  note: NoteNode,
  section: SectionNode,
  mapImage: ImageNode,
  strategy: StrategyNode
}

export default nodeTypes
