import { Box, Center, HStack, Icon, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

import { useMapCardFiltersModalContext } from './mapCardFiltersModalContext'

import type { NodeFiltersInput } from '@graphql/types'

const OptionIcon = () => (
  <Box w={4}>
    <Center>
      <Icon as={HiOutlineSearch} boxSize="4" color="gray.500" />
    </Center>
  </Box>
)

interface Props {
  name: keyof NodeFiltersInput
  displayName?: string
}

const FilterCardFacetNameOption: FC<Props> = ({ name, displayName = name }) => {
  const { setActiveFacetName } = useMapCardFiltersModalContext()

  const handleClick = () => {
    setActiveFacetName(name)
  }

  return (
    <Box
      h="45px"
      borderColor="transparent"
      borderLeftWidth="2px"
      _hover={{ borderColor: 'blue.600', bgColor: 'gray.50' }}
      onClick={handleClick}
    >
      <Box w="100%" px={4} py={3}>
        <HStack spacing={3}>
          <OptionIcon />
          <Text
            overflow="hidden"
            maxW="325px"
            color="gray.500"
            fontSize="sm"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            title={displayName}
          >
            Filter by {displayName}
          </Text>
        </HStack>
      </Box>
    </Box>
  )
}

export default FilterCardFacetNameOption
