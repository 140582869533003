import type { FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { SegmentedDataTypeEnum } from '@graphql/types'

type Props = Omit<SelectInputProps, 'children'>
const MetricSegmentedDataTypeSelect: FC<Props> = (props) => (
  <SelectInput {...props}>
    <option value={SegmentedDataTypeEnum.Sum}>Sum</option>
    <option value={SegmentedDataTypeEnum.Average}>Average</option>
  </SelectInput>
)

export default MetricSegmentedDataTypeSelect
