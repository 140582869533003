import type { FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { useLookerApiLookFieldsQuery } from '@graphql/queries'

type Props = Omit<SelectInputProps, 'children'> & {
  credentialId: string
  lookId: string
}

const LookFieldSelectInput: FC<Props> = ({ credentialId, lookId, isDisabled, ...selectInputProps }) => {
  const [{ data, fetching }] = useLookerApiLookFieldsQuery({
    variables: {
      credentialId,
      lookId
    },
    pause: !lookId
  })

  const options = data?.lookerApiLookFields || []

  return (
    <SelectInput {...selectInputProps} isDisabled={isDisabled || fetching}>
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </SelectInput>
  )
}

export default LookFieldSelectInput
