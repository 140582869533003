import { createStandaloneToast } from '@chakra-ui/react'
import pick from 'lodash/pick'
import type { RouteObject } from 'react-router-dom'

import Email from '@app/pages/onboarding/email'
import Organization from '@app/pages/onboarding/organization'
import User from '@app/pages/onboarding/user'
import SessionShell from '@app/shared/layout/shells/sessionShell'
import { Notification } from '@app/shared/toast'
import { requiresUserConfig } from '@app/utils/auth'
import { actionMutation } from '@graphql/client'
import { CaptureEmailDocument, CreateOrganizationDocument } from '@graphql/queries'

const { toast } = createStandaloneToast()

const captureEmail = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())
  const validFields = ['email', 'jobTitle']

  return actionMutation(CaptureEmailDocument, pick(input, validFields))
    .then((response) => {
      if (response.error) {
        throw response
      }

      const { success, errors } = response.data.userEmailCapture

      if (errors?.length) {
        throw errors
      }

      toast({
        title: 'Finalizing your account',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      window.location.href = '/'

      return { success }
    })
    .catch((resp) => ({ error: resp }))
}

const createOrganization = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())
  const validFields = ['name', 'homepageUrl']

  return actionMutation(CreateOrganizationDocument, pick(input, validFields))
    .then((response) => {
      if (response.error) {
        throw response
      }

      const { organization, errors } = response.data.organizationCreate

      if (errors?.length) {
        throw errors
      }

      toast({
        title: 'Creating your organization',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      window.location.href = '/playbooks'

      return { organization }
    })
    .catch((resp) => ({ error: resp }))
}

const routes: RouteObject = {
  path: 'onboarding',
  element: <SessionShell />,
  children: [
    {
      path: 'user',
      loader: () => requiresUserConfig((user, identityId) => !!identityId),
      element: <User />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return captureEmail(request)
          default:
            return null
        }
      }
    },
    {
      path: 'organization',
      element: <Organization />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return createOrganization(request)
          default:
            return null
        }
      }
    },
    {
      path: 'verify_email',
      loader: () => requiresUserConfig((user) => !user?.confirmedAt),
      element: <Email />
    }
  ]
}

export default routes
