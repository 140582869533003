import type { BoxProps } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  useDisclosure
} from '@chakra-ui/react'
import type { FC, MouseEventHandler, MutableRefObject, ReactNode } from 'react'
import { useCallback, useRef } from 'react'

import type { Props as TwoButtonProps } from '@app/shared/confirmPopoverFooter2Button'
import ConfirmPopoverFooter2Button from '@app/shared/confirmPopoverFooter2Button'

export type FooterProps = {
  onClose: () => void
  cancelRef: MutableRefObject<null>
  isLoading?: boolean
}

type Props = BoxProps &
  Partial<Pick<TwoButtonProps, 'onConfirm'>> & {
    header: ReactNode
    body?: ReactNode
    Footer?: FC<FooterProps>
    confirmButtonText?: ReactNode
    isLoading?: boolean
    onConfirm?: () => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
    onOpen?: () => void
    children: ReactNode
  }

const ConfirmPopover: FC<Props> = ({
  header,
  body = 'Are you sure?',
  Footer = null,
  confirmButtonText = 'Confirm',
  isLoading = false,
  onConfirm = async () => null,
  onOpen: propOnOpen = () => {},
  children,
  ...boxProps
}) => {
  const { isOpen, onOpen: disclosureOnOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  let foot: ReactNode
  if (Footer) {
    foot = <Footer cancelRef={cancelRef} onClose={onClose} isLoading={isLoading} />
  } else {
    foot = (
      <ConfirmPopoverFooter2Button isLoading={isLoading} onConfirm={onConfirm} onClose={onClose} cancelRef={cancelRef}>
        {confirmButtonText}
      </ConfirmPopoverFooter2Button>
    )
  }

  const onOpen: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation()
      disclosureOnOpen()
      propOnOpen()
    },
    [disclosureOnOpen, propOnOpen]
  )

  return (
    <>
      <Box onClick={onOpen} {...boxProps}>
        {children}
      </Box>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>{foot}</AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
export default ConfirmPopover
