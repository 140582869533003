import { Button, Grid, GridItem, Icon, Link as ChakraLink, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiCheck } from 'react-icons/fi'
import { Link } from 'react-router-dom'

interface Props {
  name: string
  description?: string
  icon: React.ElementType
  connected?: boolean
  path: string
  externalPath?: boolean
  buttonProps?: Record<string, unknown>
}

const IntegrationCard: FC<Props> = ({
  name,
  description = '',
  icon,
  connected = false,
  path = '',
  externalPath = false,
  buttonProps = {},
  ...rest
}) => {
  const buttonAs = externalPath ? ChakraLink : Link

  if (externalPath) {
    buttonProps.href = path
  } else {
    buttonProps.to = path
  }

  return (
    <Grid gap={4} templateRows="20px auto 50px" templateColumns="20px auto" {...rest}>
      <Icon as={icon} mt={1} color="default" />
      <GridItem colStart={2}>
        <Text as={buttonAs} {...buttonProps} color="emphasized">
          {name}
        </Text>
      </GridItem>
      <GridItem colStart={2}>
        <Text color="subtle">{description}</Text>
      </GridItem>
      <GridItem colStart={2}>
        <Button
          as={buttonAs}
          colorScheme={connected ? 'green' : 'gray'}
          leftIcon={connected ? <FiCheck /> : null}
          {...buttonProps}
        >
          {connected ? 'Connected' : 'Connect'}
        </Button>
      </GridItem>
    </Grid>
  )
}

export default IntegrationCard
