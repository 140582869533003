import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormLabel,
  HStack,
  Spacer,
  Stack,
  StackDivider,
  useColorMode,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react'
import type { FocusEventHandler, FC, FormEventHandler } from 'react'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import AiAssistantFiles from '@app/pages/maps/components/settings/components/aiAssistantFiles'
import CopyEmbedCodeButton from '@app/pages/maps/components/settings/components/copyEmbedCodeButton'
import DownloadDataButtons from '@app/pages/maps/components/settings/components/downloadDataButtons'
import PopulateMetricsWithDummyData from '@app/pages/maps/components/settings/components/populateMetricsWithDummyData'
import SendStrategyReviewButton from '@app/pages/maps/components/settings/components/sendStrategyReviewButton'
import StrategyFormAccess from '@app/pages/maps/components/settings/components/strategyFormAccess'
import StrategyDeleteButton from '@app/pages/maps/components/strategyDeleteButton'
import Can from '@app/shared/authorization/can'
import { FormAlert } from '@app/shared/forms'
import {
  Form,
  LabelAutocompleteInput,
  SlackAutocompleteInput,
  TextAreaInput,
  TextInput,
  ToggleInput,
  useForm
} from '@app/shared/rawForms'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'
import type { MapDomainStrategy } from '@app/types'

interface Props {
  isOpen?: boolean
  onClose?: () => void
}

const StrategySettings: FC<Props> = ({ isOpen = true, onClose = () => {} }) => {
  const { strategyId } = useParams()
  const strategy = useGetObject(strategyId, 'strategy')
  const updateObject = useStore.use.updateObject()
  const navigate = useNavigate()
  const { errors } = useForm({})
  const toast = useToast()
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  const navigateClose = () => {
    navigate('..')
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formValues = Object.fromEntries(formData.entries())
    const values: { id: string; labels?: string[] } = { ...formValues, id: strategyId }
    values.labels &&= JSON.parse(values.labels as unknown as string)

    try {
      await updateObject({ strategy: values }).then((response) => {
        const { errors: updateErrors, nodeObjects } = response?.data?.domainObjectUpdate || {}

        if (updateErrors.length) {
          throw new Error(updateErrors[0].message)
        }

        if (nodeObjects[0]) {
          const { labels } = nodeObjects[0] as MapDomainStrategy

          // @ts-expect-error labels are a massive time suck and should become real objects
          updateObject({ strategy: { id: strategyId, labels } }, true)
        }
      })

      toast({
        title: 'Updated Strategy',
        description: 'Successfully updated the strategy.'
      })

      onClose()

      navigateClose()
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: error.message
      })
    }
  }, [])

  const updateField = useCallback<FocusEventHandler<HTMLInputElement>>((e) => {
    const updateValue = {
      strategy: { id: strategyId, [e.target.name]: e.target.value }
    }

    return updateObject(updateValue)
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} onEsc={navigateClose} onOverlayClick={navigateClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <Form id="strategy-settings-form" onSubmit={onSubmit}>
          <ModalCloseButton onClick={() => navigate('..')} />
          <ModalHeader>Map Settings</ModalHeader>
          <ModalBody p={isDarkMode ? 2 : 0} bg="bg.surface">
            <FormAlert description={errors?.global?.message} title="Failed to update the strategy!" />
            <Tabs isFitted>
              <TabList>
                <Tab>General</Tab>
                <Tab>Sharing</Tab>
                <Tab>Downloads</Tab>
                <Tab>AI</Tab>
              </TabList>

              <TabPanels px={4}>
                <TabPanel>
                  <Stack divider={<StackDivider />} spacing={4}>
                    <Stack spacing={4}>
                      <TextInput
                        name="name"
                        label="Name"
                        placeholder="Map name"
                        defaultValue={strategy.name}
                        errors={errors}
                        onBlur={updateField}
                      />
                      <TextAreaInput
                        name="description"
                        label="Description"
                        placeholder="Describe the map."
                        defaultValue={strategy.description}
                        errors={errors}
                      />
                      <LabelAutocompleteInput
                        name="labels"
                        label="Labels"
                        defaultValue={strategy?.labels || []}
                        menuPortalTarget={document.body}
                      />
                      <Stack spacing={2}>
                        <SlackAutocompleteInput
                          helperText="Choose a Slack channel below to have a weekly Slack digest delivered each Friday at 9 a.m. PT."
                          name="slackChannelId"
                          label="Slack channel"
                          defaultValue={strategy.slackChannel}
                          errors={errors}
                        />
                        {strategy.slackChannel && <SendStrategyReviewButton strategy={strategy} />}
                      </Stack>
                    </Stack>
                    <ToggleInput
                      name="showCorrelations"
                      label="Show correlation scores"
                      defaultValue={strategy.showCorrelations}
                      errors={errors}
                    />
                    <Can I="update" a="metricSource">
                      <PopulateMetricsWithDummyData strategy={strategy} />
                    </Can>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack divider={<StackDivider />} spacing={4}>
                    <StrategyFormAccess strategy={strategy} />
                    <HStack>
                      <FormLabel fontSize="md">Embed code</FormLabel>
                      <Spacer />
                      <CopyEmbedCodeButton strategy={strategy} />
                    </HStack>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <DownloadDataButtons strategy={strategy} />
                </TabPanel>
                <TabPanel>
                  <Stack divider={<StackDivider />} spacing={4}>
                    <TextAreaInput
                      name="aiContext"
                      label="Context"
                      placeholder="Provide information that would be useful for our AI assistants to know."
                      defaultValue={strategy.aiContext}
                    />
                    <AiAssistantFiles strategy={strategy} />
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <StrategyDeleteButton strategyId={strategy.id} />
            <Spacer />
            <ButtonGroup>
              <Button onClick={navigateClose} variant="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}

export default StrategySettings
