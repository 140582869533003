import type { ButtonProps } from '@chakra-ui/react'
import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'

import useToast from '@app/shared/toast'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { MetricNodesGenerate } from '@graphql/documents/strategy.graphql'
import type { MetricNodesGenerateMutation, MetricNodesGenerateMutationVariables } from '@graphql/queries'

interface Props {
  node: Pick<MapDomainNode, 'nodeId'>
  children?: (props: Partial<ButtonProps>) => ReactNode
}

const buttonStyles = {
  borderRadius: 'sm'
}

const defaultChildren = ({ isLoading, onClick }) => (
  <IconButton
    {...buttonStyles}
    aria-label="Suggest downstream metrics"
    icon={<ICON_MAP.AiPrompt />}
    isLoading={isLoading}
    onClick={onClick}
  />
)

const SuggestDownstreamMetricsButton: FC<Props> = ({ node, children = defaultChildren }) => {
  const [isLoading, setIsLoading] = useState(false)
  const actionMutation = useStore.use.actionMutation()
  const toast = useToast()

  const onClick = async () => {
    setIsLoading(true)

    try {
      const result = await actionMutation<MetricNodesGenerateMutation, MetricNodesGenerateMutationVariables>(
        MetricNodesGenerate,
        {
          nodeId: node.nodeId
        }
      )

      if (result.error) {
        if (result.error.networkError) {
          toast({ title: 'Error', description: result.error.networkError.message, status: 'error' })
        } else {
          const errors = result.error.graphQLErrors.map((e) => e.message).join(', ')
          toast({ title: 'Error', description: errors, status: 'error' })
        }
      }
    } catch (e) {
      toast({ title: 'Error', description: e.message, status: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return <Tooltip label="Suggest downstream metrics">{children({ isLoading, onClick })}</Tooltip>
}

export default SuggestDownstreamMetricsButton
