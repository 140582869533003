import { Stack } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import { Card, CardHeader } from '@app/shared/layout'
import type { Props as CardProps } from '@app/shared/layout/card'

interface Props extends CardProps {
  title?: string
  subtitle?: string
  children: ReactNode
}

const FormContainer: FC<Props> = ({ title = null, subtitle = null, children, ...props }: Props) => (
  <Card isStack maxW="3xl" {...props}>
    {(title || subtitle) && <CardHeader title={title} subtitle={subtitle} />}
    <Stack spacing="5">{children}</Stack>
  </Card>
)

export default FormContainer
