import type { FormLabelProps } from '@chakra-ui/react'
import { FormLabel, VisuallyHiddenInput, FormControl } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback, useRef, useState } from 'react'

import CredentialSelect from './credentialSelect'
import type { Props as CredentialSelectProps } from './credentialSelect'

type Props = CredentialSelectProps & {
  labelSize?: FormLabelProps['fontSize']
  isDisabled: boolean
}

const CredentialInput: FC<Props> = ({
  isDisabled,
  credential: credentialProp,
  onSelect: propOnSelect,
  labelSize = 'md',
  ...selectProps
}) => {
  const { defaultSourceName } = selectProps
  const [credential, setCredential] = useState(credentialProp)
  const [sourceName, setSourceName] = useState(credential?.sourceName || defaultSourceName || '')
  const buttonRef = useRef<HTMLButtonElement>()

  const onSelect = useCallback<Props['onSelect']>(
    (newSourceName, newCredential) => {
      setSourceName(newSourceName)
      setCredential(newCredential)
      propOnSelect?.(newSourceName, newCredential)
    },
    [propOnSelect]
  )

  const onLabelClick = useCallback(() => {
    buttonRef.current?.click()
  }, [])

  return (
    <FormControl isDisabled={isDisabled}>
      <FormLabel fontSize={labelSize} onClick={onLabelClick}>
        Source
      </FormLabel>
      <CredentialSelect
        isDisabled={isDisabled}
        ref={buttonRef}
        credential={credential}
        onSelect={onSelect}
        {...selectProps}
      />
      <VisuallyHiddenInput name="sourceName" readOnly value={sourceName || ''} />
      <VisuallyHiddenInput name="credentialId" readOnly value={credential?.id || ''} />
    </FormControl>
  )
}

export default CredentialInput
