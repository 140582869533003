import type { IconButtonProps } from '@chakra-ui/react'
import type { MutableRefObject } from 'react'
import { forwardRef, useState } from 'react'

import AiFieldUpdateButton from '@app/pages/metrics/components/aiFieldUpdateButton'
import type { MarkdownEditorRef } from '@app/shared/rawForms/markdownEditor/markdownEditor'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'
import { MetricDescriptionSuggest } from '@graphql/documents/ai_assistant.graphql'
import type { MetricDescriptionSuggestMutation, MetricDescriptionSuggestMutationVariables } from '@graphql/queries'

type Props = Partial<IconButtonProps> & {
  metricId: string
}

const AiPopulateDescriptionButton: ReturnType<typeof forwardRef<MarkdownEditorRef, Props>> = forwardRef(
  ({ metricId, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(false)
    const actionMutation = useStore.use.actionMutation()
    const toast = useToast()

    const onClick = async () => {
      setIsLoading(true)

      try {
        const { data, error } = await actionMutation<
          MetricDescriptionSuggestMutation,
          MetricDescriptionSuggestMutationVariables
        >(MetricDescriptionSuggest, {
          metricId
        })

        if (error?.graphQLErrors?.length) {
          toast({
            title: 'Error',
            description: error.graphQLErrors[0].message,
            status: 'error'
          })

          return
        }

        // force this cast, as we know it's always an object (i.e. not a function)
        const forwardedRef = ref as MutableRefObject<MarkdownEditorRef>

        const result = data?.metricDescriptionSuggest?.result
        const { setValue } = forwardedRef?.current || {}

        if (setValue && result) {
          setValue(result)
        }
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <AiFieldUpdateButton isLoading={isLoading} onClick={onClick} data-cy="metric-description-suggest" {...props} />
    )
  }
)

export default AiPopulateDescriptionButton
