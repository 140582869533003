import { IconButton } from '@chakra-ui/react'
import type { FC, MouseEventHandler } from 'react'
import { useState } from 'react'
import { PiCopy } from 'react-icons/pi'
import { useRevalidator } from 'react-router-dom'

import { useMapCloneMutation } from '@graphql/queries'

interface Props {
  strategyId: string | null
}

const MapCloneButton: FC<Props> = ({ strategyId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [, mapClone] = useMapCloneMutation()

  const { revalidate } = useRevalidator()

  if (!strategyId) {
    return null
  }

  const onClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    setIsLoading(true)
    return mapClone({ input: { strategyId } })
      .then(revalidate)
      .finally(() => setIsLoading(false))
  }

  return (
    <IconButton
      aria-label="Copy strategy"
      colorScheme="gray"
      data-cy="map-clone-button"
      icon={<PiCopy />}
      isLoading={isLoading}
      onClick={onClick}
      variant="ghost"
    />
  )
}

export default MapCloneButton
