import type { InputProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

import BaseInput from './baseInput'
import type { Props as BaseInputProps } from './baseInput'

export type Props = Omit<BaseInputProps, 'children'> & InputProps

const TextInput: ReturnType<typeof forwardRef<HTMLInputElement, Props>> = forwardRef<HTMLInputElement, Props>(
  function TextInput(props, ref) {
    return <BaseInput {...props} ref={ref} type="text" />
  }
)

export default TextInput
