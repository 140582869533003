import type { FC, SuspenseProps } from 'react'
import { Suspense } from 'react'

type WithSuspenseWrapper = <T>(WrappedComponent: FC<T>, fallback?: SuspenseProps['fallback']) => FC<T>

const withSuspenseWrapper: WithSuspenseWrapper =
  (WrappedComponent, fallback = null) =>
  (props) => (
    <Suspense fallback={fallback}>
      <WrappedComponent {...props} />
    </Suspense>
  )

export default withSuspenseWrapper
