import { Button, useToast } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import type { useFetcher } from 'react-router-dom'

import type { GoogleIntegration } from '@graphql/types'

type GoogleProperty = {
  id: string
  display_name: string
}

type Props = {
  fetcher: ReturnType<typeof useFetcher>
  integration: Pick<GoogleIntegration, 'id'>
  property: GoogleProperty
}

const ImportPropertyMetricsButton: FC<Props> = ({ fetcher, integration, property }) => {
  const toast = useToast()
  const [disabled, setDisabled] = useState(false)

  const onClick = () => {
    setDisabled(true)

    fetcher.submit(
      { integrationId: integration.id, propertyId: property.id, propertyName: property.display_name },
      { method: 'post', action: '/settings/integrations/google/import-metrics' }
    )

    toast({
      title: 'Import started',
      description: 'Importing will begin momentarily. Please check your metrics shortly.'
    })
  }

  return (
    <Button isDisabled={disabled} isLoading={fetcher.state === 'submitting'} onClick={onClick}>
      Import metrics
    </Button>
  )
}

export default ImportPropertyMetricsButton
