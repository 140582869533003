import { Button, type ButtonProps, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

interface Props extends ButtonProps {
  text?: string | React.ReactNode
}

const CreateEntityButton: FC<Props> = ({ text = 'Create event', ...rest }) => {
  const { user } = useStoreCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()

  if (!user) {
    return null
  }

  const handleClick = () => {
    navigate('new', { state: { sender: location.pathname } })
  }

  return (
    <Tooltip label="Create event">
      <Button onClick={handleClick} {...rest}>
        {text}
      </Button>
    </Tooltip>
  )
}

export default CreateEntityButton
