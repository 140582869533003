import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import { useUploadsContext } from '../entities/uploadsContext'

import NewEntityActionBar from './newEntityActionBar'

import Gallery from '@app/shared/gallery'

type Props = {
  scrollContainer?: string
}

const NewEntityImages: FC<Props> = ({ scrollContainer }) => {
  const { uploads } = useUploadsContext()

  if (!uploads.length) {
    return null
  }

  return (
    <Stack>
      <Gallery images={uploads} scrollContainer={scrollContainer} ImageActionsComponent={NewEntityActionBar} />)
    </Stack>
  )
}

export default NewEntityImages
