import { Button } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useCallback } from 'react'

import type { FooterProps as BaseFooterProps } from '@app/shared/confirmPopover'
import ConfirmPopover from '@app/shared/confirmPopover'

export type Props = {
  onYes: () => void
  onNo: () => void
  children?: ReactNode
}

type FooterProps = BaseFooterProps & {
  onYes: () => void
  onNo: () => void
}

const Footer: FC<FooterProps> = ({ onYes, onNo, onClose, cancelRef }) => {
  const yes = () => {
    onYes()
    onClose()
  }

  const no = () => {
    onNo()
    onClose()
  }

  return (
    <>
      <Button onClick={yes} variant="primary">
        Yes
      </Button>
      <Button ml={2} onClick={no}>
        No
      </Button>
      <Button ref={cancelRef} ml={6} onClick={onClose}>
        Cancel
      </Button>
    </>
  )
}

const ClearDataPointsPopover: FC<Props> = ({ onYes, onNo, children }) => {
  const ThreeButtonFooter: FC<FooterProps> = useCallback(
    (props) => <Footer {...props} onYes={onYes} onNo={onNo} />,
    [onYes, onNo]
  )

  return (
    <ConfirmPopover
      header="Clear data points first?"
      body="Would you like us to clear all existing data points before we update the data?"
      Footer={ThreeButtonFooter}
    >
      {children}
    </ConfirmPopover>
  )
}

export default ClearDataPointsPopover
