import { HStack, Icon, Text, Th } from '@chakra-ui/react'
import type { FC } from 'react'
import type { IconType } from 'react-icons'
import { PiArrowDownBold, PiArrowUpBold } from 'react-icons/pi'

import { useSortHeaderContext } from '@app/shared/table/contexts/sortHeaderContext'
import type { SortDir } from '@app/types'

type Props = {
  title: string
  field: string
  emptyState: boolean
}

const nexts3State: Record<SortDir, SortDir> = {
  asc: 'desc',
  desc: 'asc',
  '': 'asc'
}

const nexts2State: Record<SortDir, SortDir> = {
  asc: 'desc',
  desc: 'asc',
  '': 'desc'
}

const icons: Record<SortDir, IconType> = {
  asc: PiArrowUpBold,
  desc: PiArrowDownBold,
  '': null
}

const SortHeader: FC<Props> = ({ title, field, emptyState }) => {
  const { state, dispatch } = useSortHeaderContext()
  const sortDir = state[field] || ''
  const nexts = emptyState ? nexts3State : nexts2State

  const onClick = () => {
    const value = nexts[sortDir]

    dispatch({ type: 'replace', payload: { [field]: value } })
  }

  const icon = icons[sortDir]
  const arrow = icon ? <Icon as={icon} fontSize="sm" data-cy={`icon-sort-${sortDir}`} /> : null

  return (
    <Th cursor="pointer" onClick={onClick}>
      <HStack>
        <Text>{title}</Text>
        {arrow}
      </HStack>
    </Th>
  )
}

export default SortHeader
