import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import { type FC, useRef } from 'react'
import { useRevalidator } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useMetricDeleteAllMetricDataPointsMutation } from '@graphql/queries'

interface Props {
  metric: {
    id: string
  }
}

const MetricDataPointsClearButton: FC<Props> = ({ metric, ...buttonProps }) => {
  const { user } = useStoreCurrentUser()
  const revalidator = useRevalidator()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [, deleteDataPoints] = useMetricDeleteAllMetricDataPointsMutation()

  const { id } = metric

  if (!user) {
    return null
  }

  const onSubmit = () =>
    deleteDataPoints({ input: { metricId: id } }).then(() => {
      onClose()
      revalidator.revalidate()
    })

  return (
    <>
      <Button aria-label="Clear data points" onClick={onOpen} {...buttonProps}>
        Clear data points
      </Button>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Clear data points
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete all the data for this metric? This can&apos;t be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button ml={3} bgColor="error" onClick={onSubmit} variant="primary">
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default MetricDataPointsClearButton
