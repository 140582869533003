import type { TypographyProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState, useRef } from 'react'

import ContributorsAutocomplete from '@app/shared/autocomplete/contributors/contributorsAutocomplete'
import type { Errors } from '@app/shared/rawForms/useForm'
import type { EntityContribution } from '@graphql/queries'

interface Props {
  name?: string
  label?: string | null
  labelSize?: TypographyProps['fontSize']
  placeholder?: string
  defaultValue?: Pick<EntityContribution, 'id' | 'contributor'>[]
  helperText?: React.ReactNode
  errors?: Errors
}

const ContributorAutocompleteInput: FC<Props> = ({
  name = '',
  label = null,
  labelSize = 'md',
  placeholder = '',
  helperText = null,
  errors = {},
  defaultValue = [],
  ...rest
}) => {
  const [contributors, setContributors] = useState(defaultValue)
  const ref = useRef()

  const handleChange = (newLabels) => {
    setContributors(newLabels)
  }

  return (
    <FormControl id={name} isInvalid={!!errors[name]}>
      <VisuallyHiddenInput ref={ref} id={name} name={name} readOnly value={JSON.stringify(contributors)} />
      <Stack>
        {label && (
          <FormLabel fontSize={labelSize} htmlFor={`${name}-autocomplete`}>
            {label}
          </FormLabel>
        )}
        <Stack w="100%">
          <ContributorsAutocomplete
            id={`${name}-autocomplete`}
            onChange={handleChange}
            inputRef={ref}
            placeholder={placeholder}
            defaultValue={defaultValue}
            {...rest}
          />
          <FormErrorMessage>{errors[name] && errors[name].message}</FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      </Stack>
    </FormControl>
  )
}

export default ContributorAutocompleteInput
