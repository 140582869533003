import type { FormControlProps, FormLabelProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import type { Errors } from '@app/shared/rawForms/useForm'

export type Props = FormControlProps & {
  name?: string
  label?: ReactNode
  labelSize?: FormLabelProps['fontSize']
  helperText?: ReactNode
  errors?: Errors
  children: ReactNode
}

const RootFormControl: FC<Props> = ({
  name,
  label,
  labelSize = 'md',
  helperText,
  errors = {},
  children,
  ...formControlProps
}) => (
  <FormControl isInvalid={!!errors[name]} {...formControlProps}>
    <Stack>
      {label && <FormLabel fontSize={labelSize}>{label}</FormLabel>}
      <Stack w="100%">
        {children}
        <FormErrorMessage>{errors[name] && errors[name].message}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    </Stack>
  </FormControl>
)

export default RootFormControl
