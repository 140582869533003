import { ButtonGroup, IconButton, Link } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiDownload } from 'react-icons/fi'

import Can from '@app/shared/authorization/can'
import EntityDeleteAttachmentButton from '@app/shared/entities/entityDeleteAttachmentButton'
import type { Attachment } from '@graphql/queries'

interface Props {
  attachment: Attachment
}

const ActionBar: FC<Props> = ({ attachment, ...rest }) => (
  <ButtonGroup size="xs" {...rest}>
    <IconButton as={Link} aria-label="Download image" href={attachment.url} icon={<FiDownload />} isExternal />
    <Can I="update" an="event">
      <EntityDeleteAttachmentButton attachmentId={attachment.id} entityId={attachment.entityId} />
    </Can>
  </ButtonGroup>
)

export default ActionBar
