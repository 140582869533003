import type { useCheckboxGroup } from '@chakra-ui/react'
import { Center, Checkbox, Th } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import type { EventHandler, FC, SyntheticEvent } from 'react'
import { useRef } from 'react'

import useGetObjectPage from '@app/hooks/useGetObjectPage'

type SelectAllCheckboxHeaderProps = {
  collectionType: Parameters<typeof useGetObjectPage>[0]
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
}

const SelectAllCheckboxHeader: FC<SelectAllCheckboxHeaderProps> = ({ collectionType, selected, setSelected }) => {
  const { collection } = useGetObjectPage(collectionType)
  const ref = useRef(null)

  const isChecked = isEqual(selected.sort(), collection.map((mdo) => mdo.id).sort())
  const onChange: EventHandler<SyntheticEvent> = (e) => {
    e.stopPropagation()
    if (ref.current.checked) {
      setSelected([])
    } else {
      setSelected(collection.map((mdo) => mdo.id))
    }
  }

  return (
    <Th onClick={onChange}>
      <Center>
        <Checkbox ref={ref} isChecked={isChecked} onChange={onChange} />
      </Center>
    </Th>
  )
}

export default SelectAllCheckboxHeader
