import { Box, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react'
import type { ChangeEventHandler, FC, PropsWithChildren } from 'react'
import { BiSearch } from 'react-icons/bi'

import { TextInput } from '@app/shared/rawForms'
import type { FormProps } from '@app/shared/rawForms/form'
import { RawForm } from '@app/shared/rawForms/form'

type Props = FormProps &
  PropsWithChildren<{
    onChange: ChangeEventHandler<HTMLFormElement>
  }>

const MapDrawerQueryForm: FC<Props> = ({ children, onChange, ...formProps }) => (
  <Box py={4}>
    <RawForm onChange={onChange} {...formProps}>
      <Stack py={4}>
        {children}
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <BiSearch />
          </InputLeftElement>
          <TextInput pl={8} name="query" placeholder="Find..." />
        </InputGroup>
      </Stack>
    </RawForm>
  </Box>
)

export default MapDrawerQueryForm
