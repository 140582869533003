import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import { ChakraReactSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import type { ChakraReactSelectProps, ReactSelectAutocompleteValue } from '@app/shared/autocomplete/chakraAutocompletes'
import RootFormControl from '@app/shared/rawForms/rootFormControl'
import { useLookerApiLooksQuery } from '@graphql/queries'

type Props = Omit<ChakraReactSelectProps<ReactSelectAutocompleteValue, false>, 'defaultValue'> & {
  credentialId: string
  defaultValue?: string
}

const LookSelectInput: FC<Props> = ({ credentialId, isDisabled, defaultValue, ...selectInputProps }) => {
  const [{ data, fetching }] = useLookerApiLooksQuery({
    variables: {
      credentialId
    }
  })

  const options = data?.lookerApiLooks || []

  const allOpts = sortBy(
    options.map(({ label, value }) => ({ label, value })),
    'label'
  )
  const defaultObject = defaultValue ? allOpts.find((opt) => opt.value === defaultValue) : null

  return (
    <RootFormControl label="Look" isDisabled={isDisabled || fetching} isRequired>
      <ChakraReactSelect
        isDisabled={isDisabled || fetching}
        initialValue={defaultObject}
        options={allOpts}
        placeholder="Select a look"
        noOptionsMessage="No matching looks found"
        isClearable={false}
        {...selectInputProps}
      />
    </RootFormControl>
  )
}

export default LookSelectInput
