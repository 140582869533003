import type { Dispatch } from 'react'
import { createContext } from 'react'

const MapSegmentFiltersModalContext = createContext({
  segmentFilters: [],
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  activeSegmentName: null,
  setActiveSegmentName: (() => {}) as Dispatch<unknown>,
  onFilter: (() => {}) as (segmentFilter: { id: string; value: string; active: boolean }) => void
  // onSelectSegmentName: () => {},
  // onSelectSegmentValue: () => {}
})

export default MapSegmentFiltersModalContext
