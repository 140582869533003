import type { ButtonProps, MenuItemProps } from '@chakra-ui/react'
import {
  Input,
  Button,
  ButtonGroup,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useState } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import track from '@app/lib/analyticsHelper'
import { FormContainer } from '@app/shared/forms'
import UppyFileInput from '@app/shared/forms/uppyFileInput'
import ToggleInput from '@app/shared/rawForms/toggleInput'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'
import { MetricUploadBatchFile, MetricUploadFile } from '@graphql/documents/metric.graphql'
import type { MetricUploadFileMutationVariables } from '@graphql/queries'
import type { Metric } from '@graphql/types'

// TODO: hardcoding both prop types is dirty. This could be worked around with
//       separate modals, or a bunch of conditionals.
type Props<OProps = ButtonProps & MenuItemProps> = OProps & {
  metric?: Pick<Metric, 'id'> | null
  OpenerComponent?: FC<OProps>
}

const MetricCSVModal: FC<Props> = ({ metric = null, OpenerComponent = Button, ...openerProps }) => {
  const [error, setError] = useState<string | null>(null)
  const [shrineHash, setShrineHash] = useState<string | null>(null)
  const { user } = useStoreCurrentUser()
  const toast = useToast()
  const actionMutation = useStore.use.actionMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()

      const formData = new FormData(e.currentTarget)
      const values = Object.fromEntries(formData.entries())
      const { clearExistingData, shrineHash: inputShrineHash } = values

      let input: Partial<MetricUploadFileMutationVariables['input']> = {
        clearExistingData: clearExistingData as string,
        shrineHash: inputShrineHash as string
      }
      let mutation = MetricUploadBatchFile

      if (metric?.id) {
        input = { ...input, metricId: metric.id }
        mutation = MetricUploadFile
      }

      try {
        await actionMutation<typeof mutation>(mutation, input)
        setShrineHash(null)

        onClose()

        toast({
          title: 'CSV Upload Successful!',
          description:
            'We will begin processing the file soon. Check a metric page after a few minutes to see the results.',
          status: 'success'
        })
      } catch (err) {
        setError(err.message)
      }
    },
    [metric]
  )

  if (!user) {
    return null
  }

  return (
    <>
      <OpenerComponent onClick={onOpen} {...openerProps}>
        Upload CSV
      </OpenerComponent>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload metric data via CSV</ModalHeader>
          <ModalCloseButton />
          <form id="metric-csv-form" onSubmit={onSubmit}>
            <ModalBody>
              <FormContainer>
                <Text>
                  For information on how to format your CSV, please see our{' '}
                  <Link
                    color="link"
                    href="https://docs.doubleloop.app/measure/upload-metrics-via-a-csv-file"
                    isExternal
                  >
                    help document.
                  </Link>
                </Text>

                <UppyFileInput
                  label="CSV"
                  isRequired
                  name="csv"
                  fileTypes={['text/csv']}
                  errored={!!error}
                  onUploadSuccess={(newShrineHash, file) => {
                    setShrineHash(newShrineHash)
                    track('csv:succeed', user, { filename: file.name })
                  }}
                  onUploadError={(file) => {
                    track('csv:fail', user, { filename: file?.name })
                  }}
                />
                <Input isRequired name="shrineHash" type="hidden" value={shrineHash} />
                <ToggleInput
                  name="clearExistingData"
                  label="Clear existing data points?"
                  defaultValue={false}
                  labelSize="sm"
                />
              </FormContainer>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    onClose()
                    setShrineHash(null)
                  }}
                  variant="secondary"
                >
                  Close
                </Button>
                <Button isDisabled={!shrineHash} type="submit" variant="primary">
                  Upload
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MetricCSVModal
