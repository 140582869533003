import { Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import type { Strategy } from '@graphql/queries'

interface Props {
  data: Strategy
}

const StrategyName: FC<Props> = ({ data: strategy }) => {
  const { id, name } = strategy

  return (
    <ChakraLink
      as={Link}
      data-cy="map-name"
      onClick={(e) => {
        e.stopPropagation()
      }}
      to={id}
    >
      <Stack>
        <Text minW="xs" fontSize="md" whiteSpace="normal" wordBreak="break-all" title={name}>
          {truncate(name, { length: 100 })}
        </Text>
      </Stack>
    </ChakraLink>
  )
}

export default StrategyName
