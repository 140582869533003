import type { BoxProps } from '@chakra-ui/react'
import { Icon, IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { PiLockKeyFill } from 'react-icons/pi'

import { Card, CardTitle } from '../components'
import { CardProvider, useCardContext } from '../contexts/cardContext'

import DomainObjectStatusPopover from '@app/next/domainObjectStatusPopover'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import cardFontSize from '@app/shared/cards/cardFontSize'
import CardLabels from '@app/shared/cards/components/cardLabels'
import CardSection from '@app/shared/cards/components/cardSection'
import CardSharedActions from '@app/shared/cards/components/cardSharedActions'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainNode, MapDomainStrategy } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { SharingTypesEnum } from '@graphql/types'

interface Props extends BoxProps {
  strategy?: MapDomainStrategy
  node?: MapDomainNode
}

export const SubMapCard: FC<Props> = ({ strategy, node, ...rest }) => {
  const { size } = useCardContext()
  const { id, sharing, description } = strategy
  const fontSize = cardFontSize('sm', size)

  const lockedDisplay = sharing !== SharingTypesEnum.PublicAccess && (
    <Tooltip
      hasArrow
      label="This card will not display to logged out users unless you make it public."
      shouldWrapChildren
    >
      <IconButton
        cursor="default"
        aria-label="Sharing"
        icon={<Icon as={PiLockKeyFill} boxSize={size === 'normal' ? 5 : 8} color="fg.subtle" />}
        variant="ghost"
      />
    </Tooltip>
  )

  return (
    <Card {...rest}>
      <CardTypeSection title="Map" icon={ICON_MAP.StrategyMap} route={`preview/${id}`} size={size} />
      <CardSection size={size}>
        <CardTitle domainObject={strategy} name="name" size={size} />
        {description && <MarkdownDisplay text={description} fontSize={fontSize} />}
      </CardSection>
      <CardLabels fieldName="labels" domainObject={strategy} size={size} />
      <CardSharedActions domainObject={strategy} size={size}>
        <DomainObjectStatusPopover domainObject={strategy} mx={2} />
        {lockedDisplay}
      </CardSharedActions>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export const SubMapCardWithContext = memo(withBasicProvider(CardProvider)(SubMapCard))
