import { Stack, Text } from '@chakra-ui/react'
import type { StackProps } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

interface Props {
  showLabel?: boolean
  label: string
  children: ReactNode
  stackProps?: StackProps
}
const WithLabel: FC<Props> = ({ showLabel = false, label, children, stackProps }) => (
  <Stack spacing={1} {...stackProps}>
    {showLabel && (
      <Text fontSize="sm" fontWeight="semibold">
        {label}
      </Text>
    )}
    {children}
  </Stack>
)

export default WithLabel
