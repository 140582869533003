import { Button, ButtonGroup, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { ChangeEvent, FC, ReactNode } from 'react'
import { useState } from 'react'
import type { FormProps } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import YearSelectInput from '@app/pages/metrics/components/goals/yearSelectInput'
import { ButtonRow, FormAlert } from '@app/shared/forms'
import { Form, NumberInput, SelectInput, useForm, DateInput } from '@app/shared/rawForms'
import type { Props as UseFormProps } from '@app/shared/rawForms/useForm'
import type { Goal, Metric } from '@graphql/queries'
import { PeriodUnitEnum } from '@graphql/queries'

type Props = FormProps & {
  metric: Pick<Metric, 'id'>
  goal: Goal | null
  onSuccess?: UseFormProps['onSuccess']
}

const GoalForm: FC<Props> = ({ metric, goal, onSuccess = () => {}, ...rest }) => {
  const [periodUnit, setPeriodUnit] = useState<PeriodUnitEnum>(goal?.periodUnit || PeriodUnitEnum.All)
  const { errors } = useForm({ onSuccess })
  const navigate = useNavigate()

  const onPeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPeriodUnit(event.target.value as PeriodUnitEnum)
  }

  const onCancel = () => {
    navigate(-1)
  }

  const yearSelect = (
    <YearSelectInput
      label="Year"
      name="periodYear"
      defaultValue={goal?.periodYear || new Date().getFullYear()}
      endYear={new Date().getFullYear() + 3}
      placeholder={null}
      isRequired
    />
  )

  const periodSection = (): ReactNode => {
    switch (periodUnit) {
      case PeriodUnitEnum.All:
        return null
      case PeriodUnitEnum.Year:
        return yearSelect
      case PeriodUnitEnum.Quarter:
        return (
          <>
            <SelectInput label="Quarter" name="periodNumber" defaultValue={goal?.periodNumber || 1} isRequired>
              <option value={1}>Q1</option>
              <option value={2}>Q2</option>
              <option value={3}>Q3</option>
              <option value={4}>Q4</option>
            </SelectInput>
            {yearSelect}
          </>
        )
      case PeriodUnitEnum.Month:
        return (
          <>
            <SelectInput label="Month" name="periodNumber" defaultValue={goal?.periodNumber || 1} isRequired>
              <option value={1}>January</option>
              <option value={2}>February</option>
              <option value={3}>March</option>
              <option value={4}>April</option>
              <option value={5}>May</option>
              <option value={6}>June</option>
              <option value={7}>July</option>
              <option value={8}>August</option>
              <option value={9}>September</option>
              <option value={10}>October</option>
              <option value={11}>November</option>
              <option value={12}>December</option>
            </SelectInput>
            {yearSelect}
          </>
        )
      case PeriodUnitEnum.Custom:
        return (
          <>
            <DateInput
              name="startDate"
              label="Start date"
              placeholder="Start (optional)"
              errors={errors}
              defaultValue={goal?.startDate}
            />
            <DateInput
              name="endDate"
              label="End date"
              placeholder="End"
              errors={errors}
              defaultValue={goal?.endDate}
              isRequired
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <Form method="post" {...rest}>
      <Stack spacing={5}>
        {!goal && <VisuallyHiddenInput defaultValue={metric.id} name="metricId" />}
        {goal && <VisuallyHiddenInput defaultValue={goal.id} name="goalId" />}
        <FormAlert
          description={errors?.global?.message as string}
          title={`Failed to ${goal ? 'update' : 'create'} the goal!`}
        />
        <SelectInput label="Time Unit" name="periodUnit" defaultValue={periodUnit} isRequired onChange={onPeriodChange}>
          <option value={PeriodUnitEnum.All}>All Time</option>
          <option value={PeriodUnitEnum.Year}>Year</option>
          <option value={PeriodUnitEnum.Quarter}>Quarter</option>
          <option value={PeriodUnitEnum.Month}>Month</option>
          <option value={PeriodUnitEnum.Custom}>Custom date range</option>
        </SelectInput>
        {periodSection()}
        <NumberInput label="Starting value" name="startValue" defaultValue={goal?.startValue || 0} isRequired />
        <NumberInput label="Target value" name="targetValue" defaultValue={goal?.targetValue || 0} isRequired />
        <ButtonRow>
          <ButtonGroup>
            <Button onClick={onCancel} variant="secondary">
              Cancel
            </Button>

            <Button type="submit" variant="primary">
              Save
            </Button>
          </ButtonGroup>
        </ButtonRow>
      </Stack>
    </Form>
  )
}

export default GoalForm
