import type { FormLabelProps, InputProps, SelectProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Select, Stack } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import type { Errors } from '@app/shared/rawForms/useForm'

export type Props = SelectProps & {
  children: ReactNode
  name?: SelectProps['name']
  label?: ReactNode | string
  labelSize?: FormLabelProps['fontSize']
  isRequired?: boolean
  placeholder?: InputProps['placeholder'] | null
  helperText?: ReactNode | null
  errors?: Errors
}

const SelectInput: FC<Props> = ({
  name = '',
  label = '',
  labelSize = 'md',
  placeholder = 'Select an option',
  isRequired = false,
  helperText = null,
  errors = {},
  children,
  ...rest
}: Props) => (
  <FormControl id={name} isInvalid={!!errors[name]} isRequired={isRequired}>
    <Stack>
      {label && (
        <FormLabel fontSize={labelSize} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <Stack w="100%">
        <Select name={name} placeholder={placeholder} {...rest} w="100%">
          {children}
        </Select>
        <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    </Stack>
  </FormControl>
)

export default SelectInput
