import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'

import Sidebar from './sidebar'

interface DrawerSidebarProps {
  isOpen: boolean
  onClose(): void
}

const DrawerSidebar = ({ isOpen, onClose }: DrawerSidebarProps) => (
  <Drawer
    isFullHeight
    isOpen={isOpen}
    onClose={onClose}
    placement="left"
    // Only disabled for showcase
    preserveScrollBarGap
    trapFocus={false}
  >
    <DrawerOverlay />
    <DrawerContent zIndex="skipLink">
      <Sidebar />
    </DrawerContent>
  </Drawer>
)

export default DrawerSidebar
