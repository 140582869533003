import { VisuallyHiddenInput } from '@chakra-ui/react'
import type { ChangeEventHandler, FC } from 'react'

import isChartUrl from './isChartUrl'

import { TextInput } from '@app/shared/rawForms'
import type { Props as TextInputProps } from '@app/shared/rawForms/textInput'

type Props = Omit<TextInputProps, 'name' | 'ref'> & {
  onChange: ChangeEventHandler<HTMLInputElement>
  chart: string
}

const ChartInput: FC<Props> = ({ chart, onChange, ...rest }) => {
  const chartIsUrl = isChartUrl(chart || '')
  const chartField = chartIsUrl ? 'chart_url' : 'chart_id'
  const chartName = `configuration.${chartField}`
  const otherChartField = chartIsUrl ? 'chart_id' : 'chart_url'
  const otherChartName = `configuration.${otherChartField}`

  return (
    <>
      <TextInput name={chartName} onChange={onChange} value={chart || ''} {...rest} />
      <VisuallyHiddenInput defaultValue="" name={otherChartName} />
    </>
  )
}

export default ChartInput
