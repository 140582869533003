import { AvatarGroup, Avatar, Text, HStack } from '@chakra-ui/react'
import type { FC } from 'react'

import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize } from '@app/types'
import profileImage from '@app/utils/profileImage'
import type { EntityContribution } from '@graphql/queries'

interface Props {
  contributors?: EntityContribution[]
  size?: CardSize
}

const CardContributors: FC<Props> = ({ contributors = [], size = 'normal' }) => {
  const fontSize = cardFontSize('sm', size)

  if (!contributors || contributors.length === 0) {
    return null
  }

  return (
    <HStack spacing={1}>
      <AvatarGroup max={3} size={size === 'normal' ? 'xs' : 'sm'}>
        {contributors.map((contributor) => (
          <Avatar
            key={contributor?.id}
            name={contributor?.contributor?.name}
            src={profileImage(contributor?.contributor?.email)}
            title={contributor?.contributor?.name}
          />
        ))}
      </AvatarGroup>

      {contributors.length > 3 && (
        <Text color="#a0aec0" fontSize={fontSize}>
          + {contributors.length - 3} more
        </Text>
      )}
    </HStack>
  )
}

export default CardContributors
