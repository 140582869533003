import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiBook } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import Can from '@app/shared/authorization/can'
import { usePlaybookCreateFromStrategyMutation } from '@graphql/queries'
import type { Strategy } from '@graphql/types'

interface Props {
  strategy: Pick<Strategy, 'id'>
  show?: boolean
}

const PlaybookCreateButton: FC<Props> = ({ strategy, show = true }) => {
  const navigate = useNavigate()
  const { user } = useStoreCurrentUser()
  const { canEdit } = usePermissionsContext()
  const [, createPlaybook] = usePlaybookCreateFromStrategyMutation()

  if (!user || !show || !canEdit) {
    return null
  }

  const onClick = () =>
    createPlaybook({
      input: {
        strategyId: strategy.id
      }
    }).then(
      ({
        data: {
          playbookCreateFromStrategy: {
            playbook: { id }
          }
        }
      }) => {
        navigate(`/playbooks/${id}`)
      }
    )

  return (
    <Can I="create" a="playbook">
      <Tooltip hasArrow label="Create a playbook">
        <IconButton
          fontSize="lg"
          aria-label="Create playbook"
          colorScheme="gray"
          icon={<PiBook />}
          onClick={onClick}
          variant="ghost"
        >
          Create playbook
        </IconButton>
      </Tooltip>
    </Can>
  )
}

export default PlaybookCreateButton
