import { HStack, Link as ChakraLink, Tag } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import ContainmentTag from '@app/shared/entity/containmentTag'
import type { MapDomainEntity } from '@app/types'

export interface Props {
  entity: Pick<MapDomainEntity, 'id' | 'name' | 'entityType' | 'containerEntityContainments'>
  entityPath: string
}

const MinorEntity: FC<Props> = ({ entity, entityPath }) => (
  <HStack spacing={0}>
    <ChakraLink as={Link} to={entityPath}>
      {entity.name}
      {entity.entityType && <Tag ml={4}>{capitalize(entity.entityType)}</Tag>}
    </ChakraLink>
    {entity.containerEntityContainments?.map((containment) => (
      <ContainmentTag key={containment.id} ml={4} containment={containment} />
    ))}
  </HStack>
)

export default MinorEntity
