import { Icon, Link, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { TextInput } from '@app/shared/rawForms'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'apiKey'>
}

const StripeAuth: FC<Props> = ({ credential = {} }) => (
  <Stack>
    <TextInput
      name="apiKey"
      label={
        <>
          <Text display="inline">API key </Text>
          <Link verticalAlign="middle" color="link" href="https://stripe.com/docs/keys" isExternal>
            <Icon as={FiHelpCircle} boxSize="4" />
          </Link>
        </>
      }
      placeholder="Stripe API Key"
      isRequired
      autoComplete="off"
      defaultValue={credential?.apiKey}
      data-1p-ignore
    />
  </Stack>
)

export default StripeAuth
