import type { InputProps } from '@chakra-ui/react'
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useRef } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'

import type { Errors } from '@app/shared/rawForms/useForm'

interface Props extends InputProps {
  name: string
  label?: string
  labelSize?: string
  placeholder?: string
  isRequired?: boolean
  helperText?: ReactNode | null
  errors?: Errors
  inlineLink?: React.ReactNode
}

const PasswordInput: FC<Props> = ({
  name = 'password',
  label = 'Password',
  labelSize = 'md',
  placeholder = 'Enter a password',
  isRequired = false,
  helperText = null,
  errors = {},
  inlineLink = null,
  ...rest
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const inputRef = useRef(null)

  const onClickReveal = () => {
    onToggle()
    const input = inputRef.current
    if (input) {
      input.focus({ preventScroll: true })
      const length = input.value.length * 2
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length)
      })
    }
  }

  return (
    <FormControl id={name} isInvalid={!!errors[name]} isRequired={isRequired}>
      <Stack>
        {label && (
          <Flex justify="space-between">
            <FormLabel fontSize={labelSize} htmlFor={name}>
              {label}
            </FormLabel>
            {inlineLink}
          </Flex>
        )}
        <InputGroup>
          <InputRightElement>
            <IconButton
              bg="transparent !important"
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={isOpen ? <FiEyeOff /> : <FiEye />}
              onClick={onClickReveal}
              tabIndex={-1}
              variant="ghost"
            />
          </InputRightElement>
          <Input
            ref={inputRef}
            maxW={{ md: '3xl' }}
            autoComplete="current-password"
            name={name}
            placeholder={placeholder}
            required={isRequired}
            type={isOpen ? 'text' : 'password'}
            variant="outline"
            {...rest}
          />
        </InputGroup>
        <FormErrorMessage>{errors[name] && (errors[name].message as string)}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    </FormControl>
  )
}

export default PasswordInput
