import type { IconProps } from '@chakra-ui/react'
import { Icon, useToken } from '@chakra-ui/react'
import type { FC } from 'react'

import { COLOR_MAP } from '@app/utils/iconMap'
import { toSentenceCase } from '@app/utils/stringHelpers'
import type { EntityWorkflowStateEnum } from '@graphql/types'

interface WorkflowStateProps extends IconProps {
  workflowState: EntityWorkflowStateEnum
}

// Adapted from: https://www.smashingmagazine.com/2015/07/designing-simple-pie-charts-with-css/
const WorkflowStateIcon: FC<WorkflowStateProps> = ({ workflowState, ...rest }) => {
  const [unstarted, started, reviewing, finished] = useToken('colors', [
    `${COLOR_MAP.WorkflowStateUnstarted}.500`,
    `${COLOR_MAP.WorkflowStateStarted}.500`,
    `${COLOR_MAP.WorkflowStateReviewing}.500`,
    `${COLOR_MAP.WorkflowStateFinished}.500`
  ])
  let color = unstarted
  let percentage = 0

  if (workflowState === 'finished') {
    color = finished
    percentage = 100
  } else if (workflowState === 'reviewing') {
    color = reviewing
    percentage = 66
  } else if (workflowState === 'started') {
    color = started
    percentage = 33
  }

  return (
    <Icon viewBox="0 0 32 32" {...rest}>
      <g>
        <title>{toSentenceCase(workflowState)}</title>
        <circle
          cx="16"
          cy="16"
          r="4"
          fill="none"
          stroke={color}
          strokeWidth="8"
          strokeDasharray={`${percentage / 4} ${2 * Math.PI * 4}`}
          transform="rotate(-90 16 16)"
          style={{
            transition: 'stroke-dasharray 1s ease-in-out, stroke 1s'
          }}
        />
        <circle
          cx="16"
          cy="16"
          r="13"
          fill="none"
          stroke={color}
          strokeWidth="3"
          style={{
            transition: 'stroke 1s'
          }}
        />
      </g>
    </Icon>
  )
}

export default WorkflowStateIcon
