import isEmpty from 'lodash/isEmpty'
import type { FC, PropsWithChildren } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { JSONValue } from '@app/types'

type Props = PropsWithChildren<{
  value: JSONValue
}>

const ViewerFieldWrapper: FC<Props> = ({ children, value }) => {
  const { user } = useStoreCurrentUser()
  const editor = ['admin', 'editor'].includes(user?.role)

  if (!editor && (!value || isEmpty(value))) {
    return null
  }

  return children
}

ViewerFieldWrapper.displayName = 'InlinePermissionsWrapper'

export default ViewerFieldWrapper
