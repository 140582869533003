import { Button, Flex, FormControl, HStack, Stack } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FormEventHandler } from 'react'
import { useRef, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import { ButtonRow, FormAlert, FormContainer } from '@app/shared/forms'
import { EmailInput, SelectInput } from '@app/shared/rawForms'
import useToast from '@app/shared/toast'
import { RoleEnum, useInvitationCreateMutation } from '@graphql/queries'

const InvitationForm = () => {
  const { user } = useStoreCurrentUser()
  const [error, setError] = useState<Error | null>(null)
  const ref = useRef<HTMLInputElement>()
  const toast = useToast()
  const { revalidate } = useRevalidator()
  const [, invitationCreate] = useInvitationCreateMutation()

  const successCallback = (invitation) => {
    revalidate()

    toast({
      title: 'Invitation successful!',
      description: 'They should receive the email soon.',
      status: 'success'
    })

    ahoy.track('user:sent_invitation', {
      email: invitation.email
    })
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    setError(null)

    const formData = new FormData(event.currentTarget)
    const values = Object.fromEntries(formData.entries())

    try {
      await invitationCreate({
        input: {
          email: values.email as string,
          role: values.role as RoleEnum
        }
      })

      if (ref?.current) {
        ref.current.value = ''
      }

      successCallback(values)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Can I="create" an="invitation">
      <form onSubmit={onSubmit}>
        <FormContainer title="Invite users" subtitle="Add users to keep your team informed">
          <FormAlert description={error?.message} title="Failed to send the invitation!" />
          <FormControl>
            <Stack>
              <HStack>
                <Flex grow={1}>
                  <EmailInput
                    ref={ref}
                    name="email"
                    placeholder="Invite a colleague by email address"
                    isRequired
                    data-cy="invitation-email-input"
                  />
                </Flex>
                <Flex shrink={0}>
                  <SelectInput name="role" placeholder="" maxW="max-content" bgColor="bg.subtle">
                    {user.role === RoleEnum.Admin && <option value={RoleEnum.Admin}>Admin</option>}
                    <option value={RoleEnum.Editor}>Editor</option>
                    <option value={RoleEnum.Viewer}>Viewer</option>
                  </SelectInput>
                </Flex>
              </HStack>
            </Stack>
          </FormControl>
          <ButtonRow bg="bg.subtle">
            <Button type="submit" variant="primary">
              Send invitation
            </Button>
          </ButtonRow>
        </FormContainer>
      </form>
    </Can>
  )
}

export default InvitationForm
