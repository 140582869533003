import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import type { FC, ReactElement } from 'react'
import { cloneElement } from 'react'

import ColorOptionLabel from './colorOptionLabel'

interface Props {
  value: string
  onClick?: (arg0: string) => void
  children: ReactElement
}

const ColorPickerPopover: FC<Props> = ({ value, onClick, children }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const trigger = cloneElement(children, { onClick: onToggle })

  const handleClick = (e: string) => {
    if (onClick) {
      onClick(e)
    }

    onToggle()
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody my={2}>
          <Stack spacing={2}>
            <ColorOptionLabel color="gray" label="none" value="" onClick={handleClick} isActive={value === ''} />
            <ColorOptionLabel color="red" onClick={handleClick} isActive={value === 'red'} />
            <ColorOptionLabel color="orange" onClick={handleClick} isActive={value === 'orange'} />
            <ColorOptionLabel color="yellow" onClick={handleClick} isActive={value === 'yellow'} />
            <ColorOptionLabel color="green" onClick={handleClick} isActive={value === 'green'} />
            <ColorOptionLabel color="teal" onClick={handleClick} isActive={value === 'teal'} />
            <ColorOptionLabel color="blue" onClick={handleClick} isActive={value === 'blue'} />
            <ColorOptionLabel color="cyan" onClick={handleClick} isActive={value === 'cyan'} />
            <ColorOptionLabel color="purple" onClick={handleClick} isActive={value === 'purple'} />
            <ColorOptionLabel color="pink" onClick={handleClick} isActive={value === 'pink'} />
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ColorPickerPopover
