import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import MetricDeleteButton from './metricDeleteButton'

import Can from '@app/shared/authorization/can'
import FavoriteToggleButton from '@app/shared/favorites/favoriteToggleButton'
import type { Metric } from '@graphql/queries'

interface Props {
  data: Metric
}

const MetricListActions: FC<Props> = ({ data }) => {
  const { id, favoriteId } = data

  return (
    <HStack spacing={0}>
      <Spacer />
      <FavoriteToggleButton favoritableType="Metric" favoritableId={id} favoriteId={favoriteId} />
      <Can I="update" a="metric">
        <IconButton
          as={Link}
          aria-label="Edit metric"
          colorScheme="gray"
          icon={<FiEdit2 />}
          to={`${data.id}/settings`}
          variant="ghost"
        />
        <MetricDeleteButton metricId={data.id}>
          {(props) => (
            <IconButton color="error" aria-label="Delete metric" icon={<FiTrash2 />} variant="ghost" {...props} />
          )}
        </MetricDeleteButton>
      </Can>
    </HStack>
  )
}

export default MetricListActions
