import { Button, ButtonGroup, Icon } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  strategyId: string
}

const ToggleMetricData: FC<Props> = ({ strategyId }) => {
  const strategy = useGetObject(strategyId, 'strategy')
  const updateObject = useStore.use.updateObject()
  const hideMetricData = strategy?.hideMetricData || false
  const buttonText = hideMetricData ? 'Show metric data' : 'Hide metric data'

  const toggleMetricData = () => {
    const updatedStrategy = { strategy: { id: strategy.id, hideMetricData: !hideMetricData } }

    updateObject(updatedStrategy, true)
  }

  return (
    <ButtonGroup colorScheme="gray" size="2xs" variant="ghost">
      <Button aria-label="Toggle metric data" leftIcon={<Icon as={ICON_MAP.ExpandAction} />} onClick={toggleMetricData}>
        {buttonText}
      </Button>
    </ButtonGroup>
  )
}

export default ToggleMetricData
