import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react'
import type { ResponsiveValue, StackProps } from '@chakra-ui/react'
import type { CSSProperties, FC } from 'react'

import { COLORS } from '@app/utils/metricHelpers'

type ColorKeys = keyof typeof COLORS

interface ColorKeyComponentProps {
  colorKey: ColorKeys
}

const ColorKeyComponent: FC<ColorKeyComponentProps> = ({ colorKey }) => {
  let text = null
  let alignment: ResponsiveValue<CSSProperties['textAlign']> = 'inherit'

  if (colorKey === 'negative') {
    alignment = 'left'
    text = -1
  } else if (colorKey === 'positive') {
    alignment = 'right'
    text = 1
  } else if (colorKey === 'neutral') {
    alignment = 'center'
    text = 0
  }

  return (
    <Text fontSize="sm" textAlign={alignment}>
      {text !== null ? text : <>&nbsp;</>}
    </Text>
  )
}

interface Props extends StackProps {}

const CorrelationScoreDescription: FC<Props> = ({ ...rest }) => (
  <Stack
    p={4}
    borderWidth={1}
    borderColor="border.default"
    borderRadius="md"
    spacing={4}
    {...rest}
    data-testid="correlation-score-description"
  >
    <Center>
      <Text fontSize="sm" fontWeight="semibold">
        Correlation Score Index
      </Text>
    </Center>
    <Box>
      <HStack justify="space-between" gap={0}>
        {Object.keys(COLORS)
          .reverse()
          .map((colorKey: ColorKeys) => {
            const { color } = COLORS[colorKey]

            return (
              <Stack key={color} w="100%" spacing={2}>
                <ColorKeyComponent colorKey={colorKey} />
                <Box h={2} bgColor={color}>
                  &nbsp;
                </Box>
              </Stack>
            )
          })}
      </HStack>
      <HStack justify="space-between" pt={1}>
        <Text fontSize="sm">Inversely correlated</Text>
        <Text fontSize="sm">Highly correlated</Text>
      </HStack>
    </Box>
  </Stack>
)

export default CorrelationScoreDescription
