import { Icon, Link, Text, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { PasswordInput, TextInput } from '@app/shared/rawForms'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'projectId' | 'workspaceId' | 'username'>
}

const MixpanelAuth: FC<Props> = ({ credential = {} }) => {
  const { projectId, workspaceId, username } = credential

  return (
    <Stack>
      <TextInput
        name="projectId"
        label={
          <>
            <Text display="inline">Project ID </Text>
            <Link
              verticalAlign="middle"
              color="link"
              href="https://help.mixpanel.com/hc/en-us/articles/115004490503-Project-Settings#project-id"
              isExternal
            >
              <Icon as={FiHelpCircle} boxSize="4" />
            </Link>
          </>
        }
        placeholder="Mixpanel Project ID"
        defaultValue={projectId}
        autoComplete="off"
        isRequired
        data-1p-ignore
      />
      <TextInput
        defaultValue={workspaceId}
        name="workspaceId"
        label="Workspace ID"
        autoComplete="off"
        placeholder="(Optional) Mixpanel Workspace ID"
        data-1p-ignore
      />
      <TextInput
        isRequired
        name="username"
        label="Username"
        placeholder="Mixpanel service account name"
        autoComplete="off"
        defaultValue={username}
        data-1p-ignore
      />
      <PasswordInput
        isRequired={!credential}
        name="password"
        label="Password"
        placeholder="Mixpanel service account password"
        autoComplete="off"
        helperText={credential ? 'Leave blank to keep the current password or fill in to set a new password' : null}
        data-1p-ignore
      />
      {Object.keys(credential || {}).length && <VisuallyHiddenInput defaultValue="password" name="secretFields" />}
    </Stack>
  )
}

export default MixpanelAuth
