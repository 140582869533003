import type { BoxProps } from '@chakra-ui/react'
import { Stack, StackDivider, useColorModeValue } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import { useCardContext } from '@app/shared/cards/contexts/cardContext'

export interface Props extends BoxProps {
  children: ReactNode
}

const Card: FC<Props> = ({ children, ...props }) => {
  const { color } = useCardContext()

  const divider = <StackDivider borderColor="border.emphasized" />
  const cardProps = {} as { borderBottomColor?: string; borderBottomWidth?: number }

  if (color) {
    cardProps.borderBottomColor = `${color}.400`
    cardProps.borderBottomWidth = 8
  }

  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      borderWidth="1px"
      borderStyle="solid"
      borderColor="border.emphasized"
      borderRadius="6px"
      shadow="sm"
      {...cardProps}
      {...props}
      divider={divider}
      spacing={0}
    >
      {children}
    </Stack>
  )
}

export default Card
