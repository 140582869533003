import type { FormLabelProps, StackDirection, SwitchProps } from '@chakra-ui/react'
import { FormControl, FormLabel, Spacer, Stack, Switch, VisuallyHiddenInput, useBoolean } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import type { Errors } from '@app/shared/rawForms/useForm'

export interface Props extends Omit<SwitchProps, 'defaultValue' | 'name' | 'onChange'> {
  name?: string | null
  label?: ReactNode | null
  labelSize?: Pick<FormLabelProps, 'fontSize'>['fontSize']
  isRequired?: boolean
  defaultValue?: boolean
  stackDirection?: StackDirection
  value?: string | number
  onChange?: (value: boolean) => void
  errors?: Errors
}

const ToggleInput: FC<Props> = ({
  name = null,
  label = null,
  labelSize = 'md',
  isRequired = false,
  stackDirection = 'row',
  defaultValue = false,
  onChange = () => {},
  ...switchProps
}: Props) => {
  const [isChecked, setIsChecked] = useBoolean(defaultValue)

  const handleChange = () => {
    onChange(!isChecked)
    setIsChecked.toggle()
  }

  return (
    <FormControl isRequired={isRequired}>
      <VisuallyHiddenInput name={name} readOnly value={isChecked.toString()} />
      <Stack direction={stackDirection}>
        {label && (
          <FormLabel mb="0" fontSize={labelSize}>
            {label}
          </FormLabel>
        )}
        <Spacer />
        <Switch isChecked={isChecked} onChange={handleChange} value={isChecked.toString()} {...switchProps} />
      </Stack>
    </FormControl>
  )
}

export default ToggleInput
