import { Outlet } from 'react-router-dom'

import { MatrixScoresContextProvider } from '@app/pages/correlations/components/matrixScoresContext'
import Show from '@app/pages/correlations/show'

// Not in use because the graphLoader does some strange stuff that we'll need to refactor
// eslint-disable-next-line no-unused-vars
// const loadCorrelationPair = async ({ params }) => {
//   const { data } = await loaderQuery(CorrelationPair, {
//     sourceId: params.sourceId,
//     targetId: params.targetId
//   })

//   return { correlate: data.organization.correlate }
// }

// const loadMatrix = async () => {
//   const matrixPromise = loaderQuery(OrganizationCorrelationPairs, {}, { requestPolicy: 'cache-first' })
//
//   return defer({ pairs: matrixPromise })
// }

const Layout = () => (
  <MatrixScoresContextProvider>
    <Outlet />
  </MatrixScoresContextProvider>
)

const routes = {
  path: 'correlations',
  element: <Layout />,
  children: [
    // {
    //   index: true,
    //   loader: loadMatrix,
    //   element: <Matrix />
    // },
    {
      path: 'graph/:sourceId/:targetId',
      element: <Show />
    }
  ]
}

export default routes
