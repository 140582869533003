import type { UppyEventMap, UppyOptions } from '@uppy/core'
import type { FC, ReactNode } from 'react'

import DoubleloopUploader from './doubleloopUploader'
import UploaderModal from './uploaderModal'

type Props = {
  title?: string
  children?: ReactNode
  uppyOptions?: UppyOptions
  onUploadSuccess?: UppyEventMap['upload-success']
  onComplete?: UppyEventMap['complete']
}

const DoubleloopUploaderModal: FC<Props> = ({
  title = 'Upload Files',
  children = null,
  uppyOptions = {},
  onUploadSuccess = () => {},
  onComplete = () => {}
}) => {
  const uploaderBody = (
    <DoubleloopUploader onUploadSuccess={onUploadSuccess} onComplete={onComplete} uppyOptions={uppyOptions} />
  )

  return (
    <UploaderModal title={title} uploaderBody={uploaderBody}>
      {children}
    </UploaderModal>
  )
}

export default DoubleloopUploaderModal
