import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useState } from 'react'

import LookFieldSelectInput from './lookFieldSelectInput'
import LookSelectInput from './lookSelectInput'

import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'

export type Props = {
  metricSource: Pick<MetricSource, 'configuration'> | null
  credentialId: string
  isDisabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
}

const LookerApi: FC<Props> = ({ apiRef, isDisabled, credentialId, metricSource }) => {
  const defaultLookId = metricSource?.configuration?.look_id || ''
  const [lookId, setLookId] = useState<string>(defaultLookId)

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => {
        setLookId(defaultLookId)
      },
      clear: () => {
        setLookId('')
      }
    }),
    [defaultLookId]
  )

  return (
    <>
      <LookSelectInput
        name="configuration.look_id"
        credentialId={credentialId}
        placeholder={null}
        defaultValue={defaultLookId}
        isDisabled={isDisabled}
        isRequired
        onChange={(look) => setLookId(look.value)}
      />
      {lookId && (
        <>
          <LookFieldSelectInput
            name="configuration.date_field"
            label="Date field"
            placeholder=""
            isDisabled={isDisabled}
            isRequired
            defaultValue={metricSource?.configuration?.date_field}
            credentialId={credentialId}
            lookId={lookId}
          />
          <LookFieldSelectInput
            name="configuration.value_field"
            label="Value field"
            placeholder=""
            isDisabled={isDisabled}
            isRequired
            defaultValue={metricSource?.configuration?.value_field}
            credentialId={credentialId}
            lookId={lookId}
          />
        </>
      )}
    </>
  )
}

export default LookerApi
