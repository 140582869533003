import type { ButtonProps } from '@chakra-ui/react'
import { MenuItem } from '@chakra-ui/react'
import type { FC } from 'react'

import { useRecurringReportConfigurationRunMutation } from '@graphql/queries'

type Props = ButtonProps & {
  recurringReportConfigurationId: string
}

const RecurringReportConfigurationRunButton: FC<Props> = ({ recurringReportConfigurationId, ...props }) => {
  const [, run] = useRecurringReportConfigurationRunMutation()

  const onClick = () =>
    run({
      input: {
        recurringReportConfigurationId
      }
    })

  return (
    <MenuItem onClick={onClick} {...props}>
      Run now
    </MenuItem>
  )
}

export default RecurringReportConfigurationRunButton
