import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  VisuallyHiddenInput
} from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import { type ChangeEventHandler, type FC, useCallback, useRef, useState } from 'react'
import { useFetcher } from 'react-router-dom'

import SourceConfiguration from './sourceConfiguration'
import SourceNameSelect from './sourceNameSelect'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { FetchForm } from '@app/shared/rawForms'
import type { Credential } from '@graphql/types'

type Props = Partial<ModalProps> & {
  credential?: Credential | null
  isOpen: boolean
}

const MetricIntegrationModal: FC<Props> = ({ credential, onClose: propOnClosed, ...props }) => {
  const [step, setStep] = useState(credential ? 1 : 0)
  const [sourceName, setSourceName] = useState(credential?.sourceName || '')
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'
  const formRef = useRef<HTMLFormElement>(null)
  const [isValid, setIsValid] = useState(!!credential)

  const { user } = useStoreCurrentUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    debounce<ChangeEventHandler<HTMLFormElement>>(() => {
      setIsValid(formRef.current?.checkValidity())
    }, 50),
    []
  )

  if (!user) {
    return null
  }

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const onClose = () => {
    propOnClosed()

    if (!credential) {
      setIsValid(false)
      setStep(0)
      setSourceName('')
    }
  }

  const closeAndReset = () => {
    onClose()
    formRef.current?.reset()
  }

  const stepParts = () => {
    switch (step) {
      case 0:
        return {
          body: <SourceNameSelect value={sourceName} onClick={setSourceName} />,
          footer: (
            <ButtonGroup>
              <Button isDisabled type="button" variant="secondary">
                Prev
              </Button>
              <Button as={Link} isDisabled={!sourceName} onClick={nextStep} type="button" variant="primary">
                Next
              </Button>
            </ButtonGroup>
          )
        }
      case 1:
        return {
          body: (
            <Box px={6}>
              <SourceConfiguration
                credential={credential}
                sourceName={sourceName}
                formRef={formRef}
                isValid={isValid}
                onChange={onChange}
              />
            </Box>
          ),
          footer: (
            <ButtonGroup>
              {!credential && (
                <Button onClick={prevStep} type="button" variant="secondary">
                  Prev
                </Button>
              )}
              <Button isDisabled={!isValid} isLoading={isLoading} type="submit" variant="primary">
                Save
              </Button>
            </ButtonGroup>
          )
        }
      default:
        return {
          body: null,
          footer: (
            <Button onClick={closeAndReset} type="button" variant="primary">
              Close
            </Button>
          )
        }
    }
  }

  const { body, footer } = stepParts()
  const method = credential ? 'patch' : 'post'
  const header = credential ? 'Edit metric integration' : 'Add metric integration'

  return (
    <Modal isCentered onClose={closeAndReset} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <FetchForm
          fetcher={fetcher}
          id="metric-integration-form"
          method={method}
          action="/settings/integrations/metrics/credentials"
          variant="drawer"
          onSubmit={(e) => {
            // This stopPropagation is necessary to prevent the other form on the page, MetricSourceCreate from submitting.
            e.stopPropagation()
            onClose()
          }}
          onChange={onChange}
          ref={formRef}
        >
          <VisuallyHiddenInput defaultValue={sourceName} name="sourceName" />
          {credential && <VisuallyHiddenInput defaultValue={credential.id} name="credentialId" />}
          <ModalBody px="0">{body}</ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </FetchForm>
      </ModalContent>
    </Modal>
  )
}

export default MetricIntegrationModal
