import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { RiImageAddFill } from 'react-icons/ri'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import type { Props as ClassificationsProps } from '@app/shared/entities/classifications'
import Classifications from '@app/shared/entities/classifications'
import ExistingEntityAttachmentUploadButton from '@app/shared/entities/existingEntityAttachmentUploadButton'
import NewEntityAttachmentUploadButton from '@app/shared/entities/newEntityAttachmentUploadButton'
import { EntityMarkdownView } from '@app/shared/entities/shared'
import { UploadsContextProvider } from '@app/shared/entities/uploadsContext'
import EntityImages from '@app/shared/entity/entityImages'
import NewEntityImages from '@app/shared/entity/newEntityImages'
import { ButtonRow, FormAlert } from '@app/shared/forms'
import {
  ContributorAutocompleteInput,
  DateInput,
  DateTimeInput,
  Form,
  LabelAutocompleteInput,
  RichInput,
  SelectInput,
  TextInput,
  useForm
} from '@app/shared/rawForms'
import type { FormProps } from '@app/shared/rawForms/form'
import type { MapDomainEntity } from '@app/types'
import type { Label } from '@graphql/types'
import { ImpactEnum, ImportanceEnum } from '@graphql/types'

const labelToPickerItem = (label) => ({ id: label, name: label, label })

type EntityClassifications = Partial<Pick<ClassificationsProps, 'classifications'>>
type EntityLabels = Partial<Label>[]

interface ClassificationsInputProps {
  entity: EntityClassifications
}

const ClassificationsInput: FC<ClassificationsInputProps> = ({ entity }) => (
  <FormControl>
    <Stack>
      <FormLabel fontSize="md">Classifications</FormLabel>
      <Box w="100%">
        <Classifications classifications={entity?.classifications} />
      </Box>
    </Stack>
  </FormControl>
)

const uploadButtonContent = (
  <Button aria-label="Upload Attachments" leftIcon={<RiImageAddFill />}>
    Upload image
  </Button>
)

interface EntityFormProps extends Omit<FormProps, 'children'> {
  entity?: Omit<
    MapDomainEntity,
    | 'organization'
    | 'commentsCount'
    | 'classifications'
    | 'thumbnail'
    | 'labels'
    | 'rfId'
    | 'containerEntityContainments'
  > &
    EntityClassifications & { labels?: EntityLabels }
  onCancel?: () => void
  onDelete?: () => void
  onSuccess?: () => void
  children?: FormProps['children']
}

const EntityForm: FC<EntityFormProps> = ({ entity = null, onCancel, onSuccess, children, ...rest }) => {
  const { strategyId, reportId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const labels = entity?.labels || location?.state?.labels?.map(labelToPickerItem) || []
  const { isOpen, onToggle } = useDisclosure()
  const { errors } = useForm({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()

        return
      }

      // TODO: fix this routing mess
      navigate(strategyId && !reportId ? '../../' : '..', { relative: 'path' })
    }
  })

  const handleCancel = () => {
    if (onCancel) {
      onCancel()

      return
    }

    navigate('..', { relative: 'path' })
  }

  const uploadButton = entity ? (
    <ExistingEntityAttachmentUploadButton entityId={entity.id}>
      {uploadButtonContent}
    </ExistingEntityAttachmentUploadButton>
  ) : (
    <NewEntityAttachmentUploadButton>{uploadButtonContent}</NewEntityAttachmentUploadButton>
  )

  const imagePreview = entity ? (
    <EntityImages entity={entity} scrollContainer="entity-form" />
  ) : (
    <NewEntityImages scrollContainer="entity-form" />
  )

  return (
    <UploadsContextProvider>
      <Form id="entity-form" method="post" {...rest} variant="drawer">
        <FormAlert
          description={(errors as { global: { message: string } })?.global?.message}
          title="Failed to edit the event!"
        />
        <Stack divider={<StackDivider />} spacing="5">
          <TextInput
            name="name"
            label="Title"
            placeholder="What happened?"
            data-cy="entity-name"
            isRequired
            defaultValue={entity?.name}
            errors={errors}
          />
          <DateTimeInput name="happenedAt" label="When did the event occur" defaultValue={entity?.happenedAt} />
          <DateInput
            name="dueDate"
            label="Due date"
            placeholder="Due date (optional)"
            errors={errors}
            defaultValue={entity?.dueDate}
          />
          <HStack>
            <Box w="50%">
              <SelectInput
                name="importance"
                label="Importance"
                placeholder=""
                defaultValue={entity?.importance}
                errors={errors}
                size="sm"
              >
                <option value={ImportanceEnum.Minor}>Minor</option>
                <option value={ImportanceEnum.Medium}>Medium</option>
                <option value={ImportanceEnum.Major}>Major</option>
              </SelectInput>
            </Box>
            <Box w="50%">
              <SelectInput
                name="impact"
                label="Impact"
                placeholder=""
                defaultValue={entity?.impact}
                errors={errors}
                size="sm"
              >
                <option value="">None</option>
                <option value={ImpactEnum.Positive}>Positive Impact</option>
                <option value={ImpactEnum.Negative}>Negative Impact</option>
              </SelectInput>
            </Box>
          </HStack>
          <Stack spacing={4}>
            <RichInput
              name="summary"
              label="Summary"
              placeholder="Details about what what happened"
              defaultValue={entity?.summary || ''}
              errors={errors}
            />
            {entity?.sourceSummary && (
              <>
                <Button onClick={onToggle}>{isOpen ? 'Hide' : 'Show'} Source Summary</Button>
                <Collapse in={isOpen}>
                  <Box p={4} bgColor="bg.muted" rounded="md">
                    <EntityMarkdownView field={entity.sourceSummary} />
                  </Box>
                </Collapse>
              </>
            )}
          </Stack>
          <RichInput
            name="goalHypothesis"
            label="Goal / Hypothesis"
            placeholder="What were you thinking might happen or hypothesis for the change"
            defaultValue={entity?.goalHypothesis || ''}
            errors={errors}
          />
          <RichInput
            name="results"
            label="Results"
            placeholder="What actually happened as a result"
            defaultValue={entity?.results || ''}
            errors={errors}
          />
          <TextInput
            name="entityType"
            label="Type"
            placeholder="chore, feature, fix, etc."
            defaultValue={entity?.entityType}
            errors={errors}
          />
          <TextInput
            name="linkTitle"
            label="URL title"
            placeholder="Demonstration"
            helperText="The title of the link to the demonstration"
            defaultValue={entity?.linkTitle}
            errors={errors}
          />
          <TextInput
            name="linkUrl"
            label="URL"
            placeholder="https://"
            defaultValue={entity?.linkUrl}
            errors={errors}
            helperText="The URL of the link of the demonstration"
          />
          {entity?.classifications?.length > 0 && <ClassificationsInput entity={entity} />}
          <LabelAutocompleteInput name="labels" label="Labels" defaultValue={labels} menuPortalTarget={document.body} />
          <ContributorAutocompleteInput
            name="contributors"
            label="Contributors"
            defaultValue={entity?.entityContributions}
          />
          {entity?.entityAttachmentsCount > 0 ? imagePreview : null}
          {children}
          <ButtonRow>
            <ButtonGroup>
              {uploadButton}
              <Button onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                {entity ? 'Update event' : 'Create event'}
              </Button>
            </ButtonGroup>
          </ButtonRow>
        </Stack>
      </Form>
    </UploadsContextProvider>
  )
}

export default EntityForm
