import type { FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { DisplayFormatEnum } from '@graphql/queries'

type Props = Omit<SelectInputProps, 'children'>

const MetricFormatSelect: FC<Props> = (props) => (
  <SelectInput {...props}>
    <optgroup label="Value formats">
      <option value={DisplayFormatEnum.Number}>Number</option>
      <option value={DisplayFormatEnum.Percent}>Percent</option>
    </optgroup>
    <optgroup label="Currency formats">
      <option value={DisplayFormatEnum.Usd}>U.S. Dollars</option>
      <option value={DisplayFormatEnum.Gbp}>British Pound Sterling</option>
      <option value={DisplayFormatEnum.Eur}>Euro</option>
    </optgroup>
    <optgroup label="Time formats">
      <option value={DisplayFormatEnum.Hours}>Hours</option>
      <option value={DisplayFormatEnum.Minutes}>Minutes</option>
      <option value={DisplayFormatEnum.Seconds}>Seconds</option>
    </optgroup>
  </SelectInput>
)

export default MetricFormatSelect
