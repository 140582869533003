import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type {
  ChakraAsyncCreatableSelectProps,
  ReactSelectAutocompleteValue
} from '@app/shared/autocomplete/chakraAutocompletes'
import LabelsAutocomplete from '@app/shared/autocomplete/labels/labelsAutocomplete'
import type { Errors } from '@app/shared/rawForms/useForm'
import { useStore } from '@app/store'
import type { MapDomainObject } from '@app/types'

const valueTransformer = (labels) => JSON.stringify(labels.map((lbl) => lbl.name))

export type AutocompleteLabel = { id: string | number; name: string }

export interface Props
  extends Partial<Omit<ChakraAsyncCreatableSelectProps<ReactSelectAutocompleteValue[]>, 'defaultValue'>> {
  label?: ReactNode | string
  domainObject: MapDomainObject
  errors?: Errors
  helperText?: ReactNode
}

const LabelsInput: FC<Props> = ({ domainObject, name, label, helperText, errors = {}, ...rest }) => {
  const [labels, setLabels] = useState(domainObject?.[name] || [])
  const { user } = useStoreCurrentUser()
  const updateLabels = useStore.use.updateLabels()

  useEffect(() => {
    if (domainObject?.[name] !== labels) {
      setLabels(domainObject?.[name])
    }
  }, [domainObject, labels, name])

  const handleChange = (value) => {
    const newLabels = value.map((newLabel) => ({ id: newLabel.value, name: newLabel.label }))

    setLabels(newLabels)
    updateLabels(domainObject, newLabels)

    return null
  }

  return (
    <FormControl id={name} isInvalid={!!errors[name]}>
      <VisuallyHiddenInput id={name} name={name} readOnly value={valueTransformer(labels)} />
      <Stack>
        {label && <FormLabel htmlFor={`${name}-autocomplete`}>{label}</FormLabel>}
        <Stack w="100%">
          <LabelsAutocomplete
            key={valueTransformer(labels)}
            className="nodrag"
            id={`${name}-autocomplete`}
            onChange={handleChange}
            defaultValue={labels}
            variant="unstyled"
            isReadOnly={!['admin', 'editor'].includes(user?.role)}
            chakraStyles={{
              inputContainer: (provided, _state) => ({
                ...provided,
                cursor: 'pointer'
              })
            }}
            components={{
              DropdownIndicator: () => null
            }}
            {...rest}
          />
          <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      </Stack>
    </FormControl>
  )
}

export default LabelsInput
