import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import type { FC } from 'react'
import { useFetcher } from 'react-router-dom'

import useFormSubmitAction from '@app/hooks/useFormSubmitAction'
import type { Strategy } from '@graphql/queries'

type Props = ButtonProps & {
  strategy: Pick<Strategy, 'id'>
}

const SendStrategyReviewButton: FC<Props> = ({ strategy, ...rest }) => {
  const { submit, state } = useFetcher()
  const routeId = useFormSubmitAction('map/send-review')
  const isLoading = state !== 'idle'

  const onClick = () => {
    submit({ strategyId: strategy.id }, { method: 'post', action: routeId })
  }

  return (
    <Button isLoading={isLoading} onClick={onClick} variant="secondary" {...rest}>
      Send to Slack now
    </Button>
  )
}

export default SendStrategyReviewButton
