import { Button, Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import UserForm from './components/userForm'

import AccountsList from '@app/pages/settings/user/components/accountsList'
import { CardHeader, PageStack } from '@app/shared/layout'
import Card from '@app/shared/layout/card'

export const Edit = () => (
  <Center>
    <PageStack>
      <Card isStack maxW="3xl">
        <CardHeader title="Profile" subtitle="User profile information" />
        <UserForm />
      </Card>
      <Card isStack maxW="3xl">
        <CardHeader title="Accounts" subtitle="Access to multiple organizations">
          <Button as={Link} to="/onboarding/organization">
            Create organization
          </Button>
        </CardHeader>
        <AccountsList />
      </Card>
    </PageStack>
  </Center>
)

export default Edit
