import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { Form } from 'react-router-dom'

import { FormAlert } from '@app/shared/forms'
import { CardHeader } from '@app/shared/layout'
import { TermsAndConditionsToggle, useForm } from '@app/shared/rawForms'

const UserForm: FC = () => {
  const { errors } = useForm({}) as { errors: { global?: { message?: string }; email?: string } }

  return (
    <Stack spacing="6">
      <CardHeader
        title="Almost there!"
        subtitle="Add your work email address and job title to finish setting up your account."
      />
      <Form id="user-form" method="post">
        <Stack spacing="5">
          <FormAlert description={errors?.global?.message} title="Failed to setup your account!" />

          <FormControl id="email" isInvalid={!!errors?.email} isRequired>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input autoComplete="email" id="email" name="email" placeholder="Enter your email" />
          </FormControl>
          <FormControl id="jobTitle">
            <FormLabel htmlFor="jobTitle">Job title</FormLabel>
            <Input id="jobTitle" name="jobTitle" placeholder="Enter your job title" />
          </FormControl>
          <TermsAndConditionsToggle name="termsAndConditions" />
        </Stack>
      </Form>
      <Stack spacing={4}>
        <Button form="user-form" size="md" type="submit" variant="primary">
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}

export default UserForm
