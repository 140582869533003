import { Icon, IconButton, type IconButtonProps, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Slack from '@app/images/slack'
import type { Strategy } from '@graphql/types'

interface Props extends IconButtonProps {
  strategy?: Strategy
}

const StrategySlackDisplay: FC<Props> = ({ strategy = null, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const { organization } = user

  if (!organization.slackable) {
    return (
      <Tooltip label="Connect live updates to Slack">
        <IconButton
          as={Link}
          fontSize="lg"
          aria-label="Slack integration"
          icon={<Icon as={Slack} />}
          to="/settings/integrations/slack"
          variant="ghost"
          {...rest}
        />
      </Tooltip>
    )
  }

  if (strategy?.slackChannelId) {
    return (
      <Tooltip label="Slack settings">
        <IconButton
          as={Link}
          fontSize="lg"
          aria-label="Slack settings"
          icon={<Icon as={Slack} />}
          to="map/settings"
          variant="ghost"
          {...rest}
        />
      </Tooltip>
    )
  }

  return null
}

export default StrategySlackDisplay
