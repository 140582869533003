import { Button, ButtonGroup, Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetNodes from '@app/hooks/useGetNodes'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

const MapExpandAllNodes: FC = () => {
  const { strategyId } = useParams<{ strategyId: string }>()
  const { canEdit } = usePermissionsContext()
  const nodes = useGetNodes(strategyId)
  const expandNodes = useStore.use.expandNodes()
  const collapsedNodes = Object.values(nodes).filter((node) => node.metadata?.collapsed)

  if (!canEdit) {
    return null
  }

  if (!collapsedNodes.length) {
    return null
  }

  const handleUnCollapseAll = () => {
    expandNodes(strategyId, collapsedNodes)
  }

  return (
    <ButtonGroup colorScheme="gray" size="2xs" variant="ghost">
      <Button
        aria-label="Uncollapse all nodes"
        leftIcon={<Icon as={ICON_MAP.ExpandAction} />}
        onClick={handleUnCollapseAll}
      >
        Uncollapse all
      </Button>
    </ButtonGroup>
  )
}

export default MapExpandAllNodes
