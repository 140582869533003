import type { UppyOptions } from '@uppy/core'
import type { FC, ReactNode } from 'react'

import AwsUploader from './awsUploader'

import UploaderModal from '@app/shared/uploader/uploaderModal'
import type { OnUploadSuccess } from '@app/types'

type Props = {
  title?: string
  children?: ReactNode
  uppyOptions?: UppyOptions
  onUploadSuccess?: OnUploadSuccess
}

const AwsUploaderModal: FC<Props> = ({
  title = 'Upload Photos',
  children = null,
  uppyOptions = {},
  onUploadSuccess = () => {}
}) => {
  const uploaderBody = <AwsUploader onUploadSuccess={onUploadSuccess} uppyOptions={uppyOptions} />

  return (
    <UploaderModal title={title} uploaderBody={uploaderBody}>
      {children}
    </UploaderModal>
  )
}

export default AwsUploaderModal
