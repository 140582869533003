import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import BasicCardDeleteButton from '@app/pages/basicCards/components/basicCardDeleteButton'
import Can from '@app/shared/authorization/can'
import type { BasicCard } from '@graphql/types'

interface Props {
  data: BasicCard
}

const BasicCardListActions: FC<Props> = ({ data }) => (
  <HStack spacing={0}>
    <Spacer />
    <Can I="update" a="metric">
      <BasicCardDeleteButton basicCardId={data.id}>
        {(props) => (
          <IconButton color="error" aria-label="Delete card" icon={<FiTrash2 />} variant="ghost" {...props} />
        )}
      </BasicCardDeleteButton>
    </Can>
  </HStack>
)

export default BasicCardListActions
