import { Stack, Center, Heading } from '@chakra-ui/react'

const EmbedTest = () => (
  <Stack p={4} spacing={4}>
    <Heading>Embed Test</Heading>
    <Center w="100%" h="100%" border="1px solid #999">
      <iframe
        title="Embed code test page"
        src={`${window.location.origin}/strategy/embed/3602/map`}
        width="100%"
        height="500px"
        frameBorder="0"
        allowFullScreen
      />
    </Center>
  </Stack>
)

export default EmbedTest
