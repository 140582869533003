import { Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'

import Task from './task'

import { Card, CardHeader } from '@app/shared/layout'
import type { Task as TaskType } from '@graphql/types'

interface Props {
  date: string
  tasks: TaskType[]
}

const TaskDateGroup: FC<Props> = ({ date, tasks }) => {
  const midDayDate = new Date(date)
  midDayDate.setHours(12, 0, 0, 0)

  return (
    <Card isStack>
      <CardHeader title={`Imports for ${Intl.DateTimeFormat().format(midDayDate)}`} />
      <Stack divider={<StackDivider />} spacing={6}>
        {tasks.map((task) => (
          <Task key={task.id} task={task} />
        ))}
      </Stack>
    </Card>
  )
}

export default TaskDateGroup
