import { Box, ListItem, Text, UnorderedList, useMultiStyleConfig } from '@chakra-ui/react'
import type { FC } from 'react'

import Entity from '@app/shared/entity'
import type { MapDomainEntity } from '@app/types'

interface Props {
  entities?: MapDomainEntity[]
  variant?: string
  colorScheme?: string
  size?: string
}

const MinorEntitiesList: FC<Props> = ({ entities = [], ...rest }) => {
  const { variant, colorScheme, size } = rest
  const styles = useMultiStyleConfig('AggregatedEntities', { variant, colorScheme, size })

  return (
    <Box>
      <Text sx={{ ...styles.minorEntitiesHeading }}>Minor updates</Text>
      <UnorderedList pb={4} spacing={1}>
        {entities.map((entity) => (
          <ListItem key={`entity-${entity.id}`}>
            <Entity entity={entity} isListView />
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  )
}

export default MinorEntitiesList
