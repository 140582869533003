import type { ButtonProps } from '@chakra-ui/react'
import { Button, Link } from '@chakra-ui/react'
import type { FC } from 'react'

import { Paths } from '@app/utils/routeHelpers'
import type { Metric } from '@graphql/queries'

type Props = ButtonProps & {
  metric: Pick<Metric, 'id'>
}
const MetricDownloadCSVButton: FC<Props> = ({ metric, ...props }) => (
  // as={Link} does not render correctly for attached Buttons in ButtonGroups
  <Button {...props}>
    <Link href={Paths.downloadMetricCSVPath({ metricId: metric.id })}>Download CSV</Link>
  </Button>
)

export default MetricDownloadCSVButton
