import type { StackProps } from '@chakra-ui/react'
import { HStack, Spacer, Stack, StackDivider } from '@chakra-ui/react'
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import type { LexicalEditor } from 'lexical'
import debounce from 'lodash/debounce'
import type { ChangeEvent, ChangeEventHandler, FC } from 'react'
import { useEffect, useRef } from 'react'

import RichTextInput from '@app/next/forms/inline/richTextInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import ChannelInput from '@app/pages/reports/components/channelInput'
import ReportDeleteButton from '@app/pages/reports/components/reportDeleteButton'
import { useForm, Form } from '@app/shared/rawForms'
import type { MapDomainReport } from '@app/types'
import { useReportUpdateMutation } from '@graphql/queries'

type Props = StackProps & {
  report: MapDomainReport
}

const ReportContent: FC<Props> = ({ report, ...rest }) => {
  const summary = report?.summary
  const summaryRef = useRef<LexicalEditor>(null)
  const [, updateReport] = useReportUpdateMutation()
  const { reset } = useForm({})

  // Update the summary when it changes in the background, so AI pipelines can update it.
  useEffect(() => {
    if (
      summary?.length > 0 &&
      summaryRef.current?.isEditable() &&
      // do not update if the editor is focused, as it makes editing it inline nearly impossible.
      summaryRef.current.getRootElement() !== document.activeElement
    ) {
      summaryRef.current.update(() => {
        $convertFromMarkdownString(summary, TRANSFORMERS)
      })
    }
  }, [summary])

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    updateReport({
      input: {
        reportId: report.id,
        [e.target.name]: e.target.value
      }
    })

  const handleSubmit: ChangeEventHandler<HTMLFormElement> = (event) => {
    const formData = new FormData(event.target.form)
    const input = Object.fromEntries(formData.entries())

    reset()

    return updateReport({
      input: {
        ...input,
        reportId: report.id
      }
    })
  }

  const debouncedSubmit = debounce((e) => handleSubmit(e), 200)

  return (
    <Stack px={8} pb={4} bgColor="bg.surface" {...rest}>
      <Stack mb={3} pb={2} borderBottom="1px" borderBottomColor="border.default">
        <HStack>
          <TextAreaInput
            domainObject={report}
            name="name"
            title={report.name}
            fontWeight="bold"
            wordBreak="break-word"
            fontSize="2xl"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur()
              }
            }}
          />
          <Spacer />
        </HStack>

        <ViewerFieldWrapper value={report?.summary}>
          <RichTextInput domainObject={report} name="summary" placeholder="Summary..." ref={summaryRef} />
        </ViewerFieldWrapper>
      </Stack>
      <Stack pb={4} divider={<StackDivider />} spacing={6}>
        <Form method="post" onChange={(e: ChangeEvent<HTMLFormElement>) => debouncedSubmit(e)} variant="drawer">
          <ChannelInput report={report} onChange={onChange} />
        </Form>
        <ReportDeleteButton reportId={report.id} />
      </Stack>
    </Stack>
  )
}

export default ReportContent
