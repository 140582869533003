import type { ChakraProps } from '@chakra-ui/react'
import { Icon, Box, Input, Text, useColorModeValue, useToken } from '@chakra-ui/react'
import { Handle, Position, useViewport } from '@xyflow/react'
import type { ResizeParams } from '@xyflow/react'
import isEmpty from 'lodash/isEmpty'
import type { ChangeEvent, FC } from 'react'
import { memo, useRef, useState, useEffect, useContext } from 'react'
import { MdOutlineDragIndicator } from 'react-icons/md'

import '@reactflow/node-resizer/dist/style.css'
import useGetNode from '@app/hooks/useGetNode'
import { handleStyles } from '@app/pages/maps/components/nodes/components/node'
import NodeResizer from '@app/pages/maps/components/nodes/components/nodeResizer'
import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import useInteraction from '@app/pages/maps/useInteraction'
import Can, { AbilityContext } from '@app/shared/authorization/can'
import { CardProvider } from '@app/shared/cards/contexts/cardContext'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import type { NodeObjectInput } from '@graphql/types'

const SectionNode: FC<MapDomainNode> = (rfNode) => {
  const { canEdit } = usePermissionsContext()
  const updateNodes = useStore.use.updateNodes()
  const { id, selected: nodeSelected } = rfNode
  // AT SOME POINT THIS STARTED COMING BACK AS UNDEFINED BY DEFAULT. FIGURING THAT OUT WILL ELIMINATE THIS HACK
  const selected = nodeSelected === undefined ? false : nodeSelected
  const node = useGetNode(id)
  const { strategyId } = node || {}
  const { handleSectionIntersections } = useInteraction(strategyId)

  const { zoom } = useViewport()
  const [resizingBorderColor] = useToken('colors', ['blue.500'])

  const editable = useContext(AbilityContext).can('update', 'strategy')

  const updateObject = useStore.use.updateObject()

  const [sectionHover, setSectionHover] = useState(false)
  const [inputFocus, setInputFocus] = useState(false)
  const inputRef = useRef(null)

  const borderColor = useColorModeValue('gray.500', 'gray.400')
  const textColor = useColorModeValue('gray.700', 'gray.300')

  const handleInputBlur = (event: KeyboardEventInit) => {
    if (event.key === 'Enter') {
      setInputFocus(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`${id}-input`)?.focus()
    }, 0)

    document.addEventListener('keydown', handleInputBlur)

    return () => {
      document.removeEventListener('keydown', handleInputBlur)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!node || !node.data || isEmpty(node.data)) {
    return null
  }

  const { width, height, data } = node

  const textStyle: ChakraProps = {
    pos: 'absolute',
    left: 0,
    color: textColor,
    fontSize: scaleSizeToMapZoom(20, zoom, 30),
    py: 1,
    pl: 3
  }

  const dragHandle = <Icon as={MdOutlineDragIndicator} color={textColor} fontSize={scaleSizeToMapZoom(20, zoom, 30)} />

  const elementBehaviors = {
    onDoubleClick: () => {
      setInputFocus(true)
    },
    onMouseEnter: () => setSectionHover(true),
    onMouseLeave: () => setSectionHover(false)
  }

  const textDisplay = (text, additionalProps = {}) => (
    <Text
      bottom={height}
      {...elementBehaviors}
      {...textStyle}
      {...additionalProps}
      maxW={width - 6}
      visibility={!inputFocus ? 'visible' : 'hidden'}
      data-testid="section-label"
    >
      {text}
    </Text>
  )

  const displayPlaceholder = data.name?.length === 0 && !inputFocus
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value

    updateObject({ section: { id: data.id, name } } as NodeObjectInput)
  }

  const onResizeEnd = (_, params: ResizeParams) => {
    const { width: newWidth, height: newHeight, x, y } = params

    // Rebuilding the node with new dimensions, position, and ensuring:
    // `type` of section to trigger correct intersection logic
    // and classType of `node` to update the server properly
    const sectionClone = {
      ...rfNode,
      id: node.id,
      nodeId: node.nodeId,
      classType: 'node',
      position: { x, y },
      measured: {
        width: Math.floor(newWidth),
        height: Math.floor(newHeight)
      },
      width: Math.floor(newWidth),
      height: Math.floor(newHeight)
    } as MapDomainNode

    const updatedNodes = handleSectionIntersections([sectionClone])

    updateNodes(strategyId, updatedNodes)
  }

  return (
    <>
      {inputFocus && selected && (
        <Input
          className="nodrag"
          ref={inputRef}
          zIndex="tooltip"
          top="-90px"
          bg="bg.surface"
          border="2px"
          borderColor={resizingBorderColor}
          autoFocus
          defaultValue={data.name}
          id={`${id}-input`}
          onBlur={() => setInputFocus(false)}
          onChange={handleChange}
          placeholder="Add Text"
          variant="unstyled"
          {...textStyle}
          data-testid="section-input"
        />
      )}
      {displayPlaceholder ? dragHandle : textDisplay(data.name)}
      <Can I="update" a="strategy">
        <NodeResizer strategyId={strategyId} nodeId={id} isVisible={selected && canEdit} onResizeEnd={onResizeEnd} />
      </Can>
      <Box pos="absolute" top="-35" w={`${width}px`} h="35px" {...elementBehaviors} />
      <Handle type="source" position={Position.Top} style={handleStyles(canEdit)} id="top" />
      <Handle type="source" position={Position.Left} style={handleStyles(canEdit)} id="left" />
      <Handle type="source" position={Position.Right} style={handleStyles(canEdit)} id="right" />
      <Handle type="source" position={Position.Bottom} style={handleStyles(canEdit)} id="bottom" />

      <Box
        w={`${width}px`}
        h={`${height}px`}
        //! the solid is a hack to make the border appear, please to now remove
        border={selected ? '3px solid' : '2px'}
        borderColor={selected || sectionHover ? 'blue.500' : borderColor}
        borderRadius="md"
        cursor={editable ? 'pointer' : 'grab'}
        transition="background-color .2s ease-in-out"
        {...elementBehaviors}
        data-testid="section-node"
      />
    </>
  )
}

export default memo(withBasicProvider(CardProvider)(SectionNode))
