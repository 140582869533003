import type { IconButtonProps } from '@chakra-ui/react'
import { useClipboard, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FiCheck, FiCopy } from 'react-icons/fi'

type Props = Partial<IconButtonProps> & {
  content: string
}

const CopyButton: FC<Props> = ({ content, ...rest }) => {
  const [copied, setCopied] = useState(false)
  const { onCopy } = useClipboard(content)

  const onClick = () => {
    setCopied(true)
    onCopy()
    setTimeout(() => setCopied(false), 1500)
  }

  return (
    <IconButton
      aria-label="Copy"
      icon={copied ? <FiCheck /> : <FiCopy />}
      onClick={onClick}
      variant="ghost"
      {...rest}
    />
  )
}

export default CopyButton
