import { Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'

import { TextInput, PasswordInput } from '@app/shared/rawForms'
import type { Credential } from '@graphql/types'

interface Props {
  serviceName: string
  credential?: Pick<Credential, 'host' | 'port' | 'database' | 'username'>
}

const OdbcAuth: FC<Props> = ({ serviceName, credential }) => (
  <Stack>
    <TextInput
      name="host"
      label="Host"
      placeholder={`${serviceName} host URL/IP`}
      isRequired
      autoComplete="off"
      defaultValue={credential?.host}
      data-1p-ignore
    />
    <TextInput
      name="port"
      label="Database port"
      placeholder={`${serviceName} port number`}
      isRequired
      autoComplete="off"
      defaultValue={credential?.port}
      data-1p-ignore
    />
    <TextInput
      name="database"
      label="Database name"
      placeholder="Database name"
      isRequired
      autoComplete="off"
      defaultValue={credential?.database}
      data-1p-ignore
    />
    <TextInput
      name="username"
      label="Username"
      placeholder="Service user name"
      isRequired
      autoComplete="off"
      defaultValue={credential?.username}
      data-1p-ignore
    />
    <PasswordInput
      name="password"
      label="Password"
      placeholder="Service user password"
      autoComplete="off"
      helperText={credential ? 'Leave blank to keep the current password or fill in to set a new password' : null}
      data-1p-ignore
    />
    {Object.keys(credential || {}).length && <VisuallyHiddenInput defaultValue="password" name="secretFields" />}
  </Stack>
)

export default OdbcAuth
