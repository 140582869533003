import type { FlexProps } from '@chakra-ui/react'
import { Flex, ButtonGroup } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'

type ButtonRowProps = PropsWithChildren<FlexProps>

const ButtonRow: FC<ButtonRowProps> = ({ children, ...flexProps }) => (
  <Flex direction="row-reverse" w="100%" py={2} {...flexProps}>
    <ButtonGroup>{children}</ButtonGroup>
  </Flex>
)

export default ButtonRow
