import { Center } from '@chakra-ui/react'

import OrganizationForm from './components/organizationForm'

import { PageHeader, PageStack } from '@app/shared/layout'

export const Edit = () => (
  <Center>
    <PageStack>
      <PageHeader title="Edit your organization" />
      <OrganizationForm />
    </PageStack>
  </Center>
)

export default Edit
