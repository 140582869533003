import { IconButton } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { type FC, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import ConfirmPopover from '@app/shared/confirmPopover'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'

type Props = {
  type: string
  selected: (string | number)[]
  onDelete?: () => void
}

const MultiDeleteButton: FC<Props> = ({ type, selected, onDelete = null }) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const bulkDelete = useStore.use.bulkDelete()
  const count = selected.length
  const humanizedType = type === 'basicCard' ? 'card' : type
  const pluralized = type === 'entity' ? 'work' : pluralize(humanizedType, count)
  const deleteObject = async () => {
    if (count === 0) {
      return
    }

    setIsLoading(true)

    try {
      await bulkDelete([{ [type]: selected }], { updatePageCounts: true })

      toast({ title: `Deleted ${count} ${pluralized}` })

      onDelete?.()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ConfirmPopover
      onConfirm={deleteObject}
      header={`Delete ${pluralized}`}
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
      isLoading={isLoading}
    >
      <IconButton
        color="error"
        aria-label={`Delete ${type}`}
        data-cy={`${type}-delete-button`}
        icon={<FiTrash2 />}
        variant="ghost"
      />
    </ConfirmPopover>
  )
}

export default MultiDeleteButton
