import type { StackProps } from '@chakra-ui/react'
import { HStack } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'

type Props = PropsWithChildren<StackProps>

const DrawerHeader: FC<Props> = ({ children, ...rest }) => (
  <HStack
    pos="sticky"
    zIndex="overlay"
    top={0}
    py={4}
    borderBottom="1px"
    borderBottomColor="border.default"
    bgColor="bg.muted"
    {...rest}
  >
    {children}
  </HStack>
)

export default DrawerHeader
