import type { TextareaProps } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react'
import { forwardRef } from 'react'

import RootFormControl from '@app/next/forms/rootFormControl'
import type { Props as RootFormControlProps } from '@app/next/forms/rootFormControl'

export type Props = Pick<RootFormControlProps, 'label' | 'labelSize' | 'helperText' | 'errors'> & TextareaProps

const TextAreaInput: ReturnType<typeof forwardRef<HTMLTextAreaElement, Props>> = forwardRef<HTMLTextAreaElement, Props>(
  function BaseInput(props, ref) {
    const { name, label, labelSize, isRequired, helperText, errors, ...rest } = props

    return (
      <RootFormControl
        name={name}
        label={label}
        labelSize={labelSize}
        isRequired={isRequired}
        helperText={helperText}
        errors={errors}
      >
        <Textarea name={name} {...rest} ref={ref} />
      </RootFormControl>
    )
  }
)

export default TextAreaInput
