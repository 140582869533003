import { Container } from '@chakra-ui/react'
import { useOutletContext } from 'react-router-dom'

import EntityForm from './components/entityForm'

const Create = () => {
  const optionContext: unknown[] = useOutletContext()

  let options = {}

  if (optionContext) {
    ;[, , , options] = optionContext
  }

  return (
    <Container py={8}>
      <EntityForm {...options} />
    </Container>
  )
}

export default Create
