import { Box, Stack, Text } from '@chakra-ui/react'
import { getIncomers, getOutgoers } from '@xyflow/react'
import { type FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetEdges from '@app/hooks/useGetEdges'
import useGetNodes from '@app/hooks/useGetNodes'
import useGetObjectsByIds from '@app/hooks/useGetObjectsByIds'
import { idAndTypeToRfId } from '@app/pages/maps/components/nodes/helpers'
import MetricGraph from '@app/pages/metrics/components/metricGraph'
import EmptyState from '@app/shared/emptyState/emptyState'
import { useStore } from '@app/store'
import type { MapDomainNode, Nodes } from '@app/types'

interface MetricImpactProps {
  node: MapDomainNode
  metricNodes: Pick<MapDomainNode, 'id'>[]
}

// I don't love this extra component, but it's the easiest way to get the metric
// since you can't use a useEffect inside the return.
const MetricImpact: FC<MetricImpactProps> = ({ node, metricNodes }) => {
  const metricIds = metricNodes.map((metricNode) => metricNode.id.substring(1))
  const metrics = useGetObjectsByIds('metric', metricIds)
  const shownMetrics = metrics.filter((metric) => metric.metricDataPointsCount > 0)

  if (!shownMetrics.length) {
    const cardType = node.type === 'basicCard' ? 'card' : node.type

    return <EmptyState title={`To see the impact, connect this ${cardType} to a metric with data.`} />
  }

  return (
    <Stack spacing={6}>
      {shownMetrics.map((metric) => (
        <Stack key={metric.id}>
          <Text color="subtle">{metric.name}</Text>
          <MetricGraph metric={metric} domainObject={{ id: node.data.id, type: node.type }} />
        </Stack>
      ))}
    </Stack>
  )
}

interface Props {
  nodeType: Nodes
}

const ImpactGraphs: FC<Props> = ({ nodeType }) => {
  const { strategyId, nodeId } = useParams()
  const nodes = useGetNodes(strategyId)
  const edges = useGetEdges(strategyId)
  const getNodeById = useStore.use.getNodeById()

  const node = getNodeById(idAndTypeToRfId(nodeId, nodeType))

  let metrics = []

  // When shown on a map, use the directly connected metrics of the node to get the metric graphs
  if (node) {
    const filter = (outgoer) => outgoer.type === 'metric'

    metrics = [
      ...getOutgoers(node, Object.values(nodes), Object.values(edges)).filter(filter),
      ...getIncomers(node, Object.values(nodes), Object.values(edges)).filter(filter)
    ]
  } else {
    return (
      <Box p={4}>
        <EmptyState title="To see the impact, add this node to your map." />
      </Box>
    )

    // When on the non-map page, maybe setup a new endpoint to get the ids of all
    // attached metrics, then use the use the following to get the metric graphs
  }

  if (!metrics) {
    const cardType = node.type === 'basicCard' ? 'card' : node.type

    return (
      <Box p={4}>
        <EmptyState title={`To see the impact, connect this ${cardType} to a metric with data.`} />
      </Box>
    )
  }

  return (
    <Box p={4}>
      <MetricImpact metricNodes={metrics} node={node} />
    </Box>
  )
}

export default ImpactGraphs
