import { Text } from '@chakra-ui/react'
import type { ChangeEventHandler, FC } from 'react'
import { useEffect, useState } from 'react'
import { useAsyncValue, useParams } from 'react-router-dom'
import type { OperationResult } from 'urql'

import { deriveType } from '../helpers'

import QueryExistingBasicCardFields from '@app/pages/maps/components/addCards/queryExisting/queryExistingBasicCardFields'
import QueryExistingEntityFields from '@app/pages/maps/components/addCards/queryExisting/queryExistingEntityFields'
import QueryExistingMetricFields from '@app/pages/maps/components/addCards/queryExisting/queryExistingMetricFields'
import MapDrawerQueryForm from '@app/pages/maps/components/drawer/mapDrawerQueryForm'
import withAwait from '@app/shared/withAwait'
import type { SourcesQuery } from '@graphql/queries'

interface Props {
  onChange: ChangeEventHandler<HTMLFormElement>
  type?: string
}

const QueryExistingCardForm: FC<Props> = ({ onChange, type: propType }) => {
  const { data: sourcesData } = useAsyncValue() as OperationResult<SourcesQuery>
  const { type: paramType } = useParams()
  const type = propType || paramType

  const [typeValue, setTypeValue] = useState(deriveType(type))

  useEffect(() => {
    setTypeValue(deriveType(type))
  }, [type])

  const sourcesDataSources = sourcesData?.sources

  const customFields = () => {
    switch (typeValue) {
      case 'metric':
        return <QueryExistingMetricFields sources={sourcesDataSources.metric} />
      case 'entity':
        return <QueryExistingEntityFields sources={sourcesDataSources.entity} />
      case 'basicCard':
        return <QueryExistingBasicCardFields />
      default:
        return null
    }
  }

  return (
    <MapDrawerQueryForm id="add-existing-form" data-testid="add-existing-form" onChange={onChange}>
      <Text color="subtle" fontSize="sm">
        Select cards from the library
      </Text>
      {customFields()}
    </MapDrawerQueryForm>
  )
}

export default withAwait(QueryExistingCardForm, 'integrationInfo')
