import { createContext, type FC, type ReactNode, useContext, useMemo } from 'react'

import { abilitiesFor } from '@app/shared/authorization/caslProvider'
import type { StrategyQueryStrategy } from '@app/types'
import type { User as UserType } from '@graphql/types'

interface Props {
  canEdit: boolean
  children: ReactNode
}

type User = Pick<UserType, 'abilities'> & {
  organization?: Pick<UserType['organization'], 'id'>
}

interface PermissionsContextWrapperProps {
  strategy: StrategyQueryStrategy
  user?: User | null
  children: ReactNode
}

const PermissionsContextProvider = createContext(undefined)

const PermissionsContext: FC<Props> = ({ canEdit, children }) => {
  const value = useMemo(
    () => ({
      canEdit
    }),
    [canEdit]
  )

  return <PermissionsContextProvider.Provider value={value}>{children}</PermissionsContextProvider.Provider>
}

const PermissionsContextWrapper: FC<PermissionsContextWrapperProps> = ({ strategy, user = null, children }) => {
  // Anonymous and Users from other Orgs are treated the same. This is getting moved to a better purpose
  // location.
  const userInStrategyOrganization = user && user.organization?.id === strategy.organization.id
  const isAnonymousUser = user === null || !userInStrategyOrganization

  // Renaming to something like `canEdit` would be more clear.
  const canEdit =
    !isAnonymousUser && abilitiesFor(user).can('update', 'strategy') && !strategy.backgroundChangeInProgress

  return <PermissionsContext canEdit={canEdit}>{children}</PermissionsContext>
}

const usePermissionsContext = () => {
  const context = useContext<Props>(PermissionsContextProvider)

  if (context === undefined) {
    throw new Error('usePermissionsContext must be used within PermissionsContext.Provider')
  }

  return context
}

export { PermissionsContext, usePermissionsContext }

export default PermissionsContextWrapper
