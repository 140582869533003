import { Box, FormControl, FormLabel, HStack, Spacer, Switch } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useState } from 'react'

import AccountsFetcher from '@app/fetchers/accountsFetcher'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import NameCell from '@app/pages/settings/organization/components/columns/nameCell'
import RoleCell from '@app/pages/settings/organization/components/columns/roleCell'
import UserActionsCell from '@app/pages/settings/organization/components/columns/userActionsCell'
import Table from '@app/shared/table'
import type { Column, ColumnCellProps, Data } from '@app/shared/table/types'

const columns: Column<Data>[] = [
  {
    header: 'Name',
    cell: NameCell as unknown as FC<ColumnCellProps<Data>>
  },
  {
    header: 'Role',
    cell: RoleCell
  },
  {
    header: '',
    cell: UserActionsCell as unknown as FC<ColumnCellProps<Data>>
  }
]

interface Props {}

const AccountsList: FC<Props> = () => {
  const { user } = useStoreCurrentUser()
  const [showInactive, setShowInactive] = useState(false)
  const organizationAccounts = useGetObjectsByProperties('account', {
    organizationId: user.organization?.id
  })

  let sortedAccounts = sortBy(organizationAccounts || [], ['disabledAt', 'name']).reverse()
  if (showInactive === false) {
    sortedAccounts = sortedAccounts.filter((account) => !account.disabledAt)
  }

  return (
    <>
      <AccountsFetcher showInactive={!showInactive} />
      <Box overflow="auto">
        <Table columns={columns} data={sortedAccounts as Data[]} />
      </Box>
      <FormControl>
        <HStack mt={4}>
          <Spacer />
          <FormLabel mb="0" fontSize="sm" htmlFor="show-inactive">
            Show inactive users
          </FormLabel>
          <Switch
            id="show-inactive"
            isChecked={showInactive}
            onChange={() => {
              setShowInactive(!showInactive)
            }}
          />
        </HStack>
      </FormControl>
    </>
  )
}

export default AccountsList
