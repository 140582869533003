import { Box, Container, Stack } from '@chakra-ui/react'
import groupBy from 'lodash/groupBy'
import type { FC } from 'react'
import { memo, useState } from 'react'

import TasksFetcher from '@app/fetchers/tasksFetcher'
import useGetObjectPage from '@app/hooks/useGetObjectPage'
import useGetObjects from '@app/hooks/useGetObjects'
import TaskDateGroup from '@app/pages/tasks/components/taskDateGroup'
import EmptyState from '@app/shared/emptyState/emptyState'
import Pagination from '@app/shared/pagination'

interface Props {}

const Tasks: FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { collection, metadata } = useGetObjectPage('task')
  const newTasks = useGetObjects('task')
  const tasks = collection
  const firstTaskId = tasks?.[0]?.id || '0'
  const appendNewlyCreatedTasks = (firstTaskId || isLoading === false) && metadata?.page === 1
  let fauxSubscriptionTasks = []

  // Since the task list is paginated, new store tasks created via subscription will not be displayed.
  // We grab all the tasks off the store and filter to just the ones with a higher ID than the first task in the list.
  // This only occurs on page 1 of the task list.
  if (appendNewlyCreatedTasks) {
    fauxSubscriptionTasks = newTasks.filter((task) => parseInt(task.id, 10) > parseInt(firstTaskId, 10))
  }

  // Group tasks by date and sort by id
  const groupedTasks = groupBy(
    fauxSubscriptionTasks.concat(tasks).sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)) || [],
    (task) => new Intl.DateTimeFormat().format(new Date(task.updatedAt))
  )

  return (
    <Box overflow="hidden" w="100%" h="100%" bgColor="bg.muted">
      <Box overflow="auto" w="100%" h="100%" p={4}>
        <Container maxW="3xl">
          <TasksFetcher isLoading={isLoading} setIsLoading={setIsLoading} />
          {isLoading === false && (!groupedTasks || Object.keys(groupedTasks).length === 0) && <EmptyState />}
          <Stack spacing={8}>
            {Object.entries(groupedTasks).map(([date, taskGroup]) => (
              <TaskDateGroup key={date} date={date} tasks={taskGroup} />
            ))}
          </Stack>
          <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
        </Container>
      </Box>
    </Box>
  )
}

export default memo(Tasks)
