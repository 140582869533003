import type { AvatarProps } from '@chakra-ui/react'

import type { CardSize } from '@app/types'

type CardAvatarSize = (defaultAvatarSize: AvatarProps['size'], cardSize: CardSize) => AvatarProps['size']
const cardAvatarSize: CardAvatarSize = (defaultAvatarSize, cardSize) => {
  if (cardSize === 'normal') {
    return defaultAvatarSize
  }

  switch (cardSize) {
    case 'large':
      return 'sm'
    default:
      return defaultAvatarSize
  }
}

export default cardAvatarSize
