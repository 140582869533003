import { Button, FormControl, FormLabel, FormErrorMessage, Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import { CSRFInput, FormAlert } from '@app/shared/forms'
import { useForm } from '@app/shared/rawForms'

const SamlOrganization: FC = () => {
  const { ssoIdentifier: ssoId } = useParams()
  const [ssoIdentifier, setSsoIdentifier] = useState(ssoId || '')
  const { errors } = useForm({})

  const onChange = (event) => setSsoIdentifier(event.target.value)

  return (
    <Stack w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} spacing="8">
      <SessionHeader
        title="Sign in with SSO"
        subtitle="Using email and password instead?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack spacing="6">
        <FormAlert description={errors?.global?.message} />
        <form id="sso-form" action={`/auth/saml/${ssoIdentifier}`} method="post">
          <Stack spacing="5">
            <FormControl id="sso_identifier" isInvalid={!!errors?.ssoIdentifier} isRequired>
              <FormLabel htmlFor="sso_identifier">Organization SSO Identifier</FormLabel>
              <Input
                defaultValue={ssoIdentifier}
                name="sso_identifier"
                onChange={onChange}
                placeholder="Organization SSO Identifier"
              />
              <FormErrorMessage>{errors?.ssoIdentifier?.message}</FormErrorMessage>
            </FormControl>
            <CSRFInput />
          </Stack>
        </form>
        <Button form="sso-form" size="md" type="submit" variant="primary">
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}

export default SamlOrganization
