import type { FC } from 'react'

import { fetchLabels, labelToPickerItem } from './labelsAutocompleteShared'

import type {
  ChakraAsyncCreatableSelectProps,
  ReactSelectAutocompleteValue
} from '@app/shared/autocomplete/chakraAutocompletes'
import { ChakraAsyncCreatableSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import { type AutocompleteLabel } from '@app/shared/rawForms/labelAutocompleteInput'

export interface Props
  extends Partial<Omit<ChakraAsyncCreatableSelectProps<ReactSelectAutocompleteValue[]>, 'defaultValue'>> {
  defaultValue?: AutocompleteLabel[]
}

const LabelsAutocomplete: FC<Props> = ({ defaultValue = [], onChange = () => {}, ...rest }) => (
  <ChakraAsyncCreatableSelect<ReactSelectAutocompleteValue[]>
    aria-label="labels-autocomplete"
    asyncFetch={(inputValue) => fetchLabels(inputValue)}
    placeholder="Labels..."
    onChange={onChange}
    initialValues={defaultValue?.map(labelToPickerItem) || []}
    {...rest}
  />
)

export default LabelsAutocomplete
