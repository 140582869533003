import type { InputProps } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { forwardRef } from 'react'

import RootFormControl from '@app/next/forms/rootFormControl'
import type { Props as RootFormControlProps } from '@app/next/forms/rootFormControl'

export type Props = Omit<RootFormControlProps, 'children'> & InputProps

const BaseInput: ReturnType<typeof forwardRef<HTMLInputElement, Props>> = forwardRef<HTMLInputElement, Props>(
  function BaseInput(props, ref) {
    const { name, label, labelSize, isRequired, helperText, errors, ...rest } = props

    return (
      <RootFormControl
        name={name}
        label={label}
        labelSize={labelSize}
        isRequired={isRequired}
        helperText={helperText}
        errors={errors}
      >
        <Input name={name} {...rest} ref={ref} />
      </RootFormControl>
    )
  }
)

export default BaseInput
