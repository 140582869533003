import { Box, Input, Stack, StackDivider } from '@chakra-ui/react'
import type { StackProps } from '@chakra-ui/react'
import Fuse from 'fuse.js'
import type { FC, ReactNode, ReactElement } from 'react'
import { Children, cloneElement, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import SearchListItem from '@app/shared/searchList/searchListItem'

interface Props extends Omit<StackProps, 'onChange'> {
  searchField: string
  children: ReactNode
  initialFocusRef?: React.RefObject<HTMLInputElement>
  currentValue?: string
  isLoading?: boolean
  onChange?: (arg0: string) => void
}

const SearchList: FC<Props> = ({
  searchField,
  children,
  initialFocusRef,
  currentValue,
  isLoading = false,
  onChange = () => {},
  ...rest
}) => {
  const [query, setQuery] = useState('')
  const childArray = Children.toArray(children)
  const [highlightedIndex, setHighlightedIndex] = useState(
    childArray.findIndex((child: ReactElement) => child.props.value === currentValue)
  )

  // Setup the fuse search
  const fuse = new Fuse(childArray, {
    keys: ['props.text']
  })
  const results: ReactElement[] =
    query !== '' ? fuse.search(query).map((result) => result.item as ReactElement) : (childArray as ReactElement[])

  // Upgrade the search list items to include hotkeys and click handlers
  const upgradeElement = (element: ReactElement, index: number) => {
    if (element.type !== SearchListItem) {
      return element
    }

    const upgradedProps = {
      key: index,
      hotkey: index.toString(),
      isSelected: element.props.value === currentValue,
      isHighlighted: index === highlightedIndex,
      onClick: () => {
        onChange(element.props.value)
      }
    }

    if (element.props.onClick) {
      delete upgradedProps.onClick
    }

    return cloneElement(element, upgradedProps)
  }

  const bodyContent = isLoading ? <SuspenseLoader m={4} /> : results.map(upgradeElement)

  // Attach hotkeys to the search list, and prevent the default behavior of the up/down arrow keys
  useHotkeys('up', () => {}, { preventDefault: true })
  useHotkeys('down', () => {}, { preventDefault: true })
  useHotkeys(
    'enter',
    () => {
      if (highlightedIndex !== -1) {
        onChange(results[highlightedIndex].props.value)
      }
    },
    {
      enableOnFormTags: true
    }
  )

  // If we've searched and the highlighted index is out of bounds, reset the highlighted index
  useEffect(() => {
    if (highlightedIndex > results.length - 1) {
      setHighlightedIndex(results.findIndex((result) => result.props.value === currentValue))
    }
  }, [childArray.length, currentValue, highlightedIndex, query, results])

  const handleSearchQuery = (e) => {
    setQuery(e.target.value)
  }

  const handleKeys = (e) => {
    const isAtEnd = initialFocusRef?.current?.selectionStart === query.length

    if (e.key === 'ArrowDown' && isAtEnd) {
      setHighlightedIndex(highlightedIndex === results.length - 1 ? 0 : highlightedIndex + 1)
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex(highlightedIndex === 0 ? results.length - 1 : highlightedIndex - 1)
    }
  }

  return (
    <Stack divider={<StackDivider />} spacing={0}>
      <Box px={2}>
        <Input
          ref={initialFocusRef}
          onChange={handleSearchQuery}
          onKeyDown={handleKeys}
          placeholder={`Change ${searchField}...`}
          size="sm"
          type="search"
          value={query}
          variant="unstyled"
        />
      </Box>
      <Stack overflow="auto" spacing={0} {...rest}>
        {bodyContent}
      </Stack>
    </Stack>
  )
}

export default SearchList
