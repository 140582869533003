import type { FC } from 'react'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { RollUpEnum } from '@graphql/queries'

type Props = Omit<SelectInputProps, 'children'> & {
  metricId: string | null
}

const MetricRollUpSelect: FC<Props> = ({ metricId, ...props }) => {
  const source = useGetObjectsByProperties('metricSource', { metricId })?.[0]

  const disableWeightedAverage = source?.sourceName !== 'MetricSources::Calculated'

  return (
    <SelectInput {...props}>
      <option value={RollUpEnum.Sum}>Sum</option>
      <option value={RollUpEnum.Average}>Average</option>
      <option value={RollUpEnum.Increase}>Amount Increased</option>
      <option disabled={disableWeightedAverage} value={RollUpEnum.WeightedAverage}>
        Weighted Average
      </option>
      <option value={RollUpEnum.LastMonthAvailable}>MoM, month over 3-month average, YoY</option>
    </SelectInput>
  )
}

export default MetricRollUpSelect
