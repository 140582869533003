import { HStack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import DateInput from '@app/next/forms/inline/dateInput'
import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize, MapDomainObject } from '@app/types'

interface Props {
  domainObject: MapDomainObject
  fieldName?: string
  size?: CardSize
  showLabel?: boolean
}

const CardDueDate: FC<Props> = ({ domainObject, fieldName, size = 'normal', showLabel = true }) => {
  const fontSize = cardFontSize('md', size)

  return (
    <HStack>
      {showLabel && (
        <Text color="fg.muted" fontSize={fontSize} fontWeight="bold" whiteSpace="nowrap">
          Due date
        </Text>
      )}
      <DateInput
        fontSize={fontSize}
        domainObject={domainObject}
        type="date"
        name={fieldName}
        defaultValue={domainObject?.[fieldName] || ''}
      />
    </HStack>
  )
}

export default CardDueDate
