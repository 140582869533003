import {
  Box,
  IconButton,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  HStack,
  Text
} from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

const PreviewModal: FC = () => {
  const { submapId: strategyId } = useParams()
  const navigate = useNavigate()

  const handleClose = () => {
    navigate('..')
  }

  return (
    <Modal autoFocus={false} isCentered isOpen onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={6} py={4}>
          <HStack>
            <Text fontSize="sm">Strategy Preview</Text>
            <Tooltip label="Go to map">
              <IconButton
                as={Link}
                aria-label="Go to map"
                colorScheme="gray"
                href={`/strategy/${strategyId}`}
                icon={<FiExternalLink />}
                isExternal
                size="2xs"
                variant="ghost"
              />
            </Tooltip>
          </HStack>
        </ModalHeader>

        <ModalCloseButton fontSize="2xs" />

        <ModalBody p={0} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.300">
          <Box>
            <iframe title="submap strategy" src={`/strategy/${strategyId}/map`} width="100%" height="600px" />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default memo(PreviewModal)
