import { Modal, ModalBody, ModalContent } from '@chakra-ui/react'
import delay from 'lodash/delay'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import CardFilters from './cardFilters'
import { filtersToInput } from './helpers'
import MapCardFiltersModalContext from './mapCardFiltersModalContext'
import type { MapCardFiltersModalContextType } from './mapCardFiltersModalContext'

import type { NodeFiltersInput } from '@graphql/types'

type Props = Partial<Pick<MapCardFiltersModalContextType, 'cardFilters' | 'isOpen' | 'onOpen' | 'onClose' | 'onFilter'>>

const CardFiltersModal: FC<Props> = ({ cardFilters = [], isOpen, onOpen = () => {}, onClose, onFilter = () => {} }) => {
  const [activeFacetName, setActiveFacetName] = useState<keyof NodeFiltersInput>(null)

  const handleClose = useCallback(() => {
    onClose()
    delay(() => {
      setActiveFacetName(null)
    }, 500)
  }, [onClose])

  const providerValue = useMemo(
    () => ({
      cardFilters,
      isOpen,
      onOpen,
      onClose: handleClose,
      activeFacetName,
      setActiveFacetName,
      onFilter
    }),
    [cardFilters, isOpen, onOpen, handleClose, activeFacetName, onFilter]
  )

  const filtersInput = useMemo(() => filtersToInput(cardFilters), [cardFilters])

  return (
    <MapCardFiltersModalContext.Provider value={providerValue}>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalContent shadow="dark-lg">
          <ModalBody p={0}>
            <CardFilters filters={filtersInput} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </MapCardFiltersModalContext.Provider>
  )
}

export default CardFiltersModal
